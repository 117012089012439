import { useAuth0 } from "@auth0/auth0-react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { BiLogOut } from "react-icons/bi";

export interface LockedAccountProps {}

export const LockedAccount = ({
  children,
}: PropsWithChildren<LockedAccountProps>) => {
  const { logout } = useAuth0();

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color='primary.500'
          display='flex'
          gap={2}
          alignItems='center'
        >
          <WarningTwoIcon color='red.400 ' /> Account Suspended
        </ModalHeader>
        <ModalBody>
          <VStack>
            {children}
            <p className=' text-sm text-primary-300'>
              Please contact support at{" "}
              <a className=' text-accent-500' href={"tel:+2349169853364"}>
                09169853364
              </a>{" "}
              for further assistance.
            </p>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            rightIcon={<Icon as={BiLogOut} />}
            mr={3}
            onClick={() => {
              logout({
                returnTo: window.location.origin,
              });
            }}
            size='sm'
          >
            Sign Out
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
