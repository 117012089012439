import StudyWorkAbroad from "../../assets/tracks/study-work-abroad.svg";
import LocalWork from "../../assets/tracks/local-work.svg";
import { ProTrack } from "../../types";

export const tracks: Record<
  ProTrack,
  { title: string; description: string; path: string; image: string }
> = {
  [ProTrack.STUDYWORK]: {
    title: "Study/Work Abroad",
    description:
      "LaborHack offers study and work opportunities to other countries.",
    path: "/study-work-abroad",
    image: StudyWorkAbroad,
  },
  [ProTrack.LOCALGIG]: {
    title: "Get Local Work",
    description:
      "Get matched with jobs available in your area and start earning.",
    path: "/trades",
    image: LocalWork,
  },
};
