import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

type QuickActionCardProps = {
	cta: string;
	ctaLink: string;
	description: string;
	title: string;
};
export const QuickActionCard = ({
	cta,
	ctaLink,
	description,
	title,
}: QuickActionCardProps) => {
	return (
		<div className="flex flex-col bg-[#FAFAFA] border-[1px] border-[#D2D2D2] p-4 items-start gap-4 lg:gap-6">
			<div className="bg-[#FEFEFE] border-[1px] border-[#DDDDDD] w-[40px] lg:w-[48px] h-[40px] lg:h-[48px] rounded-full flex items-center justify-center p-6"></div>
			<div className="flex flex-col gap-4">
				<h3 className="text-[#333333] text-base lg:text-lg font-medium">
					{title}
				</h3>
				<p className="text-[#464646] text-sm lg:text-base">{description}</p>
			</div>
			<div className="flex justify-center ">
				<Link to={ctaLink}>
					<div className="flex items-center gap-2">
						<p className="text-[#212121] text-sm lg:text-base font-medium">
							{cta}
						</p>
						<ChevronRight className="w-5 h-5" />
					</div>
				</Link>
			</div>
		</div>
	);
};
