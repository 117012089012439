import { useEffect } from "react";
import {
	Outlet,
	Route,
	Routes,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { HasApplicationForm } from "./components/HasApplicationForm";
import { Layout } from "./components/Layout";
import { RequireAuthentication } from "./components/RequireAuthentication";
import { CompleteProfileProvider } from "./contexts/complete-profile.context";
import Apply from "./pages/apply";
import { IdentityVerification } from "./pages/identity-verification";
import { Capture } from "./pages/identity-verification/capture";
import { IDUpload } from "./pages/identity-verification/id-upload";
import HowItWorks from "./pages/how-it-works";
import Login from "./pages/login";
import BackgroundCheck from "./pages/background-check";
import BankAccountInformation from "./pages/bank-account-information/";
import { ViewTrade } from "./pages/trades/ViewTrade";
import { ConfirmTradeLevelPayment } from "./pages/trades/ConfirmTradeLevelPayment";
import { Trades } from "./pages/trades/Trades";
import NotFound from "./pages/not-found/NotFound";
import CertificatePage from "./pages/certificates";
import TelegramSoftskillsPage from "./pages/telegram-token";
import SwitchTrackPage from "./pages/switch-track";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "./components/Loading";
import { TradeCompleteProfile } from "./pages/trades/complete-profile";
import { HasTradesProfileInformation } from "./features/trades/has-trades-profile-information";
import { RegionGuard } from "./components/region-guard";
import { trackRegions } from "./constants";
import { ConfirmTradeRequirementPayment } from "./pages/trades/confirm-trade-requirement-payment";
import { BasicApplicationGuard } from "./guards/basic-application.guard";
import { IdentityVerificationGuard } from "./guards/identity-verification.guard";
import { useToggles } from "./hooks/useToggles";
import { LayoutV2 } from "./components/LayoutV2";
import { ApplyV2 } from "./pages/apply/apply-v2";
import { ProWebsiteLayout } from "./components/ProWebsiteLayout";
import ProProfilePage from "./pages/my-portfolio";
import { AddJob } from "./pages/my-portfolio/add-job";
import { AddCertification } from "./pages/my-portfolio/add-certification";
import { NewDashboard } from "./pages/dashboard";
import { AddEducation } from "./pages/my-portfolio/add-education";
import { HasCompletedPortfolioRequirements } from "./guards/has-completed-portfolio-requirements.guard";
import ChatWithAInaPage from "./pages/my-portfolio/chat-with-AIna";

type CustomNavigateProps = {
	to: string;
};

export const CustomNavigate = ({ to }: CustomNavigateProps) => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(to);
	});

	return <></>;
};

function App() {
	const { isLoading } = useAuth0();

	const { isLoading: isLoadingToggles, toggles } = useToggles();

	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get("leadSource")) {
			localStorage.setItem("leadSource", searchParams.get("leadSource") || "");
		}

		if (searchParams.get("referredBy")) {
			localStorage.setItem("referredBy", searchParams.get("referredBy") || "");
		}

		if (searchParams.get("preferredTrack")) {
			localStorage.setItem(
				"preferredTrack",
				searchParams.get("preferredTrack") || ""
			);
		}
	}, [searchParams]);

	if (isLoading || isLoadingToggles) {
		return <Loading />;
	}

	return (
		<CompleteProfileProvider>
			<Routes>
				<Route
					path="/"
					element={
						<RequireAuthentication>
							<Outlet />
						</RequireAuthentication>
					}
				>
					{toggles.use_new_pro_frontend_layout && (
						<Route
							path="/my-portfolio"
							element={
								<HasApplicationForm>
									<ProWebsiteLayout />
								</HasApplicationForm>
							}
						>
							<Route path="add-job" element={<AddJob />}></Route>
							<Route
								path="add-certification"
								element={<AddCertification />}
							></Route>
							<Route path="add-education" element={<AddEducation />}></Route>
							<Route
								path="chat-with-aina"
								element={<ChatWithAInaPage />}
							></Route>
							<Route index element={<ProProfilePage />}></Route>
						</Route>
					)}
					<Route
						path="/"
						element={
							toggles.use_new_pro_frontend_layout ? <LayoutV2 /> : <Layout />
						}
					>
						<Route
							path="/apply"
							element={
								toggles.use_new_pro_frontend_layout ? <ApplyV2 /> : <Apply />
							}
						/>
						<Route
							element={
								<HasApplicationForm>
									{toggles.use_new_pro_frontend_layout ? (
										<HasCompletedPortfolioRequirements>
											<Outlet />
										</HasCompletedPortfolioRequirements>
									) : (
										<Outlet />
									)}
								</HasApplicationForm>
							}
						>
							<Route
								index
								element={
									toggles.use_new_pro_frontend_layout ? (
										<NewDashboard />
									) : (
										<CustomNavigate to="/switch-track" />
									)
								}
							/>
							<Route path="/dashboard" element={<CustomNavigate to="/" />} />
							<Route
								path="/identity-verification"
								element={<IdentityVerification />}
							/>
							<Route
								path="/background-check"
								element={
									<RegionGuard regions={trackRegions.LOCALGIG}>
										<BackgroundCheck />
									</RegionGuard>
								}
							/>
							<Route
								path="/bank-account-information"
								element={
									<RegionGuard regions={trackRegions.LOCALGIG}>
										<BankAccountInformation />
									</RegionGuard>
								}
							/>
							<Route
								path="/complete-profile/id-upload"
								element={<IDUpload />}
							/>
							<Route path="/complete-profile/capture" element={<Capture />} />
							<Route path="/how-it-works" element={<HowItWorks />} />

							<Route
								path="/trades"
								element={
									<BasicApplicationGuard>
										<RegionGuard regions={trackRegions.LOCALGIG}>
											<Outlet />
										</RegionGuard>
									</BasicApplicationGuard>
								}
							>
								<Route
									path="/trades/complete-profile"
									element={<TradeCompleteProfile />}
								/>
								<Route
									path=""
									element={
										<HasTradesProfileInformation>
											<Outlet />
										</HasTradesProfileInformation>
									}
								>
									<Route index element={<Trades />} />
									<Route
										path="confirmation/:proTradeRequirementPaymentId"
										element={<ConfirmTradeRequirementPayment />}
									/>
									<Route path=":tradeId" element={<ViewTrade />} />
									<Route
										path=":tradeId/confirmation/:levelPaymentId"
										element={<ConfirmTradeLevelPayment />}
									/>
									<Route path="*" element={<div>Trade 404</div>} />
								</Route>
							</Route>

							<Route
								path="/certificates"
								element={
									<RegionGuard regions={trackRegions.LOCALGIG}>
										<HasTradesProfileInformation>
											<Outlet />
										</HasTradesProfileInformation>
									</RegionGuard>
								}
							>
								<Route index element={<CertificatePage />} />
							</Route>
							<Route path="/switch-track" element={<SwitchTrackPage />} />
						</Route>
					</Route>
				</Route>

				<Route path="/login" element={<Login />} />

				<Route path="/telegram-token">
					<Route
						index
						element={
							<RequireAuthentication>
								<BasicApplicationGuard>
									<IdentityVerificationGuard>
										<TelegramSoftskillsPage />
									</IdentityVerificationGuard>
								</BasicApplicationGuard>
							</RequireAuthentication>
						}
					/>
				</Route>

				<Route path="*" element={<NotFound />} />
			</Routes>
		</CompleteProfileProvider>
	);
}

export default App;
