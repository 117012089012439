import { Alert, AlertIcon } from "@chakra-ui/react";
interface BannerProps {
  isVisible: boolean;
  message: JSX.Element | string;
}

const Banner: React.FC<BannerProps> = ({ isVisible, message }) => {
  return isVisible ? (
    <Alert
      status="warning"
      mb="4"
    >
      <AlertIcon />
      {message}
    </Alert>
  ) : null;
};

export default Banner;
