import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import { RequestFailed } from "../components/RequestFailed";
import { useProApplication } from "../hooks/useProApplication";

export const BasicApplicationGuard = ({ children }: PropsWithChildren) => {
  const { loading, error, proApplication } = useProApplication();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <RequestFailed />;
  }

  if (!proApplication) {
    return <Navigate to='/apply' />;
  }

  return <>{children}</>;
};
