import {
	ArrowBackIcon,
	ArrowForwardIcon,
	CheckCircleIcon,
} from "@chakra-ui/icons";
import { Button, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import clsx from "clsx";
import { FastForward } from "lucide-react";
import { useRef, useState } from "react";
import Slider from "react-slick";
import WorkHistoryGIF from "../../assets/portfolio/work-history.gif";
import ProfileGenerationGIF from "../../assets/portfolio/profile-generation.gif";
import ShareGIF from "../../assets/portfolio/share.gif";
import BadgesGIF from "../../assets/portfolio/badges.gif";
import WelcomePNG from "../../assets/portfolio/welcome.png";
import StartYourJourneyGIF from "../../assets/portfolio/start-your-journey.gif";
import { logEvent } from "../../utlis/analytics";

const slides = [
	{
		title: "Welcome to Your Portfolio Editor",
		description:
			"Create a stunning portfolio to showcase your skills and accomplishments. This editor is designed to help artisans like you stand out and attract clients.",
		media: WelcomePNG,
	},
	{
		title: "Keep Track of Your Work History",
		description:
			"Document the amazing work you've done and share it with the world. Add detailed descriptions and images to enrich your portfolio and build client confidence.",
		media: WorkHistoryGIF,
	},
	{
		title: "AI-Powered Profile Creation",
		description:
			"With just a click, let AI create a professional profile for you, based on your work history and additional details. It's quick, easy, and impactful.",
		media: ProfileGenerationGIF,
	},
	{
		title: "Strengthen Your Profile with Badges",
		description:
			"Earn badges by completing different milestones and tasks. Badges help you stand out and build credibility with clients.",
		media: BadgesGIF,
	},
	{
		title: "Share Your Work with the World",
		description:
			"Once your portfolio is ready, click the 'Share Profile' button. Share it with prospective clients and let your work speak for itself.",
		media: ShareGIF,
	},
	{
		title: "Start Showcasing Your Talent",
		description:
			"You've learned what's possible. Now it's time to put it all together. Start building your portfolio and showcasing your skills!",
		media: StartYourJourneyGIF,
	},
];

export interface PortfolioWalkthroughProps {
	onClose: () => void;
}

export const PortfolioWalkthrough = (props: PortfolioWalkthroughProps) => {
	let sliderRef: any = useRef(null);

	const [activeSlide, setActiveSlide] = useState(0);

	const handleNext = () => {
		sliderRef.slickNext();
	};

	const handlePrev = () => {
		sliderRef.slickPrev();
	};

	const handleGoToSlide = (index: number) => {
		sliderRef.slickGoTo(index);
	};

	const settings = {
		dots: true,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		arrows: false,
		beforeChange: (current: number, next: number) => {
			setActiveSlide(next);
		},
	};

	const isStartingSlide = activeSlide === 0;
	const isIntermediateSlide =
		activeSlide > 0 && activeSlide < slides.length - 1;
	const isLastSlide = activeSlide === slides.length - 1;

	return (
		<Modal isOpen={true} onClose={() => {}} isCentered size="xl">
			<ModalOverlay />
			<ModalContent rounded="none">
				<div className="flex flex-col py-4 px-4 gap-8 w-full">
					<div className="flex justify-end">
						<Button
							rounded="none"
							variant="ghost"
							rightIcon={<FastForward size={16} />}
							isDisabled={isLastSlide}
							onClick={() => {
								logEvent("pro_portfolio/walkthrough_skip_all");
								handleGoToSlide(slides.length - 1);
							}}
						>
							Skip
						</Button>
					</div>
					<div className="py-2">
						<Slider ref={(slider) => (sliderRef = slider)} {...settings}>
							{slides.map((slide, index) => (
								<div key={index}>
									<div className="flex flex-col gap-8">
										<div className="flex justify-center">
											<img src={slide.media} alt={slide.title} />
										</div>
										<div className="flex flex-col gap-2">
											<h3 className="text-xl font-semibold font-[AeonikPro]">
												{slide.title}
											</h3>
											<p className="text-sm">{slide.description}</p>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
					<div
						className={clsx(
							"flex w-full",
							isStartingSlide ? " justify-end" : "justify-between"
						)}
					>
						{!isStartingSlide && (
							<Button
								onClick={() => {
									logEvent("pro_portfolio/walkthrough_previous", {
										current_slide: activeSlide,
									});
									handlePrev();
								}}
								leftIcon={<ArrowBackIcon w={5} h={5} />}
							>
								Previous
							</Button>
						)}
						{isStartingSlide && (
							<Button
								colorScheme={"brand"}
								onClick={() => {
									logEvent("pro_portfolio/walkthrough_next", {
										current_slide: activeSlide,
									});
									handleNext();
								}}
								rightIcon={<ArrowForwardIcon w={5} h={5} />}
							>
								Let's Go
							</Button>
						)}
						{isIntermediateSlide && (
							<Button
								colorScheme={"brand"}
								onClick={() => {
									logEvent("pro_portfolio/walkthrough_next", {
										current_slide: activeSlide,
									});
									handleNext();
								}}
								rightIcon={<ArrowForwardIcon w={5} h={5} />}
							>
								Next
							</Button>
						)}
						{isLastSlide && (
							<Button
								colorScheme={"brand"}
								onClick={() => {
									logEvent("pro_portfolio/walkthrough_completed");

									props.onClose();
								}}
								rightIcon={<CheckCircleIcon w={5} h={5} />}
							>
								Get Started
							</Button>
						)}
					</div>
				</div>
			</ModalContent>
		</Modal>
	);
};
