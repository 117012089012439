import React, { PropsWithChildren } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputLeftAddon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Textarea,
} from "@chakra-ui/react";
import { Upload } from "lucide-react";
import { useToggles } from "src/hooks/useToggles";
import { useMutation } from "@apollo/client";
import { isBefore } from "date-fns";
import { ADD_WORK_HISTORY } from "src/pages/my-portfolio/add-job";

const formSchema = z.object({
	taskName: z.string().min(3),
	taskDescription: z.string().min(10),
	budget: z.number().min(1).optional(),
	date: z
		.string()
		.transform((value) => {
			if (!value) return null;
			return new Date(value);
		})
		.refine((value) => {
			if (!value) return true;
			return isBefore(value, new Date());
		}, "Date must be today or in the past"),
});

interface WorkHistoryCreatorProps {
	onClose: () => void;
}

export const WorkHistoryCreator = (
	props: PropsWithChildren<WorkHistoryCreatorProps>
) => {
	const { toggles } = useToggles();

	const [addWorkHistoryItem, addWorkHistoryItemHandle] = useMutation(
		ADD_WORK_HISTORY,
		{
			onCompleted: () => {
				props.onClose();
			},
			refetchQueries: ["ProPortfolio"],
			awaitRefetchQueries: true,
		}
	);

	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		addWorkHistoryItem({
			variables: {
				name: data.taskName,
				description: data.taskDescription,
				budget: data.budget,
				startedAt: null,
				images: [],
				videos: [],
				endedAt: data.date,
			},
		});
	};

	return (
		<div className="flex flex-col gap-4 w-full">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-4">
						<FormControl isInvalid={!!errors.taskName} isRequired>
							<FormLabel htmlFor="firstName">Project Name</FormLabel>
							<Input
								id="taskName"
								placeholder="Example: 2HP Split AC Installation"
								{...register("taskName")}
								variant="outline"
							/>
							<FormErrorMessage>{errors.taskName?.message}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!errors.taskDescription} isRequired>
							<FormLabel htmlFor="taskDescription">
								Project Description
							</FormLabel>
							<Textarea
								id="taskDescription"
								placeholder="Example: I installed a 2HP split AC in the second floor living room."
								{...register("taskDescription")}
							/>
							<FormErrorMessage>
								{errors.taskDescription?.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!errors.date} isRequired>
							<FormLabel htmlFor="taskDate">Completion Date</FormLabel>
							<Input
								id="taskDate"
								{...register("date")}
								type="date"
								variant="outline"
							/>
							<FormErrorMessage>{errors.date?.message}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!errors.budget}>
							<FormLabel htmlFor="budget">Project Budget</FormLabel>
							<InputGroup>
								<InputLeftAddon>₦</InputLeftAddon>
								<NumberInput
									className="w-full"
									onChange={(_, value) => {
										setValue("budget", value);
									}}
									value={watch("budget")}
									step={1000}
								>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</InputGroup>
							<FormErrorMessage>{errors.budget?.message}</FormErrorMessage>
						</FormControl>
					</div>

					{toggles?.enable_pro_portfolio_media_upload && (
						<div className="flex flex-col items-start gap-2">
							<div className="flex flex-col gap-1">
								<Heading as="h4" className="!text-xl !font-medium">
									Media
								</Heading>
								<p className="text-sm text-gray-600">
									Add images and/or videos to show your prospective clients what
									you can do
								</p>
							</div>

							<Box
								as="button"
								transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
								border="1px"
								p={8}
								borderRadius="2px"
								fontWeight="semibold"
								bg="#f5f6f7"
								borderColor="#ccd0d5"
								color="#4b4f56"
								_hover={{ bg: "#ebedf0" }}
								_active={{
									bg: "#dddfe2",
									transform: "scale(0.98)",
									borderColor: "#bec3c9",
								}}
								_focus={{
									boxShadow:
										"0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
								}}
								type="button"
							>
								<Upload className="w-8 h-8" />
							</Box>
						</div>
					)}
					<div className="flex justify-end">
						<Button
							colorScheme="brand"
							size="lg"
							type="submit"
							isLoading={addWorkHistoryItemHandle.loading}
						>
							Save
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};
