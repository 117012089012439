import React from "react";
import {
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
import { IdentityVerificationIssue } from "../__generated__/graphql";

interface Props {
	issues: IdentityVerificationIssue[];
}

const IdentityVerificationDeclined: React.FC<Props> = ({ issues }) => {
	return (
		<Alert
			status="error"
			variant="subtle"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			textAlign="center"
			height="auto"
			mb={8}
			maxWidth="container.md"
		>
			<AlertIcon boxSize="40px" mr={0} />
			<AlertTitle mt={4} mb={1} fontSize="lg">
				Identity verification failed: Please add a valid identification
			</AlertTitle>
			<p>Your previous submission was declined due to the following reasons:</p>
			<AlertDescription maxWidth="md">
				{issues.map((issue, index) => (
					<p key={index}>
						{issue.message} ({issue.code})
					</p>
				))}
			</AlertDescription>
		</Alert>
	);
};

export default IdentityVerificationDeclined;
