import { gql } from "@apollo/client";

export const SUBMIT_APPLICATION = gql`
	mutation SubmitApplication($input: SubmitProApplicationInput!) {
		submitProApplication(input: $input) {
			id
			handle
		}
	}
`;
