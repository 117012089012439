import { gql, useMutation } from "@apollo/client";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Loading } from "../../../components/Loading";
import { RequestFailed } from "../../../components/RequestFailed";
import { TradesCompleteProfileSuccess } from "../../../features/trades/trades-complete-profile-success";
import { useCategories } from "../../../hooks/useCategories";
import { IncomeLevel, TrainingType, YearsOfExperience } from "../../../types";
import { logEvent } from "../../../utlis/analytics";
import {
  experienceLevelOptions,
  incomeLevelOptions,
  trainingTypeOptions,
} from "./constants";

const UPDATE_PRO_APPLICATION = gql`
  mutation UpdateMyProApplication(
    $categoryId: String
    $yearsOfExperience: YearsOfExperience
    $trainingType: TrainingType
    $incomeLevel: IncomeLevel
    $numberOfDaysWorkedPerWeek: Float
    $otherCategoryText: String
  ) {
    updateMyProApplication(
      categoryId: $categoryId
      yearsOfExperience: $yearsOfExperience
      trainingType: $trainingType
      incomeLevel: $incomeLevel
      numberOfDaysWorkedPerWeek: $numberOfDaysWorkedPerWeek
      otherCategoryText: $otherCategoryText
    ) {
      id
      categoryId
      yearsOfExperience
      trainingType
      incomeLevel
      numberOfDaysWorkedPerWeek
      otherCategoryText
    }
  }
`;

const formSchema = z
  .object({
    categoryId: z.preprocess(
      (val) => (val === "" ? undefined : val),
      z.string()
    ),
    otherCategoryText: z.preprocess(
      (val) => (val === "" ? undefined : val),
      z.string().optional()
    ),
    yearsOfExperience: z.preprocess(
      (val) => (val === "" ? undefined : val),
      z.nativeEnum(YearsOfExperience)
    ),
    trainingType: z.preprocess(
      (val) => (val === "" ? undefined : val),
      z.nativeEnum(TrainingType)
    ),
    incomeLevel: z.preprocess(
      (val) => (val === "" ? undefined : val),
      z.nativeEnum(IncomeLevel)
    ),
    numberOfDaysWorkedPerWeek: z.preprocess(
      (val) => (val === "" ? undefined : Number(val)),
      z.number()
    ),
  })
  .refine(
    (data) => {
      console.log(data);

      if (data.categoryId === "other") {
        return !!data.otherCategoryText;
      }

      return true;
    },
    {
      message: "This field is required",
      path: ["otherCategoryText"],
    }
  );

export const TradeCompleteProfile = () => {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  const {
    categories,
    loading: categoriesLoading,
    error: categoriesError,
  } = useCategories();

  const [updateProApplication, { loading, error, data, reset }] = useMutation(
    UPDATE_PRO_APPLICATION,
    {
      refetchQueries: ["GetMyProfile"],
      awaitRefetchQueries: true,
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const watchCategoryId = watch("categoryId");

  const isOtherCategory = watchCategoryId === "other";

  const otherCategory = categories?.find(
    (category) => category.proTitle === "Other"
  );

  const onSubmit = (data: any) => {
    const payload = {
      categoryId: isOtherCategory ? otherCategory?.id : data.categoryId,
      ...(isOtherCategory && { otherCategoryText: data.otherCategoryText }),
      yearsOfExperience: data.yearsOfExperience,
      trainingType: data.trainingType,
      incomeLevel: data.incomeLevel,
      numberOfDaysWorkedPerWeek: data.numberOfDaysWorkedPerWeek,
    };

    updateProApplication({
      variables: payload,
    });
  };

  if (categoriesLoading) {
    return <Loading />;
  }

  if (categoriesError) {
    return <RequestFailed text={categoriesError.message} />;
  }

  if (error) {
    return <RequestFailed text={error.message} onRetry={reset} />;
  }

  if (data) {
    return <TradesCompleteProfileSuccess />;
  }

  return (
    <>
      <Helmet>
        <title>Complete Gig Profile | LaborHack Pro</title>
      </Helmet>
      <div className='bg-white p-4 lg:p-8'>
        <h2 className='text-lg md:text-xl text-primary-500 font-bold mb-4 md:mb-8'>
          Complete Gig Profile
        </h2>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 w-full'>
          <div>
            <h3 className='font-semibold text-primary-500'>
              Professional Information
            </h3>
            <p className='text-sm text-primary-300'>
              Provide us with some information about your professional
              background
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} maxW='2xl'>
              <FormControl
                isInvalid={
                  errors.categoryId && (touchedFields.categoryId || isSubmitted)
                }
                isDisabled={loading}
              >
                <FormLabel>Trade</FormLabel>
                <Select {...register("categoryId")}>
                  <option value={""}>Select Option</option>
                  {categories
                    ?.filter((category) => category.proTitle !== "Other")
                    .map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.proTitle}
                        </option>
                      );
                    })}
                  <option value={"other"}>Other</option>
                </Select>
                <FormErrorMessage>
                  {errors.categoryId?.message}
                </FormErrorMessage>
              </FormControl>
              {isOtherCategory && (
                <FormControl
                  isInvalid={
                    errors.otherCategoryText &&
                    (touchedFields.otherCategoryText || isSubmitted)
                  }
                  isDisabled={loading}
                >
                  <FormLabel>Please specify your trade</FormLabel>
                  <Input {...register("otherCategoryText")} />
                  <FormErrorMessage>
                    {errors.otherCategoryText?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl
                isInvalid={
                  errors.yearsOfExperience &&
                  (touchedFields.yearsOfExperience || isSubmitted)
                }
                isDisabled={loading}
              >
                <FormLabel>Experience Level</FormLabel>
                <Select {...register("yearsOfExperience")}>
                  <option value={""}>Select Option</option>
                  {experienceLevelOptions.map((option) => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.yearsOfExperience?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={
                  errors.trainingType &&
                  (touchedFields.trainingType || isSubmitted)
                }
                isDisabled={loading}
              >
                <FormLabel>How did you learn your trade?</FormLabel>
                <Select {...register("trainingType")}>
                  <option value={""}>Select Option</option>
                  {trainingTypeOptions.map((option) => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.trainingType?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  errors.incomeLevel &&
                  (touchedFields.incomeLevel || isSubmitted)
                }
                isDisabled={loading}
              >
                <FormLabel>
                  What is your current monthly income? (I do not earn less than
                  this monthly)
                </FormLabel>
                <Select {...register("incomeLevel")}>
                  <option value={""}>Select Option</option>
                  {incomeLevelOptions.map((option) => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.incomeLevel?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  errors.numberOfDaysWorkedPerWeek &&
                  (touchedFields.numberOfDaysWorkedPerWeek || isSubmitted)
                }
                isDisabled={loading}
              >
                <FormLabel>
                  On average, How many days do you work per week?
                </FormLabel>
                <Select {...register("numberOfDaysWorkedPerWeek")}>
                  <option value={""}>Select Option</option>
                  {[0, 1, 2, 3, 4, 5, 6, 7].map((value) => {
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.numberOfDaysWorkedPerWeek?.message}
                </FormErrorMessage>
              </FormControl>

              <HStack justify='end'>
                <Button
                  colorScheme='green'
                  type='submit'
                  rightIcon={<ArrowForwardIcon />}
                  isLoading={loading}
                >
                  Submit
                </Button>
              </HStack>
            </Stack>
          </form>
        </div>
      </div>
    </>
  );
};
