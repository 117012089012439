import { FaFolderOpen, FaPlaneDeparture } from "react-icons/fa";
import {
	GrCertificate,
	GrCircleQuestion,
	GrCurrency,
	GrDocumentText,
	GrHome,
	GrTools,
} from "react-icons/gr";
import { Industry, ProTrack } from "./types";
import { BiSolidHomeCircle, BiSolidMedal, BiSolidWallet } from "react-icons/bi";
import {
	GetMyProfileQuery,
	MeansOfIdentification,
} from "./__generated__/graphql";
import { largeCountriesData } from "./large-countries-data";

export const MENU: {
	sectionName: string;
	items: {
		name: string;
		url: string;
		icon: any;
		shouldRender: (
			profile?: GetMyProfileQuery["myProInformation"],
			toggles?: any
		) => boolean;
		shouldLock: (
			profile?: GetMyProfileQuery["myProInformation"],
			toggles?: any
		) => boolean;
	}[];
}[] = [
	{
		sectionName: "",
		items: [
			{
				name: "Dashboard",
				url: "/dashboard",
				icon: GrHome,
				shouldRender: (profile, toggles) => {
					if (toggles.use_pro_onboarding_levels_system) {
						return false;
					} else {
						return !!profile;
					}
				},
				shouldLock: (profile, toggles?: any) => {
					return !!profile;
				},
			},
		],
	},
	{
		sectionName: "Main Menu",
		items: [
			{
				name: "Registration",
				url: "/apply",
				icon: GrDocumentText,
				shouldRender: (profile, toggles?: any) => {
					return !profile;
				},
				shouldLock: (profile, toggles?: any) => {
					return !!profile;
				},
			},
			{
				name: "Trades",
				url: "/trades",
				icon: GrTools,
				shouldRender: (profile, toggles?: any) => {
					if (toggles.use_pro_onboarding_levels_system) {
						return !!profile && !!profile.identityVerification;
					}

					return false;
				},
				shouldLock: (profile, toggles?: any) => {
					return !profile;
				},
			},
			{
				name: "Study/Work Abroad",
				url: "/study-work-abroad",
				icon: FaPlaneDeparture,
				shouldRender: (profile, toggles?: any) => {
					return !!profile;
				},
				shouldLock: (profile, toggles?: any) => {
					return !profile;
				},
			},
		],
	},
	{
		sectionName: "Settings",
		items: [],
	},
	{
		sectionName: "Profile",
		items: [
			{
				name: "Certificates",
				url: "/certificates",
				icon: GrCertificate,
				shouldRender: (profile, toggles?: any) => {
					if (toggles.enable_certificates) {
						return !!profile && !!profile.identityVerification;
					}

					return false;
				},
				shouldLock: (profile, toggles?: any) => {
					return !profile;
				},
			},
			{
				name: "Background Check",
				url: "/background-check",
				icon: GrCircleQuestion,
				shouldRender: (profile) => {
					if (!profile?.proApplication?.country) {
						return false;
					}

					if (trackRegions.LOCALGIG.includes(profile.proApplication.country)) {
						return true;
					}

					return false;
				},
				shouldLock: (profile) => {
					return false;
				},
			},
			{
				name: "Bank Account Information",
				url: "/bank-account-information",
				icon: GrCurrency,
				shouldRender: (profile) => {
					if (!profile?.proApplication?.country) {
						return false;
					}

					if (trackRegions.LOCALGIG.includes(profile.proApplication.country)) {
						return true;
					}

					return false;
				},
				shouldLock: (profile) => {
					return false;
				},
			},
		],
	},
];

export const NAV_MENU: {
	name: string;
	url: string;
	icon: any;
	shouldRender: (
		profile?: GetMyProfileQuery["myProInformation"],
		toggles?: any
	) => boolean;
	shouldLock: (
		profile?: GetMyProfileQuery["myProInformation"],
		toggles?: any
	) => boolean;
}[] = [
	{
		name: "Dashboard",
		url: "/",
		icon: BiSolidHomeCircle,
		shouldRender: (profile, toggles?: any) => {
			return true;
		},
		shouldLock: (profile, toggles?: any) => {
			return !profile;
		},
	},
	{
		name: "My Portfolio",
		url: "/my-portfolio",
		icon: FaFolderOpen,
		shouldRender: (profile, toggles?: any) => {
			return true;
		},

		shouldLock: (profile, toggles?: any) => {
			return !profile;
		},
	},
	{
		name: "Certifications",
		url: "/certificates",
		icon: BiSolidMedal,
		shouldRender: (profile, toggles?: any) => {
			return true;
		},
		shouldLock: (profile, toggles?: any) => {
			return !profile;
		},
	},
	{
		name: "Wallet",
		url: "/wallet",
		icon: BiSolidWallet,
		shouldRender: (profile, toggles?: any) => {
			return true;
		},
		shouldLock: (profile, toggles?: any) => {
			return !profile;
		},
	},
	{
		name: "Opportunities",
		url: "/opportunities",
		icon: BiSolidWallet,
		shouldRender: (profile, toggles?: any) => {
			return true;
		},
		shouldLock: (profile, toggles?: any) => {
			return !profile;
		},
	},
];

export const IDENTIFICATION_OPTIONS = [
	{
		label: "National ID Number (NIN)",
		value: MeansOfIdentification.Nin,
		regex: /^[0-9]{11}$/,
	},
	{
		label: "Driver's License",
		value: MeansOfIdentification.DriversLicense,
		regex: /^[a-zA-Z]{3}([ -]{1})?[A-Z0-9]{6,12}$/i,
	},
	{
		label: "Bank Verification Number (BVN)",
		value: MeansOfIdentification.Bvn,
		regex: /^[0-9]{11}$/,
	},
];

export const SELFIE_IMAGE_TYPE = 2;
export const ID_IMAGE_TYPE = 3;
export const LIVENESS_IMAGE_TYPE = 6;

export const LEVEL_ORDER_MARKETING_NAMES: Record<number, { name: string }> = {
	1: { name: "Star" },
	2: { name: "Master" },
	3: { name: "Expert" },
	4: { name: "Legend" },
};

/**
 * @deprecated use countriesData instead
 */
export const countries: readonly string[] = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia, Plurinational State of",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands",
	"Colombia",
	"Comoros",
	"Congo",
	"Congo, The Democratic Republic of the",
	"Cook Islands",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (Malvinas)",
	"Faroe Islands",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (Vatican City State)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran, Islamic Republic of",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea, Democratic People's Republic of",
	"Korea, Republic of",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia, Federated States of",
	"Moldova, Republic of",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"North Macedonia",
	"Northern Mariana Islands",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Romania",
	"Russian Federation",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan, Province of China",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom",
	"United States",
	"United States Minor Outlying Islands",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela, Bolivarian Republic of",
	"Viet Nam",
	"Virgin Islands, British",
	"Virgin Islands, U.S.",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands",
];

export const IndustryTitles: { [key in Industry]: string } = {
	AerospaceAndDefense: "Aerospace and Defense",
	AgricultureAndForestry: "Agriculture and Forestry",
	ArtsDesignAndFashion: "Arts, Design, and Fashion",
	Automotive: "Automotive",
	Construction: "Construction",
	Consulting: "Consulting",
	Education: "Education",
	EnergyAndUtilities: "Energy and Utilities",
	EnvironmentalServices: "Environmental Services",
	FinanceAndBanking: "Finance and Banking",
	FoodAndBeverage: "Food and Beverage",
	GovernmentAndPublicAdministration: "Government and Public Administration",
	Healthcare: "Healthcare",
	HospitalityAndTourism: "Hospitality and Tourism",
	TechnologyAndInformationServices: "IT Services",
	LawAndLegalServices: "Law and Legal Services",
	Manufacturing: "Manufacturing",
	MarketingAndAdvertising: "Marketing and Advertising",
	MediaAndEntertainment: "Media and Entertainment",
	NonprofitAndSocialServices: "Nonprofit and Social Services",
	PharmaceuticalAndBiotechnology: "Pharmaceutical and Biotechnology",
	RealEstate: "Real Estate",
	Retail: "Retail",
	Telecommunications: "Telecommunications",
	TransportationAndLogistics: "Transportation and Logistics",
	Other: "Other",
};

export const countriesData = largeCountriesData.map((country) => ({
	key: country['wikidata_id'],
	shortName: country['ISO3166-1-Alpha-2'],
	name: country.official_name_en,
	dialingCode: country.Dial?.toString(),
	nameAndDialingCode: `${country.official_name_en} (+${country.Dial})`,
	currency: country["ISO4217-currency_name"],
}));

/**
 * @deprecated use countriesData instead
 */
export const countriesWithDialingCodes = [
	{ countryCode: "DZ", value: "213", name: "Algeria (+213)" },
	{ countryCode: "AD", value: "376", name: "Andorra (+376)" },
	{ countryCode: "AO", value: "244", name: "Angola (+244)" },
	{ countryCode: "AI", value: "1264", name: "Anguilla (+1264)" },
	{ countryCode: "AG", value: "1268", name: "Antigua & Barbuda (+1268)" },
	{ countryCode: "AR", value: "54", name: "Argentina (+54)" },
	{ countryCode: "AM", value: "374", name: "Armenia (+374)" },
	{ countryCode: "AW", value: "297", name: "Aruba (+297)" },
	{ countryCode: "AU", value: "61", name: "Australia (+61)" },
	{ countryCode: "AT", value: "43", name: "Austria (+43)" },
	{ countryCode: "AZ", value: "994", name: "Azerbaijan (+994)" },
	{ countryCode: "BS", value: "1242", name: "Bahamas (+1242)" },
	{ countryCode: "BH", value: "973", name: "Bahrain (+973)" },
	{ countryCode: "BD", value: "880", name: "Bangladesh (+880)" },
	{ countryCode: "BB", value: "1246", name: "Barbados (+1246)" },
	{ countryCode: "BY", value: "375", name: "Belarus (+375)" },
	{ countryCode: "BE", value: "32", name: "Belgium (+32)" },
	{ countryCode: "BZ", value: "501", name: "Belize (+501)" },
	{ countryCode: "BJ", value: "229", name: "Benin (+229)" },
	{ countryCode: "BM", value: "1441", name: "Bermuda (+1441)" },
	{ countryCode: "BT", value: "975", name: "Bhutan (+975)" },
	{ countryCode: "BO", value: "591", name: "Bolivia (+591)" },
	{ countryCode: "BA", value: "387", name: "Bosnia Herzegovina (+387)" },
	{ countryCode: "BW", value: "267", name: "Botswana (+267)" },
	{ countryCode: "BR", value: "55", name: "Brazil (+55)" },
	{ countryCode: "BN", value: "673", name: "Brunei (+673)" },
	{ countryCode: "BG", value: "359", name: "Bulgaria (+359)" },
	{ countryCode: "BF", value: "226", name: "Burkina Faso (+226)" },
	{ countryCode: "BI", value: "257", name: "Burundi (+257)" },
	{ countryCode: "KH", value: "855", name: "Cambodia (+855)" },
	{ countryCode: "CM", value: "237", name: "Cameroon (+237)" },
	{ countryCode: "CA", value: "1", name: "Canada (+1)" },
	{ countryCode: "CV", value: "238", name: "Cape Verde Islands (+238)" },
	{ countryCode: "KY", value: "1345", name: "Cayman Islands (+1345)" },
	{ countryCode: "CF", value: "236", name: "Central African Republic (+236)" },
	{ countryCode: "CL", value: "56", name: "Chile (+56)" },
	{ countryCode: "CN", value: "86", name: "China (+86)" },
	{ countryCode: "CO", value: "57", name: "Colombia (+57)" },
	{ countryCode: "KM", value: "269", name: "Comoros (+269)" },
	{ countryCode: "CG", value: "242", name: "Congo (+242)" },
	{ countryCode: "CK", value: "682", name: "Cook Islands (+682)" },
	{ countryCode: "CR", value: "506", name: "Costa Rica (+506)" },
	{ countryCode: "HR", value: "385", name: "Croatia (+385)" },
	{ countryCode: "CU", value: "53", name: "Cuba (+53)" },
	{ countryCode: "CY", value: "90392", name: "Cyprus North (+90392)" },
	{ countryCode: "CY", value: "357", name: "Cyprus South (+357)" },
	{ countryCode: "CZ", value: "420", name: "Czech Republic (+420)" },
	{ countryCode: "DK", value: "45", name: "Denmark (+45)" },
	{ countryCode: "DJ", value: "253", name: "Djibouti (+253)" },
	{ countryCode: "DM", value: "1809", name: "Dominica (+1809)" },
	{ countryCode: "DO", value: "1809", name: "Dominican Republic (+1809)" },
	{ countryCode: "EC", value: "593", name: "Ecuador (+593)" },
	{ countryCode: "EG", value: "20", name: "Egypt (+20)" },
	{ countryCode: "SV", value: "503", name: "El Salvador (+503)" },
	{ countryCode: "GQ", value: "240", name: "Equatorial Guinea (+240)" },
	{ countryCode: "ER", value: "291", name: "Eritrea (+291)" },
	{ countryCode: "EE", value: "372", name: "Estonia (+372)" },
	{ countryCode: "ET", value: "251", name: "Ethiopia (+251)" },
	{ countryCode: "FK", value: "500", name: "Falkland Islands (+500)" },
	{ countryCode: "FO", value: "298", name: "Faroe Islands (+298)" },
	{ countryCode: "FJ", value: "679", name: "Fiji (+679)" },
	{ countryCode: "FI", value: "358", name: "Finland (+358)" },
	{ countryCode: "FR", value: "33", name: "France (+33)" },
	{ countryCode: "GF", value: "594", name: "French Guiana (+594)" },
	{ countryCode: "PF", value: "689", name: "French Polynesia (+689)" },
	{ countryCode: "GA", value: "241", name: "Gabon (+241)" },
	{ countryCode: "GM", value: "220", name: "Gambia (+220)" },
	{ countryCode: "GE", value: "7880", name: "Georgia (+7880)" },
	{ countryCode: "DE", value: "49", name: "Germany (+49)" },
	{ countryCode: "GH", value: "233", name: "Ghana (+233)" },
	{ countryCode: "GI", value: "350", name: "Gibraltar (+350)" },
	{ countryCode: "GR", value: "30", name: "Greece (+30)" },
	{ countryCode: "GL", value: "299", name: "Greenland (+299)" },
	{ countryCode: "GD", value: "1473", name: "Grenada (+1473)" },
	{ countryCode: "GP", value: "590", name: "Guadeloupe (+590)" },
	{ countryCode: "GU", value: "671", name: "Guam (+671)" },
	{ countryCode: "GT", value: "502", name: "Guatemala (+502)" },
	{ countryCode: "GN", value: "224", name: "Guinea (+224)" },
	{ countryCode: "GW", value: "245", name: "Guinea - Bissau (+245)" },
	{ countryCode: "GY", value: "592", name: "Guyana (+592)" },
	{ countryCode: "HT", value: "509", name: "Haiti (+509)" },
	{ countryCode: "HN", value: "504", name: "Honduras (+504)" },
	{ countryCode: "HK", value: "852", name: "Hong Kong (+852)" },
	{ countryCode: "HU", value: "36", name: "Hungary (+36)" },
	{ countryCode: "IS", value: "354", name: "Iceland (+354)" },
	{ countryCode: "IN", value: "91", name: "India (+91)" },
	{ countryCode: "ID", value: "62", name: "Indonesia (+62)" },
	{ countryCode: "IR", value: "98", name: "Iran (+98)" },
	{ countryCode: "IQ", value: "964", name: "Iraq (+964)" },
	{ countryCode: "IE", value: "353", name: "Ireland (+353)" },
	{ countryCode: "IL", value: "972", name: "Israel (+972)" },
	{ countryCode: "IT", value: "39", name: "Italy (+39)" },
	{ countryCode: "JM", value: "1876", name: "Jamaica (+1876)" },
	{ countryCode: "JP", value: "81", name: "Japan (+81)" },
	{ countryCode: "JO", value: "962", name: "Jordan (+962)" },
	{ countryCode: "KZ", value: "7", name: "Kazakhstan (+7)" },
	{ countryCode: "KE", value: "254", name: "Kenya (+254)" },
	{ countryCode: "KI", value: "686", name: "Kiribati (+686)" },
	{ countryCode: "KP", value: "850", name: "Korea North (+850)" },
	{ countryCode: "KR", value: "82", name: "Korea South (+82)" },
	{ countryCode: "KW", value: "965", name: "Kuwait (+965)" },
	{ countryCode: "KG", value: "996", name: "Kyrgyzstan (+996)" },
	{ countryCode: "LA", value: "856", name: "Laos (+856)" },
	{ countryCode: "LV", value: "371", name: "Latvia (+371)" },
	{ countryCode: "LB", value: "961", name: "Lebanon (+961)" },
	{ countryCode: "LS", value: "266", name: "Lesotho (+266)" },
	{ countryCode: "LR", value: "231", name: "Liberia (+231)" },
	{ countryCode: "LY", value: "218", name: "Libya (+218)" },
	{ countryCode: "LI", value: "417", name: "Liechtenstein (+417)" },
	{ countryCode: "LT", value: "370", name: "Lithuania (+370)" },
	{ countryCode: "LU", value: "352", name: "Luxembourg (+352)" },
	{ countryCode: "MO", value: "853", name: "Macao (+853)" },
	{ countryCode: "MK", value: "389", name: "Macedonia (+389)" },
	{ countryCode: "MG", value: "261", name: "Madagascar (+261)" },
	{ countryCode: "MW", value: "265", name: "Malawi (+265)" },
	{ countryCode: "MY", value: "60", name: "Malaysia (+60)" },
	{ countryCode: "MV", value: "960", name: "Maldives (+960)" },
	{ countryCode: "ML", value: "223", name: "Mali (+223)" },
	{ countryCode: "MT", value: "356", name: "Malta (+356)" },
	{ countryCode: "MH", value: "692", name: "Marshall Islands (+692)" },
	{ countryCode: "MQ", value: "596", name: "Martinique (+596)" },
	{ countryCode: "MR", value: "222", name: "Mauritania (+222)" },
	{ countryCode: "YT", value: "269", name: "Mayotte (+269)" },
	{ countryCode: "MX", value: "52", name: "Mexico (+52)" },
	{ countryCode: "FM", value: "691", name: "Micronesia (+691)" },
	{ countryCode: "MD", value: "373", name: "Moldova (+373)" },
	{ countryCode: "MC", value: "377", name: "Monaco (+377)" },
	{ countryCode: "MN", value: "976", name: "Mongolia (+976)" },
	{ countryCode: "MS", value: "1664", name: "Montserrat (+1664)" },
	{ countryCode: "MA", value: "212", name: "Morocco (+212)" },
	{ countryCode: "MZ", value: "258", name: "Mozambique (+258)" },
	{ countryCode: "MN", value: "95", name: "Myanmar (+95)" },
	{ countryCode: "NA", value: "264", name: "Namibia (+264)" },
	{ countryCode: "NR", value: "674", name: "Nauru (+674)" },
	{ countryCode: "NP", value: "977", name: "Nepal (+977)" },
	{ countryCode: "NL", value: "31", name: "Netherlands (+31)" },
	{ countryCode: "NC", value: "687", name: "New Caledonia (+687)" },
	{ countryCode: "NZ", value: "64", name: "New Zealand (+64)" },
	{ countryCode: "NI", value: "505", name: "Nicaragua (+505)" },
	{ countryCode: "NE", value: "227", name: "Niger (+227)" },
	{ countryCode: "NG", value: "234", name: "Nigeria (+234)" },
	{ countryCode: "NU", value: "683", name: "Niue (+683)" },
	{ countryCode: "NF", value: "672", name: "Norfolk Islands (+672)" },
	{ countryCode: "NP", value: "670", name: "Northern Marianas (+670)" },
	{ countryCode: "NO", value: "47", name: "Norway (+47)" },
	{ countryCode: "OM", value: "968", name: "Oman (+968)" },
	{ countryCode: "PK", value: "92", name: "Pakistan (+92)" },
	{ countryCode: "PW", value: "680", name: "Palau (+680)" },
	{ countryCode: "PA", value: "507", name: "Panama (+507)" },
	{ countryCode: "PG", value: "675", name: "Papua New Guinea (+675)" },
	{ countryCode: "PY", value: "595", name: "Paraguay (+595)" },
	{ countryCode: "PE", value: "51", name: "Peru (+51)" },
	{ countryCode: "PH", value: "63", name: "Philippines (+63)" },
	{ countryCode: "PL", value: "48", name: "Poland (+48)" },
	{ countryCode: "PT", value: "351", name: "Portugal (+351)" },
	{ countryCode: "PR", value: "1787", name: "Puerto Rico (+1787)" },
	{ countryCode: "QA", value: "974", name: "Qatar (+974)" },
	{ countryCode: "RE", value: "262", name: "Reunion (+262)" },
	{ countryCode: "RO", value: "40", name: "Romania (+40)" },
	{ countryCode: "RU", value: "7", name: "Russia (+7)" },
	{ countryCode: "RW", value: "250", name: "Rwanda (+250)" },
	{ countryCode: "SM", value: "378", name: "San Marino (+378)" },
	{ countryCode: "ST", value: "239", name: "Sao Tome & Principe (+239)" },
	{ countryCode: "SA", value: "966", name: "Saudi Arabia (+966)" },
	{ countryCode: "SN", value: "221", name: "Senegal (+221)" },
	{ countryCode: "CS", value: "381", name: "Serbia (+381)" },
	{ countryCode: "SC", value: "248", name: "Seychelles (+248)" },
	{ countryCode: "SL", value: "232", name: "Sierra Leone (+232)" },
	{ countryCode: "SG", value: "65", name: "Singapore (+65)" },
	{ countryCode: "SK", value: "421", name: "Slovak Republic (+421)" },
	{ countryCode: "SI", value: "386", name: "Slovenia (+386)" },
	{ countryCode: "SB", value: "677", name: "Solomon Islands (+677)" },
	{ countryCode: "SO", value: "252", name: "Somalia (+252)" },
	{ countryCode: "ZA", value: "27", name: "South Africa (+27)" },
	{ countryCode: "ES", value: "34", name: "Spain (+34)" },
	{ countryCode: "LK", value: "94", name: "Sri Lanka (+94)" },
	{ countryCode: "SH", value: "290", name: "St. Helena (+290)" },
	{ countryCode: "KN", value: "1869", name: "St. Kitts (+1869)" },
	{ countryCode: "SC", value: "1758", name: "St. Lucia (+1758)" },
	{ countryCode: "SD", value: "249", name: "Sudan (+249)" },
	{ countryCode: "SR", value: "597", name: "Suriname (+597)" },
	{ countryCode: "SZ", value: "268", name: "Swaziland (+268)" },
	{ countryCode: "SE", value: "46", name: "Sweden (+46)" },
	{ countryCode: "CH", value: "41", name: "Switzerland (+41)" },
	{ countryCode: "SI", value: "963", name: "Syria (+963)" },
	{ countryCode: "TW", value: "886", name: "Taiwan (+886)" },
	{ countryCode: "TJ", value: "7", name: "Tajikstan (+7)" },
	{ countryCode: "TH", value: "66", name: "Thailand (+66)" },
	{ countryCode: "TG", value: "228", name: "Togo (+228)" },
	{ countryCode: "TO", value: "676", name: "Tonga (+676)" },
	{ countryCode: "TT", value: "1868", name: "Trinidad & Tobago (+1868)" },
	{ countryCode: "TN", value: "216", name: "Tunisia (+216)" },
	{ countryCode: "TR", value: "90", name: "Turkey (+90)" },
	{ countryCode: "TM", value: "7", name: "Turkmenistan (+7)" },
	{ countryCode: "TM", value: "993", name: "Turkmenistan (+993)" },
	{ countryCode: "TC", value: "1649", name: "Turks & Caicos Islands (+1649)" },
	{ countryCode: "TV", value: "688", name: "Tuvalu (+688)" },
	{ countryCode: "UG", value: "256", name: "Uganda (+256)" },
	{ countryCode: "UA", value: "380", name: "Ukraine (+380)" },
	{ countryCode: "AE", value: "971", name: "United Arab Emirates (+971)" },
	{ countryCode: "GB", value: "44", name: "United Kingdom (+44)" },
	{ countryCode: "US", value: "1", name: "United States (+1)" },
	{ countryCode: "UY", value: "598", name: "Uruguay (+598)" },
	{ countryCode: "UZ", value: "7", name: "Uzbekistan (+7)" },
	{ countryCode: "VU", value: "678", name: "Vanuatu (+678)" },
	{ countryCode: "VA", value: "379", name: "Vatican City (+379)" },
	{ countryCode: "VE", value: "58", name: "Venezuela (+58)" },
	{ countryCode: "VN", value: "84", name: "Vietnam (+84)" },
	{ countryCode: "WF", value: "681", name: "Wallis & Futuna (+681)" },
	{ countryCode: "YE", value: "967", name: "Yemen (+967)" },
	{ countryCode: "ZM", value: "260", name: "Zambia (+260)" },
	{ countryCode: "ZW", value: "263", name: "Zimbabwe (+263)" },
];

type TrackRegions = {
	[key in ProTrack]: string[];
};

export const trackRegions: TrackRegions = {
	[ProTrack.LOCALGIG]: ["Nigeria"],
	[ProTrack.STUDYWORK]: ["ALL"],
};
