import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
	AbsoluteCenter,
	Box,
	Button,
	Divider,
	Link,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	VStack,
} from "@chakra-ui/react";
import clsx from "clsx";
import { isBefore } from "date-fns";
import React, { useMemo, useState } from "react";
import { WorkHistoryItem } from "./work-history-item";
import { PortfolioWorkHistoryItem } from "../../types";
import { Plus } from "lucide-react";
import { WorkHistoryCreator } from "./work-history-creator";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { PortfolioSectionHeading } from "./portfolio-section-heading";
import { logEvent } from "src/utlis/analytics";
import { NothingToSeeYet } from "./nothing-to-see-yet";

const itemsPerPage = 5;

interface WorkHistoryProps {
	items: PortfolioWorkHistoryItem[];

	editable?: boolean;
}

const constructKey = (item: PortfolioWorkHistoryItem) => {
	return item.name + item.description + item.budget + item.verified;
};

const TOGGLE_PIN = gql(`
  mutation TogglePortfolioWorkHistoryItemPin($id: String!) {
    togglePortfolioWorkHistoryItemPin(id: $id) {
      id
      isPinned
    }
  }
`);

export function WorkHistory({
	items: unorderedItems,
	editable,
}: WorkHistoryProps) {
	const [showWorkHistoryCreateModal, setShowWorkHistoryCreateModal] =
		React.useState(false);

	const [togglePin, togglePinHandle] = useMutation(TOGGLE_PIN);

	const handlePinToggle = async (id?: string | null) => {
		if (!id) {
			return;
		}

		togglePin({
			variables: {
				id,
			},
		});

		logEvent("pro_portfolio/work_history_pin_toggle", { id });
	};

	const { pinnedItems, items } = useMemo(() => {
		const pinnedItems: PortfolioWorkHistoryItem[] = [];
		const rest: PortfolioWorkHistoryItem[] = [];

		unorderedItems.forEach((item) => {
			if (item.isPinned) {
				pinnedItems.push(item);
			} else {
				rest.push(item);
			}
		});

		const items = rest.slice().sort((a, b) => {
			if (editable) {
				/**
				 * Sort list by creation in edit mode
				 */

				return isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1;
			}

			const dateComparison = isBefore(new Date(a.endedAt), new Date(b.endedAt))
				? 1
				: -1;

			return dateComparison;
		});

		return { pinnedItems, items };
	}, [unorderedItems, editable]);

	const canPin = pinnedItems.length < 3;

	const [currentPage, setCurrentPage] = useState(1);

	const totalPages = Math.ceil(items.length / itemsPerPage);

	const handlePageChange = (page: number) => {
		logEvent("pro_portfolio/work_history_page_change", { page });
		setCurrentPage(page);
	};

	const currentPageItems = items.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	return (
		<>
			<Modal
				isOpen={showWorkHistoryCreateModal}
				onClose={() => setShowWorkHistoryCreateModal(false)}
				isCentered
				closeOnOverlayClick
				size="xl"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className="font-[AeonikPro] !text-2xl">
						Add Work History
					</ModalHeader>
					<Divider />
					<VStack py={4} px={6} alignItems="start">
						<WorkHistoryCreator
							onClose={() => {
								setShowWorkHistoryCreateModal(false);
								setCurrentPage(1);
							}}
						/>
					</VStack>
				</ModalContent>
			</Modal>
			<div className="flex flex-col gap-8 w-full">
				<div
					className={clsx("flex items-center w-full", {
						"justify-between": editable,
					})}
				>
					<PortfolioSectionHeading>Work History</PortfolioSectionHeading>

					{unorderedItems.length > 0 &&
						pinnedItems.length === 0 &&
						editable && (
							<Link
								href="/my-portfolio/add-job"
								className="flex items-center gap-2"
								textDecoration={"none"}
								_hover={{ textDecoration: "none" }}
							>
								<Button
									variant="outline"
									leftIcon={<Plus className="w-6 h-6" />}
									colorScheme="earthRed"
								>
									Add
								</Button>
							</Link>
						)}
				</div>
				<div className="flex flex-col gap-2">
					<div className="flex flex-col gap-4">
						{pinnedItems.map((item, index, array) => (
							<WorkHistoryItem
								key={constructKey(item)}
								item={item}
								className={clsx(index === array.length - 1 && "border-b-0")}
								editable={editable}
								pinned
								onPinToggle={() => handlePinToggle(item.id)}
								isLoading={togglePinHandle.loading}
							/>
						))}
					</div>
					{pinnedItems.length > 0 && (
						<Box position="relative" padding="10">
							<Divider />
							<AbsoluteCenter bg="white" px="4">
								All Work History
							</AbsoluteCenter>
						</Box>
					)}

					{editable && pinnedItems.length > 0 && (
						<div className="flex w-full items-center justify-end">
							<Link
								href="/my-portfolio/add-job"
								className="flex items-center gap-2"
								textDecoration={"none"}
								_hover={{ textDecoration: "none" }}
							>
								<Button
									variant="outline"
									leftIcon={<Plus className="w-6 h-6" />}
									colorScheme="earthRed"
								>
									Add
								</Button>
							</Link>
						</div>
					)}

					{unorderedItems.length === 0 && (
						<Stack
							border={"1px solid #C9C9C9"}
							p={8}
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							justifyContent={"center"}
							spacing={3}
							textAlign={"center"}
							w={"full"}
						>
							<NothingToSeeYet />
							<p className="text-[#464646] text-sm lg:text-base font-normal">
								Your job history is currently empty. Start adding completed jobs
								to build trust with clients.
							</p>
							<Link
								href="/my-portfolio/add-job"
								color={"prussianBlue.500"}
								fontSize={"sm"}
							>
								<p className="text-[#77372E] underline text-base lg:text-lg font-medium">
									Add a job
								</p>
							</Link>
						</Stack>
					)}

					<div className="flex flex-col gap-4">
						{currentPageItems.map((item, index, array) => (
							<WorkHistoryItem
								key={constructKey(item)}
								item={item}
								className={clsx(
									index === array.length - 1 && totalPages === 1 && "border-b-0"
								)}
								editable={editable}
								canPin={canPin}
								onPinToggle={() => handlePinToggle(item.id)}
								isLoading={togglePinHandle.loading}
							/>
						))}
					</div>
				</div>

				{totalPages > 1 && (
					<div className="flex justify-end items-center gap-4">
						<Button
							isDisabled={currentPage === 1}
							leftIcon={<ChevronLeftIcon />}
							onClick={() => handlePageChange(currentPage - 1)}
						>
							Previous
						</Button>

						<p>
							{currentPage} of {totalPages}
						</p>

						<Button
							isDisabled={currentPage === totalPages}
							rightIcon={<ChevronRightIcon />}
							onClick={() => handlePageChange(currentPage + 1)}
						>
							Next
						</Button>
					</div>
				)}
			</div>
		</>
	);
}
