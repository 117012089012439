import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { RequestFailed } from "../../components/RequestFailed";
import { useProApplication } from "../../hooks/useProApplication";

export const HasTradesProfileInformation = ({
  children,
}: PropsWithChildren) => {
  const { loading, error, proApplication } = useProApplication();

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    if (!proApplication?.categoryId || !proApplication?.yearsOfExperience) {
      navigate("/trades/complete-profile");
    }
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <RequestFailed text={error.message} />;
  }

  return <>{children}</>;
};
