import { Guarantor, Referee } from "src/types";
import { isValidIntlPhoneNumber } from "src/utlis/helpers";

export const isValidReferee = (
	referee: Referee,
	refereePhoneNumberPrefix?: string
): boolean => {
	return !!(
		referee.name &&
		isValidIntlPhoneNumber(
			`${refereePhoneNumberPrefix}${referee.phoneNumber}`
		) &&
		referee.email &&
		referee.companyName &&
		referee.companyAddress &&
		referee.jobTitle &&
		referee.relationship
	);
};

export const isValidGuarantor = (
	guarantor: Guarantor,
	guarantorPhoneNumberPrefix?: string
): boolean => {
	return !!(
		guarantor.name &&
		isValidIntlPhoneNumber(
			`${guarantorPhoneNumberPrefix}${guarantor.phoneNumber}`
		) &&
		guarantor.email &&
		guarantor.companyName &&
		guarantor.companyAddress &&
		guarantor.jobTitle &&
		guarantor.relationship
	);
};
