import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Select,
} from "@chakra-ui/react";
import { Check, Trash2 } from "lucide-react";
import { PropsWithChildren } from "react";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { EducationLevel, PortfolioEducation } from "../../types";

const EDIT_EDUCATION = gql(`
  mutation EditPortfolioEducation($id: String!, $schoolName: String, $fieldOfStudy: String, $qualification: String) {
  editPortfolioEducation(id: $id, schoolName: $schoolName, fieldOfStudy: $fieldOfStudy, qualification: $qualification) {
    createdAt
    fieldOfStudy
    id
    qualification
    schoolName
    verified
  }
}
`);

const REMOVE_EDUCATION = gql(`
  mutation RemovePortfolioEducation($id: String!) {
    removePortfolioEducation(id: $id) {
      id
    }
  }
`);

interface EducationEditorProps {
	onClose: () => void;
	item: PortfolioEducation & { id: string };
}

const formSchema = z.object({
	schoolName: z
		.string()
		.min(3, "School name is required and must be at least 3 characters"),
	fieldOfStudy: z
		.string()
		.min(3, "Field of study is required and must be at least 3 characters"),
	qualification: z.nativeEnum(EducationLevel).refine(
		(data) => {
			return !!data;
		},
		{
			message: "Qualification is required",
			path: [""],
		}
	),
});

export const EducationEditor = ({
	onClose,
	item,
}: PropsWithChildren<EducationEditorProps>) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const [editEducation, editEducationHandle] = useMutation(EDIT_EDUCATION);

	const [removeEducation, removeEducationHandle] = useMutation(
		REMOVE_EDUCATION,
		{
			refetchQueries: ["MyPortfolio"],
			awaitRefetchQueries: true,
		}
	);

	const loading = editEducationHandle.loading || removeEducationHandle.loading;

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		editEducation({
			variables: {
				id: item.id,
				...data,
			},
		});
	};

	const handleRemove = () => {
		removeEducation({
			variables: {
				id: item.id,
			},
		});
	};

	return (
		<div className="flex flex-col py-4 gap-4 w-full">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-4">
						<FormControl isInvalid={!!errors.schoolName}>
							<FormLabel>School Name</FormLabel>
							<Input
								{...register("schoolName")}
								placeholder="School Name"
								defaultValue={item.schoolName}
							/>
							<FormErrorMessage>{errors.schoolName?.message}</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={!!errors.qualification}>
							<FormLabel>Qualification</FormLabel>
							<Select
								id="qualification"
								{...register("qualification")}
								variant="outline"
								rounded={0}
								border={"1px solid #D7D7D7"}
								defaultValue={item.qualification}
							>
								<option value={EducationLevel.OND}>
									Ordinary National Diploma (OND)
								</option>
								<option value={EducationLevel.HND}>
									Higher National Diploma (HND)
								</option>
								<option value={EducationLevel.BACHELORS}>
									Bachelor's Degree (BSc)
								</option>
								<option value={EducationLevel.MASTERS}>
									Master's Degree (MSc)
								</option>
								<option value={EducationLevel.DOCTORATE}>
									Doctorate Degree (PhD)
								</option>
							</Select>
							<FormErrorMessage>
								{errors.qualification?.message}
							</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={!!errors.fieldOfStudy}>
							<FormLabel>Field of Study</FormLabel>
							<Input
								{...register("fieldOfStudy")}
								placeholder="Field of Study"
								defaultValue={item.fieldOfStudy}
							/>
							<FormErrorMessage>
								{errors.fieldOfStudy?.message}
							</FormErrorMessage>
						</FormControl>
					</div>

					<div className="flex justify-between">
						<Button
							colorScheme="red"
							variant="ghost"
							mr={3}
							onClick={handleRemove}
							rightIcon={<Trash2 className="w-5 h-5" />}
							isLoading={loading}
						>
							Remove
						</Button>
						<div>
							<Button
								variant="ghost"
								mr={3}
								onClick={onClose}
								isLoading={loading}
							>
								Close
							</Button>
							<Button
								type="submit"
								colorScheme="green"
								rightIcon={<Check className="w-5 h-5" />}
								isLoading={loading}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};
