import { useMutation } from "@apollo/client";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ADD_TRACK } from "../../common/graphql/mutation";
import { CMS } from "../../components/CMS";
import { RequestFailed } from "../../components/RequestFailed";
import { Pro, ProTrack } from "../../types";
import { logEvent } from "../../utlis/analytics";

export const HowItWorks = () => {
  const navigate = useNavigate();

  const [addTrack, { loading, error }] = useMutation<
    {
      addTrackToPro: Pro;
    },
    {
      track: ProTrack;
    }
  >(ADD_TRACK, {
    onCompleted: () => {
      navigate("/trades");
    },
  });

  useEffect(() => {
    logEvent("page_view");
    localStorage.setItem("howItWorksShown", "true");
  }, []);

  if (error) {
    return <RequestFailed />;
  }

  const handleAccept = () => {
    logEvent("local-gig-overview-understood");

    addTrack({
      variables: {
        track: ProTrack.LOCALGIG,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Local Gig Overview | LaborHack Pro Dashboard</title>
      </Helmet>
      <div>
        <CMS path='/new-pro-certifications' />
        <div className='w-full flex justify-end mt-8'>
          <Button
            isLoading={loading}
            colorScheme='green'
            variant='solid'
            rightIcon={<CheckCircleIcon />}
            onClick={handleAccept}
          >
            I understand
          </Button>
        </div>
      </div>
    </>
  );
};
