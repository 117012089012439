import { gql, useMutation } from "@apollo/client";
import { Button, Icon, Image, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { RequestFailed } from "../../components/RequestFailed";
import success from "../../assets/success.svg";

const CONFIRM_TRADE_REQUIREMENT_PAYMENT = gql`
  mutation ConfirmTradeRequirementPayment(
    $proTradeRequirementPaymentId: String!
  ) {
    confirmTradeRequirementPayment(
      proTradeRequirementPaymentId: $proTradeRequirementPaymentId
    ) {
      id
    }
  }
`;

export const ConfirmTradeRequirementPayment = () => {
  const { proTradeRequirementPaymentId } = useParams<{
    proTradeRequirementPaymentId: string;
  }>();

  const navigate = useNavigate();

  const [
    confirmTradeRequirementPayment,
    confirmTradeRequirementPaymentResponse,
  ] = useMutation(CONFIRM_TRADE_REQUIREMENT_PAYMENT);

  useEffect(() => {
    console.log("hey");
    confirmTradeRequirementPayment({
      variables: {
        proTradeRequirementPaymentId,
      },
    });
  }, [confirmTradeRequirementPayment, proTradeRequirementPaymentId]);

  if (confirmTradeRequirementPaymentResponse.loading) {
    return <Loading />;
  }

  if (confirmTradeRequirementPaymentResponse.error) {
    return <RequestFailed />;
  }

  return (
    <Stack gap={4} alignItems='center'>
      {confirmTradeRequirementPaymentResponse.data && (
        <Stack gap={4} alignItems='center'>
          <Text fontSize={"xl"} fontWeight='semibold'>
            Payment Confirmed
          </Text>
          <Image src={success} alt='Success' boxSize='150px' />
          <Text fontSize='sm' textAlign='center'>
            Thank you. You can now access the next level of this trade. Click
            the button below to go back to the trade.
          </Text>
        </Stack>
      )}
      <Stack direction='row'>
        <Button
          leftIcon={<Icon as={MdArrowBack} />}
          isLoading={confirmTradeRequirementPaymentResponse.loading}
          onClick={() => {
            navigate(`/trades`);
          }}
        >
          Back to Trades
        </Button>
      </Stack>
    </Stack>
  );
};
