import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  HStack,
  Link,
  VStack,
} from "@chakra-ui/react";
import community from "../assets/community.svg";
import { FaArrowRight, FaTelegramPlane } from "react-icons/fa";
import { useContext } from "react";
import { CompleteProfileContext } from "../contexts/complete-profile.context";
import usePopUpModal from "../hooks/usePopUpModal";
import { gql, useMutation } from "@apollo/client";

const UPDATE_PREFERENCES_MUTATION = gql`
  mutation UpdatePreferences($payload: PreferencesInput!) {
    updatePreferences(payload: $payload) {
      hasDisabledTelegramPopUp
    }
  }
`;

export const PopUpModal = () => {
  usePopUpModal();
  const { popUpModal, setPopUpModal } = useContext(CompleteProfileContext);
  const [updatePreferences] = useMutation(UPDATE_PREFERENCES_MUTATION, {
    onCompleted: () => {
      //
    },
  });

  if (!popUpModal.isVisible) {
    return null;
  }

  if (popUpModal.modalType === "JOIN_TELEGRAM_MODAL") {
    return (
      <>
        <Modal
          isCentered
          onClose={() => {
            setPopUpModal({
              ...popUpModal,
              isVisible: false,
            });
          }}
          isOpen={popUpModal.isVisible}
          size="md"
          motionPreset="scale"
        >
          <ModalOverlay bg="blackAlpha.700" />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <VStack w="full" bg="white" px="1" py="8" gap="8">
                <h1 className="text-2xl text-primary-500 font-semibold text-center">
                  Join the LaborHack community
                </h1>
                <img
                  className=" w-64"
                  src={community}
                  alt="a user holding a large phone with a checkmark on the screen"
                />
                <Box>
                  <p className="text-primary-300 my-2 text-center">
                    Stay updated on our latest news, updates, and announcements
                    by joining our Telegram group. Click the button below to
                    join. Please ignore if you are already in the the telegram
                    group.
                  </p>
                </Box>

                <HStack w="full" justify="center" marginTop={4}>
                  <Link href="https://t.me/+E5qyuEDJXbozMTE0" target="_blank">
                    <Button
                      colorScheme="telegram"
                      leftIcon={<FaTelegramPlane />}
                    >
                      Join
                    </Button>
                  </Link>

                  <Button
                    rightIcon={<FaArrowRight />}
                    onClick={() => {
                      setPopUpModal({
                        ...popUpModal,
                        isVisible: false,
                      });
                      updatePreferences({
                        variables: {
                          payload: { hasDisabledTelegramPopUp: true },
                        },
                      });
                    }}
                  >
                    Don't show this again
                  </Button>
                </HStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return null;
};
