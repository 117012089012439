import { CheckCircleIcon } from "@chakra-ui/icons";
import { Badge, HStack, Image, Text, VStack } from "@chakra-ui/react";

interface SwitchTrackCardProps {
  title: string;
  description: string;
  image: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

export const SwitchTrackCard = (props: SwitchTrackCardProps) => {
  const handleClick = () => {
    if (props.isDisabled) {
      return;
    }

    props.onClick();
  };

  return (
    <VStack
      opacity={props.isDisabled ? 0.5 : 1}
      position='relative'
      flex={1}
      px={6}
      py={8}
      border={
        props.isSelected
          ? "2px solid var(--chakra-colors-brand-500)"
          : "2px solid var(--chakra-colors-gray-200)"
      }
      gap={4}
      onClick={handleClick}
      cursor='pointer'
      maxW={{
        base: "full",
        md: "72",
      }}
    >
      {props.isDisabled && (
        <Badge className=' absolute top-2 right-2'>
          <Text fontSize='sm' color='primary.500'>
            Coming Soon
          </Text>
        </Badge>
      )}
      <CheckCircleIcon
        position='absolute'
        top={2}
        right={2}
        color={
          props.isSelected ? "var(--chakra-colors-brand-500)" : "transparent"
        }
        boxSize={6}
      />
      <VStack align='start' gap={8}>
        <HStack
          w='full'
          justify='center'
          h='24'
          display={{
            base: "none",
            md: "block",
          }}
        >
          <Image src={props.image} className='w-3/4' />
        </HStack>

        <VStack align='start' gap={1}>
          <Text fontWeight='semibold' color='primary.500'>
            {props.title}
          </Text>
          <Text fontSize='sm' color='primary.300'>
            {props.description}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};
