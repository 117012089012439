import { gql, useLazyQuery } from "@apollo/client";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { MdLink } from "react-icons/md";
import { ProTradeRequirementPayment } from "../../types";
import { formatAsNaira } from "../../utlis/helpers";

const GENERATE_PAYMENT_LINK = gql`
  query GenerateProTradeRequirementPaymentLink(
    $proTradeRequirementPaymentId: String!
  ) {
    generateProTradeRequirementPaymentLink(
      proTradeRequirementPaymentId: $proTradeRequirementPaymentId
    )
  }
`;

export interface TradeRequirementCheckoutProps {
  isOpen: boolean;
  onClose: () => void;
  proTradeRequirementPayment: ProTradeRequirementPayment;
}

export const TradeRequirementCheckout = ({
  isOpen,
  onClose,
  proTradeRequirementPayment,
}: TradeRequirementCheckoutProps) => {
  const { proTradeRequirement, discount } = proTradeRequirementPayment;

  const { requirement } = proTradeRequirement;

  const subTotal = requirement.price;

  const discountAmount = (subTotal * (discount?.value || 0)) / 100;

  const [generatePaymentLink, generatePaymentLinkResponse] = useLazyQuery<{
    generateProTradeRequirementPaymentLink: string;
  }>(GENERATE_PAYMENT_LINK, {
    onCompleted: (data) => {
      window.open(data.generateProTradeRequirementPaymentLink, "_self");
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='primary.500'>Checkout</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack p={4} spacing={8}>
            <Stack spacing={4}>
              <Stack align='start' key={requirement.id}>
                <Text color='primary.500' fontWeight='medium'>
                  {requirement.name}
                </Text>
                <Text fontSize='sm' color='primary.500'>
                  {formatAsNaira(requirement.price)}
                </Text>
              </Stack>
              {discount && (
                <Alert status='info' variant='top-accent' fontSize='sm'>
                  {/* <AlertIcon /> */}
                  <Box>
                    <AlertTitle>{discount.code}</AlertTitle>
                    <AlertDescription>{discount.description}</AlertDescription>
                  </Box>
                </Alert>
              )}
            </Stack>
            <TableContainer w='full'>
              <Table variant='simple' w='full' size='sm'>
                <Tbody>
                  <Tr>
                    <Td color='primary.300'>Sub Total</Td>
                    <Td color='primary.500' isNumeric>
                      {formatAsNaira(subTotal)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td color='primary.300'>
                      Discount ({discount?.value || 0}%)
                    </Td>
                    <Td color='primary.500' isNumeric>
                      {" "}
                      - {formatAsNaira(discountAmount)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td color='primary.500' fontSize='md' fontWeight='semibold'>
                      Total
                    </Td>
                    <Td
                      color='primary.500'
                      fontSize='md'
                      fontWeight='semibold'
                      isNumeric
                    >
                      {formatAsNaira(subTotal - discountAmount)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            <Stack direction='row' w='full' justify='end'>
              <Button
                isLoading={generatePaymentLinkResponse.loading}
                loadingText='Processing...'
                onClick={() => {
                  generatePaymentLink({
                    variables: {
                      proTradeRequirementPaymentId:
                        proTradeRequirementPayment.id,
                    },
                  });
                }}
                rightIcon={<Icon as={MdLink} />}
              >
                Pay Now
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
