import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Button, Link } from "@chakra-ui/react";
import { PropsWithChildren, useContext } from "react";
import { Outlet } from "react-router-dom";
import Banner from "./Banner";
import { CompleteProfileContext } from "../contexts/complete-profile.context";
import useBanner from "../hooks/useBanner";
import { PopUpModal } from "./PopUpModal";
import { SideNavV2 } from "./SideNavV2";
import { NavV2 } from "./NavV2";

export interface LayoutProps {}

export const LayoutV2 = ({ children }: PropsWithChildren<LayoutProps>) => {
	const { banners } = useContext(CompleteProfileContext);
	useBanner();

	return (
		<>
			<PopUpModal />
			<Link
				href="https://www.laborhack.com/contact"
				target="_blank"
				className="md:hidden"
			>
				<Button
					leftIcon={<QuestionOutlineIcon />}
					zIndex={999}
					position="fixed"
					bottom={"20px"}
					left={"20px"}
					colorScheme="teal"
					size="sm"
					fontWeight={400}
					boxShadow="lg"
					borderRadius={9999}
				>
					Need help?
				</Button>
			</Link>

			<div className="grid grid-cols-1 lg:grid-cols-[272px_1fr] w-full h-screen">
				<div className="hidden lg:block">
					{/* side bar here */}
					<SideNavV2 />
				</div>
				<div className="flex flex-col max-h-screen">
					{/* main content here */}

					{/* nav here */}
					<div className="">
						<NavV2 />
					</div>

					<main className="max-h-max overflow-y-auto flex-auto bg-[#FEFEFE] py-10 px-10 lg:px-14">
						{banners.map(({ isVisible, message }) => (
							<Banner isVisible={isVisible} message={message} />
						))}
						<Outlet />
					</main>
				</div>
			</div>
		</>
	);
};
