import {
	Button,
	Container,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Stack,
	Text,
	VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { SwitchTrackCard } from "../../features/switch-track/switch-track-card";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { RequestFailed } from "../../components/RequestFailed";
import { tracks } from "../../features/switch-track/constants";
import { useProfile } from "../../hooks/useProfile";
import { Pro, ProTrack } from "../../types";
import { Helmet } from "react-helmet";
import { ADD_TRACK } from "../../common/graphql/mutation";
import { useMutation } from "@apollo/client";
import { logEvent } from "../../utlis/analytics";
import { trackRegions } from "../../constants";
import { GetMyProfileQuery } from "../../__generated__/graphql";

const canAccess = (
	track: ProTrack,
	profile?: GetMyProfileQuery["myProInformation"]
) => {
	if (!profile?.proApplication?.country) return false;

	if (trackRegions[track][0] === "ALL") return true;

	return trackRegions[track].includes(profile.proApplication.country || "ALL");
};

export default function SwitchTrackPage() {
	const navigate = useNavigate();

	useEffect(() => {
		logEvent("page_view");
	}, []);

	const { profile, loading, error } = useProfile();

	/**
	 * Pull preferred track from local storage
	 *
	 * This is set if the a track was injected into the URL
	 */
	const preferredTrack = localStorage.getItem("preferredTrack") as
		| ProTrack
		| undefined;

	const [selectedTrack, setSelectedTrack] = useState<ProTrack | undefined>(
		preferredTrack
	);

	const [addTrack, addTrackResponse] = useMutation<
		{
			addTrackToPro: Pro;
		},
		{
			track: ProTrack;
		}
	>(ADD_TRACK, {
		onCompleted: () => {
			const redirect = localStorage.getItem("redirect");

			if (redirect) {
				localStorage.removeItem("redirect");
				return navigate(redirect);
			}

			navigate(tracks[selectedTrack!].path);
		},
	});

	useEffect(() => {
		if (loading) return;

		if (preferredTrack) {
			localStorage.removeItem("preferredTrack");
			addTrack({
				variables: {
					track: preferredTrack,
				},
			});
		} else if (profile?.tracks?.length === 1) {
			navigate(tracks[profile.tracks[0]].path);
		}
	}, [profile, loading, navigate, preferredTrack, addTrack]);

	if (loading || addTrackResponse.loading) {
		return <Loading />;
	}

	if (error || addTrackResponse.error) {
		return <RequestFailed />;
	}

	const handleSubmit = () => {
		if (!selectedTrack) return;

		addTrack({
			variables: {
				track: selectedTrack,
			},
		});
	};

	return (
		<>
			<Helmet>
				<title>Switch Track | LaborHack Pro</title>
			</Helmet>
			<Container maxW="container.xl" bgColor="white" paddingY="4">
				<Modal size="xl" isOpen={true} onClose={() => {}} isCentered>
					<ModalOverlay />
					<ModalContent>
						<ModalBody>
							<VStack align="start" py={4}>
								<Text
									fontSize="2xl"
									fontWeight="bold"
									className="!m-0"
									color="primary.500"
								>
									Choose Track
								</Text>
								<Text color="primary.300" fontSize="sm">
									We offer various tracks to help you get started on your
									journey with LaborHack. You can be on more than one track at
									the same time, and you can always switch to a different track
									if you want.
								</Text>
							</VStack>

							<Stack
								w="full"
								justify="center"
								direction={{
									base: "column",
									md: "row",
								}}
								gap={2}
								py={8}
							>
								{Object.entries(tracks).map(([id, track]) => {
									const isDisabled = !canAccess(id as ProTrack, profile);

									return (
										<SwitchTrackCard
											key={track.title}
											title={track.title}
											description={track.description}
											image={track.image}
											onClick={() => {
												setSelectedTrack(id as ProTrack);
											}}
											isSelected={selectedTrack === id}
											isDisabled={isDisabled}
										/>
									);
								})}
							</Stack>
						</ModalBody>
						<ModalFooter>
							<Button
								isLoading={addTrackResponse.loading}
								colorScheme="brand"
								isDisabled={!selectedTrack}
								rightIcon={<ArrowForwardIcon />}
								onClick={handleSubmit}
							>
								Go To Track
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</Container>
		</>
	);
}
