import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Radio,
	RadioGroup,
	Select,
	Stack,
	useToast,
} from "@chakra-ui/react";
import { Select as CRSelect } from "chakra-react-select";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { GrLock } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { countries } from "../constants";
import { useProfile } from "../hooks/useProfile";
import { Gender, ProLeadSource, ReasonForJoiningLaborHack } from "../types";
import { isDate } from "../utlis/helpers";
import { Loading } from "./Loading";
import { RequestFailed } from "./RequestFailed";
import {
	leadSourceOptions,
	reasonsForJoiningLaborHackOptions,
} from "src/pages/trades/complete-profile/constants";
import { SUBMIT_APPLICATION } from "src/pages/apply/constants";

const cleanPhoneNumber = (phoneNumberString: string) => {
	let cleaned = phoneNumberString.trim();
	// remove braces, dashes and spaces
	cleaned = cleaned.replace(/-|\s|\(|\)/g, "");

	if (cleaned.startsWith("0")) {
		cleaned = cleaned.replace(/^0/, "");
	}

	return cleaned;
};

export const isValidPhoneNumber = (phoneNumberString: string) => {
	const cleaned = cleanPhoneNumber(phoneNumberString);

	// Check if the cleaned number is 10 digits long
	if (cleaned.length === 10) {
		return true;
	}

	// If it's not 10 digits long, it's not a number.
	return false;
};

export interface ApplicationFormProps {}

export const ApplicationForm = () => {
	const toast = useToast();
	const { user, getAccessTokenSilently } = useAuth0();

	const navigate = useNavigate();

	const { profile, loading, error } = useProfile();

	/**
	 * Check if lead source exists in local storage
	 */
	const leadSourceFromLocalStorage = localStorage.getItem("leadSource");

	/**
	 * Check if local storage lead source is a valid lead source
	 */
	const localStorageLeadSourceIsValid =
		leadSourceFromLocalStorage &&
		Object.values(ProLeadSource).includes(
			leadSourceFromLocalStorage as ProLeadSource
		);

	/**
	 * Get referredBy from local storage
	 */
	const referredByFromLocalStorage = localStorage.getItem("referredBy");

	const [submitApplication, submitApplicationResponse] = useMutation(
		SUBMIT_APPLICATION,
		{
			onCompleted: () => {
				toast({
					title: "Application submitted",
					description: "Your application has been submitted successfully.",
					status: "success",
					duration: 9000,
					isClosable: true,
					position: "top",
					size: "sm",
				});

				if (user) {
					user.given_name = firstName;
					user.family_name = lastName;
				}

				getAccessTokenSilently()
					.then(() => {
						navigate("/switch-track");
					})
					.catch((error) => {
						/**
						 * Fallback to redirecting without token
						 *
						 * The browser reloads the page and the user is redirected to the switch track page
						 * which would go through the authentication process again
						 */
						window.open("/switch-track", "_self");
					});
			},
			refetchQueries: ["GetMyProApplication", "GetMyProfile"],
			awaitRefetchQueries: true,
		}
	);

	/** States to hold form data */
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phone, setPhone] = React.useState("");
	const [gender, setGender] = React.useState("");
	const [address, setAddress] = React.useState("");
	const [stateOfResidence, setStateOfResidence] = React.useState("");
	const [leadSource, setLeadSource] = React.useState<string>(
		localStorageLeadSourceIsValid
			? (leadSourceFromLocalStorage as ProLeadSource)
			: ""
	);
	const [otherLeadSource, setOtherLeadSource] = React.useState("");
	const [reasonForJoiningLaborHack, setReasonForJoiningLaborHack] =
		React.useState("");
	const [
		reasonForJoiningLaborHackOtherText,
		setReasonForJoiningLaborHackOtherText,
	] = React.useState("");
	const [dateOfBirth, setDateOfBirth] = React.useState<string>();
	const [country, setCountry] = React.useState("");
	const [showErrors, setShowErrors] = React.useState(false);

	useEffect(() => {
		if (profile) {
			setFirstName(profile.firstName);
			setLastName(profile.lastName);
			setPhone(profile.phoneNumber.replace("+234", ""));
		}
	}, [profile]);

	const getStringFieldChangeHandler = (
		handler: Dispatch<SetStateAction<string>>
	) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			handler(e.target.value);
		};
	};

	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(e.target.value.trim());
	};

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLastName(e.target.value.trim());
	};

	if (!user) {
		return <RequestFailed />;
	}

	if (loading) {
		return <Loading />;
	}

	if (error) {
		return <RequestFailed text={error.message} />;
	}

	if (submitApplicationResponse.error) {
		return (
			<RequestFailed
				text={submitApplicationResponse.error.message}
				onRetry={submitApplicationResponse.reset}
			/>
		);
	}

	/** Readonly fields */
	const emailIsReadonly = !!user.email;
	const phoneNumberIsReadonly = !!user.phone_number;

	const isValidDateOfBirth = dateOfBirth && isDate(dateOfBirth);

	const canSubmit =
		firstName &&
		!firstName.includes(" ") &&
		lastName &&
		!lastName.includes(" ") &&
		phone &&
		(!emailIsReadonly ? !!email : true) &&
		isValidDateOfBirth &&
		address &&
		stateOfResidence &&
		leadSource &&
		reasonForJoiningLaborHack &&
		country &&
		(leadSource === ProLeadSource.OTHER ? otherLeadSource : true) &&
		(reasonForJoiningLaborHack === ReasonForJoiningLaborHack.OTHER
			? reasonForJoiningLaborHackOtherText
			: true);

	const handleSubmit = () => {
		if (!canSubmit) {
			setShowErrors(true);
			return;
		}

		const input = {
			firstName,
			lastName,
			email: emailIsReadonly ? user.email : email,
			phoneNumber: phoneNumberIsReadonly
				? user.phone_number || profile?.phoneNumber
				: phone,
			dateOfBirth: new Date(dateOfBirth!).toISOString(),
			gender,
			address,
			stateOfResidence,
			country,
			leadSource,
			...(leadSource === ProLeadSource.OTHER && {
				leadSourceText: otherLeadSource,
			}),
			reasonForJoiningLaborHack,
			...(reasonForJoiningLaborHack === ReasonForJoiningLaborHack.OTHER && {
				reasonForJoiningLaborHackOtherText,
			}),
			referredBy: referredByFromLocalStorage,
		};

		submitApplication({ variables: { input } });

		localStorage.removeItem("leadSource");
		localStorage.removeItem("referredBy");
	};

	return (
		<div className="bg-white p-4 lg:p-8">
			<h2 className="text-lg md:text-xl text-primary-500 font-bold mb-4 md:mb-8">
				Registration Form
			</h2>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 w-full">
				<div>
					<h3 className="font-semibold text-primary-500">
						Personal Information
					</h3>
					<p className="text-sm text-primary-300">
						Provide your personal information
					</p>
				</div>
				<Stack spacing={2} className="px-2 md:px-4">
					<FormControl
						isDisabled={
							submitApplicationResponse.loading || !!profile?.firstName
						}
						isRequired
						isInvalid={(!firstName || firstName.includes(" ")) && showErrors}
					>
						<FormLabel fontSize={"sm"} htmlFor="firstName" color="primary.500">
							First Name
						</FormLabel>
						<Input
							id="firstName"
							fontSize={"sm"}
							type={"text"}
							value={firstName}
							onChange={handleFirstNameChange}
							placeholder="Tolu"
						/>
						{!firstName && showErrors && (
							<FormErrorMessage fontSize={"sm"}>
								First name is required
							</FormErrorMessage>
						)}
						{firstName.includes(" ") && showErrors && (
							<FormErrorMessage fontSize={"sm"}>
								First name cannot contain spaces
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl
						isDisabled={
							submitApplicationResponse.loading || !!profile?.lastName
						}
						isRequired
						isInvalid={(!lastName || lastName.includes(" ")) && showErrors}
					>
						<FormLabel fontSize={"sm"} htmlFor="lastName" color="primary.500">
							Last Name
						</FormLabel>
						<Input
							id="lastName"
							fontSize={"sm"}
							type={"text"}
							value={lastName}
							onChange={handleLastNameChange}
							placeholder="Holmes"
						/>
						{!lastName && showErrors && (
							<FormErrorMessage fontSize={"sm"}>
								Last name is required
							</FormErrorMessage>
						)}
						{lastName.includes(" ") && showErrors && (
							<FormErrorMessage fontSize={"sm"}>
								Last name cannot contain spaces
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl
						isDisabled={submitApplicationResponse.loading}
						isRequired
						isInvalid={showErrors && !isValidDateOfBirth}
						as="fieldset"
					>
						<FormLabel fontSize={"sm"} as="legend" color="primary.500">
							Date of Birth
						</FormLabel>
						<Input
							id="dateOfBirth"
							fontSize={"sm"}
							type={"date"}
							alignItems="center"
							value={dateOfBirth}
							max={new Date().toISOString().split("T")[0]}
							onChange={(event) => {
								setDateOfBirth(event.target.value);
							}}
						/>
						{showErrors && !isValidDateOfBirth && (
							<FormErrorMessage fontSize={"sm"}>
								Date of birth is required
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl
						isDisabled={submitApplicationResponse.loading}
						isRequired
						isInvalid={showErrors && !gender}
						as="fieldset"
					>
						<FormLabel fontSize={"sm"} as="legend">
							Sex
						</FormLabel>
						<RadioGroup
							colorScheme={"brand"}
							value={gender}
							onChange={setGender}
						>
							<Stack spacing={2}>
								<Radio size={"sm"} value={Gender.MALE}>
									Male
								</Radio>
								<Radio size={"sm"} value={Gender.FEMALE}>
									Female
								</Radio>
							</Stack>
						</RadioGroup>
						{showErrors && !gender && (
							<FormErrorMessage fontSize={"sm"}>
								Select a gender
							</FormErrorMessage>
						)}
					</FormControl>
				</Stack>
			</div>
			<div className="my-4 md:my-8 w-full h-0.5 bg-primary-100" />
			<div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 w-full ">
				<div>
					<h3 className="font-semibold text-primary-500">
						Contact Information
					</h3>
					<p className="text-sm text-primary-300">
						Provide your contact information
					</p>
				</div>
				<Stack spacing={2} className="px-2 md:px-4">
					<FormControl
						isRequired
						isReadOnly={emailIsReadonly}
						isDisabled={!!profile?.email}
					>
						<FormLabel fontSize={"sm"} htmlFor="email" color="primary.500">
							Email Address
						</FormLabel>
						<InputGroup>
							<Input
								id="email"
								type={"email"}
								fontSize={"sm"}
								value={user.email || email}
								onChange={getStringFieldChangeHandler(setEmail)}
							/>
							{emailIsReadonly && <InputRightElement children={<GrLock />} />}
						</InputGroup>
					</FormControl>
					<FormControl
						isRequired
						isReadOnly={phoneNumberIsReadonly}
						isDisabled={
							phoneNumberIsReadonly ||
							submitApplicationResponse.loading ||
							!!profile?.phoneNumber
						}
						isInvalid={showErrors && !phone}
					>
						<FormLabel fontSize={"sm"} htmlFor="phone" color="primary.500">
							Phone Number
						</FormLabel>
						<InputGroup>
							<Input
								id="phone"
								type={"phone"}
								fontSize={"sm"}
								value={user.phone_number || phone}
								onChange={getStringFieldChangeHandler(setPhone)}
								placeholder="+23480312345678"
							/>

							{phoneNumberIsReadonly && (
								<InputRightElement children={<GrLock />} />
							)}
						</InputGroup>
						{!phone && showErrors && (
							<FormErrorMessage>
								Please enter a valid phone number
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl
						isDisabled={submitApplicationResponse.loading}
						isRequired
						isInvalid={!address && showErrors}
					>
						<FormLabel fontSize={"sm"} htmlFor="address" color="primary.500">
							Address
						</FormLabel>
						<Input
							id="address"
							fontSize={"sm"}
							value={address}
							onChange={getStringFieldChangeHandler(setAddress)}
							placeholder="1234, Main Street"
						/>
						{!address && showErrors && (
							<FormErrorMessage fontSize={"sm"}>
								Address is required
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl
						isDisabled={submitApplicationResponse.loading}
						isRequired
						isInvalid={!stateOfResidence && showErrors}
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="stateOfResidence"
							color="primary.500"
						>
							State of Residence
						</FormLabel>
						<Input
							id="stateOfResidence"
							fontSize={"sm"}
							value={stateOfResidence}
							onChange={getStringFieldChangeHandler(setStateOfResidence)}
							placeholder="Lagos"
						/>
						{!stateOfResidence && showErrors && (
							<FormErrorMessage fontSize={"sm"}>
								State of residence is required
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl
						isDisabled={submitApplicationResponse.loading}
						isRequired
						isInvalid={!country && showErrors}
					>
						<FormLabel fontSize={"sm"} htmlFor="country" color="primary.500">
							Country
						</FormLabel>
						<CRSelect
							id="country"
							placeholder="Select option"
							options={countries.map((country) => ({
								label: country,
								value: country,
							}))}
							onChange={(selectedOption) => {
								if (selectedOption) setCountry(selectedOption.value);
							}}
						/>

						{!country && showErrors && (
							<FormErrorMessage fontSize={"sm"}>
								Please select your country
							</FormErrorMessage>
						)}
					</FormControl>
				</Stack>
			</div>
			<div className="my-4 md:my-8 w-full h-0.5 bg-primary-100" />
			<div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 w-full ">
				<div>
					<h3 className="font-semibold text-primary-500">Other Information</h3>
					<p className="text-sm text-primary-300">
						Provide other information about yourself
					</p>
				</div>
				<Stack spacing={2} className="px-2 md:px-4">
					{!localStorageLeadSourceIsValid && (
						<FormControl
							isDisabled={submitApplicationResponse.loading}
							isRequired
							isInvalid={!leadSource && showErrors}
						>
							<FormLabel
								fontSize={"sm"}
								htmlFor="leadSource"
								color="primary.500"
							>
								How did you hear about us?
							</FormLabel>
							<Select
								fontSize={"sm"}
								placeholder="Select option"
								value={leadSource}
								onChange={(event) => setLeadSource(event.target.value)}
							>
								{leadSourceOptions.map(({ label, value }) => {
									return (
										<option key={label} value={value}>
											{label}
										</option>
									);
								})}
							</Select>
							{!leadSource && showErrors && (
								<FormErrorMessage fontSize={"sm"}>
									Please select how you heard about us
								</FormErrorMessage>
							)}
						</FormControl>
					)}
					{leadSource === ProLeadSource.OTHER && (
						<FormControl
							isDisabled={submitApplicationResponse.loading}
							isRequired
							isInvalid={!otherLeadSource && showErrors}
						>
							<FormLabel
								fontSize={"sm"}
								htmlFor="leadSourceOther"
								color="primary.500"
							>
								Please specify how you heard about us
							</FormLabel>
							<Input
								id="leadSourceOther"
								fontSize={"sm"}
								value={otherLeadSource}
								onChange={getStringFieldChangeHandler(setOtherLeadSource)}
							/>
							{!otherLeadSource && showErrors && (
								<FormErrorMessage fontSize={"sm"}>
									Please specify how you heard about us
								</FormErrorMessage>
							)}
						</FormControl>
					)}

					<FormControl
						isDisabled={submitApplicationResponse.loading}
						isRequired
						isInvalid={!reasonForJoiningLaborHack && showErrors}
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="reasonForJoiningLaborHack"
							color="primary.500"
						>
							Why are you joining LaborHack?
						</FormLabel>
						<Select
							fontSize={"sm"}
							placeholder="Select option"
							value={reasonForJoiningLaborHack}
							onChange={(event) =>
								setReasonForJoiningLaborHack(event.target.value)
							}
						>
							{reasonsForJoiningLaborHackOptions.map(({ label, value }) => {
								return (
									<option key={label} value={value}>
										{label}
									</option>
								);
							})}
						</Select>
						{!reasonForJoiningLaborHack && showErrors && (
							<FormErrorMessage fontSize={"sm"}>
								Please select your reason for joining LaborHack
							</FormErrorMessage>
						)}
					</FormControl>
					{reasonForJoiningLaborHack === ReasonForJoiningLaborHack.OTHER && (
						<FormControl
							isDisabled={submitApplicationResponse.loading}
							isRequired
							isInvalid={!reasonForJoiningLaborHackOtherText && showErrors}
						>
							<FormLabel
								fontSize={"sm"}
								htmlFor="reasonForJoiningLaborHackOtherText"
								color="primary.500"
							>
								Please specify your reason for joining LaborHack
							</FormLabel>
							<Input
								id="reasonForJoiningLaborHackOtherText"
								fontSize={"sm"}
								value={reasonForJoiningLaborHackOtherText}
								onChange={getStringFieldChangeHandler(
									setReasonForJoiningLaborHackOtherText
								)}
							/>
							{!reasonForJoiningLaborHackOtherText && showErrors && (
								<FormErrorMessage fontSize={"sm"}>
									Please specify your reason for joining LaborHack
								</FormErrorMessage>
							)}
						</FormControl>
					)}
				</Stack>
			</div>
			<div className="my-4 md:my-8 w-full h-0.5 bg-primary-100" />
			<div className="flex justify-end">
				<Button
					fontSize={"sm"}
					colorScheme={"red"}
					variant="ghost"
					className="mr-2"
				>
					Cancel
				</Button>
				<Button
					isLoading={submitApplicationResponse.loading}
					loadingText="Submitting"
					fontSize={"sm"}
					colorScheme={"green"}
					variant={"solid"}
					spinnerPlacement="start"
					onClick={handleSubmit}
				>
					Submit Application
				</Button>
			</div>
		</div>
	);
};
