import { Button } from "@chakra-ui/react";
import React from "react";
import warning from "../assets/not-verified.svg";

export interface RequestFailedProps {
	onRetry?: () => void;
	text?: string;
}

export const RequestFailed = ({ text, onRetry }: RequestFailedProps) => {
	return (
		<div className="flex justify-center items-center my-8 mx-0">
			<div className="flex flex-col justify-center items-center max-w-sm">
				<h2 className="text-xl font-bold text-primary-500 mb-4">
					Something Went Wrong!
				</h2>
				<img
					className="w-80"
					src={warning}
					alt="construction worker holding a surrender flag"
				/>
				{text && <p className="text-primary-300 my-2 text-center">{text}</p>}

				{onRetry && (
					<div className=" flex w-full mt-4 justify-center">
						<Button variant="solid" onClick={onRetry}>
							Try Again
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
