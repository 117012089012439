import { gql, useQuery } from "@apollo/client";
import { BackgroundCheck } from "../types";

const GET_BACKGROUND_CHECK_BY_USER_ID = gql`
  query GetBackgroundCheck {
    getBackgroundCheckByUserId {
      id
      userId
      referees {
        name
        phoneNumber
        email
        companyName
        companyAddress
        jobTitle
        relationship
      }
      guarantors {
        name
        phoneNumber
        email
        companyName
        companyAddress
        jobTitle
        relationship
      }
      isComplete
    }
  }
`;

export const useBackGroundCheck = () => {
  const { loading, data, refetch } = useQuery<{
    getBackgroundCheckByUserId: BackgroundCheck;
  }>(GET_BACKGROUND_CHECK_BY_USER_ID, {
    fetchPolicy: "network-only",
  });

  return {
    loading,
    backgroundCheckData: data?.getBackgroundCheckByUserId,
    refetch,
  };
};
