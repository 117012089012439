import { gql, useQuery } from "@apollo/client";
import { PaymentProviderData } from "../types";

const GET_SAVED_BANK_ACCOUNT_INFO = gql`
  query GetSavedBankAccountInfo {
    paymentProviderInformation {
      userId
      defaultAccountNumber
      providerData {
        accountNumber
        providers {
          bankInformation {
            accountName
            accountNumber
            bankName
          }
        }
      }
    }
  }
`;

export const useBankAccountInfo = () => {
  const { loading, data, refetch } = useQuery<{
    paymentProviderInformation: {
      userId: string;
      defaultAccountNumber: string;
      providerData: any[];
    }
  }>(GET_SAVED_BANK_ACCOUNT_INFO, {
    fetchPolicy: "network-only"
  });

  const maskedAccountNumber =
    data?.paymentProviderInformation?.defaultAccountNumber;

  const bankInformation = data?.paymentProviderInformation?.providerData?.find(
    (data: PaymentProviderData) =>
      data.accountNumber === maskedAccountNumber
  ).providers[0]?.bankInformation;

  return {
    loading,
    hasProvidedBankAccountInfo: !!data?.paymentProviderInformation?.userId,
    maskedAccountNumber,
    accountName: bankInformation?.accountName,
    bankName: bankInformation?.bankName,
    refetchBankAccountInfo: refetch,
  };
};
