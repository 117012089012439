import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Link } from "@chakra-ui/react";
import clsx from "clsx";
import { isBefore } from "date-fns";
import React, { useMemo, useState } from "react";
import { logEvent } from "src/utlis/analytics";
import { PortfolioCertification } from "../../types";
import { CertificationItem } from "./certification-item";

const itemsPerPage = 5;

interface CertificationsProps {
	items: PortfolioCertification[];

	editable?: boolean;
}

const constructKey = (item: PortfolioCertification) => {
	return item.name + item.issuedBy + item.issuedAt + item.issuerCertificationId;
};

export function Certifications({
	items: unorderedItems,
	editable,
}: CertificationsProps) {
	const items = useMemo(() => {
		return unorderedItems.slice().sort((a, b) => {
			const dateComparison = isBefore(
				new Date(a.createdAt),
				new Date(b.createdAt)
			)
				? 1
				: -1;

			return dateComparison;
		});
	}, [unorderedItems]);

	const [currentPage, setCurrentPage] = useState(1);

	const totalPages = Math.ceil(items.length / itemsPerPage);

	const handlePageChange = (page: number) => {
		logEvent("pro_portfolio/certifications_page_change", { page });
		setCurrentPage(page);
	};

	const currentPageItems = items.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const hasAtLeastOneVerifiedCertification = items.some(
		(item) => item.verified
	);

	return (
		<div className="flex flex-col gap-8 w-full">
			{!hasAtLeastOneVerifiedCertification && (
				<div className="flex flex-col gap-4">
					<Link href="/certificates" color={"prussianBlue.500"} fontSize={"sm"}>
						<p className="text-[#77372E] underline text-sm lg:text-base font-medium">
							Get certified as a LaborHack Pro
						</p>
					</Link>
				</div>
			)}
			<div className="flex flex-col gap-2">
				<div className="flex flex-col gap-4">
					{currentPageItems.map((item, index, array) => (
						<CertificationItem
							key={constructKey(item)}
							item={item}
							className={clsx(
								index === array.length - 1 && totalPages === 1 && "border-b-0"
							)}
							editable={editable}
						/>
					))}
				</div>
			</div>

			{totalPages > 1 && (
				<div className="flex justify-end items-center gap-4">
					<Button
						isDisabled={currentPage === 1}
						leftIcon={<ChevronLeftIcon />}
						onClick={() => handlePageChange(currentPage - 1)}
					>
						Previous
					</Button>

					<p>
						{currentPage} of {totalPages}
					</p>

					<Button
						isDisabled={currentPage === totalPages}
						rightIcon={<ChevronRightIcon />}
						onClick={() => handlePageChange(currentPage + 1)}
					>
						Next
					</Button>
				</div>
			)}
		</div>
	);
}
