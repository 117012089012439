import {
	Divider,
	Heading,
	IconButton,
	useDisclosure,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Tag,
} from "@chakra-ui/react";
import clsx from "clsx";
import { format } from "date-fns";
import { Edit2, ShieldCheck } from "lucide-react";
import React from "react";
import { PortfolioCertification } from "../../types";
import { CertificationEditor } from "./certification-editor";

interface CertificationProps
	extends React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {
	item: PortfolioCertification;
	editable?: boolean;
}

export function CertificationItem({
	item,
	className,
	editable,
}: CertificationProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { name, issuedAt, issuedBy, issuerCertificationId, id, verified } =
		item;

	return (
		<>
			{!!id && (
				<Modal
					isOpen={isOpen}
					onClose={onClose}
					isCentered
					closeOnOverlayClick
					size="lg"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader className="font-[AeonikPro] !text-2xl">
							Edit Certification
						</ModalHeader>
						<Divider />
						<ModalBody>
							<CertificationEditor item={{ ...item, id }} onClose={onClose} />
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
			<div
				className={clsx(" grid grid-cols-[1fr,32px] border-b py-4", className)}
			>
				<div className={clsx("flex flex-col gap-4 ")}>
					<div className="flex flex-col gap-2 items-start">
						<div className="flex flex-col lg:flex-row items-start lg:items-center  gap-1">
							<Heading as="h4" fontSize={18} className="font-medium m-0">
								{name}
							</Heading>
							{issuerCertificationId && <Tag>#{issuerCertificationId}</Tag>}

							{verified && (
								<div className="flex  items-center gap-1">
									<ShieldCheck className="fill-brand-500 stroke-white" />
									<p>Verified</p>
								</div>
							)}
						</div>

						<p>{issuedBy}</p>

						<p className="text-sm text-gray-500">
							Issued {format(new Date(issuedAt), "MMM dd, yyyy")}
						</p>
					</div>
				</div>
				{!!id && editable && (
					<div className="flex flex-col">
						<IconButton
							colorScheme="earthRed"
							variant="ghost"
							aria-label="Edit Work History"
							icon={<Edit2 className="w-5 h-5" />}
							onClick={onOpen}
						/>
					</div>
				)}
			</div>
		</>
	);
}
