import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RequestFailed } from "../../components/RequestFailed";
import { useBankAccountInfo } from "../../hooks/useBankAccountInfo";
import wait from "../../assets/wait.svg";

const GET_LIST_OF_BANKS = gql`
  query ListBanks {
    listBanks {
      data {
        name
        code
      }
    }
  }
`;

const GET_BANK_ACCOUNT_INFO = gql`
  query GetBankAccountInfo($accountNumber: String!, $bankCode: String!) {
    getBankAccountInformation(
      input: { accountNumber: $accountNumber, bankCode: $bankCode }
    ) {
      data {
        accountName
        accountNumber
      }
    }
  }
`;

const SAVE_BANK_ACCOUNT_INFO = gql`
  mutation SaveBankAccountInfo($accountNumber: String!, $bankCode: String!) {
    addProPaymentInformation(
      payload: { accountNumber: $accountNumber, bankCode: $bankCode }
    ) {
      userId
      
    }
  }
`;

export interface BankAccountInformationProps {}

export interface Bank {
  name: string;
  code: string;
}

export const BankAccountInformation = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const {
    loading: loadingBankAccountInfo,
    hasProvidedBankAccountInfo,
    refetchBankAccountInfo,
    maskedAccountNumber,
    accountName,
    bankName,
  } = useBankAccountInfo();

  const [accountNumber, setAccountNumber] = useState<string>("");
  const [bankCode, setBankCode] = useState<string>("");

  const isValidAccountNumber = (accountNumber: string) => {
    return (
      accountNumber.trim().length === 10 &&
      !isNaN(accountNumber as unknown as number)
    );
  };

  const { loading: bankListLoading, data } = useQuery<{
    listBanks: {
      data: Bank[];
    };
  }>(GET_LIST_OF_BANKS);

  const [
    getBankAccountInfo,
    { loading: bankAccountInfoLoading, data: bankAccountInfo },
  ] = useLazyQuery(GET_BANK_ACCOUNT_INFO);

  const [saveBankAccountInfo, saveBankAccountInfoResponse] = useMutation(
    SAVE_BANK_ACCOUNT_INFO,
    {
      onCompleted: async () => {
        try {
          await refetchBankAccountInfo();
        } catch (error) {
          throw error;
        }

        getAccessTokenSilently({
          ignoreCache: true,
        });
      },
    }
  );

  useEffect(() => {
    if (isValidAccountNumber(accountNumber) && bankCode) {
      getBankAccountInfo({
        variables: {
          bankCode,
          accountNumber,
        },
      });
    }
  }, [accountNumber, bankCode, getBankAccountInfo]);

  if (loadingBankAccountInfo) {
    return null;
  }

  if (!user) {
    return <RequestFailed />;
  }

  const handleSubmit = () => {
    saveBankAccountInfo({
      variables: {
        accountNumber,
        bankCode,
      },
    });
  };

  if (saveBankAccountInfoResponse.error) {
    return (
      <RequestFailed
        text={saveBankAccountInfoResponse.error.message}
        onRetry={saveBankAccountInfoResponse.reset}
      />
    );
  }

  const canSubmit = isValidAccountNumber(accountNumber) && bankCode;
  const hasSubmittedBankAccountDetails = hasProvidedBankAccountInfo;

  return (
    <div className='bg-white p-4 lg:p-8'>
      <h2 className='text-lg md:text-xl text-primary-500 font-bold mb-4 md:mb-8'>
        Bank Account Information
      </h2>
      {!hasSubmittedBankAccountDetails && (
        <h4 className='font-semibold text-primary-500 mb-2'>
          Status:{" "}
          <span className='text-red-500'>ACCOUNT DETAILS NOT SUBMITTED</span>
        </h4>
      )}
      {hasSubmittedBankAccountDetails ? (
        <div className='w-4/5 mt-8 lg:mt-0 flex flex-col text-center ml-auto mr-auto'>
          <h1 className='text-lg mb-4  font-semibold text-primary-500'>
            Bank account details submitted
          </h1>
          <Box className='text-left ml-auto mr-auto '>
            <p className='text-primary-300 mt-4 p-2 bg-gray-100 rounded-md'>
              <span className='font-medium'>Account name:</span> {accountName}
              <br />
              <span className='font-medium'>Account number:</span>{" "}
              {maskedAccountNumber}
              <br />
              {bankName && (
                <span>
                  <span className='font-medium'>Bank name:</span> {bankName}
                </span>
              )}
            </p>
          </Box>

          <div className='flex self-center justify-center my-4 w-36 h-36 lg:w-56 lg:h-56'>
            <img src={wait} alt='' />
          </div>
          <p className='text-primary-300 mt-4'>
            If you want to change your account number, please contact our
            support team via email at{" "}
            <a className='!text-accent-500' href='mailto:support@laborhack.com'>
              support@laborhack.com
            </a>
            . Thank you.
          </p>
        </div>
      ) : (
        <>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 w-full'>
            <div>
              <h3 className='font-semibold text-primary-500'>Bank Details</h3>
              <p className='text-sm text-primary-300'>
                Provide your bank details
              </p>
            </div>
            <Stack spacing={2} className='px-2 md:px-4'>
              <FormControl
                isRequired
                isInvalid={!isValidAccountNumber(accountNumber)}
              >
                <FormLabel
                  fontSize={"sm"}
                  htmlFor='firstName'
                  color='primary.500'
                >
                  Bank Account Number
                </FormLabel>
                <InputGroup>
                  <Input
                    id='accountNumber'
                    fontSize={"sm"}
                    type={"text"}
                    name='name'
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    placeholder='1234567890'
                  />
                  <InputRightElement>
                    {isValidAccountNumber(accountNumber) && (
                      <CheckIcon color={"green.500"} />
                    )}
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  Ensure account number is correct
                </FormErrorMessage>
              </FormControl>
              <FormControl isDisabled={bankListLoading} isRequired>
                <FormLabel
                  fontSize={"sm"}
                  htmlFor='bankName'
                  color='primary.500'
                >
                  Bank
                </FormLabel>
                <Select
                  id='bankName'
                  fontSize={"sm"}
                  placeholder='Select option'
                  value={bankCode}
                  onChange={(e) => {
                    setBankCode(e.target.value);
                  }}
                >
                  {data?.listBanks.data.map((bank: Bank) => {
                    return (
                      <option key={bank.code} value={bank.code}>
                        {bank.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontSize={"sm"} htmlFor='phone' color='primary.500'>
                  Bank Account Name
                </FormLabel>
                <InputGroup>
                  <Input
                    id='accountName'
                    type='text'
                    fontSize={"sm"}
                    value={
                      bankAccountInfo?.getBankAccountInformation?.data
                        ?.accountName || ""
                    }
                    disabled
                    placeholder='Not available'
                  />
                  <InputRightElement>
                    {bankAccountInfoLoading && <Spinner color='green.500' />}
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>
          </div>
          <div className='my-4 md:my-8 w-full h-0.5 bg-primary-100' />
          <div className='flex justify-end'>
            <Button
              isLoading={saveBankAccountInfoResponse.loading}
              isDisabled={!canSubmit}
              loadingText='Submitting'
              fontSize={"sm"}
              colorScheme={"green"}
              variant={"solid"}
              spinnerPlacement='start'
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
