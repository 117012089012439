import { ApolloError, useQuery } from "@apollo/client";
import { gql } from "../__generated__";
import { GetMyProfileQuery } from "../__generated__/graphql";

const GET_MY_PROFILE = gql(`
	query GetMyProfile {
		myProInformation {
			id
			userId
			firstName
			lastName
			email
			phoneNumber
			phoneNumberVerified
			categoryIds
			onboardedAt
			tracks
			trades {
				id
			}
			identityVerification(showDeclined: true) {
				id
				status
				identificationNumber
				type
				issues {
					code
					message
				}
			}
			associatedLaborHackEmail
			proApplication {
				country
			}
			overview
		}
	}
`);

export const useProfile = (): {
	loading: boolean;
	error?: ApolloError;
	profile?: GetMyProfileQuery["myProInformation"];
} => {
	const { loading, error, data } = useQuery(GET_MY_PROFILE);

	if (error && error.message.includes("Pro not found")) {
		return {
			loading: false,
		};
	}

	return {
		loading,
		error,
		...(!!data?.myProInformation && { profile: data.myProInformation }),
	};
};
