import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import logo from "../assets/laborhack-white-logo.png";
import { NAV_MENU } from "../constants";
import { useProfile } from "../hooks/useProfile";
import { useToggles } from "../hooks/useToggles";
import { Loading } from "./Loading";
import { HelpCenterV2 } from "./HelpCenterV2";
import { IoLogOut } from "react-icons/io5";
import { SideNavItemV2 } from "./NavItem/SideNavItem";

export interface SideNavProps {}

export const SideNavV2 = () => {
	const location = useLocation();
	const { logout, user } = useAuth0();
	const { profile, loading: profileLoading } = useProfile();

	const { isLoading: isLoadingToggles, toggles } = useToggles();

	if (isLoadingToggles) {
		return <Loading />;
	}

	if (!user) {
		return null;
	}

	return (
		<div className="w-full h-[100dvh] bg-[#F9F9F9] border-r-[1px] border-[#E7E7E7]  flex flex-col">
			<div className="bg-[#ED6E5C] px-8 py-8 flex h-8 items-center">
				<img src={logo} width="120px" alt="laborhack's logo" />
			</div>
			<div className="flex-auto py-8 overflow-auto">
				<ul className="flex flex-col gap-3">
					{NAV_MENU.filter(
						({ shouldRender }) =>
							!profileLoading && shouldRender(profile, toggles)
					).map((item) => {
						return (
							<SideNavItemV2
								key={item.name}
								name={item.name}
								path={item.url}
								locked={!profileLoading && item.shouldLock(profile)}
								active={location.pathname === item.url}
								icon={item.icon}
							/>
						);
					})}
				</ul>
			</div>
			<div className="flex items-center justify-center px-4 py-6 border-y-[1px] border-y-[#E7E7E7]">
				<HelpCenterV2 />
			</div>
			<div className="my-4 px-4 w-fit">
				<Button
					width={"fit"}
					background={"none"}
					borderRadius={0}
					px={3}
					fontSize={"sm"}
					onClick={() =>
						logout({
							returnTo: window.location.origin,
						})
					}
					gap={"16px"}
					_hover={{ backgroundColor: "#464646", textColor: "#FEFEFE" }}
				>
					<IoLogOut size={20} style={{ transform: "rotate(180deg)" }} />
					<p className="font-medium text-lg">Log Out</p>
				</Button>
			</div>
		</div>
	);
};
