import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { RequestFailed } from "./RequestFailed";
import { Loading } from "./Loading";
import { useProApplication } from "../hooks/useProApplication";

export interface HasApplicationFormProps {}

export const HasApplicationForm = ({
  children,
}: PropsWithChildren<HasApplicationFormProps>) => {
  const { pathname } = useLocation();

  /**
   * If the user does not have an application form submitted, redirect to the form
   */

  const { loading, error, proApplication } = useProApplication();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <RequestFailed text={error.message} />;
  }

  /**
   * If the user does not have a profile, redirect to the application form
   *
   * Right now, a user can only have a profile if they have submitted an application form
   */
  if (!proApplication) {
    /**
     * Keep track of where the user was going before they were redirected to the application form
     *
     * The user will be redirected back to that page after they complete the application form
     */

    localStorage.setItem("redirect", pathname);

    return <Navigate to='/apply' />;
  }

  return <>{children}</>;
};
