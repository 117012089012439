import React, { PropsWithChildren, useMemo, useState } from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { RequestFailed } from "./RequestFailed";

export interface ApolloConfigProps {}

export const ApolloConfig = ({
  children,
}: PropsWithChildren<ApolloConfigProps>) => {
  const [error, setError] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
      return new Promise(async (resolve) => {
        const token = await getAccessTokenSilently().catch((e) => {
          console.error(e.error);

          if (e.error === "login_required") {
            return null;
          }

          setError(e.error);
        });

        resolve({
          headers: {
            ...headers,
            authprovider: "auth0",
            authorization: token || "",
          },
        });
      });
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <RequestFailed text={error} onRetry={() => window.location.reload()} />
    );
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
