import {
  Button,
  Center,
  Code,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { addSeconds, formatDistanceToNow } from "date-fns";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { MdCheck, MdCopyAll, MdHome, MdRefresh } from "react-icons/md";
import { useCoundownTimer } from "../../hooks/useCountdownTimer";
import { useProfile } from "../../hooks/useProfile";
import { useToggles } from "../../hooks/useToggles";

const Step = ({
  step,
  children,
}: PropsWithChildren<{
  step: number;
}>) => {
  return (
    <VStack spacing={2}>
      <Center w={8} h={8} backgroundColor='brand.50' rounded='full'>
        <Text color='brand.900' fontWeight='semibold'>
          {step}
        </Text>
      </Center>
      <Stack alignItems='center'>{children}</Stack>
    </VStack>
  );
};

const TelegramTokenPage = () => {
  const { profile } = useProfile();

  const { toggles } = useToggles();

  const [copied, setCopied] = React.useState(false);

  const till = useMemo(() => addSeconds(new Date(), 300), []);

  const { timeRemaining, isExpired } = useCoundownTimer(till);

  const [token, setToken] = React.useState<string>("");

  const getNewToken = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_TELEGRAM_BOT_URL}/tokens`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          proId: profile?.id,
        }),
      }
    );

    const data = await response.json();

    return data;
  }, [profile]);

  useEffect(() => {
    if (profile) {
      getNewToken().then((data) => {
        setToken(data.code);
      });
    }
  }, [profile, getNewToken]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(token);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [token]);

  return (
    <VStack w='full' spacing={8} padding={8}>
      <VStack spacing={4} align='center'>
        <Icon as={FaTelegramPlane} color='telegram.500' fontSize='4xl' />
        <HStack justifyContent='center'>
          <Text
            fontSize='lg'
            color='gray.700'
            fontWeight='semibold'
            align='center'
          >
            Authenticate Telegram
          </Text>
        </HStack>
      </VStack>
      <VStack spacing={4}>
        <Step step={1}>
          <Heading fontSize='md' color='gray.700' fontWeight='semibold'>
            Install Telegram
          </Heading>
          <Text fontSize='sm' align='center'>
            Install Telegram on your phone or computer. You can download it from{" "}
            <Link
              color='telegram.500'
              href='https://telegram.org/'
              fontWeight='semibold'
              isExternal
            >
              https://telegram.org/
            </Link>
          </Text>
        </Step>
        <Center>
          <Divider orientation='vertical' />
        </Center>
        <Step step={2}>
          <Heading fontSize='md' color='gray.700' fontWeight='semibold'>
            Generate a Token
          </Heading>
          <Stack spacing={2} align='center'>
            <Code fontSize='2xl'>{isExpired ? "Expired" : token}</Code>
            {!isExpired && (
              <Text>
                Expires in{" "}
                {timeRemaining &&
                  formatDistanceToNow(addSeconds(new Date(), timeRemaining), {
                    includeSeconds: true,
                  })}
              </Text>
            )}

            {isExpired ? (
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                size='sm'
                rightIcon={<Icon as={MdRefresh} />}
              >
                Refresh Token
              </Button>
            ) : (
              <HStack spacing={2}>
                <Button
                  isDisabled={copied}
                  onClick={handleCopy}
                  rightIcon={<Icon as={copied ? MdCheck : MdCopyAll} />}
                >
                  {copied ? "Copied" : "Copy"}
                </Button>
                {toggles.show_telegram_url_button && (
                  <Button
                    colorScheme='telegram'
                    size='sm'
                    as={Link}
                    href={`https://t.me/${process.env.REACT_APP_SOFTSKILLS_BOT_USERNAME}?start=${token}`}
                    rightIcon={<Icon as={FaTelegramPlane} />}
                  >
                    Go To Telegram
                  </Button>
                )}
              </HStack>
            )}
          </Stack>
        </Step>
        <Center>
          <Divider orientation='vertical' />
        </Center>
        <Step step={3}>
          <Heading fontSize='md' color='gray.700' fontWeight='semibold'>
            Send a Message
          </Heading>
          <Text fontSize='sm' align='center'>
            Send a message to{" "}
            <Link
              color='telegram.500'
              href={
                "https://t.me/" + process.env.REACT_APP_SOFTSKILLS_BOT_USERNAME
              }
              fontWeight='semibold'
              isExternal
            >
              @{process.env.REACT_APP_SOFTSKILLS_BOT_USERNAME}
            </Link>{" "}
            on Telegram
          </Text>
        </Step>
      </VStack>
      <HStack>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          size='sm'
          rightIcon={<Icon as={MdRefresh} />}
        >
          Refresh
        </Button>
        <Button
          as={Link}
          href='/'
          size='sm'
          colorScheme='brand'
          rightIcon={<Icon as={MdHome} />}
        >
          Back to Dashboard
        </Button>
      </HStack>
    </VStack>
  );
};

export default TelegramTokenPage;
