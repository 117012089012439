import { gql, useApolloClient } from "@apollo/client";
import {
  Flex,
  Icon,
  IconButton,
  Image,
  Input,
  Progress,
} from "@chakra-ui/react";
import { PropsWithChildren, useState } from "react";
import { MdCheckCircle, MdDeleteOutline } from "react-icons/md";
import { onSelectedFile } from "../utlis/helpers";
import { RequestFailed } from "./RequestFailed";

const GET_PRE_SIGNED_URL = gql`
  query GetPreSignedUrl($path: String!, $fileExtension: String!) {
    getProApplicationUploadPresignedUrl(
      path: $path
      fileExtension: $fileExtension
    )
  }
`;

export interface ImageUploadProps {
  uploadPathPrefix?: string;
  onUploaded: (url: string) => void;
  /**
   * This is called when the user clicks the remove button
   *
   * This ideally should be used to set the image url to undefined and possibly remove the image from the server
   */
  onRemove: () => void;
  imageUrl?: string;
}

export const ImageUpload = ({
  uploadPathPrefix,
  onUploaded,
  onRemove,
  imageUrl,
}: PropsWithChildren<ImageUploadProps>) => {
  const [imageExtensionToPresignedUrl, setImageExtensionToPresignedUrl] =
    useState<Record<string, string>>({});

  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>();

  const [uploading, setUploading] = useState(false);

  const [error, setError] = useState<string | undefined>();

  const client = useApolloClient();

  const handleSelectImage = (event: any) => {
    onSelectedFile(event, setThumbnailUrl);

    uploadImage(event);
  };

  const handleRemove = () => {
    console.log("handleRemove");
    onRemove();
    setThumbnailUrl(undefined);
  };

  const getPresignedUrl = async (fileExtension: string) => {
    let presignedUrl = imageExtensionToPresignedUrl[fileExtension];

    if (!presignedUrl) {
      const { data } = await client.query({
        query: GET_PRE_SIGNED_URL,
        variables: {
          path: uploadPathPrefix,
          fileExtension,
        },
      });

      presignedUrl = data.getProApplicationUploadPresignedUrl;

      setImageExtensionToPresignedUrl((prev) => ({
        ...prev,
        [fileExtension]: presignedUrl,
      }));
    }

    return presignedUrl;
  };

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);

    try {
      /**
       * Get presigned S3 url from server
       */
      const file = e.target.files?.[0];
      if (!file) {
        throw new Error("No file selected");
      }

      const fileExtension = file.name.split(".").pop();

      if (!fileExtension) {
        throw new Error("No file extension");
      }

      const presignedUrl = await getPresignedUrl(fileExtension);

      /**
       * Upload image to S3
       *
       */

      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
      });

      if (response.status !== 200) {
        throw new Error("Failed to upload image");
      }

      /**
       * Set image url
       */
      const imageUrl = presignedUrl.split("?")[0];

      onUploaded(imageUrl);
    } catch (error) {
      setError((error as Error).message);
    }

    setUploading(false);
  };

  if (error) {
    return (
      <RequestFailed
        onRetry={() => {
          handleRemove();
          setError(undefined);
        }}
        text={error}
      />
    );
  }

  return imageUrl || thumbnailUrl ? (
    <Flex alignItems='center' gap={2}>
      <Flex direction='column' position='relative'>
        {!uploading && imageUrl && (
          <Icon
            as={MdCheckCircle}
            w={6}
            h={6}
            color='green.500'
            position='absolute'
            right={4}
            top={4}
          />
        )}
        <Image
          p={2}
          w={200}
          h={200}
          boxShadow='base'
          objectFit='cover'
          src={
            imageUrl
              ? `${imageUrl}?random=${Math.floor(Math.random() * 1000)}`
              : thumbnailUrl
          }
        />
        {uploading && <Progress size='sm' isIndeterminate />}
      </Flex>

      <IconButton
        aria-label='remove image'
        variant='ghost'
        colorScheme='red'
        icon={<Icon as={MdDeleteOutline} />}
        onClick={handleRemove}
      />
    </Flex>
  ) : (
    <Input
      fontSize={"sm"}
      type={"file"}
      alignItems='center'
      accept='image/*'
      onChange={handleSelectImage}
    />
  );
};
