import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";

export const useCoundownTimer = (till: Date) => {
  const duration = differenceInSeconds(till, new Date());

  const [seconds, setSeconds] = useState(duration);

  console.log(seconds);

  const [intervalHandle, setIntervalHandle] = useState<any>();

  useEffect(() => {
    // Setting up the interval
    const interval = setInterval(() => {
      setSeconds((current) => current - 1);
    }, 1000);

    setIntervalHandle(interval);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [till]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // When countdown reaches zero
    if (seconds <= 0) {
      clearInterval(intervalHandle);
    }
  }, [seconds]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    timeRemaining: seconds,
    isExpired: seconds <= 0,
  };
};
