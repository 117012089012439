import { useMutation } from "@apollo/client";
import { Button, Link, SkeletonText, Stack, Textarea } from "@chakra-ui/react";
import { Edit, Save, Sparkles } from "lucide-react";
import { useState } from "react";
import { gql } from "../../__generated__";
import { PortfolioSectionHeading } from "./portfolio-section-heading";

const GENERATE_PORFOLIO_OVERVIEW = gql(`
	mutation GeneratePortfolioOverview {
		generatePortfolioOverview {
			id
			overview
		}
	}
`);

const SET_OVERVIEW = gql(`
	mutation SetOverview($overview: String!) {
		setProProfileOverview(overview: $overview) {
			id
			overview
		}
	}
`);

export interface PortfolioProfileProps {
	overview?: string | null;
	hasJobs?: boolean;
	canGenerateProfile?: boolean;
}

export const PortfolioProfile = ({
	overview,
	canGenerateProfile,
	hasJobs,
}: PortfolioProfileProps) => {
	const hasOverview = typeof overview === "string";

	const [generatePortfolioOverview, generatePortfolioOverviewHandle] =
		useMutation(GENERATE_PORFOLIO_OVERVIEW);

	const [setOverview, setOverviewHandle] = useMutation(SET_OVERVIEW);

	const [editing, setEditing] = useState(false);

	const [wipOverview, setWipOverview] = useState<string>();

	const isGenerating = generatePortfolioOverviewHandle.loading;

	const handleGenerateProfile = () => {
		generatePortfolioOverview();
	};

	let body: JSX.Element | null = null;

	if (isGenerating) {
		body = (
			<Stack className="w-full" spacing={6}>
				<SkeletonText
					className="w-full"
					noOfLines={3}
					spacing="3"
					skeletonHeight="5"
					startColor="pink.200"
					endColor="orange.200"
				/>
				<SkeletonText
					className="w-full"
					noOfLines={3}
					spacing="3"
					skeletonHeight="5"
					startColor="pink.200"
					endColor="orange.200"
				/>
			</Stack>
		);
	} else {
		if (hasOverview) {
			body = (
				<article className="flex flex-col gap-4 font-[Inter]">
					{overview.split("\n\n").map((para, index) => (
						<p key={index}>{para}</p>
					))}
				</article>
			);
		} else {
			body = (
				<Stack
					border={"1px solid #C9C9C9"}
					p={8}
					textAlign={"center"}
					className="w-full"
				>
					<div className="flex flex-col items-center justify-center gap-3 w-full">
						<p className="text-[#121212] text-base lg:text-lg font-medium">
							Nothing to see yet
						</p>
						<p className="text-[#464646] text-sm lg:text-base font-normal">
							{hasJobs
								? "Let's generate your profile to see how it looks. You can always edit it after it's generated."
								: "Your profile is currently empty. Complete it by adding your job history and other details."}
						</p>
						{hasJobs ? (
							<Button
								colorScheme="earthRed"
								variant={"solid"}
								rightIcon={<Sparkles className="w-4 h-4" />}
								isDisabled={!canGenerateProfile}
								isLoading={isGenerating}
								loadingText="Generating"
								onClick={handleGenerateProfile}
							>
								Generate Profile
							</Button>
						) : (
							<Link
								href="/my-portfolio/add-job"
								color={"prussianBlue.500"}
								fontSize={"sm"}
							>
								<p className="text-[#77372E] underline text-base lg:text-lg font-medium">
									Add job history
								</p>
							</Link>
						)}
					</div>
				</Stack>
			);
		}
	}

	const handleSaveOverview = () => {
		if (typeof wipOverview !== "string") return;

		setOverview({
			variables: { overview: wipOverview },
			onCompleted: () => setEditing(false),
		});
	};

	return (
		<div className="p-6 flex flex-col gap-4 border-b items-start">
			<div className="flex justify-between w-full items-center">
				<PortfolioSectionHeading>Profile</PortfolioSectionHeading>
				<div className="flex items-center gap-2">
					{!editing && hasOverview && (
						<Button
							colorScheme="earthRed"
							variant={"outline"}
							rightIcon={<Edit className="w-4 h-4" />}
							onClick={() => {
								setEditing(true);
								setWipOverview(overview);
							}}
						>
							Edit
						</Button>
					)}
					{!!editing && (
						<Button
							colorScheme="green"
							rightIcon={<Save className="w-4 h-4" />}
							isLoading={setOverviewHandle.loading}
							loadingText="Saving"
							onClick={handleSaveOverview}
						>
							Save
						</Button>
					)}
					<Button
						colorScheme="earthRed"
						variant={"outline"}
						rightIcon={<Sparkles className="w-4 h-4" />}
						isDisabled={!canGenerateProfile}
						isLoading={isGenerating}
						loadingText="Generating"
						onClick={handleGenerateProfile}
					>
						Generate
					</Button>
				</div>
			</div>
			{editing ? (
				<Textarea
					value={wipOverview}
					onChange={(e) => setWipOverview(e.target.value)}
					minH={300}
				/>
			) : (
				body
			)}
		</div>
	);
};
