import { useQuery } from "@apollo/client";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { CustomNavigate } from "../App";
import { GET_MY_PORTFOLIO } from "../common/graphql/typed-queries";
import { Loading } from "../components/Loading";
import { RequestFailed } from "../components/RequestFailed";

const allowedOverridePaths = ["/"];

export const HasCompletedPortfolioRequirements = ({
	children,
}: PropsWithChildren) => {
	const { pathname } = useLocation();

	const hasAcknowledgedPortfolioWalkthrough = localStorage.getItem(
		"hasAcknowledgedPortfolioWalkthrough"
	);

	const { data, loading, error } = useQuery(GET_MY_PORTFOLIO);

	if (loading) return <Loading />;

	if (error) return <RequestFailed />;

	if (
		!data?.myPortfolio?.hasCompletedRequiredPortfolioSteps &&
		allowedOverridePaths.includes(pathname) &&
		!hasAcknowledgedPortfolioWalkthrough
	) {
		return <CustomNavigate to="/my-portfolio" />;
	}

	return <>{children}</>;
};
