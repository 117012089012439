import React from "react";
import { useQuery } from "@apollo/client";
import { Link as RouterLink } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { RequestFailed } from "../../components/RequestFailed";
import { Global } from "@emotion/react";
import {
	Avatar as CRAvatar,
	Button,
	Divider,
	Heading,
	HStack,
	Link,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Progress,
	Stack,
	useBreakpointValue,
	VStack,
	Alert,
	AlertIcon,
} from "@chakra-ui/react";
import { ArrowUpRight, Check, Plus, Share } from "lucide-react";
import clsx from "clsx";
import { WorkHistory } from "../../features/pro-portfolio/work-history";
import { Rating } from "../../features/pro-portfolio/rating";
import { Helmet } from "react-helmet";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { logEvent } from "src/utlis/analytics";
import { useAuth0 } from "@auth0/auth0-react";
import { PortfolioBadgeSection } from "../../features/pro-portfolio/badge-section";
import { PortfolioSectionHeading } from "../../features/pro-portfolio/portfolio-section-heading";
import { CertificationCreator } from "../../features/pro-portfolio/certification-creator";
import { Certifications } from "../../features/pro-portfolio/certifications";
import HelmetIcon from "../../assets/helmet-icon.png";
import TransactionsIcon from "../../assets/transactions-icon.png";
import Slider from "react-slick";
import { useToggles } from "src/hooks/useToggles";
import { formatAsNaira, getCappedAmount } from "src/utlis/helpers";
import { ProNotFound } from "src/features/pro-portfolio/pro-not-found";
import { NothingToSeeYet } from "src/features/pro-portfolio/nothing-to-see-yet";
import { IoLogOutOutline } from "react-icons/io5";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useProfile } from "../../hooks/useProfile";
import { PortfolioProfile } from "../../features/pro-portfolio/profile";
import { EducationEntries } from "src/features/pro-portfolio/education-entries";
import { GET_MY_PORTFOLIO } from "../../common/graphql/typed-queries";
import { PortfolioWalkthrough } from "../../features/pro-portfolio/walkthrough";

export default function ProProfilePage() {
	const { toggles, isLoading: togglesAreLoading } = useToggles();

	const hasAcknowledgedPortfolioWalkthrough = localStorage.getItem(
		"hasAcknowledgedPortfolioWalkthrough"
	);

	const [showWalkthrough, setShowWalkthrough] = React.useState(
		!hasAcknowledgedPortfolioWalkthrough
	);

	const getProfileHandle = useProfile();

	const carouselSettings = useBreakpointValue({
		base: {
			slidesToShow: 1,
			dots: true,
		},
		lg: {},
	});

	const { isAuthenticated, user } = useAuth0();

	const [copied, setCopied] = React.useState(false);

	const [showShareModal, setShowShareModal] = React.useState(false);

	const [inEditView, setInEditView] = React.useState<boolean>(false);

	const [showCertificationCreateModal, setShowCertificationCreateModal] =
		React.useState(false);

	React.useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	const { data, loading, error } = useQuery(GET_MY_PORTFOLIO);

	const isPro = isAuthenticated && user?.email === data?.myPortfolio?.email;

	React.useEffect(() => {
		if (isPro) {
			setInEditView(true);
		}
	}, [isPro]);

	if (loading || togglesAreLoading || getProfileHandle.loading)
		return <Loading />;

	if (error || getProfileHandle.error) return <RequestFailed />;

	if (!data || !getProfileHandle.profile) return <RequestFailed />;

	if (!data.myPortfolio) return <ProNotFound />;

	const canEdit = inEditView;

	const noOfJobsCompleted = data.myPortfolio.workHistory.length;

	const pro = getProfileHandle.profile;

	const totalBudget = data.myPortfolio.totalBudget;

	const averageBudget = totalBudget > 0 ? totalBudget / noOfJobsCompleted : 0;

	const workHistory = data.myPortfolio.workHistory;

	const workHistoryImages = workHistory
		.map((item) => item?.images)
		.flat()
		.filter((image) => !image?.deletedAt);

	const portfolioMedia = workHistoryImages.map(
		(image) => `data:${image?.mimeType};base64,${image?.data}`
	);

	const shortenedName = `${data.myPortfolio.firstName} ${data.myPortfolio.lastName[0]}.`;

	const linkToPublicView = `${process.env.REACT_APP_HOME_APP_URL}/pros/@${data.myPortfolio.handle}`;

	const handleAcknowledgeWalkthrough = () => {
		logEvent("pro_portfolio/portfolio_walkthrough_acknowledged");

		localStorage.setItem("hasAcknowledgedPortfolioWalkthrough", "yes");

		setShowWalkthrough(false);
	};

	const handleShare = () => {
		logEvent("pro_portfolio/share_profile_clicked");

		if (!data.myPortfolio) return;

		/**
		 * Open the Web Share API
		 */
		if (navigator.share) {
			navigator
				.share({
					title: `${shortenedName} | LaborHack Pro`,
					text: `Check out ${shortenedName}'s profile on LaborHack`,
					url: linkToPublicView,
				})
				.then(() => {
					logEvent("pro_portfolio/profile_shared");
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						return;
					}

					logEvent("pro_portfolio/profile_share_failed", {
						error: error.message,
					});

					setShowShareModal(true);
				});
		} else {
			setShowShareModal(true);
		}
	};

	const steps = [
		{
			name: "Personal Information",
			isComplete: true,
			isRequired: true,
		},
		{
			name: "Add Work History",
			isComplete: noOfJobsCompleted > 0,
			isRequired: true,
			cta: {
				text: "Add Work History",
				url: "/my-portfolio/add-job",
			},
		},
		{
			name: "Generate Profile",
			isComplete: !!pro.overview,
			isRequired: true,
		},
		{
			name: "Add Certifications",
			isComplete: data.myPortfolio.certifications.length > 0,
			cta: {
				text: "Add",
				url: "/my-portfolio/add-certification",
			},
		},
		{
			name: "Add Education",
			isComplete: data.myPortfolio.educationEntries.length > 0,
			cta: {
				text: "Add",
				url: "/my-portfolio/add-education",
			},
		},
	];

	const allRequiredStepsComplete = steps
		.filter((step) => step.isRequired)
		.every((step) => step.isComplete);

	const progress =
		(steps.filter((step) => step.isComplete).length / steps.length) * 100;

	return (
		<>
			<Helmet>
				<title>
					{data.myPortfolio.firstName} {data.myPortfolio.lastName?.[0]}. |
					LaborHack
				</title>
			</Helmet>
			<Global
				styles={`
       body {
          background-color: white !important;
        }
      `}
			/>
			{showWalkthrough && (
				<PortfolioWalkthrough onClose={handleAcknowledgeWalkthrough} />
			)}

			<Modal
				isOpen={showCertificationCreateModal}
				onClose={() => setShowCertificationCreateModal(false)}
				isCentered
				closeOnOverlayClick
				size="xl"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className="font-[AeonikPro] !text-2xl">
						Add Certification
					</ModalHeader>
					<Divider />
					<VStack py={4} px={6} alignItems="start">
						<CertificationCreator
							onClose={() => setShowCertificationCreateModal(false)}
						/>
					</VStack>
				</ModalContent>
			</Modal>

			<Modal
				isOpen={showShareModal}
				onClose={() => setShowShareModal(false)}
				isCentered
				closeOnOverlayClick
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Share Profile</ModalHeader>
					<VStack pb={8} px={6} alignItems="start">
						<HStack>
							<Link
								href={`https://twitter.com/intent/tweet?text=Check out ${data.myPortfolio.firstName} ${data.myPortfolio.lastName[0]} on LaborHack&url=${linkToPublicView}`}
								isExternal
							>
								<Button
									rightIcon={<FaTwitter />}
									colorScheme="twitter"
									variant="solid"
									onClick={() =>
										logEvent("pro_portfolio/twitter_share_clicked")
									}
								>
									Tweet
								</Button>
							</Link>
							<Link
								href={
									"https://www.facebook.com/sharer/sharer.php?u=" +
									encodeURIComponent(linkToPublicView)
								}
								isExternal
							>
								<Button
									rightIcon={<FaFacebookF />}
									colorScheme="facebook"
									variant="solid"
									onClick={() =>
										logEvent("pro_portfolio/facebook_share_clicked")
									}
								>
									Post to Facebook
								</Button>
							</Link>
						</HStack>
					</VStack>
				</ModalContent>
			</Modal>

			<HStack w="full" bgColor="white" justifyContent="center">
				<Stack
					px={{
						base: 0,
						lg: 5,
					}}
					py={{
						base: 8,
						lg: 18,
					}}
					spacing={{
						base: 8,
						lg: 8,
					}}
					direction="column"
					w="full"
					maxW="container.xl"
					alignItems={{
						base: "start",
						lg: "center",
					}}
				>
					<div className="px-4 lg:px-0 w-full">
						<HStack
							borderBottom={"1px"}
							borderColor={"#E7E7E7"}
							py={3}
							w={"full"}
							textColor={"#8F8F8F"}
						>
							<Link
								href="/"
								_hover={{
									textDecoration: "none",
								}}
							>
								<Button
									variant="ghost"
									colorScheme="earthRed"
									display={"flex"}
									alignItems={"center"}
									px={"3px"}
									textColor={"#212121"}
									gap={2}
								>
									<IoLogOutOutline
										size={20}
										style={{ transform: "rotate(180deg)" }}
									/>
									<span className="text-lg lg:text-xl">Exit portfolio</span>
								</Button>
							</Link>
						</HStack>
					</div>
					<div className="w-full grid grid-cols-5 px-4 lg:px-0">
						<div className="col-span-5 lg:col-span-3 flex flex-col border">
							<div className="p-4 flex flex-col gap-4 border-b">
								<Progress
									size="lg"
									value={progress}
									colorScheme={allRequiredStepsComplete ? "green" : "yellow"}
								/>
								<div className="flex flex-col lg:flex-row gap-2 w-full justify-between">
									<p>Your portfolio setup progress</p>
									<p className=" uppercase">
										<span
											className={clsx({
												"text-green-500": allRequiredStepsComplete,
												"text-yellow-500": !allRequiredStepsComplete,
											})}
										>{`${steps.filter((step) => step.isComplete).length} of ${
											steps.length
										}`}</span>{" "}
										Completed
									</p>
								</div>
							</div>
							<div className=" bg-gray-50">
								{steps.map((step) => (
									<div className="w-full flex justify-between items-center p-4 border-b">
										<div className="flex gap-2">
											{step.isComplete ? (
												<div className="rounded-full flex justify-center items-center border w-6 h-6 bg-green-500">
													<Check className="w-4 h-4 text-white" />
												</div>
											) : (
												<div className="rounded-full border w-6 h-6 bg-white" />
											)}
											<p>
												{step.name}
												{!step.isRequired && (
													<span className="text-gray-500"> (Optional)</span>
												)}
											</p>
										</div>
										{step.cta && !step.isComplete && (
											<RouterLink to={step.cta.url}>
												<div className="flex gap-2 items-center">
													<p className="text-sm">{step.cta.text}</p>
													<ArrowUpRight className="w-4 h-4" />
												</div>
											</RouterLink>
										)}

										{step.isComplete && (
											<p className="text-green-500">Completed</p>
										)}
									</div>
								))}
							</div>
						</div>
						<div className="col-span-2"></div>
					</div>
					<div className="w-full px-4 lg:px-0">
						<Alert status="info" w={"full"}>
							<AlertIcon />
							<p>
								You can easily create and send professional invoices to your
								clients through WhatsApp using our AI powered assistant.{" "}
								<br className="block lg:hidden" />
								{/* <Link
									href={`${process.env.REACT_APP_ARTISAN_ASSISTANT_WHATSAPP_BOT}`}
									textDecoration={"underline"}
								>
									Chat with Aina
								</Link> */}
								<RouterLink to="chat-with-aina">Chat with Aina</RouterLink>
							</p>
						</Alert>
					</div>
					<div className="flex flex-col w-full border-brand-50 lg:border-[1px] border-[#C9C9C9]">
						<div className="flex flex-col lg:flex-row lg:justify-between gap-8 border-b-[1px] px-6 py-9">
							<div className="flex items-start gap-5">
								<CRAvatar
									bg="brand.500"
									size={{
										base: "xl",
										lg: "2xl",
									}}
									textColor={"#FEFEFE"}
									name={`${data.myPortfolio.firstName} ${data.myPortfolio.lastName[0]}.`}
									src={""}
								/>
								<div className="flex flex-col gap-4">
									<div className="flex flex-col gap-1">
										<Heading
											as="h2"
											fontSize={{
												base: 24,
												lg: 28,
											}}
											fontWeight={500}
											textColor={"#040A0D"}
										>
											{data.myPortfolio.firstName}{" "}
											{data.myPortfolio.lastName[0]}.
										</Heading>
										<p className="font-normal text-xl text-[#464646]">
											{data.myPortfolio.title}
										</p>
									</div>

									<div className="flex items-center gap-2">
										<Rating rating={data.myPortfolio.averageRating} />
										<p className="text-sm">
											{data.myPortfolio.averageRating &&
												data.myPortfolio.averageRating.toFixed(2)}{" "}
											<span className="text-gray-500">
												({data.myPortfolio.ratingCount} reviews)
											</span>
										</p>
									</div>
								</div>
							</div>
							<div className="flex flex-col lg:flex-row gap-6 w-full lg:w-auto">
								<Button
									leftIcon={<Share className="w-5 h-5" />}
									colorScheme="prussianBlue"
									size="lg"
									variant="ghost"
									className={clsx("w-full order-2 lg:order-1 lg:w-auto")}
									onClick={handleShare}
								>
									Share Profile
								</Button>
								<Button
									colorScheme="earthRed"
									size="lg"
									variant="outline"
									className={clsx("w-full order-2 lg:order-1 lg:w-auto")}
									onClick={() => window.open(linkToPublicView, "_blank")}
								>
									See Public View
								</Button>
							</div>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-3 ">
							<div className="col-span-1 flex flex-col">
								<div className="p-6 flex flex-col gap-6 border-b items-start">
									<PortfolioSectionHeading>
										Contact Information
									</PortfolioSectionHeading>
									<div className="flex flex-col gap-4">
										{!data.myPortfolio.isEmailHidden && (
											<div className="flex flex-col gap-2">
												<p className="text-base text-[#6E6E6E] font-[Inter]">
													Email address
												</p>
												<p className="text-lg font-[Inter]">
													{data.myPortfolio.email}
												</p>
											</div>
										)}
										<div className="flex flex-col gap-2">
											<p className="text-base text-[#6E6E6E] font-[Inter]">
												Phone number
											</p>
											<p className="text-lg font-[Inter]">
												{data.myPortfolio.phone}
											</p>
										</div>
									</div>
								</div>
								<div className="p-6 grid grid-cols-2 gap-5 border-b items-start">
									<div>
										<p className="font-[Inter] text-lg text-[#121212]">
											Punctuality
										</p>
									</div>
									<Rating
										rating={
											data.myPortfolio.averageLaborHackJobsRatings
												.averagePunctualityRating
										}
									/>
									<div>
										<p className="font-[Inter] text-lg text-[#121212]">
											Appearance
										</p>
									</div>
									<Rating
										rating={
											data.myPortfolio.averageLaborHackJobsRatings
												.averageAppearanceRating
										}
									/>
									<div>
										<p className="font-[Inter] text-lg text-[#121212]">
											Behaviour
										</p>
									</div>
									<Rating
										rating={
											data.myPortfolio.averageLaborHackJobsRatings
												.averageBehaviorRating
										}
									/>
									<div>
										<p className="font-[Inter] text-lg text-[#121212]">
											Proper tool usage
										</p>
									</div>
									<Rating
										rating={
											data.myPortfolio.averageLaborHackJobsRatings
												.averageProperToolUsageRating
										}
									/>
									<div>
										<p className="font-[Inter] text-lg text-[#121212]">
											Post service clean up
										</p>
									</div>
									<Rating
										rating={
											data.myPortfolio.averageLaborHackJobsRatings
												.averagePostServiceCleanUpRating
										}
									/>
								</div>
								<div className="p-6 flex gap-5 border-b items-start">
									<div className="w-[64px] h-[64px]">
										<img src={HelmetIcon} alt="Helmet Icon" />
									</div>
									<div className="flex flex-col gap-3">
										<Heading
											as="h3"
											fontSize={{
												base: "22px",
												lg: "26px",
											}}
											textColor={"#040A0D"}
										>
											{noOfJobsCompleted > 100 ? `100+` : noOfJobsCompleted}
										</Heading>
										<p className="text-base lg:text-lg font-normal font-[Inter] text-[#464646]">
											{noOfJobsCompleted === 1
												? "Job completed"
												: "Jobs completed"}
										</p>
									</div>
								</div>
								<div className="p-6 flex gap-5 border-b items-start">
									<div className="w-[64px] h-[64px]">
										<img src={TransactionsIcon} alt="Transaction Icon" />
									</div>
									<div className="flex flex-col gap-3">
										<Heading
											as="h3"
											fontSize={{
												base: "22px",
												lg: "26px",
											}}
											textColor={"#040A0D"}
										>
											{`${
												canEdit
													? formatAsNaira(averageBudget, 2)
													: formatAsNaira(getCappedAmount(averageBudget), 0)
											}${canEdit ? "" : noOfJobsCompleted > 0 ? "+" : ""}`}
										</Heading>
										<p className="text-base lg:text-lg font-normal font-[Inter] text-[#464646]">
											Average Budget
										</p>
									</div>
								</div>
								{canEdit && (
									<div className="p-6 flex gap-5 border-b items-start">
										<div className="w-[64px] h-[64px]">
											<img src={TransactionsIcon} alt="Transaction Icon" />
										</div>
										<div className="flex flex-col gap-3">
											<Heading
												as="h3"
												fontSize={{
													base: "22px",
													lg: "26px",
												}}
												textColor={"#040A0D"}
											>
												{formatAsNaira(totalBudget, 2)}
											</Heading>
											<p className="text-base lg:text-lg font-normal font-[Inter] text-[#464646]">
												Total Budget
											</p>
										</div>
									</div>
								)}

								<PortfolioBadgeSection
									portfolio={data.myPortfolio}
									editMode={canEdit}
								/>
							</div>
							<div className="col-span-1 lg:col-span-2 border-l flex flex-col">
								<PortfolioProfile
									canGenerateProfile={
										noOfJobsCompleted > 0 && typeof pro.overview !== "string"
									}
									hasJobs={noOfJobsCompleted > 0}
									overview={pro.overview}
								/>
								{toggles?.enable_portfolio_section_on_pro_portfolio &&
									(canEdit || !!portfolioMedia.length) && (
										<div className=" w-full border-b py-4">
											<div
												className={clsx("p-6 flex items-start w-full", {
													"justify-between": canEdit,
												})}
											>
												<PortfolioSectionHeading>
													Gallery
												</PortfolioSectionHeading>
											</div>
											<div
												className={`w-full px-6 ${
													portfolioMedia.length < 1 ? "py-4" : "py-12"
												}`}
											>
												{portfolioMedia.length >= 3 ? (
													<Slider
														dots={false}
														infinite={true}
														speed={3000}
														slidesToShow={3}
														slidesToScroll={1}
														autoplay={true}
														autoplaySpeed={3000}
														pauseOnHover={true}
														{...carouselSettings}
													>
														{portfolioMedia.map((media, index) => (
															<div
																key={index}
																className="w-[54px] h-[190px] px-4"
															>
																<img
																	src={media}
																	alt="Portfolio Media"
																	width={"fit"}
																/>
															</div>
														))}
													</Slider>
												) : portfolioMedia.length > 0 ? (
													<div className="grid grid-cols-2 gap-4">
														{portfolioMedia.map((media, index) => (
															<div
																key={index}
																className="w-[254px] h-[150px] px-2"
															>
																<img src={media} alt="Portfolio Media" />
															</div>
														))}
													</div>
												) : (
													<Stack
														border={"1px solid #C9C9C9"}
														p={8}
														display={"flex"}
														flexDirection={"column"}
														alignItems={"center"}
														justifyContent={"center"}
														spacing={3}
														textAlign={"center"}
														w={"full"}
													>
														<NothingToSeeYet />
														<p className="text-[#464646] text-sm lg:text-base font-normal">
															Showcase your work by creating a portfolio. Add
															completed jobs to build your profile.
														</p>
													</Stack>
												)}
											</div>
										</div>
									)}

								{(canEdit || !!data.myPortfolio.certifications.length) && (
									<div className="p-6 flex flex-col items-start gap-4 border-b ">
										<div
											className={clsx("flex items-center w-full", {
												"justify-between": canEdit,
											})}
										>
											<PortfolioSectionHeading>
												Certifications
											</PortfolioSectionHeading>

											{canEdit &&
												data.myPortfolio.certifications.length > 0 && (
													<div className="flex items-center">
														<Link
															href="/my-portfolio/add-certification"
															className="flex items-center gap-2"
															textDecoration={"none"}
															_hover={{ textDecoration: "none" }}
														>
															<Button
																variant="outline"
																leftIcon={<Plus className="w-6 h-6" />}
																colorScheme="earthRed"
															>
																Add
															</Button>
														</Link>
													</div>
												)}
										</div>

										<div className="py-4 w-full">
											{data.myPortfolio.certifications.length ? (
												<Certifications
													items={data.myPortfolio.certifications}
													editable={canEdit}
												/>
											) : (
												<Stack
													border={"1px solid #C9C9C9"}
													p={8}
													display={"flex"}
													flexDirection={"column"}
													alignItems={"center"}
													justifyContent={"center"}
													spacing={3}
													textAlign={"center"}
													w={"full"}
												>
													<NothingToSeeYet />
													<p className="text-[#464646] text-sm lg:text-base font-normal">
														Certifications help highlight your skills. Upload
														existing certifications or start one with us today.
													</p>
													<div className="flex flex-col lg:flex-row gap-2 items-center">
														<div>
															<Link
																href="/certificates"
																color={"prussianBlue.500"}
																fontSize={"sm"}
															>
																<p className="text-[#77372E] underline text-base lg:text-lg font-medium">
																	Start one with us
																</p>
															</Link>
														</div>
														<Divider
															className="hidden lg:block"
															orientation="vertical"
															h={"20px"}
														/>
														<Divider
															className="block lg:hidden"
															orientation="horizontal"
														/>
														<div>
															<Link
																href="/my-portfolio/add-certification"
																color={"prussianBlue.500"}
																fontSize={"sm"}
															>
																<p className="text-[#77372E] underline text-base lg:text-lg font-medium">
																	Upload a certificate
																</p>
															</Link>
														</div>
													</div>
												</Stack>
											)}
										</div>
									</div>
								)}

								<div className="p-6 flex flex-col gap-4 border-b items-start">
									<div
										className={clsx("flex items-center w-full", {
											"justify-between": canEdit,
										})}
									>
										<PortfolioSectionHeading>Education</PortfolioSectionHeading>

										{data.myPortfolio.educationEntries.length > 0 && (
											<div className="flex items-center">
												<Link
													href="/my-portfolio/add-education"
													className="flex items-center gap-2"
													textDecoration={"none"}
													_hover={{ textDecoration: "none" }}
												>
													<Button
														variant="outline"
														leftIcon={<Plus className="w-6 h-6" />}
														colorScheme="earthRed"
													>
														Add
													</Button>
												</Link>
											</div>
										)}
									</div>
									{data.myPortfolio.educationEntries.length ? (
										<EducationEntries
											items={data.myPortfolio.educationEntries}
											editable={canEdit}
										/>
									) : (
										<Stack
											border={"1px solid #C9C9C9"}
											p={8}
											display={"flex"}
											flexDirection={"column"}
											alignItems={"center"}
											justifyContent={"center"}
											spacing={3}
											textAlign={"center"}
											w={"full"}
										>
											<NothingToSeeYet />
											<p className="text-[#464646] text-sm lg:text-base font-normal">
												Education entries help highlight your academic
												background. Add your educational qualifications here.
											</p>
											<div className="flex flex-col lg:flex-row gap-2 items-center">
												<div>
													<Link
														href="/my-portfolio/add-education"
														color={"prussianBlue.500"}
														fontSize={"sm"}
													>
														<p className="text-[#77372E] underline text-base lg:text-lg font-medium">
															Upload an education entry
														</p>
													</Link>
												</div>
											</div>
										</Stack>
									)}
								</div>

								<div className="p-6 flex flex-col gap-4 border-b items-start">
									<WorkHistory
										items={data.myPortfolio.workHistory}
										editable={canEdit}
									/>
								</div>
							</div>
						</div>
					</div>
				</Stack>
			</HStack>
		</>
	);
}
