import {
	Stack,
	Card,
	Image as ChakraImage,
	CardBody,
	Text,
	CardFooter,
	Button,
	VStack,
	HStack,
	Icon,
	List,
	ListItem,
	ListIcon,
	Badge,
	Link,
	Modal,
	ModalHeader,
	ModalContent,
	ModalOverlay,
} from "@chakra-ui/react";
import { PropsWithChildren, useState } from "react";
import { Trade, TradeLevel, TradeRequirementType } from "../../types";
import CertificateThumbnail from "../../assets/laborhack-certificate.png";
import { LEVEL_ORDER_MARKETING_NAMES } from "../../constants";
import {
	MdCheckCircle,
	MdRemoveCircle,
	MdShare,
	MdTrendingUp,
} from "react-icons/md";
import { DownloadIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { logEvent } from "../../utlis/analytics";
import { useToggles } from "../../hooks/useToggles";
import {
	GetMyProfileQuery,
	IdentityVerificationStatus,
} from "../../__generated__/graphql";

interface CertificateListItemProps {
	pro: GetMyProfileQuery["myProInformation"];
	trade: Trade;
	tradeLevel: TradeLevel;
	hasSubmittedBankAccount?: boolean;
	hasSubmittedGuarantor?: boolean;
}

export const CertificateListItem = ({
	pro,
	trade,
	tradeLevel,
	hasSubmittedBankAccount,
	hasSubmittedGuarantor,
}: PropsWithChildren<CertificateListItemProps>) => {
	const { toggles } = useToggles();

	const hasCompletedLevelRequirements =
		trade.currentLevel && trade.currentLevel >= tradeLevel.order;

	const isOnboarded = !!pro.onboardedAt;

	const hasIdentityVerified =
		pro.identityVerification?.status === IdentityVerificationStatus.Verified;

	const isActive =
		isOnboarded &&
		hasCompletedLevelRequirements &&
		hasSubmittedBankAccount &&
		hasSubmittedGuarantor &&
		hasIdentityVerified;

	const allBaseRequirementsMet =
		hasCompletedLevelRequirements &&
		hasSubmittedBankAccount &&
		hasSubmittedGuarantor;

	const [showShareModal, setShowShareModal] = useState(false);

	return (
		<>
			<Modal
				isOpen={showShareModal}
				onClose={() => setShowShareModal(false)}
				isCentered
				closeOnOverlayClick
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Share Your Certificate</ModalHeader>
					<VStack pb={8} px={6} alignItems="start">
						<HStack>
							<Link
								href={`https://twitter.com/intent/tweet?text=I%20just%20earned%20the%20LaborHack%20${trade.name}%20certificate!%20https://www.laborhack.com/artisans`}
								isExternal
							>
								<Button
									rightIcon={<FaTwitter />}
									colorScheme="twitter"
									variant="solid"
									onClick={() => logEvent("share_certificate_twitter_clicked")}
								>
									Tweet
								</Button>
							</Link>
							<Link
								href={`https://www.facebook.com/sharer.php?u=www.laborhack.com/artisans`}
								isExternal
							>
								<Button
									rightIcon={<FaFacebookF />}
									colorScheme="facebook"
									variant="solid"
									onClick={() => logEvent("share_certificate_facebook_clicked")}
								>
									Post to Facebook
								</Button>
							</Link>
						</HStack>
					</VStack>
				</ModalContent>
			</Modal>
			<Card
				direction={{
					base: "column",
					sm: "row",
				}}
				overflow="hidden"
				variant="outline"
			>
				<ChakraImage
					filter={isActive ? "none" : "grayscale(100%)"}
					objectFit="cover"
					maxW={{ base: "100%", sm: "200px" }}
					src={CertificateThumbnail}
					alt="Certificate thumbnail"
				/>

				<Stack>
					<CardBody>
						<VStack align="start" spacing={4}>
							<VStack align="start">
								<HStack
									w={{
										base: "full",
										md: "500px",
									}}
								>
									<Text fontSize="md" fontWeight="semibold" color="primary.500">
										LaborHack {trade.name} -{" "}
										{LEVEL_ORDER_MARKETING_NAMES[tradeLevel.order].name}
									</Text>
								</HStack>

								<HStack align="center" gap={2}>
									<HStack
										border="1px"
										rounded="full"
										paddingY="1"
										paddingX="3"
										borderColor="primary.200"
									>
										<Icon as={MdTrendingUp} />
										<Text fontSize="sm" color="primary.500">
											Level {tradeLevel.order} of {trade.levels.length}
										</Text>
									</HStack>
									{isActive && (
										<Badge fontSize="md" colorScheme="green">
											Complete
										</Badge>
									)}
								</HStack>
							</VStack>

							<VStack align="start" spacing={1}>
								<Text color="primary.500">
									Requirements to earn this certificate
								</Text>
								<List>
									{hasCompletedLevelRequirements ? (
										tradeLevel.requirements
											.slice()
											.filter((requirement) => {
												if (!toggles.show_interview_requirements) {
													return (
														requirement.type !== TradeRequirementType.INTERVIEW
													);
												}

												return true;
											})
											.map((requirement) => (
												<ListItem marginY={1} key={requirement.id}>
													<HStack spacing={1}>
														<ListIcon as={MdCheckCircle} color="green.500" />
														<Text fontSize="sm" color="primary.400">
															{requirement.name}
														</Text>
													</HStack>
												</ListItem>
											))
									) : (
										<ListItem marginY={1}>
											<HStack spacing={1}>
												<ListIcon as={MdRemoveCircle} color="gray.500" />
												<Text fontSize="sm" color="primary.400">
													Complete level {tradeLevel.order}{" "}
													{tradeLevel.order > 1 && `and previous level`}{" "}
													requirements.{" "}
													<Link
														as={RouterLink}
														to={`/trades/${trade.id}`}
														color="brand.500"
													>
														Take Me There
													</Link>
												</Text>
											</HStack>
										</ListItem>
									)}
									{!hasSubmittedBankAccount && (
										<ListItem marginY={1}>
											<HStack spacing={1}>
												<ListIcon as={MdRemoveCircle} color={"gray.500"} />
												<Text fontSize="sm" color="primary.400">
													Submit bank account information.{" "}
													<Link
														as={RouterLink}
														to={`/bank-account-information`}
														color="brand.500"
													>
														Take Me There
													</Link>
												</Text>
											</HStack>
										</ListItem>
									)}
									{!hasSubmittedGuarantor && (
										<ListItem marginY={1}>
											<HStack spacing={1}>
												<ListIcon as={MdRemoveCircle} color={"gray.500"} />
												<Text fontSize="sm" color="primary.400">
													Submit background check information.{" "}
													<Link
														as={RouterLink}
														to={`/background-check`}
														color="brand.500"
													>
														Take Me There
													</Link>
												</Text>
											</HStack>
										</ListItem>
									)}
									{allBaseRequirementsMet && !hasIdentityVerified && (
										<ListItem marginY={1}>
											<HStack spacing={1}>
												<ListIcon as={MdRemoveCircle} color={"yellow.500"} />
												<Text fontSize="sm" color="primary.400">
													Confirm identity verification.{" "}
													<Link
														href="https://www.laborhack.com/contact"
														isExternal
														color="brand.500"
													>
														Contact Support
													</Link>
												</Text>
											</HStack>
										</ListItem>
									)}
								</List>
							</VStack>
						</VStack>
					</CardBody>

					<CardFooter>
						<Stack
							direction={{
								base: "column",
								sm: "row",
							}}
							w="full"
						>
							<Button
								variant="solid"
								colorScheme="primary"
								rightIcon={<DownloadIcon />}
								isDisabled={true}
								width={{
									base: "full",
									sm: "auto",
								}}
							>
								Download
							</Button>
							<Button
								variant="outline"
								colorScheme="primary"
								rightIcon={<MdShare />}
								isDisabled={true}
								width={{
									base: "full",
									sm: "auto",
								}}
							>
								Share Certificate
							</Button>
						</Stack>
					</CardFooter>
				</Stack>
			</Card>
		</>
	);
};
