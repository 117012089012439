import { Box, Button, Icon, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ImageUpload } from "../../components/ImageUpload";
import { CompleteProfileContext } from "../../contexts/complete-profile.context";

export const IDUpload = () => {
  const navigate = useNavigate();

  const { setIdImageUrl, idImageUrl } = useContext(CompleteProfileContext);

  const handleBack = () => {
    navigate(-1);
  };

  const handleUploaded = (url: string) => {
    setIdImageUrl(url);
  };

  const handleRemove = () => {
    setIdImageUrl(undefined);
  };

  return (
    <VStack gap={2} w='full' alignItems='start'>
      <Button
        onClick={handleBack}
        leftIcon={<Icon as={MdOutlineArrowBack} w='6' h='6' />}
      >
        Back
      </Button>
      <Box w='full' bgColor='white' py={4} px={6}>
        <h2 className='text-lg md:text-xl text-primary-500 font-bold mb-4 md:mb-8'>
          Upload your National ID (NIN)
        </h2>
        <VStack gap={4} alignItems='start'>
          <VStack gap={2} alignItems='start'>
            <p className='text-primary-500 '>
              We'll need to verify your identity against your National ID (NIN).
              Please upload an image of your generated digital ID card from the
              NIMC app.
            </p>
            <Box>
              <h3 className='font-semibold text-primary-500 m-0 p-0'>Steps</h3>
              <p className='text-primary-500 '>
                Please visit{" "}
                <a
                  className='text-primary-300 underline'
                  href='https://nimc.gov.ng/how-to-print-your-improved-nin-slip/'
                  target='_blank'
                  rel='noreferrer'
                >
                  How to print NIN Slip
                </a>{" "}
                for detailed instructions.
              </p>
            </Box>
          </VStack>

          <ImageUpload
            onUploaded={handleUploaded}
            onRemove={handleRemove}
            imageUrl={idImageUrl}
            uploadPathPrefix='nin-slips'
          />
          <Button
            isDisabled={!idImageUrl}
            colorScheme='green'
            rightIcon={<Icon as={MdOutlineArrowForward} />}
            onClick={() => navigate("/complete-profile")}
          >
            Continue
          </Button>
        </VStack>
      </Box>
    </VStack>
  );
};
