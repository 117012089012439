import { Button, HStack, Progress, Stack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useCategories } from "src/hooks/useCategories";
import {
	Gender,
	ProLeadSource,
	ReasonForJoiningLaborHack,
	YearsOfExperience,
} from "src/types";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { FiArrowRight } from "react-icons/fi";
import PersonalInformationForm from "./PersonalInformationForm";
import { RequestFailed } from "./RequestFailed";
import { Loading } from "./Loading";
import ContactAndProfessionalInformationForm from "./ContactAndProfessionalInformation";
import OtherInformation from "./OtherInformation";
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { SUBMIT_APPLICATION } from "src/pages/apply/constants";
import { isDate } from "date-fns";
import { useProfile } from "src/hooks/useProfile";
import { RegistrationConfirmation } from "./ConfirmationLayout";
import { RegistrationSteps } from "../features/registration/types";
import { ExistingPhoneNumber } from "../features/registration/existing-phone-number";

export default function ApplicationFormV2() {
	const {
		profile,
		loading: loadingProfile,
		error: profileError,
	} = useProfile();

	const {
		categories,
		loading: categoriesLoading,
		error: categoriesError,
	} = useCategories();

	const { user, getAccessTokenSilently } = useAuth0();

	/**
	 * Get referredBy from local storage
	 */
	const referredByFromLocalStorage = localStorage.getItem("referredBy");

	/**
	 * Check if lead source exists in local storage
	 */
	const leadSourceFromLocalStorage = localStorage.getItem("leadSource");

	/**
	 * Check if local storage lead source is a valid lead source
	 */
	const localStorageLeadSourceIsValid =
		leadSourceFromLocalStorage &&
		Object.values(ProLeadSource).includes(
			leadSourceFromLocalStorage as ProLeadSource
		);

	const [submitApplication, submitApplicationResponse] = useMutation(
		SUBMIT_APPLICATION,
		{
			onCompleted: () => {
				if (user) {
					user.given_name = step2formData.firstName;
					user.family_name = step2formData.lastName;
				}

				getAccessTokenSilently()
					.then(() => {
						console.log("Token refreshed");
						setRegistrationConfirmationIsOpen(true);
					})
					.catch((error) => {
						/**
						 * Fallback to redirecting without token
						 *
						 * The browser reloads the page and the user is redirected to the login page
						 * which would go through the authentication process again
						 */
						console.log(error);
						window.open("/login", "_self");
					});
			},
			refetchQueries: ["GetMyProfile"],
			awaitRefetchQueries: true,
		}
	);

	const [step, setStep] = React.useState<RegistrationSteps>(1);
	const [registrationConfirmationIsOpen, setRegistrationConfirmationIsOpen] =
		React.useState(false);

	const [step1IsValid, setStep1IsValid] = React.useState(false);

	const step2Schema = z
		.object({
			firstName: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.string()
			),
			lastName: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.string()
			),
			dateOfBirth: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.string()
			),
			gender: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.nativeEnum(Gender)
			),
			categoryId: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.string()
			),
			otherCategoryText: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.string().optional()
			),
		})
		.refine(
			(data) => {
				if (data.firstName && data.firstName.includes(" ")) {
					return false;
				}

				return true;
			},
			{ message: "First name cannot contain spaces", path: ["firstName"] }
		)
		.refine(
			(data) => {
				if (data.lastName && data.lastName.includes(" ")) {
					return false;
				}

				return true;
			},
			{ message: "Last name cannot contain spaces", path: ["lastName"] }
		)
		.refine(
			(data) => {
				if (data.categoryId === "other" && !data.otherCategoryText) {
					return false;
				}

				return true;
			},
			{ message: "Please provide a category", path: ["otherCategoryText"] }
		);

	const {
		register: registerStep2,
		watch: watchStep2,
		setValue: setStep2Value,
		formState: { touchedFields: step2TouchedFields },
	} = useForm<z.infer<typeof step2Schema>>({
		resolver: zodResolver(step2Schema),
	});

	const step3Schema = z.object({
		country: z.object({
			label: z.string(),
			value: z.string(),
		}),
		phoneNumber: z.preprocess(
			(val) => (val === "" ? undefined : val),
			z.string()
		),
		stateOfResidence: z.preprocess(
			(val) => (val === "" ? undefined : val),
			z.string()
		),
		incomeCurrency: z.object({
			label: z.string(),
			value: z.string(),
		}),
		averageMonthlyIncome: z.preprocess(
			(val) => (val === 0 ? undefined : val),
			z.number()
		),
		yearsOfExperience: z.preprocess(
			(val) => (val === "" ? undefined : val),
			z.nativeEnum(YearsOfExperience)
		),
	});

	React.useEffect(() => {
		if (profile) {
			setStep2Value("firstName", profile.firstName);
			setStep2Value("lastName", profile.lastName);
		}
	}, [profile, setStep2Value]);

	const {
		control: controlStep3,
		register: registerStep3,
		watch: watchStep3,
		setValue: setStep3Value,
		formState: { touchedFields: step3TouchedFields },
	} = useForm<z.infer<typeof step3Schema>>({
		resolver: zodResolver(step3Schema),
	});

	useEffect(() => {
		if (profile && profile.phoneNumber && profile.phoneNumberVerified) {
			setStep3Value("phoneNumber", profile.phoneNumber);
			setStep(2);
		}
	}, [profile, setStep3Value, setStep]);

	const step4Schema = z
		.object({
			noOfDaysOfWorkPerWeek: z.preprocess(
				(val) => (val === "" ? undefined : Number(val)),
				z.number()
			),
			leadSource: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.nativeEnum(ProLeadSource)
			),
			otherLeadSource: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.string().optional()
			),
			reasonForJoiningLaborHack: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.nativeEnum(ReasonForJoiningLaborHack)
			),
			reasonForJoiningLaborHackOtherText: z.preprocess(
				(val) => (val === "" ? undefined : val),
				z.string().optional()
			),
		})
		.refine((data) => {
			if (data.reasonForJoiningLaborHack === ReasonForJoiningLaborHack.OTHER) {
				return !!data.reasonForJoiningLaborHackOtherText;
			}

			return true;
		})
		.refine((data) => {
			if (data.leadSource === ProLeadSource.OTHER) {
				return !!data.otherLeadSource;
			}

			return true;
		});

	const {
		register: registerStep4,
		watch: watchStep4,
		setValue: setStep4Value,
		formState: { touchedFields: step4TouchedFields },
	} = useForm<z.infer<typeof step4Schema>>({
		resolver: zodResolver(step4Schema),
	});

	React.useEffect(() => {
		if (leadSourceFromLocalStorage && localStorageLeadSourceIsValid) {
			setStep4Value("leadSource", leadSourceFromLocalStorage as ProLeadSource);
		}
	}, [
		leadSourceFromLocalStorage,
		localStorageLeadSourceIsValid,
		setStep4Value,
	]);

	const goBack = () => {
		if (step === 1) {
			return;
		}

		const newStep = (step - 1) as RegistrationSteps;

		setStep(newStep);
	};

	const step2formData = watchStep2();
	const step3formData = watchStep3();
	const step4formData = watchStep4();
	const step2IsValid = step2Schema.safeParse(step2formData).success;
	const step3IsValid = step3Schema.safeParse(step3formData).success;
	const step4IsValid = step4Schema.safeParse(step4formData).success;

	const stepsValidityMap = {
		1: step1IsValid,
		2: step2IsValid,
		3: step3IsValid,
		4: step4IsValid,
	};

	const canProceed = stepsValidityMap[step];

	const handleProceed = () => {
		const newStep = (step + 1) as 1 | 2 | 3;
		setStep(newStep);
	};

	const dateOfBirth = step2formData.dateOfBirth;

	const isValidDateOfBirth = dateOfBirth && isDate(new Date(dateOfBirth));

	const isOtherCategory = step2formData.categoryId === "other";

	const otherCategory = categories?.find(
		(category) => category.proTitle === "Other"
	);

	const input = {
		firstName: step2formData?.firstName,
		lastName: step2formData?.lastName,
		email: user?.email,
		phoneNumber: step3formData?.phoneNumber,
		dateOfBirth: isValidDateOfBirth
			? new Date(dateOfBirth).toISOString()
			: undefined,
		gender: step2formData?.gender,
		categoryId: isOtherCategory ? otherCategory?.id : step2formData?.categoryId,
		...(step2formData.categoryId === "other" && {
			otherCategoryText: step2formData?.otherCategoryText,
		}),
		stateOfResidence: step3formData?.stateOfResidence,
		country: step3formData?.country?.value,
		leadSource: step4formData?.leadSource,
		...(step4formData?.leadSource === ProLeadSource.OTHER && {
			leadSourceText: step4formData?.otherLeadSource,
		}),
		reasonForJoiningLaborHack: step4formData?.reasonForJoiningLaborHack,
		...(step4formData?.reasonForJoiningLaborHack ===
			ReasonForJoiningLaborHack.OTHER && {
			reasonForJoiningLaborHackOtherText:
				step4formData?.reasonForJoiningLaborHackOtherText,
		}),
		averageMonthlyIncome: step3formData?.averageMonthlyIncome,
		incomeCurrency: step3formData?.incomeCurrency?.value,
		yearsOfExperience: step3formData?.yearsOfExperience,
		numberOfDaysWorkedPerWeek: Number(step4formData?.noOfDaysOfWorkPerWeek),
		referredBy: referredByFromLocalStorage,
	};

	const handleSubmit = () => {
		submitApplication({ variables: { input } });

		localStorage.removeItem("leadSource");

		localStorage.removeItem("referredBy");
	};

	if (categoriesLoading || loadingProfile) {
		return <Loading />;
	}

	if (categoriesError || !categories) {
		return <RequestFailed text="Failed to load categories" />;
	}

	if (profileError) {
		return <RequestFailed text="Failed to load profile" />;
	}

	if (submitApplicationResponse.error) {
		return (
			<RequestFailed
				text={submitApplicationResponse.error.message}
				onRetry={submitApplicationResponse.reset}
			/>
		);
	}

	return (
		<>
			<Helmet>
				<title>Application Form</title>
			</Helmet>
			<Stack
				my={-6}
				mx={{
					base: -10,
					lg: -14,
				}}
			>
				<Stack spacing={0}>
					<HStack
						borderY={"1px"}
						borderColor={"#E7E7E7"}
						py={{
							base: 4,
							lg: 6,
						}}
						textColor={"#8F8F8F"}
						px={{
							base: 6,
							lg: 12,
						}}
					>
						<div
							className="hover:cursor-pointer flex items-center gap-3"
							onClick={goBack}
						>
							<IoChevronBackOutline />
							<span className="text-lg lg:text-xl">Back</span>
						</div>
					</HStack>
					<div className="px-6 lg:px-12 border-b-[1px] border-[#E7E7E7] ">
						{step === 1 && (
							<ExistingPhoneNumber
								onProceed={handleProceed}
								setIsValid={(valid: boolean) => {
									setStep1IsValid(valid);
								}}
								initialPhoneNumber={step3formData.phoneNumber}
								addPhoneNumber={(phoneNumber) => {
									setStep3Value("phoneNumber", phoneNumber);
								}}
							/>
						)}
						{step === 2 && (
							<PersonalInformationForm
								categories={categories}
								registerPersonalInfo={registerStep2}
								touchedFields={step2TouchedFields}
								formData={step2formData}
							/>
						)}
						{step === 3 && (
							<ContactAndProfessionalInformationForm
								control={controlStep3}
								registerContactAndProfessionalInfo={registerStep3}
								setValue={setStep3Value}
								touchedFields={step3TouchedFields}
								formData={step3formData}
							/>
						)}
						{step === 4 && (
							<OtherInformation
								registerOtherInfo={registerStep4}
								touchedFields={step4TouchedFields}
								formData={step4formData}
							/>
						)}
					</div>
					<HStack
						py={6}
						px={{
							base: 6,
							lg: 12,
						}}
						w={"full"}
						justifyContent={"space-between"}
					>
						<Stack
							w={{
								base: "40%",
								lg: "60%",
							}}
						>
							<p className="text-base lg:text-xl text-[#828282]">
								Step <span className="font-medium text-[#212121]">{step}</span>{" "}
								of <span className="font-medium text-[#212121]">4</span>
							</p>
							<Progress
								value={step === 4 ? 100 : step * 25}
								background={"#EEEEEE"}
								colorScheme="brand"
								rounded={"lg"}
							/>
						</Stack>
						<Stack mt={"auto"}>
							<Button
								px={{
									base: "20px",
									lg: "30px",
								}}
								py={{
									base: "20px",
									lg: "26px",
								}}
								fontSize={"20px"}
								fontWeight={500}
								colorScheme="brand"
								rounded={0}
								rightIcon={<FiArrowRight size={"18px"} />}
								isDisabled={!canProceed}
								isLoading={submitApplicationResponse.loading}
								onClick={step === 4 ? handleSubmit : handleProceed}
							>
								{step === 4 ? "Submit" : "Proceed"}
							</Button>
						</Stack>
					</HStack>
				</Stack>
			</Stack>
			{registrationConfirmationIsOpen && <RegistrationConfirmation />}
		</>
	);
}
