import React, { FC, SVGProps } from "react";

export interface LogoProps extends SVGProps<SVGSVGElement> {
  onClick?: () => void;
}

export const Logo: FC<LogoProps> = ({
  style,
  width,
  height,
  fill,
  ...props
}) => {
  return (
    <svg
      width={width || 156}
      height={height || 26}
      viewBox='0 0 1157 158'
      fill={fill || "#ED6E5C"}
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M109.234 122.127V154.785H0V7.69788H36.5597V122.127H109.234Z' />
      <path d='M230.259 49.8774V154.785H195.204V149.165C187.422 154.361 178.112 157.606 166.427 157.606C138.096 157.606 114.302 133.388 114.302 102.236C114.302 71.0837 138.096 47.0781 166.427 47.0781C178.112 47.0781 187.4 50.1106 195.204 55.3062V49.8986H230.259V49.8774ZM195.204 118.459V86.013C188.503 78.6544 181.145 76.7034 173.574 76.7034C159.302 76.7034 148.699 87.7307 148.699 102.236C148.699 116.741 159.302 127.768 173.574 127.768C181.145 127.768 188.503 125.817 195.204 118.459Z' />
      <path d='M363.858 102.448C363.858 133.812 340.065 157.606 311.733 157.606C300.261 157.606 290.972 154.573 283.189 149.611V154.806H247.923V1.20886H283.189V55.4971C290.972 50.5136 300.282 47.5023 311.733 47.5023C340.065 47.5023 363.858 71.2958 363.858 102.448ZM329.462 102.448C329.462 88.1761 319.071 77.1488 304.799 77.1488C297.228 77.1488 289.657 79.0997 283.168 86.2251V119.095C289.657 126.241 297.228 128.171 304.799 128.171C319.071 128.171 329.462 117.144 329.462 102.427V102.448Z' />
      <path d='M485.646 102.448C485.646 134.894 461.853 157.606 428.113 157.606C394.374 157.606 370.347 134.894 370.347 102.448C370.347 70.0022 394.353 47.0781 428.113 47.0781C461.874 47.0781 485.646 70.2354 485.646 102.448ZM452.776 102.448C452.776 87.3065 442.619 76.4913 428.113 76.4913C413.608 76.4913 403.238 87.3065 403.238 102.448C403.238 117.589 413.396 128.404 428.113 128.404C442.831 128.404 452.776 117.801 452.776 102.448Z' />
      <path d='M569.093 81.4536C552.001 82.111 541.186 86.6491 532.979 94.6439V154.785H497.713V49.8773H532.979V61.1167C542.288 53.1219 553.528 48.1384 569.093 48.1384V81.4536Z' />
      <path d='M713.169 7.69788V154.785H676.822V99.6273H616.914V154.785H580.354V7.69788H616.914V66.7575H676.822V7.69788H713.169Z' />
      <path d='M844.67 49.8774V154.785H809.616V149.165C801.833 154.361 792.523 157.606 780.839 157.606C752.507 157.606 728.713 133.388 728.713 102.236C728.713 71.0837 752.507 47.0781 780.839 47.0781C792.523 47.0781 801.812 50.1106 809.616 55.3062V49.8986H844.67V49.8774ZM809.616 118.459V86.013C802.914 78.6544 795.556 76.7034 787.985 76.7034C773.713 76.7034 763.11 87.7307 763.11 102.236C763.11 116.741 773.713 127.768 787.985 127.768C795.556 127.768 802.914 125.817 809.616 118.459Z' />
      <path d='M934.818 111.736H967.921C965.334 138.774 943.046 157.584 914.057 157.584C881.187 157.584 856.736 134.215 856.736 102.215C856.736 70.2142 881.187 47.0569 914.057 47.0569C943.046 47.0569 965.313 65.8881 967.921 92.905H934.818C933.079 83.3833 924.872 76.6821 914.269 76.6821C899.128 76.6821 890.263 87.9215 890.263 102.215C890.263 116.508 899.128 127.959 914.269 127.959C924.872 127.959 933.1 121.258 934.818 111.736Z' />
      <path d='M1029.99 118.438L1015.51 134.003V154.764H980.242V1.20886H1015.51V89.0243L1051.86 49.8774H1093.61L1053.81 92.7142L1097.07 154.806H1055.1L1030.01 118.459L1029.99 118.438Z' />
      <path d='M1120.9 7.80393H1112.02V28.5013H1103.62V7.80393H1094.84V0H1120.92V7.80393H1120.9ZM1156.85 28.5013H1148.45V15.7139L1142.19 24.8962V25.0023H1137.8V24.8962L1131.46 15.6079L1131.57 28.5013H1123.28V0H1129.53L1140.07 14.5475L1150.53 0H1156.87V28.5225L1156.85 28.5013Z' />
    </svg>
  );
};
