import { Box, Button, HStack, Link, VStack } from "@chakra-ui/react";
import { FaArrowRight, FaTelegramPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import community from "../../assets/community.svg";

export const TradesCompleteProfileSuccess = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/trades");
  };

  return (
    <VStack w='full' bg='white' px='4' py='12' gap='8'>
      <h1 className='text-2xl text-primary-500 font-semibold text-center'>
        Join the LaborHack community
      </h1>
      <img
        className=' w-64'
        src={community}
        alt='a user holding a large phone with a checkmark on the screen'
      />
      <Box>
        <p className='text-primary-300 my-2 text-center'>
          Thank you for signing up! Stay updated on our latest news, updates,
          and announcements by joining our Telegram group. Click the button
          below to join. See you there!
        </p>
      </Box>

      <HStack w='full' justify='center' marginTop={4}>
        <Link href='https://t.me/+E5qyuEDJXbozMTE0' target='_blank'>
          <Button colorScheme='telegram' leftIcon={<FaTelegramPlane />}>
            Join
          </Button>
        </Link>

        <Button rightIcon={<FaArrowRight />} onClick={handleContinue}>
          Continue
        </Button>
      </HStack>
    </VStack>
  );
};
