import { gql } from "../../__generated__";

export const START_PRO_PHONE_VERIFICATION = gql(`
	mutation StartProPhoneNumberVerification($phoneNumber: String!) {
		startProPhoneNumberVerification(phoneNumber: $phoneNumber)
	}
`);

export const SEND_OTP = gql(`
	mutation SendOTP($phoneNumber: String!) {
		sendOneTimePasscode(phoneNumber: $phoneNumber) 
	}
`);

export const VERIFY_OTP = gql(`
	mutation VerifyProOTP($phoneNumber: String!, $otp: String!) {
		verifyProPhoneNumber(phoneNumber: $phoneNumber, code: $otp) {
			success
			message
			code
		}
	}
	`);
