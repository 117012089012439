import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Image,
  Progress,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Trade } from "../../types";

export interface TradeCardProps {
  trade: Trade;
  isEnrolled?: boolean;
}

export const TradeCard = ({ trade, isEnrolled }: TradeCardProps) => {
  const progress = ((trade.currentLevel || 0) / trade.levels.length) * 100;

  let colorScheme = "yellow";
  if (progress === 100) colorScheme = "green";
  if (progress === 0) colorScheme = "gray";

  return (
    <VStack
      w='full'
      align='start'
      border='1px'
      rounded='lg'
      borderColor='primary.150'
      maxW={{
        base: "full",
        sm: "full",
        md: "xs",
      }}
    >
      <Box position='relative' w='full'>
        {isEnrolled && (
          <Tag borderRadius='none' position='absolute'>
            Level {trade.currentLevel}
          </Tag>
        )}

        <Image
          src={trade.imageUrl}
          alt='all'
          w='100%'
          height={120}
          fit='cover'
          roundedTop={"lg"}
        />
      </Box>

      <HStack>
        <HStack gap='2' padding={4} align='start'>
          <Text fontWeight='semibold' color='primary.500'>
            {trade.name}
          </Text>
        </HStack>
      </HStack>

      {isEnrolled && (
        <Progress
          size='xs'
          value={progress}
          w='full'
          colorScheme={colorScheme}
        />
      )}

      <HStack padding='4' justify='space-between' w='full'>
        <Text color='primary.500' fontSize='sm'>
          <strong>{progress.toFixed(0)}%</strong> complete
        </Text>

        <Link to={"/trades/" + trade.id}>
          <Button rightIcon={<ArrowForwardIcon />} size='sm'>
            {isEnrolled ? "Continue" : "View Trade"}
          </Button>
        </Link>
      </HStack>
    </VStack>
  );
};
