import { gql, useQuery } from "@apollo/client";
import { ProApplication } from "../types";

const GET_PRO_APPLICATION = gql`
  query GetMyProApplication {
    myProApplication {
      id
      userId
      firstName
      lastName
      dateOfBirth
      country
      categoryId
      yearsOfExperience
      trainingType
      incomeLevel
      numberOfDaysWorkedPerWeek
      otherCategoryText
      employmentStatus
      currentIndustry
      highestEducationLevel
      otherOccupationText
      hasInternationalPassport
      emergencyContactName
      emergencyContactPhone
      yearOfGraduation
      passportNumber
      passportIssueDate
      passportExpiryDate
      passportIssueAuthority
    }
  }
`;

export const useProApplication = () => {
  const { data, loading, error } = useQuery<{
    myProApplication: ProApplication;
  }>(GET_PRO_APPLICATION);

  return {
    proApplication: data?.myProApplication,
    loading,
    error,
  };
};
