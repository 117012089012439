import { CheckIcon } from "@chakra-ui/icons";
import {
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	Modal,
	ModalContent,
	ModalOverlay,
	ModalHeader,
	ModalBody,
	ModalFooter,
	HStack,
	PinInput,
	PinInputField,
	Stack,
	Heading,
	Text,
	Link,
	Icon,
} from "@chakra-ui/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { PortfolioItemCreatorLayout } from "../../../features/pro-portfolio/creator-layout";
import { useProfile } from "../../../hooks/useProfile";
import {
	cleanPhoneNumber,
	isValidIntlPhoneNumber,
} from "../../../utlis/helpers";
import { countriesData } from "../../../constants";
import { Check, ChevronsUpDown, Loader2 } from "lucide-react";

import { cn } from "@/lib/utils";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Button as SButton } from "@/components/ui/button";
import { useMutation } from "@apollo/client";
import {
	ConfirmationLayout,
	ConfirmationLayoutContent,
} from "@/components/ConfirmationLayout";
import ConfirmationImage from "@/assets/registration-confirmation.svg";
import { FaWhatsapp } from "react-icons/fa";
import { RequestFailed } from "../../../components/RequestFailed";
import {
	START_PRO_PHONE_VERIFICATION,
	VERIFY_OTP,
} from "../../../common/graphql/typed-mutations";

const Layout = ({ children }: PropsWithChildren) => {
	return (
		<PortfolioItemCreatorLayout
			title="Chat with AIna"
			description="Create and send professional invoices to your client. Connect your WhatsApp number to chat with AIna"
		>
			{children}
		</PortfolioItemCreatorLayout>
	);
};

export default function ChatWithAInaPage() {
	const { loading, error, profile } = useProfile();

	const [open, setOpen] = useState(false);

	const [editing, setEditing] = useState(false);

	const [sendCodeConfirmationOpen, setSendCodeConfirmationOpen] =
		useState(false);

	const [sendOneTimePasscode, sendOneTimePasscodeHandle] = useMutation(
		START_PRO_PHONE_VERIFICATION,
		{
			onCompleted: (data) => {
				setSendCodeConfirmationOpen(false);
				setIsSent(true);
			},
		}
	);

	const [verifyOneTimePasscode, verifyOneTimePasscodeHandle] = useMutation(
		VERIFY_OTP,
		{
			refetchQueries: ["GetMyProfile"],
			awaitRefetchQueries: true,
		}
	);

	const [phoneNumber, setPhoneNumber] = useState("");

	const [isSent, setIsSent] = useState(false);

	const [selectedCountry, setSelectedCountry] = useState<
		(typeof countriesData)[number] | undefined
	>(
		countriesData.find(
			(country) => country.shortName === "NG"
		) as (typeof countriesData)[number]
	);

	useEffect(() => {
		if (loading) return;

		if (profile?.phoneNumber && isValidIntlPhoneNumber(profile.phoneNumber)) {
			setPhoneNumber(cleanPhoneNumber(profile.phoneNumber));
		}
	}, [profile?.phoneNumber, profile?.phoneNumberVerified, loading]);

	const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPhoneNumber(e.target.value);
	};

	const cleaned = cleanPhoneNumber(phoneNumber);

	const combinedPhoneNumber = `+${
		selectedCountry?.dialingCode
	}${cleanPhoneNumber(phoneNumber)}`;

	const isValidPhoneNumber = isValidIntlPhoneNumber(combinedPhoneNumber);

	useEffect(() => {
		if (isValidPhoneNumber && cleaned) {
			setPhoneNumber(cleaned);
		}
	}, [isValidPhoneNumber, phoneNumber, cleaned]);

	if (loading) return <div>Loading...</div>;

	if (error || !profile) return <div>Error...</div>;

	const isPhoneNumberVerified = profile?.phoneNumberVerified;

	const handleSendCode = () => {
		sendOneTimePasscode({
			variables: {
				phoneNumber: combinedPhoneNumber,
			},
		});
	};

	const handleVerifyCode = (oneTimePasscode: string) => {
		verifyOneTimePasscode({
			variables: {
				phoneNumber: combinedPhoneNumber,
				otp: oneTimePasscode,
			},
		});
	};

	if (sendOneTimePasscodeHandle.error) {
		return (
			<RequestFailed
				text={sendOneTimePasscodeHandle.error.graphQLErrors[0]?.message}
				onRetry={sendOneTimePasscodeHandle.reset}
			/>
		);
	}

	if (isPhoneNumberVerified) {
		return (
			<Layout>
				<div className="flex w-full justify-center">
					<ConfirmationLayout className="max-w-[600px]">
						<ConfirmationLayoutContent padding={0}>
							<Stack spacing={6}>
								<img src={ConfirmationImage} alt="check icon" />
								<Stack px={4}>
									<Heading
										as="h2"
										size="md"
										textAlign="center"
										fontWeight={500}
										textColor={"#153243"}
									>
										Account Setup Complete
									</Heading>
									<Text
										fontSize="md"
										textAlign="center"
										fontWeight={500}
										textColor={"#5E5E5E"}
									>
										You have successfully set up{" "}
										<strong>{profile.phoneNumber}</strong> as your WhatsApp
										number to chat with AIna.
									</Text>
								</Stack>

								<Stack w={"full"}>
									<Button
										as={Link}
										href={`${process.env.REACT_APP_ARTISAN_ASSISTANT_WHATSAPP_BOT}`}
										isExternal
										w={"full"}
										colorScheme="brand"
										rounded={0}
										size="lg"
										p={8}
										fontWeight={500}
										leftIcon={
											<Icon as={FaWhatsapp} w={6} h={6} color="white" />
										}
									>
										Chat with Aina
									</Button>

									<Text fontSize={"sm"} textAlign="center">
										If you need to change your WhatsApp number, please contact
										our support team.
									</Text>
								</Stack>
							</Stack>
						</ConfirmationLayoutContent>
					</ConfirmationLayout>
				</div>
			</Layout>
		);
	}

	if (isSent) {
		return (
			<Layout>
				<div className="flex flex-col gap-4">
					<FormControl
						isInvalid={
							!!verifyOneTimePasscodeHandle.data &&
							!verifyOneTimePasscodeHandle.data.verifyProPhoneNumber.success
						}
					>
						<FormLabel>Please enter the OTP</FormLabel>

						<HStack>
							<PinInput
								isDisabled={verifyOneTimePasscodeHandle.loading}
								onComplete={(value) => {
									handleVerifyCode(value);
								}}
								isInvalid={
									!!verifyOneTimePasscodeHandle.data &&
									!verifyOneTimePasscodeHandle.data.verifyProPhoneNumber.success
								}
								otp
							>
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
							</PinInput>
							{verifyOneTimePasscodeHandle.loading && (
								<Loader2 className="animate-spin" />
							)}
						</HStack>

						<FormHelperText>
							Enter the 6-digit code sent to your WhatsApp number
						</FormHelperText>
						{verifyOneTimePasscodeHandle.data?.verifyProPhoneNumber.success ===
							false && (
							<FormErrorMessage>
								Invalid verification code. Please try again.
							</FormErrorMessage>
						)}
					</FormControl>

					<div className="flex justify-end">
						<Button
							colorScheme="brand"
							variant="link"
							onClick={() => {
								handleSendCode();
							}}
							isLoading={sendOneTimePasscodeHandle.loading}
							loadingText={"Resending..."}
						>
							Resend code via WhatsApp
						</Button>
					</div>
				</div>
			</Layout>
		);
	}

	if (editing) {
		return (
			<Layout>
				<Modal
					isOpen={sendCodeConfirmationOpen}
					onClose={() => {
						setSendCodeConfirmationOpen(false);
					}}
				>
					<ModalOverlay />
					<ModalContent rounded="none">
						<ModalHeader>Confirmation</ModalHeader>
						<ModalBody>
							<p>
								You are about to send a verification code to the number{" "}
								<strong>{combinedPhoneNumber}</strong> on WhatsApp. Do you want
								to proceed?
							</p>
						</ModalBody>
						<ModalFooter>
							<Button
								variant="ghost"
								colorScheme="red"
								onClick={() => {
									setSendCodeConfirmationOpen(false);
								}}
								isLoading={sendOneTimePasscodeHandle.loading}
							>
								Cancel
							</Button>
							<Button
								colorScheme="green"
								onClick={() => {
									handleSendCode();
								}}
								isLoading={sendOneTimePasscodeHandle.loading}
							>
								Yes, Send
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<div className="flex flex-col gap-4">
					<FormControl isInvalid={!isValidPhoneNumber}>
						<FormLabel>Please provide your WhatsApp number</FormLabel>

						<div className="flex items-center gap-2">
							<Popover open={open} onOpenChange={setOpen}>
								<PopoverTrigger asChild>
									<SButton
										variant="outline"
										role="combobox"
										aria-expanded={open}
										className="w-[150px] justify-between rounded-none"
									>
										{selectedCountry
											? `
										${selectedCountry.shortName} (+${selectedCountry.dialingCode})
									`
											: "Select Country..."}
										<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
									</SButton>
								</PopoverTrigger>
								<PopoverContent className="w-[200px] p-0">
									<Command>
										<CommandInput placeholder="Choose Country" />
										<CommandList>
											<CommandEmpty>No framework found.</CommandEmpty>
											<CommandGroup>
												{countriesData
													.filter(
														(
															country
														): country is (typeof countriesData)[number] & {
															shortName: string;
														} => !!country.shortName
													)
													.map((country) => {
														const {
															key,
															name,
															dialingCode,
															nameAndDialingCode,
														} = country;

														return (
															<CommandItem
																key={key}
																value={nameAndDialingCode}
																onSelect={() => {
																	setSelectedCountry(country);
																	setOpen(false);
																}}
															>
																<Check
																	className={cn(
																		"mr-2 h-4 w-4",
																		selectedCountry?.dialingCode === dialingCode
																			? "opacity-100"
																			: "opacity-0"
																	)}
																/>
																{name} (+{dialingCode})
															</CommandItem>
														);
													})}
											</CommandGroup>
										</CommandList>
									</Command>
								</PopoverContent>
							</Popover>
							<InputGroup>
								<Input
									placeholder="Enter your WhatsApp number"
									type="tel"
									value={phoneNumber}
									onChange={handlePhoneNumberChange}
									className="rounded-none"
								/>
								{isValidPhoneNumber && (
									<InputRightElement>
										<CheckIcon color="green.500" />
									</InputRightElement>
								)}
							</InputGroup>
						</div>

						{!isValidPhoneNumber ? (
							<FormHelperText>
								Enter a valid phone number. e.g. 8123456789
							</FormHelperText>
						) : (
							<FormErrorMessage>
								Invalid phone number. e.g. 8123456789
							</FormErrorMessage>
						)}
					</FormControl>
					<div className="flex justify-end">
						<Button
							isDisabled={!isValidPhoneNumber}
							colorScheme="brand"
							onClick={() => setSendCodeConfirmationOpen(true)}
							isLoading={sendOneTimePasscodeHandle.loading}
							loadingText="Sending..."
						>
							Send Verification Code
						</Button>
					</div>
				</div>
			</Layout>
		);
	}

	return (
		<Layout>
			<div className="flex flex-col gap-4">
				<Heading as="h2" size="md">
					Verify your WhatsApp number
				</Heading>
				<Text>
					Your WhatsApp number is currently set to{" "}
					<strong>{profile.phoneNumber}</strong>. If this is incorrect, please
					update it by clicking the button below.
				</Text>
				<div className="flex justify-end gap-2">
					<Button
						onClick={() => {
							setEditing(true);
						}}
						isLoading={sendOneTimePasscodeHandle.loading}
						loadingText="Sending..."
					>
						Update Phone Number
					</Button>
					<Button
						colorScheme="brand"
						onClick={() => {
							handleSendCode();
						}}
						isLoading={sendOneTimePasscodeHandle.loading}
						loadingText="Sending..."
					>
						Send Verification Code
					</Button>
				</div>
			</div>
		</Layout>
	);
}
