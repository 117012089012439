import {
	IncomeLevel,
	ProLeadSource,
	ReasonForJoiningLaborHack,
	TrainingType,
	YearsOfExperience,
} from "../../../types";

export const experienceLevelOptions = [
	{ label: "less than 1 year", value: YearsOfExperience.UNDER_ONE },
	{ label: "1 year", value: YearsOfExperience.ONE },
	{ label: "2 years", value: YearsOfExperience.TWO },
	{ label: "3 years", value: YearsOfExperience.THREE },
	{ label: "4 years", value: YearsOfExperience.FOUR },
	{ label: "5 years", value: YearsOfExperience.FIVE },
	{ label: "6 - 10 years", value: YearsOfExperience.SIX_TO_TEN },
	{ label: "more than 10 years", value: YearsOfExperience.ABOVE_TEN },
];

export const trainingTypeOptions = [
	{ label: "Government Technical College", value: TrainingType.COLLEGE },
	{ label: "Polytechnic", value: TrainingType.POLYTECHNIC },
	{ label: "TVET Training Institute", value: TrainingType.TRAINING_INSTITUTE },
	{ label: "Apprenticeship", value: TrainingType.APPRENTICE },
	{ label: "Other", value: TrainingType.OTHER },
];

export const incomeLevelOptions = [
	{ label: "Below NGN30,000.00", value: IncomeLevel.UNDER_30K },
	{
		label: "NGN30,000.00 - NGN50,000.00",
		value: IncomeLevel.BETWEEN_30K_AND_50K,
	},
	{
		label: "NGN50,000.00 - NGN70,000.00",
		value: IncomeLevel.BETWEEN_50K_AND_70K,
	},
	{
		label: "NGN70,000.00 - NGN100,000.00",
		value: IncomeLevel.BETWEEN_70K_AND_100K,
	},
	{
		label: "NGN100,000.00 - NGN150,000.00",
		value: IncomeLevel.BETWEEN_100K_AND_150K,
	},
	{
		label: "NGN150,000.00 - NGN200,000.00",
		value: IncomeLevel.BETWEEN_150K_AND_200K,
	},
	{ label: "Above NGN200,000.00", value: IncomeLevel.OVER_200K },
];

export const leadSourceOptions = [
	{ label: "Radio", value: ProLeadSource.RADIO },
	{ label: "From A Friend", value: ProLeadSource.FROM_A_FRIEND },
	{ label: "Flyer", value: ProLeadSource.FLYER },
	{ label: "Social Media", value: ProLeadSource.SOCIAL_MEDIA },
	{ label: "Google Search", value: ProLeadSource.GOOGLE },
	{ label: "OPL Academy", value: ProLeadSource.OPL_ACADEMY },
	{
		label: "LaborHack Community Outreach Team",
		value: ProLeadSource.COMMUNITY_OUTREACH,
	},
	{ label: "Other", value: ProLeadSource.OTHER },
];

export const reasonsForJoiningLaborHackOptions = [
	{
		label: "My personal portfolio",
		value: ReasonForJoiningLaborHack.MY_PERSONAL_PORTFOLIO,
	},
	{ label: "Invoicing tool", value: ReasonForJoiningLaborHack.INVOICING_TOOL },
	{ label: "Tool financing", value: ReasonForJoiningLaborHack.TOOL_FINANCING },
	{ label: "Local jobs", value: ReasonForJoiningLaborHack.LOCAL_JOBS },
	{
		label: "International jobs",
		value: ReasonForJoiningLaborHack.INTERNATIONAL_JOBS,
	},
	{
		label: "Certifications",
		value: ReasonForJoiningLaborHack.CERTIFICATIONS,
	},
	{ label: "Other", value: ReasonForJoiningLaborHack.OTHER },
];
