import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { MdArrowForward, MdLink } from "react-icons/md";
import { GET_PRO_TRADE_LEVEL_PAYMENTS } from "../../common/graphql/queries";
import { RequestFailed } from "../../components/RequestFailed";
import { ProTradeLevelPayment } from "../../types";
import { formatAsNaira } from "../../utlis/helpers";

const ADD_DISCOUNT_CODE = gql`
  mutation AddDiscountCode(
    $tradeLevelPaymentId: String!
    $discountCode: String!
  ) {
    addDiscountToProTradeLevelPayment(
      tradeLevelPaymentId: $tradeLevelPaymentId
      discountCode: $discountCode
    ) {
      id
      affectedTradeRequirements {
        id
        name
        price
      }
      payments {
        id
        amount
        checkout
      }
      discount {
        id
        code
        description
        value
      }
    }
  }
`;

export interface TradeLevelCheckoutProps {
  tradeLevelId: string;
  proId: string;
  onClose: () => void;
}

export const TradeLevelCheckout = ({
  tradeLevelId,
  proId,
  onClose,
}: TradeLevelCheckoutProps) => {
  const {
    data: getProTradeLevelPaymentsData,
    loading: getProTradeLevelPaymentsLoading,
  } = useQuery<{
    proTradeLevelPayments: ProTradeLevelPayment[];
  }>(GET_PRO_TRADE_LEVEL_PAYMENTS, {
    variables: {
      tradeLevelId,
      proId,
    },
    fetchPolicy: "network-only",
  });

  const [
    addDiscountCode,
    {
      loading: addDiscountCodeLoading,
      error: addDiscountCodeError,
      reset: addDiscountCodeReset,
    },
  ] = useMutation<{
    addDiscountToProTradeLevelPayment: ProTradeLevelPayment;
  }>(ADD_DISCOUNT_CODE);

  const [discountCode, setDiscountCode] = React.useState<string>("");

  const tradeLevelPayment = getProTradeLevelPaymentsData?.proTradeLevelPayments
    .slice()
    .sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    })[0];

  const handleContinue = () => {
    onClose();
  };

  if (getProTradeLevelPaymentsLoading) return <div>Loading...</div>;

  if (!tradeLevelPayment) return <RequestFailed onRetry={onClose} />;

  const { payments, discount: existingDiscount } = tradeLevelPayment;

  let discount;

  if (!existingDiscount?.isRevoked) {
    discount = existingDiscount;
  }

  const subTotal = tradeLevelPayment.affectedTradeRequirements.reduce(
    (acc, tradeRequirement) => acc + tradeRequirement.price,
    0
  );

  const mostRecentPayment = payments[payments.length - 1];

  const isFullyDiscounted = discount?.value === 100;

  const discountAmount = ((discount?.value || 0) / 100) * subTotal;

  const handleAddDiscountCode = async () => {
    addDiscountCode({
      variables: {
        tradeLevelPaymentId: tradeLevelPayment.id,
        discountCode,
      },
    });
  };

  return (
    <VStack w='full' gap={8} paddingY='4'>
      <VStack w='full' align='start' gap={2}>
        {tradeLevelPayment.affectedTradeRequirements.map((tradeRequirement) => {
          return (
            <VStack align='start' key={tradeRequirement.id}>
              <Text color='primary.500' fontWeight='semibold'>
                {tradeRequirement.name}
              </Text>
              <Text fontSize='sm' color='primary.500'>
                {formatAsNaira(tradeRequirement.price)}
              </Text>
            </VStack>
          );
        })}
      </VStack>

      {discount ? (
        <Alert status='info' variant='top-accent' fontSize='sm'>
          {/* <AlertIcon /> */}
          <Box>
            <AlertTitle>{discount.code}</AlertTitle>
            <AlertDescription>{discount.description}</AlertDescription>
          </Box>
        </Alert>
      ) : (
        <FormControl isInvalid={!!addDiscountCodeError}>
          <FormLabel color='primary.500' fontSize='sm'>
            Discount Code
          </FormLabel>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              placeholder='Enter Code'
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              onFocus={() => !!addDiscountCodeError && addDiscountCodeReset()}
            />
            <InputRightElement width='4.5rem'>
              <Button
                h='1.75rem'
                size='sm'
                isLoading={addDiscountCodeLoading}
                isDisabled={!discountCode}
                onClick={handleAddDiscountCode}
              >
                Apply
              </Button>
            </InputRightElement>
          </InputGroup>
          {addDiscountCodeError && (
            <FormErrorMessage>{addDiscountCodeError.message}</FormErrorMessage>
          )}
        </FormControl>
      )}

      <TableContainer w='full'>
        <Table variant='simple' w='full' size='sm'>
          <Tbody>
            <Tr>
              <Td color='primary.300'>Sub Total</Td>
              <Td color='primary.500' isNumeric>
                {formatAsNaira(subTotal)}
              </Td>
            </Tr>
            <Tr>
              <Td color='primary.300'>Discount ({discount?.value || 0}%)</Td>
              <Td color='primary.500' isNumeric>
                {" "}
                - {formatAsNaira(discountAmount)}
              </Td>
            </Tr>
            <Tr>
              <Td color='primary.500' fontSize='md' fontWeight='semibold'>
                Total
              </Td>
              <Td
                color='primary.500'
                fontSize='md'
                fontWeight='semibold'
                isNumeric
              >
                {formatAsNaira(subTotal - discountAmount || 0)}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <HStack w='full' justify='end'>
        {isFullyDiscounted ? (
          <Button
            onClick={handleContinue}
            rightIcon={<Icon as={MdArrowForward} />}
          >
            Continue
          </Button>
        ) : (
          <Button
            onClick={() => window.open(mostRecentPayment?.checkout, "_self")}
            rightIcon={<Icon as={MdLink} />}
          >
            Pay Now
          </Button>
        )}
      </HStack>
    </VStack>
  );
};
