interface EventPayload {
  [key: string]: any;
}

declare global {
  interface Window {
    gtag: (
      command: string,
      nameOrParams: string | EventPayload,
      params?: EventPayload
    ) => void;
  }
}

export function logEvent(name: string, payload?: EventPayload) {
  if (typeof window.gtag === "function") {
    window.gtag("event", name, payload);
  }
}
