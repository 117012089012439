export const largeCountriesData = [
	{
		FIFA: "AFG",
		Dial: 93,
		"ISO3166-1-Alpha-3": "AFG",
		MARC: "af",
		is_independent: "Yes",
		"ISO3166-1-numeric": 4,
		GAUL: 1,
		FIPS: "AF",
		WMO: "AF",
		"ISO3166-1-Alpha-2": "AF",
		ITU: "AFG",
		IOC: "AFG",
		DS: "AFG",
		"UNTERM Spanish Formal": "la República Islámica del Afganistán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Afghanistan",
		"UNTERM French Short": "Afghanistan (l')",
		"ISO4217-currency_name": "Afghani",
		"UNTERM Russian Formal": "Исламская Республика Афганистан",
		"UNTERM English Short": "Afghanistan",
		"ISO4217-currency_alphabetic_code": "AFN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Afganistán (el)",
		"ISO4217-currency_numeric_code": 971,
		"UNTERM Chinese Formal": "阿富汗伊斯兰共和国",
		"UNTERM French Formal": "la République islamique d'Afghanistan",
		"UNTERM Russian Short": "Афганистан",
		M49: 4,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "أفغانستان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية أفغانستان الإسلامية",
		"UNTERM Chinese Short": "阿富汗",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Afganistán",
		"UNTERM English Formal": "the Islamic Republic of Afghanistan",
		official_name_cn: "阿富汗",
		official_name_en: "Afghanistan",
		"ISO4217-currency_country_name": "AFGHANISTAN",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Asia",
		"UNTERM Arabic Short": "أفغانستان",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Афганистан",
		"Global Name": "World",
		Capital: "Kabul",
		Continent: "AS",
		TLD: ".af",
		Languages: "fa-AF,ps,uz-AF,tk",
		"Geoname ID": 1149361,
		"CLDR display name": "Afghanistan",
		EDGAR: "B2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q889",
	},
	{
		FIFA: "AIA",
		Dial: "1-264",
		"ISO3166-1-Alpha-3": "AIA",
		MARC: "am",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 660,
		GAUL: 9,
		FIPS: "AV",
		WMO: null,
		"ISO3166-1-Alpha-2": "AI",
		ITU: "AIA",
		IOC: "AIA",
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Anguilla",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "East Caribbean Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "XCD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 951,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 660,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "أنغويلا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Anguila",
		"UNTERM English Formal": null,
		official_name_cn: "安圭拉",
		official_name_en: "Anguilla",
		"ISO4217-currency_country_name": "ANGUILLA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Ангилья",
		"Global Name": "World",
		Capital: "The Valley",
		Continent: null,
		TLD: ".ai",
		Languages: "en-AI",
		"Geoname ID": 3573511,
		"CLDR display name": "Anguilla",
		EDGAR: "1A",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q25228",
	},
	{
		FIFA: "ALB",
		Dial: 355,
		"ISO3166-1-Alpha-3": "ALB",
		MARC: "aa",
		is_independent: "Yes",
		"ISO3166-1-numeric": 8,
		GAUL: 3,
		FIPS: "AL",
		WMO: "AB",
		"ISO3166-1-Alpha-2": "AL",
		ITU: "ALB",
		IOC: "ALB",
		DS: "AL",
		"UNTERM Spanish Formal": "la República de Albania",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Albanie",
		"UNTERM French Short": "Albanie (l')",
		"ISO4217-currency_name": "Lek",
		"UNTERM Russian Formal": "Республика Албания",
		"UNTERM English Short": "Albania",
		"ISO4217-currency_alphabetic_code": "ALL",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Albania",
		"ISO4217-currency_numeric_code": 8,
		"UNTERM Chinese Formal": "阿尔巴尼亚共和国",
		"UNTERM French Formal": "la République d'Albanie",
		"UNTERM Russian Short": "Албания",
		M49: 8,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "ألبانيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية ألبانيا",
		"UNTERM Chinese Short": "阿尔巴尼亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Albania",
		"UNTERM English Formal": "the Republic of Albania",
		official_name_cn: "阿尔巴尼亚",
		official_name_en: "Albania",
		"ISO4217-currency_country_name": "ALBANIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "ألبانيا",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Албания",
		"Global Name": "World",
		Capital: "Tirana",
		Continent: "EU",
		TLD: ".al",
		Languages: "sq,el",
		"Geoname ID": 783754,
		"CLDR display name": "Albania",
		EDGAR: "B3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q222",
	},
	{
		FIFA: "ALD",
		Dial: 358,
		"ISO3166-1-Alpha-3": "ALA",
		MARC: null,
		is_independent: "Part of FI",
		"ISO3166-1-numeric": 248,
		GAUL: 1242,
		FIPS: null,
		WMO: null,
		"ISO3166-1-Alpha-2": "AX",
		ITU: null,
		IOC: null,
		DS: "FIN",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles d’Åland",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 248,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "جزر ألاند",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Åland",
		"UNTERM English Formal": null,
		official_name_cn: "奥兰群岛",
		official_name_en: "Åland Islands",
		"ISO4217-currency_country_name": "ÅLAND ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Аландских островов",
		"Global Name": "World",
		Capital: "Mariehamn",
		Continent: "EU",
		TLD: ".ax",
		Languages: "sv-AX",
		"Geoname ID": 661882,
		"CLDR display name": "Kepulauan Aland",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q5689",
	},
	{
		FIFA: "ALG",
		Dial: 213,
		"ISO3166-1-Alpha-3": "DZA",
		MARC: "ae",
		is_independent: "Yes",
		"ISO3166-1-numeric": 12,
		GAUL: 4,
		FIPS: "AG",
		WMO: "AL",
		"ISO3166-1-Alpha-2": "DZ",
		ITU: "ALG",
		IOC: "ALG",
		DS: "DZ",
		"UNTERM Spanish Formal": "la República Argelina Democrática y Popular",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Algérie",
		"UNTERM French Short": "Algérie (l')",
		"ISO4217-currency_name": "Algerian Dinar",
		"UNTERM Russian Formal": "Алжирская Народная Демократическая Республика",
		"UNTERM English Short": "Algeria",
		"ISO4217-currency_alphabetic_code": "DZD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Argelia",
		"ISO4217-currency_numeric_code": 12,
		"UNTERM Chinese Formal": "阿尔及利亚民主人民共和国",
		"UNTERM French Formal":
			"la République algérienne démocratique et populaire",
		"UNTERM Russian Short": "Алжир",
		M49: 12,
		"Sub-region Code": 15,
		"Region Code": 2,
		official_name_ar: "الجزائر",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية الجزائرية الديمقراطية الشعبية",
		"UNTERM Chinese Short": "阿尔及利亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Argelia",
		"UNTERM English Formal": "the People's Democratic Republic of Algeria",
		official_name_cn: "阿尔及利亚",
		official_name_en: "Algeria",
		"ISO4217-currency_country_name": "ALGERIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "الجزائر",
		"Sub-region Name": "Northern Africa",
		official_name_ru: "Алжир",
		"Global Name": "World",
		Capital: "Algiers",
		Continent: "AF",
		TLD: ".dz",
		Languages: "ar-DZ",
		"Geoname ID": 2589581,
		"CLDR display name": "Algeria",
		EDGAR: "B4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q262",
	},
	{
		FIFA: "AND",
		Dial: 376,
		"ISO3166-1-Alpha-3": "AND",
		MARC: "an",
		is_independent: "Yes",
		"ISO3166-1-numeric": 20,
		GAUL: 7,
		FIPS: "AN",
		WMO: null,
		"ISO3166-1-Alpha-2": "AD",
		ITU: "AND",
		IOC: "AND",
		DS: "AND",
		"UNTERM Spanish Formal": "el Principado de Andorra",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Andorre",
		"UNTERM French Short": "Andorre (l')",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Княжество Андорра",
		"UNTERM English Short": "Andorra",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Andorra",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "安道尔公国",
		"UNTERM French Formal": "la Principauté d'Andorre",
		"UNTERM Russian Short": "Андорра",
		M49: 20,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "أندورا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "إمارة أندورا",
		"UNTERM Chinese Short": "安道尔",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Andorra",
		"UNTERM English Formal": "the Principality of Andorra",
		official_name_cn: "安道尔",
		official_name_en: "Andorra",
		"ISO4217-currency_country_name": "ANDORRA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "أندورا",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Андорра",
		"Global Name": "World",
		Capital: "Andorra la Vella",
		Continent: "EU",
		TLD: ".ad",
		Languages: "ca",
		"Geoname ID": 3041565,
		"CLDR display name": "Andorra",
		EDGAR: "B6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q228",
	},
	{
		FIFA: "ANG",
		Dial: 244,
		"ISO3166-1-Alpha-3": "AGO",
		MARC: "ao",
		is_independent: "Yes",
		"ISO3166-1-numeric": 24,
		GAUL: 8,
		FIPS: "AO",
		WMO: "AN",
		"ISO3166-1-Alpha-2": "AO",
		ITU: "AGL",
		IOC: "ANG",
		DS: "AO",
		"UNTERM Spanish Formal": "la República de Angola",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "Angola",
		"UNTERM French Short": "Angola (l')",
		"ISO4217-currency_name": "Kwanza",
		"UNTERM Russian Formal": "Республика Ангола",
		"UNTERM English Short": "Angola",
		"ISO4217-currency_alphabetic_code": "AOA",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Angola",
		"ISO4217-currency_numeric_code": 973,
		"UNTERM Chinese Formal": "安哥拉共和国",
		"UNTERM French Formal": "la République d'Angola",
		"UNTERM Russian Short": "Ангола",
		M49: 24,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "أنغولا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية أنغولا",
		"UNTERM Chinese Short": "安哥拉",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "Angola",
		"UNTERM English Formal": "the Republic of Angola",
		official_name_cn: "安哥拉",
		official_name_en: "Angola",
		"ISO4217-currency_country_name": "ANGOLA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "أنغولا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Ангола",
		"Global Name": "World",
		Capital: "Luanda",
		Continent: "AF",
		TLD: ".ao",
		Languages: "pt-AO",
		"Geoname ID": 3351879,
		"CLDR display name": "Angola",
		EDGAR: "B7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q916",
	},
	{
		FIFA: "ANT",
		Dial: 599,
		"ISO3166-1-Alpha-3": "BES",
		MARC: "ca",
		is_independent: "Part of NL",
		"ISO3166-1-numeric": 535,
		GAUL: 176,
		FIPS: "NL",
		WMO: "NU",
		"ISO3166-1-Alpha-2": "BQ",
		ITU: "ATN",
		IOC: "AHO",
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Bonaire, Saint-Eustache et Saba",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 535,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "بونير وسانت يوستاشيوس وسابا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Bonaire, San Eustaquio y Saba",
		"UNTERM English Formal": null,
		official_name_cn: "博纳尔，圣俄斯塔休斯和萨巴",
		official_name_en: "Bonaire, Sint Eustatius and Saba",
		"ISO4217-currency_country_name": "BONAIRE, SINT EUSTATIUS AND SABA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Бонайре, Синт-Эстатиус и Саба",
		"Global Name": "World",
		Capital: null,
		Continent: null,
		TLD: ".bq",
		Languages: "nl,pap,en",
		"Geoname ID": 7626844,
		"CLDR display name": "Belanda Caribbean",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q27561",
	},
	{
		FIFA: "ARG",
		Dial: 54,
		"ISO3166-1-Alpha-3": "ARG",
		MARC: "ag",
		is_independent: "Yes",
		"ISO3166-1-numeric": 32,
		GAUL: 12,
		FIPS: "AR",
		WMO: "AG",
		"ISO3166-1-Alpha-2": "AR",
		ITU: "ARG",
		IOC: "ARG",
		DS: "RA",
		"UNTERM Spanish Formal": "la República Argentina",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Argentine",
		"UNTERM French Short": "Argentine (l')",
		"ISO4217-currency_name": "Argentine Peso",
		"UNTERM Russian Formal": "Аргентинская Республика",
		"UNTERM English Short": "Argentina",
		"ISO4217-currency_alphabetic_code": "ARS",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Argentina (la)",
		"ISO4217-currency_numeric_code": 32,
		"UNTERM Chinese Formal": "阿根廷共和国",
		"UNTERM French Formal": "la République argentine",
		"UNTERM Russian Short": "Аргентина",
		M49: 32,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "الأرجنتين",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية الأرجنتين",
		"UNTERM Chinese Short": "阿根廷",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Argentina",
		"UNTERM English Formal": "the Argentine Republic",
		official_name_cn: "阿根廷",
		official_name_en: "Argentina",
		"ISO4217-currency_country_name": "ARGENTINA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "الأرجنتين",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Аргентина",
		"Global Name": "World",
		Capital: "Buenos Aires",
		Continent: "SA",
		TLD: ".ar",
		Languages: "es-AR,en,it,de,fr,gn",
		"Geoname ID": 3865483,
		"CLDR display name": "Argentina",
		EDGAR: "C1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q414",
	},
	{
		FIFA: "ARM",
		Dial: 374,
		"ISO3166-1-Alpha-3": "ARM",
		MARC: "ai",
		is_independent: "Yes",
		"ISO3166-1-numeric": 51,
		GAUL: 13,
		FIPS: "AM",
		WMO: "AY",
		"ISO3166-1-Alpha-2": "AM",
		ITU: "ARM",
		IOC: "ARM",
		DS: "AM",
		"UNTERM Spanish Formal": "la República de Armenia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Arménie",
		"UNTERM French Short": "Arménie (l')",
		"ISO4217-currency_name": "Armenian Dram",
		"UNTERM Russian Formal": "Республика Армения",
		"UNTERM English Short": "Armenia",
		"ISO4217-currency_alphabetic_code": "AMD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Armenia",
		"ISO4217-currency_numeric_code": 51,
		"UNTERM Chinese Formal": "亚美尼亚共和国",
		"UNTERM French Formal": "la République d'Arménie",
		"UNTERM Russian Short": "Армения",
		M49: 51,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "أرمينيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية أرمينيا",
		"UNTERM Chinese Short": "亚美尼亚",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Armenia",
		"UNTERM English Formal": "the Republic of Armenia",
		official_name_cn: "亚美尼亚",
		official_name_en: "Armenia",
		"ISO4217-currency_country_name": "ARMENIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "أرمينيا",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Армения",
		"Global Name": "World",
		Capital: "Yerevan",
		Continent: "AS",
		TLD: ".am",
		Languages: "hy",
		"Geoname ID": 174982,
		"CLDR display name": "Armenia",
		EDGAR: "1B",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q399",
	},
	{
		FIFA: "ARU",
		Dial: 297,
		"ISO3166-1-Alpha-3": "ABW",
		MARC: "aw",
		is_independent: "Part of NL",
		"ISO3166-1-numeric": 533,
		GAUL: 14,
		FIPS: "AA",
		WMO: "NU",
		"ISO3166-1-Alpha-2": "AW",
		ITU: "ABW",
		IOC: "ARU",
		DS: "AW",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Aruba",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Aruban Florin",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "AWG",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 533,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 533,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "أروبا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Aruba",
		"UNTERM English Formal": null,
		official_name_cn: "阿鲁巴",
		official_name_en: "Aruba",
		"ISO4217-currency_country_name": "ARUBA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Аруба",
		"Global Name": "World",
		Capital: "Oranjestad",
		Continent: null,
		TLD: ".aw",
		Languages: "nl-AW,pap,es,en",
		"Geoname ID": 3577279,
		"CLDR display name": "Aruba",
		EDGAR: "1C",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q21203",
	},
	{
		FIFA: "ASA",
		Dial: "1-684",
		"ISO3166-1-Alpha-3": "ASM",
		MARC: "as",
		is_independent: "Territory of US",
		"ISO3166-1-numeric": 16,
		GAUL: 5,
		FIPS: "AQ",
		WMO: null,
		"ISO3166-1-Alpha-2": "AS",
		ITU: "SMA",
		IOC: "ASA",
		DS: "USA",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Samoa américaines",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 16,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "ساموا الأمريكية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Samoa Americana",
		"UNTERM English Formal": null,
		official_name_cn: "美属萨摩亚",
		official_name_en: "American Samoa",
		"ISO4217-currency_country_name": "AMERICAN SAMOA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Polynesia",
		official_name_ru: "Американское Самоа",
		"Global Name": "World",
		Capital: "Pago Pago",
		Continent: "OC",
		TLD: ".as",
		Languages: "en-AS,sm,to",
		"Geoname ID": 5880801,
		"CLDR display name": "Samoa Amerika",
		EDGAR: "B5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q16641",
	},
	{
		FIFA: "ATG",
		Dial: "1-268",
		"ISO3166-1-Alpha-3": "ATG",
		MARC: "aq",
		is_independent: "Yes",
		"ISO3166-1-numeric": 28,
		GAUL: 11,
		FIPS: "AC",
		WMO: "AT",
		"ISO3166-1-Alpha-2": "AG",
		ITU: "ATG",
		IOC: "ANT",
		DS: null,
		"UNTERM Spanish Formal": "Antigua y Barbuda",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Antigua-et-Barbuda",
		"UNTERM French Short": "Antigua-et-Barbuda",
		"ISO4217-currency_name": "East Caribbean Dollar",
		"UNTERM Russian Formal": "Антигуа и Барбуда",
		"UNTERM English Short": "Antigua and Barbuda",
		"ISO4217-currency_alphabetic_code": "XCD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Antigua y Barbuda",
		"ISO4217-currency_numeric_code": 951,
		"UNTERM Chinese Formal": "安提瓜和巴布达",
		"UNTERM French Formal": "Antigua-et-Barbuda",
		"UNTERM Russian Short": "Антигуа и Барбуда",
		M49: 28,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "أنتيغوا وبربودا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "أنتيغوا وبربودا",
		"UNTERM Chinese Short": "安提瓜和巴布达",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Antigua y Barbuda",
		"UNTERM English Formal": "Antigua and Barbuda",
		official_name_cn: "安提瓜和巴布达",
		official_name_en: "Antigua and Barbuda",
		"ISO4217-currency_country_name": "ANTIGUA AND BARBUDA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "أنتيغوا وبربودا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Антигуа и Барбуда",
		"Global Name": "World",
		Capital: "St. John's",
		Continent: null,
		TLD: ".ag",
		Languages: "en-AG",
		"Geoname ID": 3576396,
		"CLDR display name": "Antigua dan Barbuda",
		EDGAR: "B9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q781",
	},
	{
		FIFA: "AUS",
		Dial: 61,
		"ISO3166-1-Alpha-3": "AUS",
		MARC: "at",
		is_independent: "Yes",
		"ISO3166-1-numeric": 36,
		GAUL: 17,
		FIPS: "AS",
		WMO: "AU",
		"ISO3166-1-Alpha-2": "AU",
		ITU: "AUS",
		IOC: "AUS",
		DS: "AUS",
		"UNTERM Spanish Formal": "Australia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Australie",
		"UNTERM French Short": "Australie (l')",
		"ISO4217-currency_name": "Australian Dollar",
		"UNTERM Russian Formal": "Австралия",
		"UNTERM English Short": "Australia",
		"ISO4217-currency_alphabetic_code": "AUD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Australia",
		"ISO4217-currency_numeric_code": 36,
		"UNTERM Chinese Formal": "澳大利亚",
		"UNTERM French Formal": "l'Australie",
		"UNTERM Russian Short": "Австралия",
		M49: 36,
		"Sub-region Code": 53,
		"Region Code": 9,
		official_name_ar: "أستراليا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "أستراليا",
		"UNTERM Chinese Short": "澳大利亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Australia",
		"UNTERM English Formal": "Australia",
		official_name_cn: "澳大利亚",
		official_name_en: "Australia",
		"ISO4217-currency_country_name": "AUSTRALIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "أستراليا",
		"Sub-region Name": "Australia and New Zealand",
		official_name_ru: "Австралия",
		"Global Name": "World",
		Capital: "Canberra",
		Continent: "OC",
		TLD: ".au",
		Languages: "en-AU",
		"Geoname ID": 2077456,
		"CLDR display name": "Australia",
		EDGAR: "C3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q408",
	},
	{
		FIFA: "AUT",
		Dial: 43,
		"ISO3166-1-Alpha-3": "AUT",
		MARC: "au",
		is_independent: "Yes",
		"ISO3166-1-numeric": 40,
		GAUL: 18,
		FIPS: "AU",
		WMO: "OS",
		"ISO3166-1-Alpha-2": "AT",
		ITU: "AUT",
		IOC: "AUT",
		DS: "A",
		"UNTERM Spanish Formal": "la República de Austria",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Autriche",
		"UNTERM French Short": "Autriche (l')",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Австрийская Республика",
		"UNTERM English Short": "Austria",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Austria",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "奥地利共和国",
		"UNTERM French Formal": "la République d'Autriche",
		"UNTERM Russian Short": "Австрия",
		M49: 40,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "النمسا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية النمسا",
		"UNTERM Chinese Short": "奥地利",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Austria",
		"UNTERM English Formal": "the Republic of Austria",
		official_name_cn: "奥地利",
		official_name_en: "Austria",
		"ISO4217-currency_country_name": "AUSTRIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "النمسا",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Австрия",
		"Global Name": "World",
		Capital: "Vienna",
		Continent: "EU",
		TLD: ".at",
		Languages: "de-AT,hr,hu,sl",
		"Geoname ID": 2782113,
		"CLDR display name": "Austria",
		EDGAR: "C4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q40",
	},
	{
		FIFA: "AZE",
		Dial: 994,
		"ISO3166-1-Alpha-3": "AZE",
		MARC: "aj",
		is_independent: "Yes",
		"ISO3166-1-numeric": 31,
		GAUL: 19,
		FIPS: "AJ",
		WMO: "AJ",
		"ISO3166-1-Alpha-2": "AZ",
		ITU: "AZE",
		IOC: "AZE",
		DS: "AZ",
		"UNTERM Spanish Formal": "la República de Azerbaiyán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Azerbaïdjan",
		"UNTERM French Short": "Azerbaïdjan (l')",
		"ISO4217-currency_name": "Azerbaijan Manat",
		"UNTERM Russian Formal": "Азербайджанская Республика",
		"UNTERM English Short": "Azerbaijan",
		"ISO4217-currency_alphabetic_code": "AZN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Azerbaiyán",
		"ISO4217-currency_numeric_code": 944,
		"UNTERM Chinese Formal": "阿塞拜疆共和国",
		"UNTERM French Formal": "la République d'Azerbaïdjan",
		"UNTERM Russian Short": "Азербайджан",
		M49: 31,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "أذربيجان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية أذربيجان",
		"UNTERM Chinese Short": "阿塞拜疆",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Azerbaiyán",
		"UNTERM English Formal": "the Republic of Azerbaijan",
		official_name_cn: "阿塞拜疆",
		official_name_en: "Azerbaijan",
		"ISO4217-currency_country_name": "AZERBAIJAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "أذربيجان",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Азербайджан",
		"Global Name": "World",
		Capital: "Baku",
		Continent: "AS",
		TLD: ".az",
		Languages: "az,ru,hy",
		"Geoname ID": 587116,
		"CLDR display name": "Azerbaijan",
		EDGAR: "1D",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q227",
	},
	{
		FIFA: "BAH",
		Dial: "1-242",
		"ISO3166-1-Alpha-3": "BHS",
		MARC: "bf",
		is_independent: "Yes",
		"ISO3166-1-numeric": 44,
		GAUL: 20,
		FIPS: "BF",
		WMO: "BA",
		"ISO3166-1-Alpha-2": "BS",
		ITU: "BAH",
		IOC: "BAH",
		DS: "BS",
		"UNTERM Spanish Formal": "el Commonwealth de las Bahamas",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Bahamas",
		"UNTERM French Short": "Bahamas (les)",
		"ISO4217-currency_name": "Bahamian Dollar",
		"UNTERM Russian Formal": "Содружество Багамских Островов",
		"UNTERM English Short": "Bahamas (the)",
		"ISO4217-currency_alphabetic_code": "BSD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Bahamas (las)",
		"ISO4217-currency_numeric_code": 44,
		"UNTERM Chinese Formal": "巴哈马国",
		"UNTERM French Formal": "le Commonwealth des Bahamas",
		"UNTERM Russian Short": "Багамские Острова",
		M49: 44,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جزر البهاما",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "كمنولث جزر البهاما",
		"UNTERM Chinese Short": "巴哈马",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Bahamas",
		"UNTERM English Formal": "the Commonwealth of the Bahamas",
		official_name_cn: "巴哈马",
		official_name_en: "Bahamas",
		"ISO4217-currency_country_name": "BAHAMAS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "جزر البهاما",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Багамские Острова",
		"Global Name": "World",
		Capital: "Nassau",
		Continent: null,
		TLD: ".bs",
		Languages: "en-BS",
		"Geoname ID": 3572887,
		"CLDR display name": "Bahamas",
		EDGAR: "C5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q778",
	},
	{
		FIFA: "BAN",
		Dial: 880,
		"ISO3166-1-Alpha-3": "BGD",
		MARC: "bg",
		is_independent: "Yes",
		"ISO3166-1-numeric": 50,
		GAUL: 23,
		FIPS: "BG",
		WMO: "BW",
		"ISO3166-1-Alpha-2": "BD",
		ITU: "BGD",
		IOC: "BAN",
		DS: "BD",
		"UNTERM Spanish Formal": "la República Popular de Bangladesh",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Bangladesh",
		"UNTERM French Short": "Bangladesh (le)",
		"ISO4217-currency_name": "Taka",
		"UNTERM Russian Formal": "Народная Республика Бангладеш",
		"UNTERM English Short": "Bangladesh",
		"ISO4217-currency_alphabetic_code": "BDT",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Bangladesh",
		"ISO4217-currency_numeric_code": 50,
		"UNTERM Chinese Formal": "孟加拉人民共和国",
		"UNTERM French Formal": "la République populaire du Bangladesh",
		"UNTERM Russian Short": "Бангладеш",
		M49: 50,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "بنغلاديش",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية بنغلاديش الشعبية",
		"UNTERM Chinese Short": "孟加拉国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Bangladesh",
		"UNTERM English Formal": "the People's Republic of Bangladesh",
		official_name_cn: "孟加拉国",
		official_name_en: "Bangladesh",
		"ISO4217-currency_country_name": "BANGLADESH",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Asia",
		"UNTERM Arabic Short": "بنغلاديش",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Бангладеш",
		"Global Name": "World",
		Capital: "Dhaka",
		Continent: "AS",
		TLD: ".bd",
		Languages: "bn-BD,en",
		"Geoname ID": 1210997,
		"CLDR display name": "Bangladesh",
		EDGAR: "C7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q902",
	},
	{
		FIFA: "BDI",
		Dial: 257,
		"ISO3166-1-Alpha-3": "BDI",
		MARC: "bd",
		is_independent: "Yes",
		"ISO3166-1-numeric": 108,
		GAUL: 43,
		FIPS: "BY",
		WMO: "BI",
		"ISO3166-1-Alpha-2": "BI",
		ITU: "BDI",
		IOC: "BDI",
		DS: "RU",
		"UNTERM Spanish Formal": "la República de Burundi",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Burundi",
		"UNTERM French Short": "Burundi (le)",
		"ISO4217-currency_name": "Burundi Franc",
		"UNTERM Russian Formal": "Республика Бурунди",
		"UNTERM English Short": "Burundi",
		"ISO4217-currency_alphabetic_code": "BIF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Burundi",
		"ISO4217-currency_numeric_code": 108,
		"UNTERM Chinese Formal": "布隆迪共和国",
		"UNTERM French Formal": "la République du Burundi",
		"UNTERM Russian Short": "Бурунди",
		M49: 108,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "بوروندي",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية بوروندي",
		"UNTERM Chinese Short": "布隆迪",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Burundi",
		"UNTERM English Formal": "the Republic of Burundi",
		official_name_cn: "布隆迪",
		official_name_en: "Burundi",
		"ISO4217-currency_country_name": "BURUNDI",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "بوروندي",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Бурунди",
		"Global Name": "World",
		Capital: "Gitega",
		Continent: "AF",
		TLD: ".bi",
		Languages: "fr-BI,rn",
		"Geoname ID": 433561,
		"CLDR display name": "Burundi",
		EDGAR: "E2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q967",
	},
	{
		FIFA: "BEL",
		Dial: 32,
		"ISO3166-1-Alpha-3": "BEL",
		MARC: "be",
		is_independent: "Yes",
		"ISO3166-1-numeric": 56,
		GAUL: 27,
		FIPS: "BE",
		WMO: "BX",
		"ISO3166-1-Alpha-2": "BE",
		ITU: "BEL",
		IOC: "BEL",
		DS: "B",
		"UNTERM Spanish Formal": "el Reino de Bélgica",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Belgique",
		"UNTERM French Short": "Belgique (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Королевство Бельгия",
		"UNTERM English Short": "Belgium",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Bélgica",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "比利时王国",
		"UNTERM French Formal": "le Royaume de Belgique",
		"UNTERM Russian Short": "Бельгия",
		M49: 56,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "بلجيكا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة بلجيكا",
		"UNTERM Chinese Short": "比利时",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Bélgica",
		"UNTERM English Formal": "the Kingdom of Belgium",
		official_name_cn: "比利时",
		official_name_en: "Belgium",
		"ISO4217-currency_country_name": "BELGIUM",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "بلجيكا",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Бельгия",
		"Global Name": "World",
		Capital: "Brussels",
		Continent: "EU",
		TLD: ".be",
		Languages: "nl-BE,fr-BE,de-BE",
		"Geoname ID": 2802361,
		"CLDR display name": "Belgium",
		EDGAR: "C9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q31",
	},
	{
		FIFA: "BEN",
		Dial: 229,
		"ISO3166-1-Alpha-3": "BEN",
		MARC: "dm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 204,
		GAUL: 29,
		FIPS: "BN",
		WMO: "BJ",
		"ISO3166-1-Alpha-2": "BJ",
		ITU: "BEN",
		IOC: "BEN",
		DS: "DY",
		"UNTERM Spanish Formal": "la República de Benin",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Bénin",
		"UNTERM French Short": "Bénin (le)",
		"ISO4217-currency_name": "CFA Franc BCEAO",
		"UNTERM Russian Formal": "Республика Бенин",
		"UNTERM English Short": "Benin",
		"ISO4217-currency_alphabetic_code": "XOF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Benin",
		"ISO4217-currency_numeric_code": 952,
		"UNTERM Chinese Formal": "贝宁共和国",
		"UNTERM French Formal": "la République du Bénin",
		"UNTERM Russian Short": "Бенин",
		M49: 204,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "بنن",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية بنن",
		"UNTERM Chinese Short": "贝宁",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Benin",
		"UNTERM English Formal": "the Republic of Benin",
		official_name_cn: "贝宁",
		official_name_en: "Benin",
		"ISO4217-currency_country_name": "BENIN",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "بنن",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Бенин",
		"Global Name": "World",
		Capital: "Porto-Novo",
		Continent: "AF",
		TLD: ".bj",
		Languages: "fr-BJ",
		"Geoname ID": 2395170,
		"CLDR display name": "Benin",
		EDGAR: "G6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q962",
	},
	{
		FIFA: "BER",
		Dial: "1-441",
		"ISO3166-1-Alpha-3": "BMU",
		MARC: "bm",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 60,
		GAUL: 30,
		FIPS: "BD",
		WMO: "BE",
		"ISO3166-1-Alpha-2": "BM",
		ITU: "BER",
		IOC: "BER",
		DS: "BM",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Bermudes",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Bermudian Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "BMD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 60,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 60,
		"Sub-region Code": 21,
		"Region Code": 19,
		official_name_ar: "برمودا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Bermuda",
		"UNTERM English Formal": null,
		official_name_cn: "百慕大",
		official_name_en: "Bermuda",
		"ISO4217-currency_country_name": "BERMUDA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern America",
		official_name_ru: "Бермудские острова",
		"Global Name": "World",
		Capital: "Hamilton",
		Continent: null,
		TLD: ".bm",
		Languages: "en-BM,pt",
		"Geoname ID": 3573345,
		"CLDR display name": "Bermuda",
		EDGAR: "D0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q23635",
	},
	{
		FIFA: "BFA",
		Dial: 226,
		"ISO3166-1-Alpha-3": "BFA",
		MARC: "uv",
		is_independent: "Yes",
		"ISO3166-1-numeric": 854,
		GAUL: 42,
		FIPS: "UV",
		WMO: "HV",
		"ISO3166-1-Alpha-2": "BF",
		ITU: "BFA",
		IOC: "BUR",
		DS: "BF",
		"UNTERM Spanish Formal": "Burkina Faso",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Burkina Faso",
		"UNTERM French Short": "Burkina Faso (le)",
		"ISO4217-currency_name": "CFA Franc BCEAO",
		"UNTERM Russian Formal": "Буркина-Фасо",
		"UNTERM English Short": "Burkina Faso",
		"ISO4217-currency_alphabetic_code": "XOF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Burkina Faso",
		"ISO4217-currency_numeric_code": 952,
		"UNTERM Chinese Formal": "布基纳法索",
		"UNTERM French Formal": "le Burkina Faso",
		"UNTERM Russian Short": "Буркина-Фасо",
		M49: 854,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "بوركينا فاسو",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "بوركينا فاسو",
		"UNTERM Chinese Short": "布基纳法索",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Burkina Faso",
		"UNTERM English Formal": "Burkina Faso",
		official_name_cn: "布基纳法索",
		official_name_en: "Burkina Faso",
		"ISO4217-currency_country_name": "BURKINA FASO",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "بوركينا فاسو",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Буркина-Фасо",
		"Global Name": "World",
		Capital: "Ouagadougou",
		Continent: "AF",
		TLD: ".bf",
		Languages: "fr-BF,mos",
		"Geoname ID": 2361809,
		"CLDR display name": "Burkina Faso",
		EDGAR: "X2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q965",
	},
	{
		FIFA: "BHR",
		Dial: 973,
		"ISO3166-1-Alpha-3": "BHR",
		MARC: "ba",
		is_independent: "Yes",
		"ISO3166-1-numeric": 48,
		GAUL: 21,
		FIPS: "BA",
		WMO: "BN",
		"ISO3166-1-Alpha-2": "BH",
		ITU: "BHR",
		IOC: "BRN",
		DS: "BRN",
		"UNTERM Spanish Formal": "el Reino de Bahrein",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Bahreïn",
		"UNTERM French Short": "Bahreïn",
		"ISO4217-currency_name": "Bahraini Dinar",
		"UNTERM Russian Formal": "Королевство Бахрейн",
		"UNTERM English Short": "Bahrain",
		"ISO4217-currency_alphabetic_code": "BHD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Bahrein",
		"ISO4217-currency_numeric_code": 48,
		"UNTERM Chinese Formal": "巴林王国",
		"UNTERM French Formal": "le Royaume de Bahreïn",
		"UNTERM Russian Short": "Бахрейн",
		M49: 48,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "البحرين",
		"ISO4217-currency_minor_unit": 3,
		"UNTERM Arabic Formal": "مملكة البحرين",
		"UNTERM Chinese Short": "巴林",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Bahrein",
		"UNTERM English Formal": "the Kingdom of Bahrain",
		official_name_cn: "巴林",
		official_name_en: "Bahrain",
		"ISO4217-currency_country_name": "BAHRAIN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "البحرين",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Бахрейн",
		"Global Name": "World",
		Capital: "Manama",
		Continent: "AS",
		TLD: ".bh",
		Languages: "ar-BH,en,fa,ur",
		"Geoname ID": 290291,
		"CLDR display name": "Bahrain",
		EDGAR: "C6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q398",
	},
	{
		FIFA: "BHU",
		Dial: 975,
		"ISO3166-1-Alpha-3": "BTN",
		MARC: "bt",
		is_independent: "Yes",
		"ISO3166-1-numeric": 64,
		GAUL: 31,
		FIPS: "BT",
		WMO: null,
		"ISO3166-1-Alpha-2": "BT",
		ITU: "BTN",
		IOC: "BHU",
		DS: "BT",
		"UNTERM Spanish Formal": "el Reino de Bhután",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Bhoutan",
		"UNTERM French Short": "Bhoutan (le)",
		"ISO4217-currency_name": "Indian Rupee,Ngultrum",
		"UNTERM Russian Formal": "Королевство Бутан",
		"UNTERM English Short": "Bhutan",
		"ISO4217-currency_alphabetic_code": "INR,BTN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Bhután",
		"ISO4217-currency_numeric_code": "356,064",
		"UNTERM Chinese Formal": "不丹王国",
		"UNTERM French Formal": "le Royaume du Bhoutan",
		"UNTERM Russian Short": "Бутан",
		M49: 64,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "بوتان",
		"ISO4217-currency_minor_unit": "2,2",
		"UNTERM Arabic Formal": "مملكة بوتان",
		"UNTERM Chinese Short": "不丹",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Bhután",
		"UNTERM English Formal": "the Kingdom of Bhutan",
		official_name_cn: "不丹",
		official_name_en: "Bhutan",
		"ISO4217-currency_country_name": "BHUTAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "بوتان",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Бутан",
		"Global Name": "World",
		Capital: "Thimphu",
		Continent: "AS",
		TLD: ".bt",
		Languages: "dz",
		"Geoname ID": 1252634,
		"CLDR display name": "Bhutan",
		EDGAR: "D2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q917",
	},
	{
		FIFA: "BIH",
		Dial: 387,
		"ISO3166-1-Alpha-3": "BIH",
		MARC: "bn",
		is_independent: "Yes",
		"ISO3166-1-numeric": 70,
		GAUL: 34,
		FIPS: "BK",
		WMO: "BG",
		"ISO3166-1-Alpha-2": "BA",
		ITU: "BIH",
		IOC: "BIH",
		DS: "BIH",
		"UNTERM Spanish Formal": "Bosnia y Herzegovina",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Bosnie-Herzégovine",
		"UNTERM French Short": "Bosnie-Herzégovine (la)",
		"ISO4217-currency_name": "Convertible Mark",
		"UNTERM Russian Formal": "Босния и Герцеговина",
		"UNTERM English Short": "Bosnia and Herzegovina",
		"ISO4217-currency_alphabetic_code": "BAM",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Bosnia y Herzegovina",
		"ISO4217-currency_numeric_code": 977,
		"UNTERM Chinese Formal": "波斯尼亚和黑塞哥维那",
		"UNTERM French Formal": "la Bosnie-Herzégovine",
		"UNTERM Russian Short": "Босния и Герцеговина",
		M49: 70,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "البوسنة والهرسك",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "البوسنة والهرسك",
		"UNTERM Chinese Short": "波斯尼亚和黑塞哥维那",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Bosnia y Herzegovina",
		"UNTERM English Formal": "Bosnia and Herzegovina",
		official_name_cn: "波斯尼亚和黑塞哥维那",
		official_name_en: "Bosnia and Herzegovina",
		"ISO4217-currency_country_name": "BOSNIA AND HERZEGOVINA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "البوسنة والهرسك",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Босния и Герцеговина",
		"Global Name": "World",
		Capital: "Sarajevo",
		Continent: "EU",
		TLD: ".ba",
		Languages: "bs,hr-BA,sr-BA",
		"Geoname ID": 3277605,
		"CLDR display name": "Bosnia dan Herzegovina",
		EDGAR: "1E",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q225",
	},
	{
		FIFA: "BLR",
		Dial: 375,
		"ISO3166-1-Alpha-3": "BLR",
		MARC: "bw",
		is_independent: "Yes",
		"ISO3166-1-numeric": 112,
		GAUL: 26,
		FIPS: "BO",
		WMO: "BY",
		"ISO3166-1-Alpha-2": "BY",
		ITU: "BLR",
		IOC: "BLR",
		DS: "BY",
		"UNTERM Spanish Formal": "la República de Belarús",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Bélarus",
		"UNTERM French Short": "Bélarus (le)",
		"ISO4217-currency_name": "Belarusian Ruble",
		"UNTERM Russian Formal": "Республика Беларусь",
		"UNTERM English Short": "Belarus",
		"ISO4217-currency_alphabetic_code": "BYN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Belarús",
		"ISO4217-currency_numeric_code": 933,
		"UNTERM Chinese Formal": "白俄罗斯共和国",
		"UNTERM French Formal": "la République du Bélarus",
		"UNTERM Russian Short": "Беларусь",
		M49: 112,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "بيلاروس",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية بيلاروس",
		"UNTERM Chinese Short": "白俄罗斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Belarús",
		"UNTERM English Formal": "the Republic of Belarus",
		official_name_cn: "白俄罗斯",
		official_name_en: "Belarus",
		"ISO4217-currency_country_name": "BELARUS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "بيلاروس",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Беларусь",
		"Global Name": "World",
		Capital: "Minsk",
		Continent: "EU",
		TLD: ".by",
		Languages: "be,ru",
		"Geoname ID": 630336,
		"CLDR display name": "Belarus",
		EDGAR: "1F",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q184",
	},
	{
		FIFA: "BLZ",
		Dial: 501,
		"ISO3166-1-Alpha-3": "BLZ",
		MARC: "bh",
		is_independent: "Yes",
		"ISO3166-1-numeric": 84,
		GAUL: 28,
		FIPS: "BH",
		WMO: "BH",
		"ISO3166-1-Alpha-2": "BZ",
		ITU: "BLZ",
		IOC: "BIZ",
		DS: "BH",
		"UNTERM Spanish Formal": "Belice",
		"Global Code": 1,
		"Intermediate Region Code": 13,
		official_name_fr: "Belize",
		"UNTERM French Short": "Belize (le)",
		"ISO4217-currency_name": "Belize Dollar",
		"UNTERM Russian Formal": "Белиз",
		"UNTERM English Short": "Belize",
		"ISO4217-currency_alphabetic_code": "BZD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Belice",
		"ISO4217-currency_numeric_code": 84,
		"UNTERM Chinese Formal": "伯利兹",
		"UNTERM French Formal": "le Belize",
		"UNTERM Russian Short": "Белиз",
		M49: 84,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "بليز",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "بليز",
		"UNTERM Chinese Short": "伯利兹",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Central America",
		official_name_es: "Belice",
		"UNTERM English Formal": "Belize",
		official_name_cn: "伯利兹",
		official_name_en: "Belize",
		"ISO4217-currency_country_name": "BELIZE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "بليز",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Белиз",
		"Global Name": "World",
		Capital: "Belmopan",
		Continent: null,
		TLD: ".bz",
		Languages: "en-BZ,es",
		"Geoname ID": 3582678,
		"CLDR display name": "Belize",
		EDGAR: "D1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q242",
	},
	{
		FIFA: "BOL",
		Dial: 591,
		"ISO3166-1-Alpha-3": "BOL",
		MARC: "bo",
		is_independent: "Yes",
		"ISO3166-1-numeric": 68,
		GAUL: 33,
		FIPS: "BL",
		WMO: "BO",
		"ISO3166-1-Alpha-2": "BO",
		ITU: "BOL",
		IOC: "BOL",
		DS: "BOL",
		"UNTERM Spanish Formal": "el Estado Plurinacional de Bolivia",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Bolivie (État plurinational de)",
		"UNTERM French Short": "Bolivie (État plurinational de)",
		"ISO4217-currency_name": "Boliviano",
		"UNTERM Russian Formal": "Многонациональное Государство Боливия",
		"UNTERM English Short": "Bolivia (Plurinational State of)",
		"ISO4217-currency_alphabetic_code": "BOB",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Bolivia (Estado Plurinacional de)",
		"ISO4217-currency_numeric_code": 68,
		"UNTERM Chinese Formal": "多民族玻利维亚国",
		"UNTERM French Formal": "l'État plurinational de Bolivie",
		"UNTERM Russian Short": "Боливия (Многонациональное Государство)",
		M49: 68,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "بوليفيا (دولة - المتعددة القوميات)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "دولة بوليفيا المتعددة القوميات",
		"UNTERM Chinese Short": "多民族玻利维亚国",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "South America",
		official_name_es: "Bolivia (Estado Plurinacional de)",
		"UNTERM English Formal": "the Plurinational State of Bolivia",
		official_name_cn: "多民族玻利维亚国",
		official_name_en: "Bolivia (Plurinational State of)",
		"ISO4217-currency_country_name": "BOLIVIA (PLURINATIONAL STATE OF)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "بوليفيا (دولة - المتعددة القوميات)",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Боливия (Многонациональное Государство)",
		"Global Name": "World",
		Capital: "Sucre",
		Continent: "SA",
		TLD: ".bo",
		Languages: "es-BO,qu,ay",
		"Geoname ID": 3923057,
		"CLDR display name": "Bolivia",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q750",
	},
	{
		FIFA: "BOT",
		Dial: 267,
		"ISO3166-1-Alpha-3": "BWA",
		MARC: "bs",
		is_independent: "Yes",
		"ISO3166-1-numeric": 72,
		GAUL: 35,
		FIPS: "BC",
		WMO: "BC",
		"ISO3166-1-Alpha-2": "BW",
		ITU: "BOT",
		IOC: "BOT",
		DS: "BW",
		"UNTERM Spanish Formal": "la República de Botswana",
		"Global Code": 1,
		"Intermediate Region Code": 18,
		official_name_fr: "Botswana",
		"UNTERM French Short": "Botswana (le)",
		"ISO4217-currency_name": "Pula",
		"UNTERM Russian Formal": "Республика Ботсвана",
		"UNTERM English Short": "Botswana",
		"ISO4217-currency_alphabetic_code": "BWP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Botswana",
		"ISO4217-currency_numeric_code": 72,
		"UNTERM Chinese Formal": "博茨瓦纳共和国",
		"UNTERM French Formal": "la République du Botswana",
		"UNTERM Russian Short": "Ботсвана",
		M49: 72,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "بوتسوانا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية بوتسوانا",
		"UNTERM Chinese Short": "博茨瓦纳",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Southern Africa",
		official_name_es: "Botswana",
		"UNTERM English Formal": "the Republic of Botswana",
		official_name_cn: "博茨瓦纳",
		official_name_en: "Botswana",
		"ISO4217-currency_country_name": "BOTSWANA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "بوتسوانا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Ботсвана",
		"Global Name": "World",
		Capital: "Gaborone",
		Continent: "AF",
		TLD: ".bw",
		Languages: "en-BW,tn-BW",
		"Geoname ID": 933860,
		"CLDR display name": "Botswana",
		EDGAR: "B1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q963",
	},
	{
		FIFA: "BRA",
		Dial: 55,
		"ISO3166-1-Alpha-3": "BRA",
		MARC: "bl",
		is_independent: "Yes",
		"ISO3166-1-numeric": 76,
		GAUL: 37,
		FIPS: "BR",
		WMO: "BZ",
		"ISO3166-1-Alpha-2": "BR",
		ITU: "B",
		IOC: "BRA",
		DS: "BR",
		"UNTERM Spanish Formal": "la República Federativa del Brasil",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Brésil",
		"UNTERM French Short": "Brésil (le)",
		"ISO4217-currency_name": "Brazilian Real",
		"UNTERM Russian Formal": "Федеративная Республика Бразилия",
		"UNTERM English Short": "Brazil",
		"ISO4217-currency_alphabetic_code": "BRL",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Brasil (el)",
		"ISO4217-currency_numeric_code": 986,
		"UNTERM Chinese Formal": "巴西联邦共和国",
		"UNTERM French Formal": "la République fédérative du Brésil",
		"UNTERM Russian Short": "Бразилия",
		M49: 76,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "البرازيل",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية البرازيل الاتحادية",
		"UNTERM Chinese Short": "巴西",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Brasil",
		"UNTERM English Formal": "the Federative Republic of Brazil",
		official_name_cn: "巴西",
		official_name_en: "Brazil",
		"ISO4217-currency_country_name": "BRAZIL",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "البرازيل",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Бразилия",
		"Global Name": "World",
		Capital: "Brasilia",
		Continent: "SA",
		TLD: ".br",
		Languages: "pt-BR,es,en,fr",
		"Geoname ID": 3469034,
		"CLDR display name": "Brazil",
		EDGAR: "D5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q155",
	},
	{
		FIFA: "BRB",
		Dial: "1-246",
		"ISO3166-1-Alpha-3": "BRB",
		MARC: "bb",
		is_independent: "Yes",
		"ISO3166-1-numeric": 52,
		GAUL: 24,
		FIPS: "BB",
		WMO: "BR",
		"ISO3166-1-Alpha-2": "BB",
		ITU: "BRB",
		IOC: "BAR",
		DS: "BDS",
		"UNTERM Spanish Formal": "Barbados",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Barbade",
		"UNTERM French Short": "Barbade (la)",
		"ISO4217-currency_name": "Barbados Dollar",
		"UNTERM Russian Formal": "Барбадос",
		"UNTERM English Short": "Barbados",
		"ISO4217-currency_alphabetic_code": "BBD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Barbados",
		"ISO4217-currency_numeric_code": 52,
		"UNTERM Chinese Formal": "巴巴多斯",
		"UNTERM French Formal": "la Barbade",
		"UNTERM Russian Short": "Барбадос",
		M49: 52,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "بربادوس",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "بربادوس",
		"UNTERM Chinese Short": "巴巴多斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Barbados",
		"UNTERM English Formal": "Barbados",
		official_name_cn: "巴巴多斯",
		official_name_en: "Barbados",
		"ISO4217-currency_country_name": "BARBADOS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "بربادوس",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Барбадос",
		"Global Name": "World",
		Capital: "Bridgetown",
		Continent: null,
		TLD: ".bb",
		Languages: "en-BB",
		"Geoname ID": 3374084,
		"CLDR display name": "Barbados",
		EDGAR: "C8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q244",
	},
	{
		FIFA: "BRU",
		Dial: 673,
		"ISO3166-1-Alpha-3": "BRN",
		MARC: "bx",
		is_independent: "Yes",
		"ISO3166-1-numeric": 96,
		GAUL: 40,
		FIPS: "BX",
		WMO: "BD",
		"ISO3166-1-Alpha-2": "BN",
		ITU: "BRU",
		IOC: "BRU",
		DS: "BRU",
		"UNTERM Spanish Formal": "Brunei Darussalam",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Brunéi Darussalam",
		"UNTERM French Short": "Brunéi Darussalam (le)",
		"ISO4217-currency_name": "Brunei Dollar",
		"UNTERM Russian Formal": "Бруней-Даруссалам",
		"UNTERM English Short": "Brunei Darussalam",
		"ISO4217-currency_alphabetic_code": "BND",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Brunei Darussalam",
		"ISO4217-currency_numeric_code": 96,
		"UNTERM Chinese Formal": "文莱达鲁萨兰国",
		"UNTERM French Formal": "le Brunéi Darussalam",
		"UNTERM Russian Short": "Бруней-Даруссалам",
		M49: 96,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "بروني دار السلام",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "بروني دار السلام",
		"UNTERM Chinese Short": "文莱达鲁萨兰国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Brunei Darussalam",
		"UNTERM English Formal": "Brunei Darussalam",
		official_name_cn: "文莱达鲁萨兰国",
		official_name_en: "Brunei Darussalam",
		"ISO4217-currency_country_name": "BRUNEI DARUSSALAM",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "بروني دار السلام",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Бруней-Даруссалам",
		"Global Name": "World",
		Capital: "Bandar Seri Begawan",
		Continent: "AS",
		TLD: ".bn",
		Languages: "ms-BN,en-BN",
		"Geoname ID": 1820814,
		"CLDR display name": "Brunei",
		EDGAR: "D9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q921",
	},
	{
		FIFA: "BUL",
		Dial: 359,
		"ISO3166-1-Alpha-3": "BGR",
		MARC: "bu",
		is_independent: "Yes",
		"ISO3166-1-numeric": 100,
		GAUL: 41,
		FIPS: "BU",
		WMO: "BU",
		"ISO3166-1-Alpha-2": "BG",
		ITU: "BUL",
		IOC: "BUL",
		DS: "BG",
		"UNTERM Spanish Formal": "la República de Bulgaria",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Bulgarie",
		"UNTERM French Short": "Bulgarie (la)",
		"ISO4217-currency_name": "Bulgarian Lev",
		"UNTERM Russian Formal": "Республика Болгария",
		"UNTERM English Short": "Bulgaria",
		"ISO4217-currency_alphabetic_code": "BGN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Bulgaria",
		"ISO4217-currency_numeric_code": 975,
		"UNTERM Chinese Formal": "保加利亚共和国",
		"UNTERM French Formal": "la République de Bulgarie",
		"UNTERM Russian Short": "Болгария",
		M49: 100,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "بلغاريا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية بلغاريا",
		"UNTERM Chinese Short": "保加利亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Bulgaria",
		"UNTERM English Formal": "the Republic of Bulgaria",
		official_name_cn: "保加利亚",
		official_name_en: "Bulgaria",
		"ISO4217-currency_country_name": "BULGARIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "بلغاريا",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Болгария",
		"Global Name": "World",
		Capital: "Sofia",
		Continent: "EU",
		TLD: ".bg",
		Languages: "bg,tr-BG,rom",
		"Geoname ID": 732800,
		"CLDR display name": "Bulgaria",
		EDGAR: "E0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q219",
	},
	{
		FIFA: "CAM",
		Dial: 855,
		"ISO3166-1-Alpha-3": "KHM",
		MARC: "cb",
		is_independent: "Yes",
		"ISO3166-1-numeric": 116,
		GAUL: 44,
		FIPS: "CB",
		WMO: "KP",
		"ISO3166-1-Alpha-2": "KH",
		ITU: "CBG",
		IOC: "CAM",
		DS: "K",
		"UNTERM Spanish Formal": "el Reino de Camboya",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Cambodge",
		"UNTERM French Short": "Cambodge (le)",
		"ISO4217-currency_name": "Riel",
		"UNTERM Russian Formal": "Королевство Камбоджа",
		"UNTERM English Short": "Cambodia",
		"ISO4217-currency_alphabetic_code": "KHR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Camboya",
		"ISO4217-currency_numeric_code": 116,
		"UNTERM Chinese Formal": "柬埔寨王国",
		"UNTERM French Formal": "le Royaume du Cambodge",
		"UNTERM Russian Short": "Камбоджа",
		M49: 116,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "كمبوديا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة كمبوديا",
		"UNTERM Chinese Short": "柬埔寨",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Camboya",
		"UNTERM English Formal": "the Kingdom of Cambodia",
		official_name_cn: "柬埔寨",
		official_name_en: "Cambodia",
		"ISO4217-currency_country_name": "CAMBODIA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Asia",
		"UNTERM Arabic Short": "كمبوديا",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Камбоджа",
		"Global Name": "World",
		Capital: "Phnom Penh",
		Continent: "AS",
		TLD: ".kh",
		Languages: "km,fr,en",
		"Geoname ID": 1831722,
		"CLDR display name": "Kemboja",
		EDGAR: "E3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q424",
	},
	{
		FIFA: "CAN",
		Dial: 1,
		"ISO3166-1-Alpha-3": "CAN",
		MARC: "xxc",
		is_independent: "Yes",
		"ISO3166-1-numeric": 124,
		GAUL: 46,
		FIPS: "CA",
		WMO: "CN",
		"ISO3166-1-Alpha-2": "CA",
		ITU: "CAN",
		IOC: "CAN",
		DS: "CDN",
		"UNTERM Spanish Formal": "el Canadá",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Canada",
		"UNTERM French Short": "Canada (le)",
		"ISO4217-currency_name": "Canadian Dollar",
		"UNTERM Russian Formal": "Канада",
		"UNTERM English Short": "Canada",
		"ISO4217-currency_alphabetic_code": "CAD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Canadá (el)",
		"ISO4217-currency_numeric_code": 124,
		"UNTERM Chinese Formal": "加拿大",
		"UNTERM French Formal": "le Canada",
		"UNTERM Russian Short": "Канада",
		M49: 124,
		"Sub-region Code": 21,
		"Region Code": 19,
		official_name_ar: "كندا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "كندا",
		"UNTERM Chinese Short": "加拿大",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Canadá",
		"UNTERM English Formal": "Canada",
		official_name_cn: "加拿大",
		official_name_en: "Canada",
		"ISO4217-currency_country_name": "CANADA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "كندا",
		"Sub-region Name": "Northern America",
		official_name_ru: "Канада",
		"Global Name": "World",
		Capital: "Ottawa",
		Continent: null,
		TLD: ".ca",
		Languages: "en-CA,fr-CA,iu",
		"Geoname ID": 6251999,
		"CLDR display name": "Kanada",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q16",
	},
	{
		FIFA: "CAY",
		Dial: "1-345",
		"ISO3166-1-Alpha-3": "CYM",
		MARC: "cj",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 136,
		GAUL: 48,
		FIPS: "CJ",
		WMO: "GC",
		"ISO3166-1-Alpha-2": "KY",
		ITU: "CYM",
		IOC: "CAY",
		DS: "KY",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Îles Caïmanes",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Cayman Islands Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "KYD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 136,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 136,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جزر كايمان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Islas Caimán",
		"UNTERM English Formal": null,
		official_name_cn: "开曼群岛",
		official_name_en: "Cayman Islands",
		"ISO4217-currency_country_name": "CAYMAN ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Кайман острова",
		"Global Name": "World",
		Capital: "George Town",
		Continent: null,
		TLD: ".ky",
		Languages: "en-KY",
		"Geoname ID": 3580718,
		"CLDR display name": "Kepulauan Cayman",
		EDGAR: "E9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q5785",
	},
	{
		FIFA: "CCK",
		Dial: 61,
		"ISO3166-1-Alpha-3": "CCK",
		MARC: "xb",
		is_independent: "Territory of AU",
		"ISO3166-1-numeric": 166,
		GAUL: 56,
		FIPS: "CK",
		WMO: "KK",
		"ISO3166-1-Alpha-2": "CC",
		ITU: "ICO",
		IOC: null,
		DS: "AUS",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles des Cocos (Keeling)",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Australian Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "AUD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 36,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 166,
		"Sub-region Code": 53,
		"Region Code": 9,
		official_name_ar: "جزر كوكس (كيلينغ)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Cocos (Keeling)",
		"UNTERM English Formal": null,
		official_name_cn: "科科斯（基林）群岛",
		official_name_en: "Cocos (Keeling) Islands",
		"ISO4217-currency_country_name": "COCOS (KEELING) ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Australia and New Zealand",
		official_name_ru: "Кокосовых (Килинг) островов",
		"Global Name": "World",
		Capital: "West Island",
		Continent: "AS",
		TLD: ".cc",
		Languages: "ms-CC,en",
		"Geoname ID": 1547376,
		"CLDR display name": "Kepulauan Cocos (Keeling)",
		EDGAR: "F7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q36004",
	},
	{
		FIFA: "CGO",
		Dial: 242,
		"ISO3166-1-Alpha-3": "COG",
		MARC: "cf",
		is_independent: "Yes",
		"ISO3166-1-numeric": 178,
		GAUL: 59,
		FIPS: "CF",
		WMO: "CG",
		"ISO3166-1-Alpha-2": "CG",
		ITU: "COG",
		IOC: "CGO",
		DS: "RCB",
		"UNTERM Spanish Formal": "la República del Congo",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "Congo",
		"UNTERM French Short": "Congo (le)",
		"ISO4217-currency_name": "CFA Franc BEAC",
		"UNTERM Russian Formal": "Республика Конго",
		"UNTERM English Short": "Congo (the)",
		"ISO4217-currency_alphabetic_code": "XAF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Congo (el)",
		"ISO4217-currency_numeric_code": 950,
		"UNTERM Chinese Formal": "刚果共和国",
		"UNTERM French Formal": "la République du Congo",
		"UNTERM Russian Short": "Конго",
		M49: 178,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "الكونغو",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية الكونغو",
		"UNTERM Chinese Short": "刚果（布）",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "Congo",
		"UNTERM English Formal": "the Republic of the Congo",
		official_name_cn: "刚果",
		official_name_en: "Congo",
		"ISO4217-currency_country_name": "CONGO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "الكونغو",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Конго",
		"Global Name": "World",
		Capital: "Brazzaville",
		Continent: "AF",
		TLD: ".cg",
		Languages: "fr-CG,kg,ln-CG",
		"Geoname ID": 2260494,
		"CLDR display name": "Congo - Brazzaville",
		EDGAR: "G0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q971",
	},
	{
		FIFA: "CHA",
		Dial: 235,
		"ISO3166-1-Alpha-3": "TCD",
		MARC: "cd",
		is_independent: "Yes",
		"ISO3166-1-numeric": 148,
		GAUL: 50,
		FIPS: "CD",
		WMO: "CD",
		"ISO3166-1-Alpha-2": "TD",
		ITU: "TCD",
		IOC: "CHA",
		DS: "TCH",
		"UNTERM Spanish Formal": "la República del Chad",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "Tchad",
		"UNTERM French Short": "Tchad (le)",
		"ISO4217-currency_name": "CFA Franc BEAC",
		"UNTERM Russian Formal": "Республика Чад",
		"UNTERM English Short": "Chad",
		"ISO4217-currency_alphabetic_code": "XAF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Chad (el)",
		"ISO4217-currency_numeric_code": 950,
		"UNTERM Chinese Formal": "乍得共和国",
		"UNTERM French Formal": "la République du Tchad",
		"UNTERM Russian Short": "Чад",
		M49: 148,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "تشاد",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية تشاد",
		"UNTERM Chinese Short": "乍得",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "Chad",
		"UNTERM English Formal": "the Republic of Chad",
		official_name_cn: "乍得",
		official_name_en: "Chad",
		"ISO4217-currency_country_name": "CHAD",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "تشاد",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Чад",
		"Global Name": "World",
		Capital: "N'Djamena",
		Continent: "AF",
		TLD: ".td",
		Languages: "fr-TD,ar-TD,sre",
		"Geoname ID": 2434508,
		"CLDR display name": "Chad",
		EDGAR: "F2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q657",
	},
	{
		FIFA: "CHI",
		Dial: 56,
		"ISO3166-1-Alpha-3": "CHL",
		MARC: "cl",
		is_independent: "Yes",
		"ISO3166-1-numeric": 152,
		GAUL: 51,
		FIPS: "CI",
		WMO: "CH",
		"ISO3166-1-Alpha-2": "CL",
		ITU: "CHL",
		IOC: "CHI",
		DS: "RCH",
		"UNTERM Spanish Formal": "la República de Chile",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Chili",
		"UNTERM French Short": "Chili (le)",
		"ISO4217-currency_name": "Chilean Peso",
		"UNTERM Russian Formal": "Республика Чили",
		"UNTERM English Short": "Chile",
		"ISO4217-currency_alphabetic_code": "CLP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Chile",
		"ISO4217-currency_numeric_code": 152,
		"UNTERM Chinese Formal": "智利共和国",
		"UNTERM French Formal": "la République du Chili",
		"UNTERM Russian Short": "Чили",
		M49: 152,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "شيلي",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية شيلي",
		"UNTERM Chinese Short": "智利",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Chile",
		"UNTERM English Formal": "the Republic of Chile",
		official_name_cn: "智利",
		official_name_en: "Chile",
		"ISO4217-currency_country_name": "CHILE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "شيلي",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Чили",
		"Global Name": "World",
		Capital: "Santiago",
		Continent: "SA",
		TLD: ".cl",
		Languages: "es-CL",
		"Geoname ID": 3895114,
		"CLDR display name": "Chile",
		EDGAR: "F3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q298",
	},
	{
		FIFA: "CHN",
		Dial: 86,
		"ISO3166-1-Alpha-3": "CHN",
		MARC: "cc",
		is_independent: "Yes",
		"ISO3166-1-numeric": 156,
		GAUL: 53,
		FIPS: "CH",
		WMO: "CI",
		"ISO3166-1-Alpha-2": "CN",
		ITU: "CHN",
		IOC: "CHN",
		DS: "CN",
		"UNTERM Spanish Formal": "la República Popular China",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Chine",
		"UNTERM French Short": "Chine (la)",
		"ISO4217-currency_name": "Yuan Renminbi",
		"UNTERM Russian Formal": "Китайская Народная Республика",
		"UNTERM English Short": "China",
		"ISO4217-currency_alphabetic_code": "CNY",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "China",
		"ISO4217-currency_numeric_code": 156,
		"UNTERM Chinese Formal": "中华人民共和国",
		"UNTERM French Formal": "la République populaire de Chine",
		"UNTERM Russian Short": "Китай",
		M49: 156,
		"Sub-region Code": 30,
		"Region Code": 142,
		official_name_ar: "الصين",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية الصين الشعبية",
		"UNTERM Chinese Short": "中国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "China",
		"UNTERM English Formal": "the People's Republic of China",
		official_name_cn: "中国",
		official_name_en: "China",
		"ISO4217-currency_country_name": "CHINA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "الصين",
		"Sub-region Name": "Eastern Asia",
		official_name_ru: "Китай",
		"Global Name": "World",
		Capital: "Beijing",
		Continent: "AS",
		TLD: ".cn",
		Languages: "zh-CN,yue,wuu,dta,ug,za",
		"Geoname ID": 1814991,
		"CLDR display name": "China",
		EDGAR: "F4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q148",
	},
	{
		FIFA: "CIV",
		Dial: 225,
		"ISO3166-1-Alpha-3": "CIV",
		MARC: "iv",
		is_independent: "Yes",
		"ISO3166-1-numeric": 384,
		GAUL: 66,
		FIPS: "IV",
		WMO: "IV",
		"ISO3166-1-Alpha-2": "CI",
		ITU: "CTI",
		IOC: "CIV",
		DS: "CI",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Côte d’Ivoire",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "CFA Franc BCEAO",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "XOF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 952,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 384,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "كوت ديفوار",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Côte d’Ivoire",
		"UNTERM English Formal": null,
		official_name_cn: "科特迪瓦",
		official_name_en: "Ivory Coast",
		"ISO4217-currency_country_name": "CÔTE D'IVOIRE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Кот-д'Ивуар",
		"Global Name": "World",
		Capital: "Yamoussoukro",
		Continent: "AF",
		TLD: ".ci",
		Languages: "fr-CI",
		"Geoname ID": 2287781,
		"CLDR display name": "Cote d’Ivoire",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1008",
	},
	{
		FIFA: "CMR",
		Dial: 237,
		"ISO3166-1-Alpha-3": "CMR",
		MARC: "cm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 120,
		GAUL: 45,
		FIPS: "CM",
		WMO: "CM",
		"ISO3166-1-Alpha-2": "CM",
		ITU: "CME",
		IOC: "CMR",
		DS: "CAM",
		"UNTERM Spanish Formal": "la República del Camerún",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "Cameroun",
		"UNTERM French Short": "Cameroun (le)",
		"ISO4217-currency_name": "CFA Franc BEAC",
		"UNTERM Russian Formal": "Республика Камерун",
		"UNTERM English Short": "Cameroon",
		"ISO4217-currency_alphabetic_code": "XAF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Camerún (el)",
		"ISO4217-currency_numeric_code": 950,
		"UNTERM Chinese Formal": "喀麦隆共和国",
		"UNTERM French Formal": "la République du Cameroun",
		"UNTERM Russian Short": "Камерун",
		M49: 120,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "الكاميرون",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية الكاميرون",
		"UNTERM Chinese Short": "喀麦隆",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "Camerún",
		"UNTERM English Formal": "the Republic of Cameroon",
		official_name_cn: "喀麦隆",
		official_name_en: "Cameroon",
		"ISO4217-currency_country_name": "CAMEROON",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "الكاميرون",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Камерун",
		"Global Name": "World",
		Capital: "Yaounde",
		Continent: "AF",
		TLD: ".cm",
		Languages: "en-CM,fr-CM",
		"Geoname ID": 2233387,
		"CLDR display name": "Cameroon",
		EDGAR: "E4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1009",
	},
	{
		FIFA: "COD",
		Dial: 243,
		"ISO3166-1-Alpha-3": "COD",
		MARC: "cg",
		is_independent: "Yes",
		"ISO3166-1-numeric": 180,
		GAUL: 68,
		FIPS: "CG",
		WMO: "ZR",
		"ISO3166-1-Alpha-2": "CD",
		ITU: "COD",
		IOC: "COD",
		DS: "ZRE",
		"UNTERM Spanish Formal": "la República Democrática del Congo",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "République démocratique du Congo",
		"UNTERM French Short": "République démocratique du Congo (la)",
		"ISO4217-currency_name": "Congolese Franc",
		"UNTERM Russian Formal": "Демократическая Республика Конго",
		"UNTERM English Short": "Democratic Republic of the Congo (the)",
		"ISO4217-currency_alphabetic_code": "CDF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "República Democrática del Congo (la)",
		"ISO4217-currency_numeric_code": 976,
		"UNTERM Chinese Formal": "刚果民主共和国",
		"UNTERM French Formal": "la République démocratique du Congo",
		"UNTERM Russian Short": "Демократическая Республика Конго",
		M49: 180,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "جمهورية الكونغو الديمقراطية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية الكونغو الديمقراطية",
		"UNTERM Chinese Short": "刚果民主共和国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "República Democrática del Congo",
		"UNTERM English Formal": "the Democratic Republic of the Congo",
		official_name_cn: "刚果民主共和国",
		official_name_en: "Democratic Republic of the Congo",
		"ISO4217-currency_country_name": "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "جمهورية الكونغو الديمقراطية",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Демократическая Республика Конго",
		"Global Name": "World",
		Capital: "Kinshasa",
		Continent: "AF",
		TLD: ".cd",
		Languages: "fr-CD,ln,ktu,kg,sw,lua",
		"Geoname ID": 203312,
		"CLDR display name": "Congo - Kinshasa",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q974",
	},
	{
		FIFA: "COK",
		Dial: 682,
		"ISO3166-1-Alpha-3": "COK",
		MARC: "cw",
		is_independent: "Associated with NZ",
		"ISO3166-1-numeric": 184,
		GAUL: 60,
		FIPS: "CW",
		WMO: "KU",
		"ISO3166-1-Alpha-2": "CK",
		ITU: "CKH",
		IOC: "COK",
		DS: "NZ",
		"UNTERM Spanish Formal": "las Islas Cook **",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles Cook",
		"UNTERM French Short": "Îles Cook (les) **",
		"ISO4217-currency_name": "New Zealand Dollar",
		"UNTERM Russian Formal": "Острова Кука **",
		"UNTERM English Short": "Cook Islands (the) **",
		"ISO4217-currency_alphabetic_code": "NZD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Islas Cook (las) **",
		"ISO4217-currency_numeric_code": 554,
		"UNTERM Chinese Formal": "库克群岛 **",
		"UNTERM French Formal": "les Îles Cook **",
		"UNTERM Russian Short": "Острова Кука **",
		M49: 184,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "جزر كوك",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جزر كوك **",
		"UNTERM Chinese Short": "库克群岛 **",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Cook",
		"UNTERM English Formal": "the Cook Islands **",
		official_name_cn: "库克群岛",
		official_name_en: "Cook Islands",
		"ISO4217-currency_country_name": "COOK ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "جزر كوك **",
		"Sub-region Name": "Polynesia",
		official_name_ru: "Острова Кука",
		"Global Name": "World",
		Capital: "Avarua",
		Continent: "OC",
		TLD: ".ck",
		Languages: "en-CK,mi",
		"Geoname ID": 1899402,
		"CLDR display name": "Kepulauan Cook",
		EDGAR: "G1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q26988",
	},
	{
		FIFA: "COL",
		Dial: 57,
		"ISO3166-1-Alpha-3": "COL",
		MARC: "ck",
		is_independent: "Yes",
		"ISO3166-1-numeric": 170,
		GAUL: 57,
		FIPS: "CO",
		WMO: "CO",
		"ISO3166-1-Alpha-2": "CO",
		ITU: "CLM",
		IOC: "COL",
		DS: "CO",
		"UNTERM Spanish Formal": "la República de Colombia",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Colombie",
		"UNTERM French Short": "Colombie (la)",
		"ISO4217-currency_name": "Colombian Peso",
		"UNTERM Russian Formal": "Республика Колумбия",
		"UNTERM English Short": "Colombia",
		"ISO4217-currency_alphabetic_code": "COP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Colombia",
		"ISO4217-currency_numeric_code": 170,
		"UNTERM Chinese Formal": "哥伦比亚共和国",
		"UNTERM French Formal": "la République de Colombie",
		"UNTERM Russian Short": "Колумбия",
		M49: 170,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "كولومبيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية كولومبيا",
		"UNTERM Chinese Short": "哥伦比亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Colombia",
		"UNTERM English Formal": "the Republic of Colombia",
		official_name_cn: "哥伦比亚",
		official_name_en: "Colombia",
		"ISO4217-currency_country_name": "COLOMBIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "كولومبيا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Колумбия",
		"Global Name": "World",
		Capital: "Bogota",
		Continent: "SA",
		TLD: ".co",
		Languages: "es-CO",
		"Geoname ID": 3686110,
		"CLDR display name": "Colombia",
		EDGAR: "F8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q739",
	},
	{
		FIFA: "COM",
		Dial: 269,
		"ISO3166-1-Alpha-3": "COM",
		MARC: "cq",
		is_independent: "Yes",
		"ISO3166-1-numeric": 174,
		GAUL: 58,
		FIPS: "CN",
		WMO: "IC",
		"ISO3166-1-Alpha-2": "KM",
		ITU: "COM",
		IOC: "COM",
		DS: "KM",
		"UNTERM Spanish Formal": "la Unión de las Comoras",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Comores",
		"UNTERM French Short": "Comores (les)",
		"ISO4217-currency_name": "Comorian Franc",
		"UNTERM Russian Formal": "Союз Коморских Островов",
		"UNTERM English Short": "Comoros (the)",
		"ISO4217-currency_alphabetic_code": "KMF",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Comoras (las)",
		"ISO4217-currency_numeric_code": 174,
		"UNTERM Chinese Formal": "科摩罗联盟",
		"UNTERM French Formal": "l'Union des Comores",
		"UNTERM Russian Short": "Коморские Острова",
		M49: 174,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "جزر القمر",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "اتحاد جزر القمر",
		"UNTERM Chinese Short": "科摩罗",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Comoras",
		"UNTERM English Formal": "the Union of the Comoros",
		official_name_cn: "科摩罗",
		official_name_en: "Comoros",
		"ISO4217-currency_country_name": "COMOROS",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "جزر القمر",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Коморские Острова",
		"Global Name": "World",
		Capital: "Moroni",
		Continent: "AF",
		TLD: ".km",
		Languages: "ar,fr-KM",
		"Geoname ID": 921929,
		"CLDR display name": "Comoros",
		EDGAR: "F9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q970",
	},
	{
		FIFA: "CPV",
		Dial: 238,
		"ISO3166-1-Alpha-3": "CPV",
		MARC: "cv",
		is_independent: "Yes",
		"ISO3166-1-numeric": 132,
		GAUL: 47,
		FIPS: "CV",
		WMO: "CV",
		"ISO3166-1-Alpha-2": "CV",
		ITU: "CPV",
		IOC: "CPV",
		DS: "CV",
		"UNTERM Spanish Formal": "la República de Cabo Verde",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Cabo Verde",
		"UNTERM French Short": "Cabo Verde",
		"ISO4217-currency_name": "Cabo Verde Escudo",
		"UNTERM Russian Formal": "Республика Кабо-Верде",
		"UNTERM English Short": "Cabo Verde",
		"ISO4217-currency_alphabetic_code": "CVE",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Cabo Verde",
		"ISO4217-currency_numeric_code": 132,
		"UNTERM Chinese Formal": "佛得角共和国",
		"UNTERM French Formal": "la République de Cabo Verde",
		"UNTERM Russian Short": "Кабо-Верде",
		M49: 132,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "كابو فيردي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية كابو فيردي",
		"UNTERM Chinese Short": "佛得角",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Cabo Verde",
		"UNTERM English Formal": "the Republic of Cabo Verde",
		official_name_cn: "佛得角",
		official_name_en: "Cabo Verde",
		"ISO4217-currency_country_name": "CABO VERDE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "كابو فيردي",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Кабо-Верде",
		"Global Name": "World",
		Capital: "Praia",
		Continent: "AF",
		TLD: ".cv",
		Languages: "pt-CV",
		"Geoname ID": 3374766,
		"CLDR display name": "Cape Verde",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1011",
	},
	{
		FIFA: "CRC",
		Dial: 506,
		"ISO3166-1-Alpha-3": "CRI",
		MARC: "cr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 188,
		GAUL: 61,
		FIPS: "CS",
		WMO: "CS",
		"ISO3166-1-Alpha-2": "CR",
		ITU: "CTR",
		IOC: "CRC",
		DS: "CR",
		"UNTERM Spanish Formal": "la República de Costa Rica",
		"Global Code": 1,
		"Intermediate Region Code": 13,
		official_name_fr: "Costa Rica",
		"UNTERM French Short": "Costa Rica (le)",
		"ISO4217-currency_name": "Costa Rican Colon",
		"UNTERM Russian Formal": "Республика Коста-Рика",
		"UNTERM English Short": "Costa Rica",
		"ISO4217-currency_alphabetic_code": "CRC",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Costa Rica",
		"ISO4217-currency_numeric_code": 188,
		"UNTERM Chinese Formal": "哥斯达黎加共和国",
		"UNTERM French Formal": "la République du Costa Rica",
		"UNTERM Russian Short": "Коста-Рика",
		M49: 188,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "كوستاريكا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية كوستاريكا",
		"UNTERM Chinese Short": "哥斯达黎加",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Central America",
		official_name_es: "Costa Rica",
		"UNTERM English Formal": "the Republic of Costa Rica",
		official_name_cn: "哥斯达黎加",
		official_name_en: "Costa Rica",
		"ISO4217-currency_country_name": "COSTA RICA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "كوستاريكا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Коста-Рика",
		"Global Name": "World",
		Capital: "San Jose",
		Continent: null,
		TLD: ".cr",
		Languages: "es-CR,en",
		"Geoname ID": 3624060,
		"CLDR display name": "Costa Rica",
		EDGAR: "G2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q800",
	},
	{
		FIFA: "CRO",
		Dial: 385,
		"ISO3166-1-Alpha-3": "HRV",
		MARC: "ci",
		is_independent: "Yes",
		"ISO3166-1-numeric": 191,
		GAUL: 62,
		FIPS: "HR",
		WMO: "RH",
		"ISO3166-1-Alpha-2": "HR",
		ITU: "HRV",
		IOC: "CRO",
		DS: "HR",
		"UNTERM Spanish Formal": "la República de Croacia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Croatie",
		"UNTERM French Short": "Croatie (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Республика Хорватия",
		"UNTERM English Short": "Croatia",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Croacia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "克罗地亚共和国",
		"UNTERM French Formal": "la République de Croatie",
		"UNTERM Russian Short": "Хорватия",
		M49: 191,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "كرواتيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية كرواتيا",
		"UNTERM Chinese Short": "克罗地亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Croacia",
		"UNTERM English Formal": "the Republic of Croatia",
		official_name_cn: "克罗地亚",
		official_name_en: "Croatia",
		"ISO4217-currency_country_name": "CROATIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "كرواتيا",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Хорватия",
		"Global Name": "World",
		Capital: "Zagreb",
		Continent: "EU",
		TLD: ".hr",
		Languages: "hr-HR,sr",
		"Geoname ID": 3202326,
		"CLDR display name": "Croatia",
		EDGAR: "1M",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q224",
	},
	{
		FIFA: "CTA",
		Dial: 236,
		"ISO3166-1-Alpha-3": "CAF",
		MARC: "cx",
		is_independent: "Yes",
		"ISO3166-1-numeric": 140,
		GAUL: 49,
		FIPS: "CT",
		WMO: "CE",
		"ISO3166-1-Alpha-2": "CF",
		ITU: "CAF",
		IOC: "CAF",
		DS: "RCA",
		"UNTERM Spanish Formal": "la República Centroafricana",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "République centrafricaine",
		"UNTERM French Short": "République centrafricaine (la)",
		"ISO4217-currency_name": "CFA Franc BEAC",
		"UNTERM Russian Formal": "Центральноафриканская Республика",
		"UNTERM English Short": "Central African Republic (the)",
		"ISO4217-currency_alphabetic_code": "XAF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "República Centroafricana (la)",
		"ISO4217-currency_numeric_code": 950,
		"UNTERM Chinese Formal": "中非共和国",
		"UNTERM French Formal": "la République centrafricaine",
		"UNTERM Russian Short": "Центральноафриканская Республика",
		M49: 140,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "جمهورية أفريقيا الوسطى",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية أفريقيا الوسطى",
		"UNTERM Chinese Short": "中非共和国",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "República Centroafricana",
		"UNTERM English Formal": "the Central African Republic",
		official_name_cn: "中非共和国",
		official_name_en: "Central African Republic",
		"ISO4217-currency_country_name": "CENTRAL AFRICAN REPUBLIC",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "جمهورية أفريقيا الوسطى",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Центральноафриканская Республика",
		"Global Name": "World",
		Capital: "Bangui",
		Continent: "AF",
		TLD: ".cf",
		Languages: "fr-CF,sg,ln,kg",
		"Geoname ID": 239880,
		"CLDR display name": "Republik Afrika Tengah",
		EDGAR: "F0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q929",
	},
	{
		FIFA: "CUB",
		Dial: 53,
		"ISO3166-1-Alpha-3": "CUB",
		MARC: "cu",
		is_independent: "Yes",
		"ISO3166-1-numeric": 192,
		GAUL: 63,
		FIPS: "CU",
		WMO: "CU",
		"ISO3166-1-Alpha-2": "CU",
		ITU: "CUB",
		IOC: "CUB",
		DS: "C",
		"UNTERM Spanish Formal": "la República de Cuba",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Cuba",
		"UNTERM French Short": "Cuba",
		"ISO4217-currency_name": "Cuban Peso,Peso Convertible",
		"UNTERM Russian Formal": "Республика Куба",
		"UNTERM English Short": "Cuba",
		"ISO4217-currency_alphabetic_code": "CUP,CUC",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Cuba",
		"ISO4217-currency_numeric_code": "192,931",
		"UNTERM Chinese Formal": "古巴共和国",
		"UNTERM French Formal": "la République de Cuba",
		"UNTERM Russian Short": "Куба",
		M49: 192,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "كوبا",
		"ISO4217-currency_minor_unit": "2,2",
		"UNTERM Arabic Formal": "جمهورية كوبا",
		"UNTERM Chinese Short": "古巴",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Cuba",
		"UNTERM English Formal": "the Republic of Cuba",
		official_name_cn: "古巴",
		official_name_en: "Cuba",
		"ISO4217-currency_country_name": "CUBA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "كوبا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Куба",
		"Global Name": "World",
		Capital: "Havana",
		Continent: null,
		TLD: ".cu",
		Languages: "es-CU,pap",
		"Geoname ID": 3562981,
		"CLDR display name": "Cuba",
		EDGAR: "G3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q241",
	},
	{
		FIFA: "CXR",
		Dial: 61,
		"ISO3166-1-Alpha-3": "CXR",
		MARC: "xa",
		is_independent: "Territory of AU",
		"ISO3166-1-numeric": 162,
		GAUL: 54,
		FIPS: "KT",
		WMO: "KI",
		"ISO3166-1-Alpha-2": "CX",
		ITU: "CHR",
		IOC: null,
		DS: "AUS",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Île Christmas",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Australian Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "AUD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 36,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 162,
		"Sub-region Code": 53,
		"Region Code": 9,
		official_name_ar: "جزيرة عيد الميلاد",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Isla Christmas",
		"UNTERM English Formal": null,
		official_name_cn: "圣诞岛",
		official_name_en: "Christmas Island",
		"ISO4217-currency_country_name": "CHRISTMAS ISLAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Australia and New Zealand",
		official_name_ru: "остров Рождества",
		"Global Name": "World",
		Capital: "Flying Fish Cove",
		Continent: "OC",
		TLD: ".cx",
		Languages: "en,zh,ms-CX",
		"Geoname ID": 2078138,
		"CLDR display name": "Pulau Krismas",
		EDGAR: "F6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q31063",
	},
	{
		FIFA: "CYP",
		Dial: 357,
		"ISO3166-1-Alpha-3": "CYP",
		MARC: "cy",
		is_independent: "Yes",
		"ISO3166-1-numeric": 196,
		GAUL: 64,
		FIPS: "CY",
		WMO: "CY",
		"ISO3166-1-Alpha-2": "CY",
		ITU: "CYP",
		IOC: "CYP",
		DS: "CY",
		"UNTERM Spanish Formal": "la República de Chipre",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Chypre",
		"UNTERM French Short": "Chypre",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Республика Кипр",
		"UNTERM English Short": "Cyprus",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Chipre",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "塞浦路斯共和国",
		"UNTERM French Formal": "la République de Chypre",
		"UNTERM Russian Short": "Кипр",
		M49: 196,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "قبرص",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية قبرص",
		"UNTERM Chinese Short": "塞浦路斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Chipre",
		"UNTERM English Formal": "the Republic of Cyprus",
		official_name_cn: "塞浦路斯",
		official_name_en: "Cyprus",
		"ISO4217-currency_country_name": "CYPRUS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "قبرص",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Кипр",
		"Global Name": "World",
		Capital: "Nicosia",
		Continent: "EU",
		TLD: ".cy",
		Languages: "el-CY,tr-CY,en",
		"Geoname ID": 146669,
		"CLDR display name": "Cyprus",
		EDGAR: "G4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q229",
	},
	{
		FIFA: "CZE",
		Dial: 420,
		"ISO3166-1-Alpha-3": "CZE",
		MARC: "xr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 203,
		GAUL: 65,
		FIPS: "EZ",
		WMO: "CZ",
		"ISO3166-1-Alpha-2": "CZ",
		ITU: "CZE",
		IOC: "CZE",
		DS: "CZ",
		"UNTERM Spanish Formal": "la República Checa",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Tchéquie",
		"UNTERM French Short": "Tchéquie (la)",
		"ISO4217-currency_name": "Czech Koruna",
		"UNTERM Russian Formal": "Чешская Республика",
		"UNTERM English Short": "Czechia",
		"ISO4217-currency_alphabetic_code": "CZK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Chequia",
		"ISO4217-currency_numeric_code": 203,
		"UNTERM Chinese Formal": "捷克共和国",
		"UNTERM French Formal": "la République tchèque",
		"UNTERM Russian Short": "Чехия",
		M49: 203,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "تشيكيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية التشيكية",
		"UNTERM Chinese Short": "捷克",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Chequia",
		"UNTERM English Formal": "the Czech Republic",
		official_name_cn: "捷克",
		official_name_en: "Czechia",
		"ISO4217-currency_country_name": "CZECHIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "تشيكيا",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Чехия",
		"Global Name": "World",
		Capital: "Prague",
		Continent: "EU",
		TLD: ".cz",
		Languages: "cs,sk",
		"Geoname ID": 3077311,
		"CLDR display name": "Czechia",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q213",
	},
	{
		FIFA: "DEN",
		Dial: 45,
		"ISO3166-1-Alpha-3": "DNK",
		MARC: "dk",
		is_independent: "Yes",
		"ISO3166-1-numeric": 208,
		GAUL: 69,
		FIPS: "DA",
		WMO: "DN",
		"ISO3166-1-Alpha-2": "DK",
		ITU: "DNK",
		IOC: "DEN",
		DS: "DK",
		"UNTERM Spanish Formal": "el Reino de Dinamarca",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Danemark",
		"UNTERM French Short": "Danemark (le)",
		"ISO4217-currency_name": "Danish Krone",
		"UNTERM Russian Formal": "Королевство Дания",
		"UNTERM English Short": "Denmark",
		"ISO4217-currency_alphabetic_code": "DKK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Dinamarca",
		"ISO4217-currency_numeric_code": 208,
		"UNTERM Chinese Formal": "丹麦王国",
		"UNTERM French Formal": "le Royaume du Danemark",
		"UNTERM Russian Short": "Дания",
		M49: 208,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "الدانمرك",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة الدانمرك",
		"UNTERM Chinese Short": "丹麦",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Dinamarca",
		"UNTERM English Formal": "the Kingdom of Denmark",
		official_name_cn: "丹麦",
		official_name_en: "Denmark",
		"ISO4217-currency_country_name": "DENMARK",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "الدانمرك",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Дания",
		"Global Name": "World",
		Capital: "Copenhagen",
		Continent: "EU",
		TLD: ".dk",
		Languages: "da-DK,en,fo,de-DK",
		"Geoname ID": 2623032,
		"CLDR display name": "Denmark",
		EDGAR: "G7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q756617",
	},
	{
		FIFA: "DEN",
		Dial: 45,
		"ISO3166-1-Alpha-3": "DNK",
		MARC: "dk",
		is_independent: "Yes",
		"ISO3166-1-numeric": 208,
		GAUL: 69,
		FIPS: "DA",
		WMO: "DN",
		"ISO3166-1-Alpha-2": "DK",
		ITU: "DNK",
		IOC: "DEN",
		DS: "DK",
		"UNTERM Spanish Formal": "el Reino de Dinamarca",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Danemark",
		"UNTERM French Short": "Danemark (le)",
		"ISO4217-currency_name": "Danish Krone",
		"UNTERM Russian Formal": "Королевство Дания",
		"UNTERM English Short": "Denmark",
		"ISO4217-currency_alphabetic_code": "DKK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Dinamarca",
		"ISO4217-currency_numeric_code": 208,
		"UNTERM Chinese Formal": "丹麦王国",
		"UNTERM French Formal": "le Royaume du Danemark",
		"UNTERM Russian Short": "Дания",
		M49: 208,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "الدانمرك",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة الدانمرك",
		"UNTERM Chinese Short": "丹麦",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Dinamarca",
		"UNTERM English Formal": "the Kingdom of Denmark",
		official_name_cn: "丹麦",
		official_name_en: "Denmark",
		"ISO4217-currency_country_name": "DENMARK",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "الدانمرك",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Дания",
		"Global Name": "World",
		Capital: "Copenhagen",
		Continent: "EU",
		TLD: ".dk",
		Languages: "da-DK,en,fo,de-DK",
		"Geoname ID": 2623032,
		"CLDR display name": "Denmark",
		EDGAR: "G7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q35",
	},
	{
		FIFA: "DJI",
		Dial: 253,
		"ISO3166-1-Alpha-3": "DJI",
		MARC: "ft",
		is_independent: "Yes",
		"ISO3166-1-numeric": 262,
		GAUL: 70,
		FIPS: "DJ",
		WMO: "DJ",
		"ISO3166-1-Alpha-2": "DJ",
		ITU: "DJI",
		IOC: "DJI",
		DS: "F",
		"UNTERM Spanish Formal": "la República de Djibouti",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Djibouti",
		"UNTERM French Short": "Djibouti",
		"ISO4217-currency_name": "Djibouti Franc",
		"UNTERM Russian Formal": "Республика Джибути",
		"UNTERM English Short": "Djibouti",
		"ISO4217-currency_alphabetic_code": "DJF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Djibouti",
		"ISO4217-currency_numeric_code": 262,
		"UNTERM Chinese Formal": "吉布提共和国",
		"UNTERM French Formal": "la République de Djibouti",
		"UNTERM Russian Short": "Джибути",
		M49: 262,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "جيبوتي",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية جيبوتي",
		"UNTERM Chinese Short": "吉布提",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Djibouti",
		"UNTERM English Formal": "the Republic of Djibouti",
		official_name_cn: "吉布提",
		official_name_en: "Djibouti",
		"ISO4217-currency_country_name": "DJIBOUTI",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "جيبوتي",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Джибути",
		"Global Name": "World",
		Capital: "Djibouti",
		Continent: "AF",
		TLD: ".dj",
		Languages: "fr-DJ,ar,so-DJ,aa",
		"Geoname ID": 223816,
		"CLDR display name": "Djibouti",
		EDGAR: "1G",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q977",
	},
	{
		FIFA: "DMA",
		Dial: "1-767",
		"ISO3166-1-Alpha-3": "DMA",
		MARC: "dq",
		is_independent: "Yes",
		"ISO3166-1-numeric": 212,
		GAUL: 71,
		FIPS: "DO",
		WMO: "DO",
		"ISO3166-1-Alpha-2": "DM",
		ITU: "DMA",
		IOC: "DMA",
		DS: "WD",
		"UNTERM Spanish Formal": "el Commonwealth de Dominica",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Dominique",
		"UNTERM French Short": "Dominique (la)",
		"ISO4217-currency_name": "East Caribbean Dollar",
		"UNTERM Russian Formal": "Содружество Доминики",
		"UNTERM English Short": "Dominica",
		"ISO4217-currency_alphabetic_code": "XCD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Dominica",
		"ISO4217-currency_numeric_code": 951,
		"UNTERM Chinese Formal": "多米尼克国",
		"UNTERM French Formal": "le Commonwealth de Dominique",
		"UNTERM Russian Short": "Доминика",
		M49: 212,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "دومينيكا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "كمنولث دومينيكا",
		"UNTERM Chinese Short": "多米尼克",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Dominica",
		"UNTERM English Formal": "the Commonwealth of Dominica",
		official_name_cn: "多米尼克",
		official_name_en: "Dominica",
		"ISO4217-currency_country_name": "DOMINICA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "دومينيكا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Доминика",
		"Global Name": "World",
		Capital: "Roseau",
		Continent: null,
		TLD: ".dm",
		Languages: "en-DM",
		"Geoname ID": 3575830,
		"CLDR display name": "Dominica",
		EDGAR: "G9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q784",
	},
	{
		FIFA: "DOM",
		Dial: "1-809,1-829,1-849",
		"ISO3166-1-Alpha-3": "DOM",
		MARC: "dr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 214,
		GAUL: 72,
		FIPS: "DR",
		WMO: "DR",
		"ISO3166-1-Alpha-2": "DO",
		ITU: "DOM",
		IOC: "DOM",
		DS: "DOM",
		"UNTERM Spanish Formal": "la República Dominicana",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "République dominicaine",
		"UNTERM French Short": "République dominicaine (la)",
		"ISO4217-currency_name": "Dominican Peso",
		"UNTERM Russian Formal": "Доминиканская Республика",
		"UNTERM English Short": "Dominican Republic (the)",
		"ISO4217-currency_alphabetic_code": "DOP",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "República Dominicana (la)",
		"ISO4217-currency_numeric_code": 214,
		"UNTERM Chinese Formal": "多米尼加共和国",
		"UNTERM French Formal": "la République dominicaine",
		"UNTERM Russian Short": "Доминиканская Республика",
		M49: 214,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "الجمهورية الدومينيكية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية الدومينيكية",
		"UNTERM Chinese Short": "多米尼加",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "República Dominicana",
		"UNTERM English Formal": "the Dominican Republic",
		official_name_cn: "多米尼加",
		official_name_en: "Dominican Republic",
		"ISO4217-currency_country_name": "DOMINICAN REPUBLIC",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "الجمهورية الدومينيكية",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Доминиканская Республика",
		"Global Name": "World",
		Capital: "Santo Domingo",
		Continent: null,
		TLD: ".do",
		Languages: "es-DO",
		"Geoname ID": 3508796,
		"CLDR display name": "Republik Dominica",
		EDGAR: "G8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q786",
	},
	{
		FIFA: "ECU",
		Dial: 593,
		"ISO3166-1-Alpha-3": "ECU",
		MARC: "ec",
		is_independent: "Yes",
		"ISO3166-1-numeric": 218,
		GAUL: 73,
		FIPS: "EC",
		WMO: "EQ",
		"ISO3166-1-Alpha-2": "EC",
		ITU: "EQA",
		IOC: "ECU",
		DS: "EC",
		"UNTERM Spanish Formal": "la República del Ecuador",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Équateur",
		"UNTERM French Short": "Équateur (l')",
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": "Республика Эквадор",
		"UNTERM English Short": "Ecuador",
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Ecuador (el)",
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": "厄瓜多尔共和国",
		"UNTERM French Formal": "la République de l'Équateur",
		"UNTERM Russian Short": "Эквадор",
		M49: 218,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "إكوادور",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية إكوادور",
		"UNTERM Chinese Short": "厄瓜多尔",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Ecuador",
		"UNTERM English Formal": "the Republic of Ecuador",
		official_name_cn: "厄瓜多尔",
		official_name_en: "Ecuador",
		"ISO4217-currency_country_name": "ECUADOR",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "إكوادور",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Эквадор",
		"Global Name": "World",
		Capital: "Quito",
		Continent: "SA",
		TLD: ".ec",
		Languages: "es-EC",
		"Geoname ID": 3658394,
		"CLDR display name": "Ecuador",
		EDGAR: "H1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q736",
	},
	{
		FIFA: "EGY",
		Dial: 20,
		"ISO3166-1-Alpha-3": "EGY",
		MARC: "ua",
		is_independent: "Yes",
		"ISO3166-1-numeric": 818,
		GAUL: 40765,
		FIPS: "EG",
		WMO: "EG",
		"ISO3166-1-Alpha-2": "EG",
		ITU: "EGY",
		IOC: "EGY",
		DS: "ET",
		"UNTERM Spanish Formal": "la República Árabe de Egipto",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Égypte",
		"UNTERM French Short": "Égypte (l')",
		"ISO4217-currency_name": "Egyptian Pound",
		"UNTERM Russian Formal": "Арабская Республика Египет",
		"UNTERM English Short": "Egypt",
		"ISO4217-currency_alphabetic_code": "EGP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Egipto",
		"ISO4217-currency_numeric_code": 818,
		"UNTERM Chinese Formal": "阿拉伯埃及共和国",
		"UNTERM French Formal": "la République arabe d'Égypte",
		"UNTERM Russian Short": "Египет",
		M49: 818,
		"Sub-region Code": 15,
		"Region Code": 2,
		official_name_ar: "مصر",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية مصر العربية",
		"UNTERM Chinese Short": "埃及",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Egipto",
		"UNTERM English Formal": "the Arab Republic of Egypt",
		official_name_cn: "埃及",
		official_name_en: "Egypt",
		"ISO4217-currency_country_name": "EGYPT",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "مصر",
		"Sub-region Name": "Northern Africa",
		official_name_ru: "Египет",
		"Global Name": "World",
		Capital: "Cairo",
		Continent: "AF",
		TLD: ".eg",
		Languages: "ar-EG,en,fr",
		"Geoname ID": 357994,
		"CLDR display name": "Mesir",
		EDGAR: "H2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q79",
	},
	{
		FIFA: "EQG",
		Dial: 240,
		"ISO3166-1-Alpha-3": "GNQ",
		MARC: "eg",
		is_independent: "Yes",
		"ISO3166-1-numeric": 226,
		GAUL: 76,
		FIPS: "EK",
		WMO: "GQ",
		"ISO3166-1-Alpha-2": "GQ",
		ITU: "GNE",
		IOC: "GEQ",
		DS: "EQ",
		"UNTERM Spanish Formal": "la República de Guinea Ecuatorial",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "Guinée équatoriale",
		"UNTERM French Short": "Guinée équatoriale (la)",
		"ISO4217-currency_name": "CFA Franc BEAC",
		"UNTERM Russian Formal": "Республика Экваториальная Гвинея",
		"UNTERM English Short": "Equatorial Guinea",
		"ISO4217-currency_alphabetic_code": "XAF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Guinea Ecuatorial",
		"ISO4217-currency_numeric_code": 950,
		"UNTERM Chinese Formal": "赤道几内亚共和国",
		"UNTERM French Formal": "la République de Guinée équatoriale",
		"UNTERM Russian Short": "Экваториальная Гвинея",
		M49: 226,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "غينيا الاستوائية",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية غينيا الاستوائية",
		"UNTERM Chinese Short": "赤道几内亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "Guinea Ecuatorial",
		"UNTERM English Formal": "the Republic of Equatorial Guinea",
		official_name_cn: "赤道几内亚",
		official_name_en: "Equatorial Guinea",
		"ISO4217-currency_country_name": "EQUATORIAL GUINEA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "غينيا الاستوائية",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Экваториальная Гвинея",
		"Global Name": "World",
		Capital: "Malabo",
		Continent: "AF",
		TLD: ".gq",
		Languages: "es-GQ,fr,pt",
		"Geoname ID": 2309096,
		"CLDR display name": "Guinea Khatulistiwa",
		EDGAR: "H4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q983",
	},
	{
		FIFA: "ERI",
		Dial: 291,
		"ISO3166-1-Alpha-3": "ERI",
		MARC: "ea",
		is_independent: "Yes",
		"ISO3166-1-numeric": 232,
		GAUL: 77,
		FIPS: "ER",
		WMO: null,
		"ISO3166-1-Alpha-2": "ER",
		ITU: "ERI",
		IOC: "ERI",
		DS: "ER",
		"UNTERM Spanish Formal": "el Estado de Eritrea",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Érythrée",
		"UNTERM French Short": "Érythrée (l')",
		"ISO4217-currency_name": "Nakfa",
		"UNTERM Russian Formal": "Государство Эритрея",
		"UNTERM English Short": "Eritrea",
		"ISO4217-currency_alphabetic_code": "ERN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Eritrea",
		"ISO4217-currency_numeric_code": 232,
		"UNTERM Chinese Formal": "厄立特里亚国",
		"UNTERM French Formal": "l'État d'Érythrée",
		"UNTERM Russian Short": "Эритрея",
		M49: 232,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "إريتريا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "دولة إريتريا",
		"UNTERM Chinese Short": "厄立特里亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Eritrea",
		"UNTERM English Formal": "the State of Eritrea",
		official_name_cn: "厄立特里亚",
		official_name_en: "Eritrea",
		"ISO4217-currency_country_name": "ERITREA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "إريتريا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Эритрея",
		"Global Name": "World",
		Capital: "Asmara",
		Continent: "AF",
		TLD: ".er",
		Languages: "aa-ER,ar,tig,kun,ti-ER",
		"Geoname ID": 338010,
		"CLDR display name": "Eritrea",
		EDGAR: "1J",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q986",
	},
	{
		FIFA: "ESP",
		Dial: 34,
		"ISO3166-1-Alpha-3": "ESP",
		MARC: "sp",
		is_independent: "Yes",
		"ISO3166-1-numeric": 724,
		GAUL: 229,
		FIPS: "SP",
		WMO: "SP",
		"ISO3166-1-Alpha-2": "ES",
		ITU: "E",
		IOC: "ESP",
		DS: "E",
		"UNTERM Spanish Formal": "el Reino de España",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Espagne",
		"UNTERM French Short": "Espagne (l')",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Королевство Испания",
		"UNTERM English Short": "Spain",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "España",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "西班牙王国",
		"UNTERM French Formal": "le Royaume d'Espagne",
		"UNTERM Russian Short": "Испания",
		M49: 724,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "إسبانيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة إسبانيا",
		"UNTERM Chinese Short": "西班牙",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "España",
		"UNTERM English Formal": "the Kingdom of Spain",
		official_name_cn: "西班牙",
		official_name_en: "Spain",
		"ISO4217-currency_country_name": "SPAIN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "إسبانيا",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Испания",
		"Global Name": "World",
		Capital: "Madrid",
		Continent: "EU",
		TLD: ".es",
		Languages: "es-ES,ca,gl,eu,oc",
		"Geoname ID": 2510769,
		"CLDR display name": "Sepanyol",
		EDGAR: "U3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q29",
	},
	{
		FIFA: "EST",
		Dial: 372,
		"ISO3166-1-Alpha-3": "EST",
		MARC: "er",
		is_independent: "Yes",
		"ISO3166-1-numeric": 233,
		GAUL: 78,
		FIPS: "EN",
		WMO: "EO",
		"ISO3166-1-Alpha-2": "EE",
		ITU: "EST",
		IOC: "EST",
		DS: "EST",
		"UNTERM Spanish Formal": "la República de Estonia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Estonie",
		"UNTERM French Short": "Estonie (l')",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Эстонская Республика",
		"UNTERM English Short": "Estonia",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Estonia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "爱沙尼亚共和国",
		"UNTERM French Formal": "la République d'Estonie",
		"UNTERM Russian Short": "Эстония",
		M49: 233,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "إستونيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية إستونيا",
		"UNTERM Chinese Short": "爱沙尼亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Estonia",
		"UNTERM English Formal": "the Republic of Estonia",
		official_name_cn: "爱沙尼亚",
		official_name_en: "Estonia",
		"ISO4217-currency_country_name": "ESTONIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "إستونيا",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Эстония",
		"Global Name": "World",
		Capital: "Tallinn",
		Continent: "EU",
		TLD: ".ee",
		Languages: "et,ru",
		"Geoname ID": 453733,
		"CLDR display name": "Estonia",
		EDGAR: "1H",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q191",
	},
	{
		FIFA: "ETH",
		Dial: 251,
		"ISO3166-1-Alpha-3": "ETH",
		MARC: "et",
		is_independent: "Yes",
		"ISO3166-1-numeric": 231,
		GAUL: 79,
		FIPS: "ET",
		WMO: "ET",
		"ISO3166-1-Alpha-2": "ET",
		ITU: "ETH",
		IOC: "ETH",
		DS: "ETH",
		"UNTERM Spanish Formal": "la República Democrática Federal de Etiopía",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Éthiopie",
		"UNTERM French Short": "Éthiopie (l')",
		"ISO4217-currency_name": "Ethiopian Birr",
		"UNTERM Russian Formal": "Федеративная Демократическая Республика Эфиопия",
		"UNTERM English Short": "Ethiopia",
		"ISO4217-currency_alphabetic_code": "ETB",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Etiopía",
		"ISO4217-currency_numeric_code": 230,
		"UNTERM Chinese Formal": "埃塞俄比亚联邦民主共和国",
		"UNTERM French Formal": "la République fédérale démocratique d'Éthiopie",
		"UNTERM Russian Short": "Эфиопия",
		M49: 231,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "إثيوبيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية إثيوبيا الديمقراطية الاتحادية",
		"UNTERM Chinese Short": "埃塞俄比亚",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Etiopía",
		"UNTERM English Formal": "the Federal Democratic Republic of Ethiopia",
		official_name_cn: "埃塞俄比亚",
		official_name_en: "Ethiopia",
		"ISO4217-currency_country_name": "ETHIOPIA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "إثيوبيا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Эфиопия",
		"Global Name": "World",
		Capital: "Addis Ababa",
		Continent: "AF",
		TLD: ".et",
		Languages: "am,en-ET,om-ET,ti-ET,so-ET,sid",
		"Geoname ID": 337996,
		"CLDR display name": "Ethiopia",
		EDGAR: "H5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q115",
	},
	{
		FIFA: "FIJ",
		Dial: 679,
		"ISO3166-1-Alpha-3": "FJI",
		MARC: "fj",
		is_independent: "Yes",
		"ISO3166-1-numeric": 242,
		GAUL: 83,
		FIPS: "FJ",
		WMO: "FJ",
		"ISO3166-1-Alpha-2": "FJ",
		ITU: "FJI",
		IOC: "FIJ",
		DS: "FJI",
		"UNTERM Spanish Formal": "la República de Fiji",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Fidji",
		"UNTERM French Short": "Fidji (les)",
		"ISO4217-currency_name": "Fiji Dollar",
		"UNTERM Russian Formal": "Республика  Фиджи",
		"UNTERM English Short": "Fiji",
		"ISO4217-currency_alphabetic_code": "FJD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Fiji",
		"ISO4217-currency_numeric_code": 242,
		"UNTERM Chinese Formal": "斐济共和国",
		"UNTERM French Formal": "la République des Fidji",
		"UNTERM Russian Short": "Фиджи",
		M49: 242,
		"Sub-region Code": 54,
		"Region Code": 9,
		official_name_ar: "فيجي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية فيجي",
		"UNTERM Chinese Short": "斐济",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Fiji",
		"UNTERM English Formal": "the Republic of Fiji",
		official_name_cn: "斐济",
		official_name_en: "Fiji",
		"ISO4217-currency_country_name": "FIJI",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "فيجي",
		"Sub-region Name": "Melanesia",
		official_name_ru: "Фиджи",
		"Global Name": "World",
		Capital: "Suva",
		Continent: "OC",
		TLD: ".fj",
		Languages: "en-FJ,fj",
		"Geoname ID": 2205218,
		"CLDR display name": "Fiji",
		EDGAR: "H8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q712",
	},
	{
		FIFA: "FIN",
		Dial: 358,
		"ISO3166-1-Alpha-3": "FIN",
		MARC: "fi",
		is_independent: "Yes",
		"ISO3166-1-numeric": 246,
		GAUL: 84,
		FIPS: "FI",
		WMO: "FI",
		"ISO3166-1-Alpha-2": "FI",
		ITU: "FIN",
		IOC: "FIN",
		DS: "FIN",
		"UNTERM Spanish Formal": "la República de Finlandia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Finlande",
		"UNTERM French Short": "Finlande (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Финляндская Республика",
		"UNTERM English Short": "Finland",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Finlandia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "芬兰共和国",
		"UNTERM French Formal": "la République de Finlande",
		"UNTERM Russian Short": "Финляндия",
		M49: 246,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "فنلندا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية فنلندا",
		"UNTERM Chinese Short": "芬兰",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Finlandia",
		"UNTERM English Formal": "the Republic of Finland",
		official_name_cn: "芬兰",
		official_name_en: "Finland",
		"ISO4217-currency_country_name": "FINLAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "فنلندا",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Финляндия",
		"Global Name": "World",
		Capital: "Helsinki",
		Continent: "EU",
		TLD: ".fi",
		Languages: "fi-FI,sv-FI,smn",
		"Geoname ID": 660013,
		"CLDR display name": "Finland",
		EDGAR: "H9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q33",
	},
	{
		FIFA: "FLK",
		Dial: 500,
		"ISO3166-1-Alpha-3": "FLK",
		MARC: "fk",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 238,
		GAUL: 81,
		FIPS: "FK",
		WMO: "FK",
		"ISO3166-1-Alpha-2": "FK",
		ITU: "FLK",
		IOC: "FLK",
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Îles Falkland (Malvinas)",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Falkland Islands Pound",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "FKP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 238,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 238,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جزر فوكلاند (مالفيناس)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Islas Malvinas (Falkland)",
		"UNTERM English Formal": null,
		official_name_cn: "福克兰群岛（马尔维纳斯）",
		official_name_en: "Falkland Islands",
		"ISO4217-currency_country_name": "FALKLAND ISLANDS [MALVINAS]",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Фолклендские (Мальвинские) острова",
		"Global Name": "World",
		Capital: "Stanley",
		Continent: "SA",
		TLD: ".fk",
		Languages: "en-FK",
		"Geoname ID": 3474414,
		"CLDR display name": "Kepulauan Falkland",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q9648",
	},
	{
		FIFA: "FRA",
		Dial: 33,
		"ISO3166-1-Alpha-3": "FRA",
		MARC: "fr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 250,
		GAUL: 85,
		FIPS: "FR",
		WMO: "FR",
		"ISO3166-1-Alpha-2": "FR",
		ITU: "F",
		IOC: "FRA",
		DS: "F",
		"UNTERM Spanish Formal": "la República Francesa",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "France",
		"UNTERM French Short": "France (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Французская Республика",
		"UNTERM English Short": "France",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Francia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "法兰西共和国",
		"UNTERM French Formal": "la République française",
		"UNTERM Russian Short": "Франция",
		M49: 250,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "فرنسا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية الفرنسية",
		"UNTERM Chinese Short": "法国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Francia",
		"UNTERM English Formal": "the French Republic",
		official_name_cn: "法国",
		official_name_en: "France",
		"ISO4217-currency_country_name": "FRANCE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "فرنسا",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Франция",
		"Global Name": "World",
		Capital: "Paris",
		Continent: "EU",
		TLD: ".fr",
		Languages: "fr-FR,frp,br,co,ca,eu,oc",
		"Geoname ID": 3017382,
		"CLDR display name": "Perancis",
		EDGAR: "I0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q142",
	},
	{
		FIFA: "FRO",
		Dial: 298,
		"ISO3166-1-Alpha-3": "FRO",
		MARC: "fa",
		is_independent: "Part of DK",
		"ISO3166-1-numeric": 234,
		GAUL: 82,
		FIPS: "FO",
		WMO: "FA",
		"ISO3166-1-Alpha-2": "FO",
		ITU: "FRO",
		IOC: "FAR",
		DS: "FO",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles Féroé",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Danish Krone",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "DKK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 208,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 234,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "جزر فايرو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Feroe",
		"UNTERM English Formal": null,
		official_name_cn: "法罗群岛",
		official_name_en: "Faroe Islands",
		"ISO4217-currency_country_name": "FAROE ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Фарерские острова",
		"Global Name": "World",
		Capital: "Torshavn",
		Continent: "EU",
		TLD: ".fo",
		Languages: "fo,da-FO",
		"Geoname ID": 2622320,
		"CLDR display name": "Kepulauan Faroe",
		EDGAR: "H6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q4628",
	},
	{
		FIFA: "FSM",
		Dial: 691,
		"ISO3166-1-Alpha-3": "FSM",
		MARC: "fm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 583,
		GAUL: 163,
		FIPS: "FM",
		WMO: null,
		"ISO3166-1-Alpha-2": "FM",
		ITU: "FSM",
		IOC: "FSM",
		DS: null,
		"UNTERM Spanish Formal": "los Estados Federados de Micronesia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Micronésie (États fédérés de)",
		"UNTERM French Short": "Micronésie (États fédérés de)",
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": "Федеративные Штаты Микронезии",
		"UNTERM English Short": "Micronesia (Federated States of)",
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Micronesia (Estados Federados de)",
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": "密克罗尼西亚联邦",
		"UNTERM French Formal": "les États fédérés de Micronésie",
		"UNTERM Russian Short": "Микронезия (Федеративные Штаты)",
		M49: 583,
		"Sub-region Code": 57,
		"Region Code": 9,
		official_name_ar: "ميكرونيزيا (ولايات - الموحدة)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "ولايات ميكرونيزيا الموحدة",
		"UNTERM Chinese Short": "密克罗尼西亚联邦",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Micronesia (Estados Federados de)",
		"UNTERM English Formal": "the Federated States of Micronesia",
		official_name_cn: "密克罗尼西亚联邦",
		official_name_en: "Micronesia (Federated States of)",
		"ISO4217-currency_country_name": "MICRONESIA (FEDERATED STATES OF)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "ميكرونيزيا (ولايات - الموحدة)",
		"Sub-region Name": "Micronesia",
		official_name_ru: "Микронезия (Федеративные Штаты)",
		"Global Name": "World",
		Capital: "Palikir",
		Continent: "OC",
		TLD: ".fm",
		Languages: "en-FM,chk,pon,yap,kos,uli,woe,nkr,kpg",
		"Geoname ID": 2081918,
		"CLDR display name": "Micronesia",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q702",
	},
	{
		FIFA: "GAB",
		Dial: 241,
		"ISO3166-1-Alpha-3": "GAB",
		MARC: "go",
		is_independent: "Yes",
		"ISO3166-1-numeric": 266,
		GAUL: 89,
		FIPS: "GB",
		WMO: "GO",
		"ISO3166-1-Alpha-2": "GA",
		ITU: "GAB",
		IOC: "GAB",
		DS: "G",
		"UNTERM Spanish Formal": "la República Gabonesa",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "Gabon",
		"UNTERM French Short": "Gabon (le)",
		"ISO4217-currency_name": "CFA Franc BEAC",
		"UNTERM Russian Formal": "Габонская Республика",
		"UNTERM English Short": "Gabon",
		"ISO4217-currency_alphabetic_code": "XAF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Gabón (el)",
		"ISO4217-currency_numeric_code": 950,
		"UNTERM Chinese Formal": "加蓬共和国",
		"UNTERM French Formal": "la République gabonaise",
		"UNTERM Russian Short": "Габон",
		M49: 266,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "غابون",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية الغابون",
		"UNTERM Chinese Short": "加蓬",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "Gabón",
		"UNTERM English Formal": "the Gabonese Republic",
		official_name_cn: "加蓬",
		official_name_en: "Gabon",
		"ISO4217-currency_country_name": "GABON",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "غابون",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Габон",
		"Global Name": "World",
		Capital: "Libreville",
		Continent: "AF",
		TLD: ".ga",
		Languages: "fr-GA",
		"Geoname ID": 2400553,
		"CLDR display name": "Gabon",
		EDGAR: "I5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1000",
	},
	{
		FIFA: "GAM",
		Dial: 220,
		"ISO3166-1-Alpha-3": "GMB",
		MARC: "gm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 270,
		GAUL: 90,
		FIPS: "GA",
		WMO: "GB",
		"ISO3166-1-Alpha-2": "GM",
		ITU: "GMB",
		IOC: "GAM",
		DS: "WAG",
		"UNTERM Spanish Formal": "la República de Gambia",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Gambie",
		"UNTERM French Short": "Gambie (la)",
		"ISO4217-currency_name": "Dalasi",
		"UNTERM Russian Formal": "Республика Гамбия",
		"UNTERM English Short": "Gambia (the)",
		"ISO4217-currency_alphabetic_code": "GMD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Gambia",
		"ISO4217-currency_numeric_code": 270,
		"UNTERM Chinese Formal": "冈比亚共和国",
		"UNTERM French Formal": "la République de Gambie",
		"UNTERM Russian Short": "Гамбия",
		M49: 270,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "غامبيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية غامبيا",
		"UNTERM Chinese Short": "冈比亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Gambia",
		"UNTERM English Formal": "the Republic of the Gambia",
		official_name_cn: "冈比亚",
		official_name_en: "Gambia",
		"ISO4217-currency_country_name": "GAMBIA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "غامبيا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Гамбия",
		"Global Name": "World",
		Capital: "Banjul",
		Continent: "AF",
		TLD: ".gm",
		Languages: "en-GM,mnk,wof,wo,ff",
		"Geoname ID": 2413451,
		"CLDR display name": "Gambia",
		EDGAR: "I6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1005",
	},
	{
		FIFA: "GBG",
		Dial: 44,
		"ISO3166-1-Alpha-3": "GGY",
		MARC: "uik",
		is_independent: "Crown dependency of GB",
		"ISO3166-1-numeric": 831,
		GAUL: 104,
		FIPS: "GK",
		WMO: null,
		"ISO3166-1-Alpha-2": "GG",
		ITU: null,
		IOC: null,
		DS: "GBG",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Guernesey",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Pound Sterling",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "GBP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 826,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 831,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "غيرنسي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Guernsey",
		"UNTERM English Formal": null,
		official_name_cn: "格恩西",
		official_name_en: "Guernsey",
		"ISO4217-currency_country_name": "GUERNSEY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Гернси",
		"Global Name": "World",
		Capital: "St Peter Port",
		Continent: "EU",
		TLD: ".gg",
		Languages: "en,nrf",
		"Geoname ID": 3042362,
		"CLDR display name": "Guernsey",
		EDGAR: "Y7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q25230",
	},
	{
		FIFA: "GBJ",
		Dial: 44,
		"ISO3166-1-Alpha-3": "JEY",
		MARC: "uik",
		is_independent: "Crown dependency of GB",
		"ISO3166-1-numeric": 832,
		GAUL: 128,
		FIPS: "JE",
		WMO: null,
		"ISO3166-1-Alpha-2": "JE",
		ITU: null,
		IOC: null,
		DS: "GBJ",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Jersey",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Pound Sterling",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "GBP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 826,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 832,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "جيرسي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Jersey",
		"UNTERM English Formal": null,
		official_name_cn: "泽西",
		official_name_en: "Jersey",
		"ISO4217-currency_country_name": "JERSEY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Джерси",
		"Global Name": "World",
		Capital: "Saint Helier",
		Continent: "EU",
		TLD: ".je",
		Languages: "en,fr,nrf",
		"Geoname ID": 3042142,
		"CLDR display name": "Jersey",
		EDGAR: "Y9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q785",
	},
	{
		FIFA: "GBM",
		Dial: 44,
		"ISO3166-1-Alpha-3": "IMN",
		MARC: "uik",
		is_independent: "Crown dependency of GB",
		"ISO3166-1-numeric": 833,
		GAUL: 120,
		FIPS: "IM",
		WMO: null,
		"ISO3166-1-Alpha-2": "IM",
		ITU: null,
		IOC: null,
		DS: "GBM",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Île de Man",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Pound Sterling",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "GBP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 826,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 833,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "جزيرة مان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Isla de Man",
		"UNTERM English Formal": null,
		official_name_cn: "马恩岛",
		official_name_en: "Isle of Man",
		"ISO4217-currency_country_name": "ISLE OF MAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Остров Мэн",
		"Global Name": "World",
		Capital: "Douglas",
		Continent: "EU",
		TLD: ".im",
		Languages: "en,gv",
		"Geoname ID": 3042225,
		"CLDR display name": "Isle of Man",
		EDGAR: "Y8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q9676",
	},
	{
		FIFA: "GBZ",
		Dial: 350,
		"ISO3166-1-Alpha-3": "GIB",
		MARC: "gi",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 292,
		GAUL: 95,
		FIPS: "GI",
		WMO: "GI",
		"ISO3166-1-Alpha-2": "GI",
		ITU: "GIB",
		IOC: "GIB",
		DS: "GBZ",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Gibraltar",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Gibraltar Pound",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "GIP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 292,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 292,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "جبل طارق",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Gibraltar",
		"UNTERM English Formal": null,
		official_name_cn: "直布罗陀",
		official_name_en: "Gibraltar",
		"ISO4217-currency_country_name": "GIBRALTAR",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Гибралтар",
		"Global Name": "World",
		Capital: "Gibraltar",
		Continent: "EU",
		TLD: ".gi",
		Languages: "en-GI,es,it,pt",
		"Geoname ID": 2411586,
		"CLDR display name": "Gibraltar",
		EDGAR: "J1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1410",
	},
	{
		FIFA: "GEO",
		Dial: 995,
		"ISO3166-1-Alpha-3": "GEO",
		MARC: "gs",
		is_independent: "Yes",
		"ISO3166-1-numeric": 268,
		GAUL: 92,
		FIPS: "GG",
		WMO: "GG",
		"ISO3166-1-Alpha-2": "GE",
		ITU: "GEO",
		IOC: "GEO",
		DS: "GE",
		"UNTERM Spanish Formal": "Georgia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Géorgie",
		"UNTERM French Short": "Géorgie (la)",
		"ISO4217-currency_name": "Lari",
		"UNTERM Russian Formal": "Грузия",
		"UNTERM English Short": "Georgia",
		"ISO4217-currency_alphabetic_code": "GEL",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Georgia",
		"ISO4217-currency_numeric_code": 981,
		"UNTERM Chinese Formal": "格鲁吉亚",
		"UNTERM French Formal": "la Géorgie",
		"UNTERM Russian Short": "Грузия",
		M49: 268,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "جورجيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جورجيا",
		"UNTERM Chinese Short": "格鲁吉亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Georgia",
		"UNTERM English Formal": "Georgia",
		official_name_cn: "格鲁吉亚",
		official_name_en: "Georgia",
		"ISO4217-currency_country_name": "GEORGIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "جورجيا",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Грузия",
		"Global Name": "World",
		Capital: "Tbilisi",
		Continent: "AS",
		TLD: ".ge",
		Languages: "ka,ru,hy,az",
		"Geoname ID": 614540,
		"CLDR display name": "Georgia",
		EDGAR: "2Q",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q230",
	},
	{
		FIFA: "GER",
		Dial: 49,
		"ISO3166-1-Alpha-3": "DEU",
		MARC: "gw",
		is_independent: "Yes",
		"ISO3166-1-numeric": 276,
		GAUL: 93,
		FIPS: "GM",
		WMO: "DL",
		"ISO3166-1-Alpha-2": "DE",
		ITU: "D",
		IOC: "GER",
		DS: "D",
		"UNTERM Spanish Formal": "la República Federal de Alemania",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Allemagne",
		"UNTERM French Short": "Allemagne (l')",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Федеративная Республика Германия",
		"UNTERM English Short": "Germany",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Alemania",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "德意志联邦共和国",
		"UNTERM French Formal": "la République fédérale d'Allemagne",
		"UNTERM Russian Short": "Германия",
		M49: 276,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "ألمانيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية ألمانيا الاتحادية",
		"UNTERM Chinese Short": "德国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Alemania",
		"UNTERM English Formal": "the Federal Republic of Germany",
		official_name_cn: "德国",
		official_name_en: "Germany",
		"ISO4217-currency_country_name": "GERMANY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "ألمانيا",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Германия",
		"Global Name": "World",
		Capital: "Berlin",
		Continent: "EU",
		TLD: ".de",
		Languages: "de",
		"Geoname ID": 2921044,
		"CLDR display name": "Jerman",
		EDGAR: "2M",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q183",
	},
	{
		FIFA: "GHA",
		Dial: 233,
		"ISO3166-1-Alpha-3": "GHA",
		MARC: "gh",
		is_independent: "Yes",
		"ISO3166-1-numeric": 288,
		GAUL: 94,
		FIPS: "GH",
		WMO: "GH",
		"ISO3166-1-Alpha-2": "GH",
		ITU: "GHA",
		IOC: "GHA",
		DS: "GH",
		"UNTERM Spanish Formal": "la República de Ghana",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Ghana",
		"UNTERM French Short": "Ghana (le)",
		"ISO4217-currency_name": "Ghana Cedi",
		"UNTERM Russian Formal": "Республика Гана",
		"UNTERM English Short": "Ghana",
		"ISO4217-currency_alphabetic_code": "GHS",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Ghana",
		"ISO4217-currency_numeric_code": 936,
		"UNTERM Chinese Formal": "加纳共和国",
		"UNTERM French Formal": "la République du Ghana",
		"UNTERM Russian Short": "Гана",
		M49: 288,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "غانا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية غانا",
		"UNTERM Chinese Short": "加纳",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Ghana",
		"UNTERM English Formal": "the Republic of Ghana",
		official_name_cn: "加纳",
		official_name_en: "Ghana",
		"ISO4217-currency_country_name": "GHANA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "غانا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Гана",
		"Global Name": "World",
		Capital: "Accra",
		Continent: "AF",
		TLD: ".gh",
		Languages: "en-GH,ak,ee,tw",
		"Geoname ID": 2300660,
		"CLDR display name": "Ghana",
		EDGAR: "J0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q117",
	},
	{
		FIFA: "GLP",
		Dial: 590,
		"ISO3166-1-Alpha-3": "GLP",
		MARC: "gp",
		is_independent: "Part of FR",
		"ISO3166-1-numeric": 312,
		GAUL: 100,
		FIPS: "GP",
		WMO: "MF",
		"ISO3166-1-Alpha-2": "GP",
		ITU: "GDL",
		IOC: "GUD",
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Guadeloupe",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 312,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "غوادلوب",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Guadalupe",
		"UNTERM English Formal": null,
		official_name_cn: "瓜德罗普",
		official_name_en: "Guadeloupe",
		"ISO4217-currency_country_name": "GUADELOUPE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Гваделупа",
		"Global Name": "World",
		Capital: "Basse-Terre",
		Continent: null,
		TLD: ".gp",
		Languages: "fr-GP",
		"Geoname ID": 3579143,
		"CLDR display name": "Guadeloupe",
		EDGAR: "J6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q17012",
	},
	{
		FIFA: "GNB",
		Dial: 245,
		"ISO3166-1-Alpha-3": "GNB",
		MARC: "pg",
		is_independent: "Yes",
		"ISO3166-1-numeric": 624,
		GAUL: 105,
		FIPS: "PU",
		WMO: "GW",
		"ISO3166-1-Alpha-2": "GW",
		ITU: "GNB",
		IOC: "GBS",
		DS: "GW",
		"UNTERM Spanish Formal": "la República de Guinea-Bissau",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Guinée-Bissau",
		"UNTERM French Short": "Guinée-Bissau (la)",
		"ISO4217-currency_name": "CFA Franc BCEAO",
		"UNTERM Russian Formal": "Республика Гвинея-Бисау",
		"UNTERM English Short": "Guinea-Bissau",
		"ISO4217-currency_alphabetic_code": "XOF",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Guinea-Bissau",
		"ISO4217-currency_numeric_code": 952,
		"UNTERM Chinese Formal": "几内亚比绍共和国",
		"UNTERM French Formal": "la République de Guinée-Bissau",
		"UNTERM Russian Short": "Гвинея-Бисау",
		M49: 624,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "غينيا - بيساو",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية غينيا - بيساو",
		"UNTERM Chinese Short": "几内亚比绍",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Guinea-Bissau",
		"UNTERM English Formal": "the Republic of Guinea-Bissau",
		official_name_cn: "几内亚比绍",
		official_name_en: "Guinea-Bissau",
		"ISO4217-currency_country_name": "GUINEA-BISSAU",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "غينيا - بيساو",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Гвинея-Бисау",
		"Global Name": "World",
		Capital: "Bissau",
		Continent: "AF",
		TLD: ".gw",
		Languages: "pt-GW,pov",
		"Geoname ID": 2372248,
		"CLDR display name": "Guinea Bissau",
		EDGAR: "S0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1007",
	},
	{
		FIFA: "GRE",
		Dial: 30,
		"ISO3166-1-Alpha-3": "GRC",
		MARC: "gr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 300,
		GAUL: 97,
		FIPS: "GR",
		WMO: "GR",
		"ISO3166-1-Alpha-2": "GR",
		ITU: "GRC",
		IOC: "GRE",
		DS: "GR",
		"UNTERM Spanish Formal": "la República Helénica",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Grèce",
		"UNTERM French Short": "Grèce (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Греческая Республика",
		"UNTERM English Short": "Greece",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Grecia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "希腊共和国",
		"UNTERM French Formal": "la République hellénique",
		"UNTERM Russian Short": "Греция",
		M49: 300,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "اليونان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية الهيلينية",
		"UNTERM Chinese Short": "希腊",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Grecia",
		"UNTERM English Formal": "the Hellenic Republic",
		official_name_cn: "希腊",
		official_name_en: "Greece",
		"ISO4217-currency_country_name": "GREECE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "اليونان",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Греция",
		"Global Name": "World",
		Capital: "Athens",
		Continent: "EU",
		TLD: ".gr",
		Languages: "el-GR,en,fr",
		"Geoname ID": 390903,
		"CLDR display name": "Greece",
		EDGAR: "J3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q41",
	},
	{
		FIFA: "GRL",
		Dial: 299,
		"ISO3166-1-Alpha-3": "GRL",
		MARC: "gl",
		is_independent: "Part of DK",
		"ISO3166-1-numeric": 304,
		GAUL: 98,
		FIPS: "GL",
		WMO: "GL",
		"ISO3166-1-Alpha-2": "GL",
		ITU: "GRL",
		IOC: "GRL",
		DS: "DK",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Groenland",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Danish Krone",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "DKK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 208,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 304,
		"Sub-region Code": 21,
		"Region Code": 19,
		official_name_ar: "غرينلند",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Groenlandia",
		"UNTERM English Formal": null,
		official_name_cn: "格陵兰",
		official_name_en: "Greenland",
		"ISO4217-currency_country_name": "GREENLAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern America",
		official_name_ru: "Гренландия",
		"Global Name": "World",
		Capital: "Nuuk",
		Continent: null,
		TLD: ".gl",
		Languages: "kl,da-GL,en",
		"Geoname ID": 3425505,
		"CLDR display name": "Greenland",
		EDGAR: "J4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q223",
	},
	{
		FIFA: "GRN",
		Dial: "1-473",
		"ISO3166-1-Alpha-3": "GRD",
		MARC: "gd",
		is_independent: "Yes",
		"ISO3166-1-numeric": 308,
		GAUL: 99,
		FIPS: "GJ",
		WMO: "GD",
		"ISO3166-1-Alpha-2": "GD",
		ITU: "GRD",
		IOC: "GRN",
		DS: "WG",
		"UNTERM Spanish Formal": "Granada",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Grenade",
		"UNTERM French Short": "Grenade (la)",
		"ISO4217-currency_name": "East Caribbean Dollar",
		"UNTERM Russian Formal": "Гренада",
		"UNTERM English Short": "Grenada",
		"ISO4217-currency_alphabetic_code": "XCD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Granada",
		"ISO4217-currency_numeric_code": 951,
		"UNTERM Chinese Formal": "格林纳达",
		"UNTERM French Formal": "la Grenade",
		"UNTERM Russian Short": "Гренада",
		M49: 308,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "غرينادا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "غرينادا",
		"UNTERM Chinese Short": "格林纳达",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Granada",
		"UNTERM English Formal": "Grenada",
		official_name_cn: "格林纳达",
		official_name_en: "Grenada",
		"ISO4217-currency_country_name": "GRENADA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "غرينادا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Гренада",
		"Global Name": "World",
		Capital: "St. George's",
		Continent: null,
		TLD: ".gd",
		Languages: "en-GD",
		"Geoname ID": 3580239,
		"CLDR display name": "Grenada",
		EDGAR: "J5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q769",
	},
	{
		FIFA: "GUA",
		Dial: 502,
		"ISO3166-1-Alpha-3": "GTM",
		MARC: "gt",
		is_independent: "Yes",
		"ISO3166-1-numeric": 320,
		GAUL: 103,
		FIPS: "GT",
		WMO: "GU",
		"ISO3166-1-Alpha-2": "GT",
		ITU: "GTM",
		IOC: "GUA",
		DS: "GCA",
		"UNTERM Spanish Formal": "la República de Guatemala",
		"Global Code": 1,
		"Intermediate Region Code": 13,
		official_name_fr: "Guatemala",
		"UNTERM French Short": "Guatemala (le)",
		"ISO4217-currency_name": "Quetzal",
		"UNTERM Russian Formal": "Республика Гватемала",
		"UNTERM English Short": "Guatemala",
		"ISO4217-currency_alphabetic_code": "GTQ",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Guatemala",
		"ISO4217-currency_numeric_code": 320,
		"UNTERM Chinese Formal": "危地马拉共和国",
		"UNTERM French Formal": "la République du Guatemala",
		"UNTERM Russian Short": "Гватемала",
		M49: 320,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "غواتيمالا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية غواتيمالا",
		"UNTERM Chinese Short": "危地马拉",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Central America",
		official_name_es: "Guatemala",
		"UNTERM English Formal": "the Republic of Guatemala",
		official_name_cn: "危地马拉",
		official_name_en: "Guatemala",
		"ISO4217-currency_country_name": "GUATEMALA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "غواتيمالا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Гватемала",
		"Global Name": "World",
		Capital: "Guatemala City",
		Continent: null,
		TLD: ".gt",
		Languages: "es-GT",
		"Geoname ID": 3595528,
		"CLDR display name": "Guatemala",
		EDGAR: "J8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q774",
	},
	{
		FIFA: "GUF",
		Dial: 594,
		"ISO3166-1-Alpha-3": "GUF",
		MARC: "fg",
		is_independent: "Part of FR",
		"ISO3166-1-numeric": 254,
		GAUL: 86,
		FIPS: "FG",
		WMO: "FG",
		"ISO3166-1-Alpha-2": "GF",
		ITU: "GUF",
		IOC: "FGU",
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Guyane française",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 254,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "غيانا الفرنسية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Guayana Francesa",
		"UNTERM English Formal": null,
		official_name_cn: "法属圭亚那",
		official_name_en: "French Guiana",
		"ISO4217-currency_country_name": "FRENCH GUIANA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Французская Гвиана",
		"Global Name": "World",
		Capital: "Cayenne",
		Continent: "SA",
		TLD: ".gf",
		Languages: "fr-GF",
		"Geoname ID": 3381670,
		"CLDR display name": "Guiana Perancis",
		EDGAR: "I3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q3769",
	},
	{
		FIFA: "GUI",
		Dial: 224,
		"ISO3166-1-Alpha-3": "GIN",
		MARC: "gv",
		is_independent: "Yes",
		"ISO3166-1-numeric": 324,
		GAUL: 106,
		FIPS: "GV",
		WMO: "GN",
		"ISO3166-1-Alpha-2": "GN",
		ITU: "GUI",
		IOC: "GUI",
		DS: "RG",
		"UNTERM Spanish Formal": "la República de Guinea",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Guinée",
		"UNTERM French Short": "Guinée (la)",
		"ISO4217-currency_name": "Guinean Franc",
		"UNTERM Russian Formal": "Гвинейская Республика",
		"UNTERM English Short": "Guinea",
		"ISO4217-currency_alphabetic_code": "GNF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Guinea",
		"ISO4217-currency_numeric_code": 324,
		"UNTERM Chinese Formal": "几内亚共和国",
		"UNTERM French Formal": "la République de Guinée",
		"UNTERM Russian Short": "Гвинея",
		M49: 324,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "غينيا",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية غينيا",
		"UNTERM Chinese Short": "几内亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Guinea",
		"UNTERM English Formal": "the Republic of Guinea",
		official_name_cn: "几内亚",
		official_name_en: "Guinea",
		"ISO4217-currency_country_name": "GUINEA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "غينيا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Гвинея",
		"Global Name": "World",
		Capital: "Conakry",
		Continent: "AF",
		TLD: ".gn",
		Languages: "fr-GN",
		"Geoname ID": 2420477,
		"CLDR display name": "Guinea",
		EDGAR: "J9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1006",
	},
	{
		FIFA: "GUM",
		Dial: "1-671",
		"ISO3166-1-Alpha-3": "GUM",
		MARC: "gu",
		is_independent: "Territory of US",
		"ISO3166-1-numeric": 316,
		GAUL: 101,
		FIPS: "GQ",
		WMO: "GM",
		"ISO3166-1-Alpha-2": "GU",
		ITU: "GUM",
		IOC: "GUM",
		DS: "USA",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Guam",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 316,
		"Sub-region Code": 57,
		"Region Code": 9,
		official_name_ar: "غوام",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Guam",
		"UNTERM English Formal": null,
		official_name_cn: "关岛",
		official_name_en: "Guam",
		"ISO4217-currency_country_name": "GUAM",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Micronesia",
		official_name_ru: "Гуам",
		"Global Name": "World",
		Capital: "Hagatna",
		Continent: "OC",
		TLD: ".gu",
		Languages: "en-GU,ch-GU",
		"Geoname ID": 4043988,
		"CLDR display name": "Guam",
		EDGAR: "GU",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q16635",
	},
	{
		FIFA: "GUY",
		Dial: 592,
		"ISO3166-1-Alpha-3": "GUY",
		MARC: "gy",
		is_independent: "Yes",
		"ISO3166-1-numeric": 328,
		GAUL: 107,
		FIPS: "GY",
		WMO: "GY",
		"ISO3166-1-Alpha-2": "GY",
		ITU: "GUY",
		IOC: "GUY",
		DS: "GUY",
		"UNTERM Spanish Formal": "la República Cooperativa de Guyana",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Guyana",
		"UNTERM French Short": "Guyana (le)",
		"ISO4217-currency_name": "Guyana Dollar",
		"UNTERM Russian Formal": "Кооперативная Республика Гайана",
		"UNTERM English Short": "Guyana",
		"ISO4217-currency_alphabetic_code": "GYD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Guyana",
		"ISO4217-currency_numeric_code": 328,
		"UNTERM Chinese Formal": "圭亚那合作共和国",
		"UNTERM French Formal": "la République coopérative du Guyana",
		"UNTERM Russian Short": "Гайана",
		M49: 328,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "غيانا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية غيانا التعاونية",
		"UNTERM Chinese Short": "圭亚那",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Guyana",
		"UNTERM English Formal": "the Co-operative Republic of Guyana",
		official_name_cn: "圭亚那",
		official_name_en: "Guyana",
		"ISO4217-currency_country_name": "GUYANA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "غيانا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Гайана",
		"Global Name": "World",
		Capital: "Georgetown",
		Continent: "SA",
		TLD: ".gy",
		Languages: "en-GY",
		"Geoname ID": 3378535,
		"CLDR display name": "Guyana",
		EDGAR: "K0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q734",
	},
	{
		FIFA: "HAI",
		Dial: 509,
		"ISO3166-1-Alpha-3": "HTI",
		MARC: "ht",
		is_independent: "Yes",
		"ISO3166-1-numeric": 332,
		GAUL: 108,
		FIPS: "HA",
		WMO: "HA",
		"ISO3166-1-Alpha-2": "HT",
		ITU: "HTI",
		IOC: "HAI",
		DS: "RH",
		"UNTERM Spanish Formal": "la República de Haití",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Haïti",
		"UNTERM French Short": "Haïti",
		"ISO4217-currency_name": "Gourde,US Dollar",
		"UNTERM Russian Formal": "Республика Гаити",
		"UNTERM English Short": "Haiti",
		"ISO4217-currency_alphabetic_code": "HTG,USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Haití",
		"ISO4217-currency_numeric_code": "332,840",
		"UNTERM Chinese Formal": "海地共和国",
		"UNTERM French Formal": "la République d'Haïti",
		"UNTERM Russian Short": "Гаити",
		M49: 332,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "هايتي",
		"ISO4217-currency_minor_unit": "2,2",
		"UNTERM Arabic Formal": "جمهورية هايتي",
		"UNTERM Chinese Short": "海地",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Haití",
		"UNTERM English Formal": "the Republic of Haiti",
		official_name_cn: "海地",
		official_name_en: "Haiti",
		"ISO4217-currency_country_name": "HAITI",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Americas",
		"UNTERM Arabic Short": "هايتي",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Гаити",
		"Global Name": "World",
		Capital: "Port-au-Prince",
		Continent: null,
		TLD: ".ht",
		Languages: "ht,fr-HT",
		"Geoname ID": 3723988,
		"CLDR display name": "Haiti",
		EDGAR: "K1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q790",
	},
	{
		FIFA: "HKG",
		Dial: 852,
		"ISO3166-1-Alpha-3": "HKG",
		MARC: null,
		is_independent: "Part of CN",
		"ISO3166-1-numeric": 344,
		GAUL: 33364,
		FIPS: "HK",
		WMO: "HK",
		"ISO3166-1-Alpha-2": "HK",
		ITU: "HKG",
		IOC: "HKG",
		DS: "HK",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Chine, région administrative spéciale de Hong Kong",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Hong Kong Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "HKD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 344,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 344,
		"Sub-region Code": 30,
		"Region Code": 142,
		official_name_ar: "الصين، منطقة هونغ كونغ الإدارية الخاصة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "China, región administrativa especial de Hong Kong",
		"UNTERM English Formal": null,
		official_name_cn: "中国香港特别行政区",
		official_name_en: "China, Hong Kong Special Administrative Region",
		"ISO4217-currency_country_name": "HONG KONG",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Eastern Asia",
		official_name_ru: "Китай, Специальный административный район Гонконг",
		"Global Name": "World",
		Capital: "Hong Kong",
		Continent: "AS",
		TLD: ".hk",
		Languages: "zh-HK,yue,zh,en",
		"Geoname ID": 1819730,
		"CLDR display name": "Hong Kong",
		EDGAR: "K3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q8646",
	},
	{
		FIFA: "HON",
		Dial: 504,
		"ISO3166-1-Alpha-3": "HND",
		MARC: "ho",
		is_independent: "Yes",
		"ISO3166-1-numeric": 340,
		GAUL: 111,
		FIPS: "HO",
		WMO: "HO",
		"ISO3166-1-Alpha-2": "HN",
		ITU: "HND",
		IOC: "HON",
		DS: null,
		"UNTERM Spanish Formal": "la República de Honduras",
		"Global Code": 1,
		"Intermediate Region Code": 13,
		official_name_fr: "Honduras",
		"UNTERM French Short": "Honduras (le)",
		"ISO4217-currency_name": "Lempira",
		"UNTERM Russian Formal": "Республика Гондурас",
		"UNTERM English Short": "Honduras",
		"ISO4217-currency_alphabetic_code": "HNL",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Honduras",
		"ISO4217-currency_numeric_code": 340,
		"UNTERM Chinese Formal": "洪都拉斯共和国",
		"UNTERM French Formal": "la République du Honduras",
		"UNTERM Russian Short": "Гондурас",
		M49: 340,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "هندوراس",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية هندوراس",
		"UNTERM Chinese Short": "洪都拉斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Central America",
		official_name_es: "Honduras",
		"UNTERM English Formal": "the Republic of Honduras",
		official_name_cn: "洪都拉斯",
		official_name_en: "Honduras",
		"ISO4217-currency_country_name": "HONDURAS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "هندوراس",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Гондурас",
		"Global Name": "World",
		Capital: "Tegucigalpa",
		Continent: null,
		TLD: ".hn",
		Languages: "es-HN,cab,miq",
		"Geoname ID": 3608932,
		"CLDR display name": "Honduras",
		EDGAR: "K2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q783",
	},
	{
		FIFA: "HUN",
		Dial: 36,
		"ISO3166-1-Alpha-3": "HUN",
		MARC: "hu",
		is_independent: "Yes",
		"ISO3166-1-numeric": 348,
		GAUL: 113,
		FIPS: "HU",
		WMO: "HU",
		"ISO3166-1-Alpha-2": "HU",
		ITU: "HNG",
		IOC: "HUN",
		DS: "H",
		"UNTERM Spanish Formal": "Hungría",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Hongrie",
		"UNTERM French Short": "Hongrie (la)",
		"ISO4217-currency_name": "Forint",
		"UNTERM Russian Formal": "Венгрия",
		"UNTERM English Short": "Hungary",
		"ISO4217-currency_alphabetic_code": "HUF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Hungría",
		"ISO4217-currency_numeric_code": 348,
		"UNTERM Chinese Formal": "匈牙利",
		"UNTERM French Formal": "la Hongrie",
		"UNTERM Russian Short": "Венгрия",
		M49: 348,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "هنغاريا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "هنغاريا",
		"UNTERM Chinese Short": "匈牙利",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Hungría",
		"UNTERM English Formal": "Hungary",
		official_name_cn: "匈牙利",
		official_name_en: "Hungary",
		"ISO4217-currency_country_name": "HUNGARY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "هنغاريا",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Венгрия",
		"Global Name": "World",
		Capital: "Budapest",
		Continent: "EU",
		TLD: ".hu",
		Languages: "hu-HU",
		"Geoname ID": 719819,
		"CLDR display name": "Hungary",
		EDGAR: "K5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q28",
	},
	{
		FIFA: "IDN",
		Dial: 62,
		"ISO3166-1-Alpha-3": "IDN",
		MARC: "io",
		is_independent: "Yes",
		"ISO3166-1-numeric": 360,
		GAUL: 116,
		FIPS: "ID",
		WMO: "ID",
		"ISO3166-1-Alpha-2": "ID",
		ITU: "INS",
		IOC: "INA",
		DS: "RI",
		"UNTERM Spanish Formal": "la República de Indonesia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Indonésie",
		"UNTERM French Short": "Indonésie (l')",
		"ISO4217-currency_name": "Rupiah",
		"UNTERM Russian Formal": "Республика Индонезия",
		"UNTERM English Short": "Indonesia",
		"ISO4217-currency_alphabetic_code": "IDR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Indonesia",
		"ISO4217-currency_numeric_code": 360,
		"UNTERM Chinese Formal": "印度尼西亚共和国",
		"UNTERM French Formal": "la République d'Indonésie",
		"UNTERM Russian Short": "Индонезия",
		M49: 360,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "إندونيسيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية إندونيسيا",
		"UNTERM Chinese Short": "印度尼西亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Indonesia",
		"UNTERM English Formal": "the Republic of Indonesia",
		official_name_cn: "印度尼西亚",
		official_name_en: "Indonesia",
		"ISO4217-currency_country_name": "INDONESIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "إندونيسيا",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Индонезия",
		"Global Name": "World",
		Capital: "Jakarta",
		Continent: "AS",
		TLD: ".id",
		Languages: "id,en,nl,jv",
		"Geoname ID": 1643084,
		"CLDR display name": "Indonesia",
		EDGAR: "K8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q252",
	},
	{
		FIFA: "IND",
		Dial: 91,
		"ISO3166-1-Alpha-3": "IND",
		MARC: "ii",
		is_independent: "Yes",
		"ISO3166-1-numeric": 356,
		GAUL: 115,
		FIPS: "IN",
		WMO: "IN",
		"ISO3166-1-Alpha-2": "IN",
		ITU: "IND",
		IOC: "IND",
		DS: "IND",
		"UNTERM Spanish Formal": "la República de la India",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Inde",
		"UNTERM French Short": "Inde (l')",
		"ISO4217-currency_name": "Indian Rupee",
		"UNTERM Russian Formal": "Республика Индия",
		"UNTERM English Short": "India",
		"ISO4217-currency_alphabetic_code": "INR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "India (la)",
		"ISO4217-currency_numeric_code": 356,
		"UNTERM Chinese Formal": "印度共和国",
		"UNTERM French Formal": "la République de l'Inde",
		"UNTERM Russian Short": "Индия",
		M49: 356,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "الهند",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية الهند",
		"UNTERM Chinese Short": "印度",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "India",
		"UNTERM English Formal": "the Republic of India",
		official_name_cn: "印度",
		official_name_en: "India",
		"ISO4217-currency_country_name": "INDIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "الهند",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Индия",
		"Global Name": "World",
		Capital: "New Delhi",
		Continent: "AS",
		TLD: ".in",
		Languages:
			"en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
		"Geoname ID": 1269750,
		"CLDR display name": "India",
		EDGAR: "K7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q668",
	},
	{
		FIFA: "IRL",
		Dial: 353,
		"ISO3166-1-Alpha-3": "IRL",
		MARC: "ie",
		is_independent: "Yes",
		"ISO3166-1-numeric": 372,
		GAUL: 119,
		FIPS: "EI",
		WMO: "IE",
		"ISO3166-1-Alpha-2": "IE",
		ITU: "IRL",
		IOC: "IRL",
		DS: "IRL",
		"UNTERM Spanish Formal": "Irlanda",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Irlande",
		"UNTERM French Short": "Irlande (l')",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Ирландия",
		"UNTERM English Short": "Ireland",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Irlanda",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "爱尔兰",
		"UNTERM French Formal": "l'Irlande",
		"UNTERM Russian Short": "Ирландия",
		M49: 372,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "آيرلندا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "أيرلندا",
		"UNTERM Chinese Short": "爱尔兰",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Irlanda",
		"UNTERM English Formal": "Ireland",
		official_name_cn: "爱尔兰",
		official_name_en: "Ireland",
		"ISO4217-currency_country_name": "IRELAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "أيرلندا",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Ирландия",
		"Global Name": "World",
		Capital: "Dublin",
		Continent: "EU",
		TLD: ".ie",
		Languages: "en-IE,ga-IE",
		"Geoname ID": 2963597,
		"CLDR display name": "Ireland",
		EDGAR: "L2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q27",
	},
	{
		FIFA: "IRN",
		Dial: 98,
		"ISO3166-1-Alpha-3": "IRN",
		MARC: "ir",
		is_independent: "Yes",
		"ISO3166-1-numeric": 364,
		GAUL: 117,
		FIPS: "IR",
		WMO: "IR",
		"ISO3166-1-Alpha-2": "IR",
		ITU: "IRN",
		IOC: "IRI",
		DS: "IR",
		"UNTERM Spanish Formal": "la República Islámica del Irán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Iran (République islamique d’)",
		"UNTERM French Short": "Iran (République islamique d')",
		"ISO4217-currency_name": "Iranian Rial",
		"UNTERM Russian Formal": "Исламская Республика Иран",
		"UNTERM English Short": "Iran (Islamic Republic of)",
		"ISO4217-currency_alphabetic_code": "IRR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Irán (República Islámica del)",
		"ISO4217-currency_numeric_code": 364,
		"UNTERM Chinese Formal": "伊朗伊斯兰共和国",
		"UNTERM French Formal": "la République islamique d'Iran",
		"UNTERM Russian Short": "Иран (Исламская Республика)",
		M49: 364,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "إيران (جمهورية - الإسلامية)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية إيران الإسلامية",
		"UNTERM Chinese Short": "伊朗伊斯兰共和国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Irán (República Islámica del)",
		"UNTERM English Formal": "the Islamic Republic of Iran",
		official_name_cn: "伊朗伊斯兰共和国",
		official_name_en: "Iran (Islamic Republic of)",
		"ISO4217-currency_country_name": "IRAN (ISLAMIC REPUBLIC OF)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "إيران (جمهورية - الإسلامية)",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Иран (Исламская Республика)",
		"Global Name": "World",
		Capital: "Tehran",
		Continent: "AS",
		TLD: ".ir",
		Languages: "fa-IR,ku",
		"Geoname ID": 130758,
		"CLDR display name": "Iran",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q794",
	},
	{
		FIFA: "IRQ",
		Dial: 964,
		"ISO3166-1-Alpha-3": "IRQ",
		MARC: "iq",
		is_independent: "Yes",
		"ISO3166-1-numeric": 368,
		GAUL: 118,
		FIPS: "IZ",
		WMO: "IQ",
		"ISO3166-1-Alpha-2": "IQ",
		ITU: "IRQ",
		IOC: "IRQ",
		DS: "IRQ",
		"UNTERM Spanish Formal": "la República del Iraq",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Iraq",
		"UNTERM French Short": "Iraq (l')",
		"ISO4217-currency_name": "Iraqi Dinar",
		"UNTERM Russian Formal": "Республика Ирак",
		"UNTERM English Short": "Iraq",
		"ISO4217-currency_alphabetic_code": "IQD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Iraq (el)",
		"ISO4217-currency_numeric_code": 368,
		"UNTERM Chinese Formal": "伊拉克共和国",
		"UNTERM French Formal": "la République d'Iraq",
		"UNTERM Russian Short": "Ирак",
		M49: 368,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "العراق",
		"ISO4217-currency_minor_unit": 3,
		"UNTERM Arabic Formal": "جمهورية العراق",
		"UNTERM Chinese Short": "伊拉克",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Iraq",
		"UNTERM English Formal": "the Republic of Iraq",
		official_name_cn: "伊拉克",
		official_name_en: "Iraq",
		"ISO4217-currency_country_name": "IRAQ",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "العراق",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Ирак",
		"Global Name": "World",
		Capital: "Baghdad",
		Continent: "AS",
		TLD: ".iq",
		Languages: "ar-IQ,ku,hy",
		"Geoname ID": 99237,
		"CLDR display name": "Iraq",
		EDGAR: "L0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q796",
	},
	{
		FIFA: "ISL",
		Dial: 354,
		"ISO3166-1-Alpha-3": "ISL",
		MARC: "ic",
		is_independent: "Yes",
		"ISO3166-1-numeric": 352,
		GAUL: 114,
		FIPS: "IC",
		WMO: "IL",
		"ISO3166-1-Alpha-2": "IS",
		ITU: "ISL",
		IOC: "ISL",
		DS: "IS",
		"UNTERM Spanish Formal": "la República de Islandia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Islande",
		"UNTERM French Short": "Islande (l')",
		"ISO4217-currency_name": "Iceland Krona",
		"UNTERM Russian Formal": "Республика Исландия",
		"UNTERM English Short": "Iceland",
		"ISO4217-currency_alphabetic_code": "ISK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Islandia",
		"ISO4217-currency_numeric_code": 352,
		"UNTERM Chinese Formal": "冰岛共和国",
		"UNTERM French Formal": "la République d'Islande",
		"UNTERM Russian Short": "Исландия",
		M49: 352,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "آيسلندا",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية آيسلندا",
		"UNTERM Chinese Short": "冰岛",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islandia",
		"UNTERM English Formal": "the Republic of Iceland",
		official_name_cn: "冰岛",
		official_name_en: "Iceland",
		"ISO4217-currency_country_name": "ICELAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "آيسلندا",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Исландия",
		"Global Name": "World",
		Capital: "Reykjavik",
		Continent: "EU",
		TLD: ".is",
		Languages: "is,en,de,da,sv,no",
		"Geoname ID": 2629691,
		"CLDR display name": "Iceland",
		EDGAR: "K6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q189",
	},
	{
		FIFA: "ISR",
		Dial: 972,
		"ISO3166-1-Alpha-3": "ISR",
		MARC: "is",
		is_independent: "Yes",
		"ISO3166-1-numeric": 376,
		GAUL: 121,
		FIPS: "IS",
		WMO: "IS",
		"ISO3166-1-Alpha-2": "IL",
		ITU: "ISR",
		IOC: "ISR",
		DS: "IL",
		"UNTERM Spanish Formal": "el Estado de Israel",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Israël",
		"UNTERM French Short": "Israël",
		"ISO4217-currency_name": "New Israeli Sheqel",
		"UNTERM Russian Formal": "Государство Израиль",
		"UNTERM English Short": "Israel",
		"ISO4217-currency_alphabetic_code": "ILS",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Israel",
		"ISO4217-currency_numeric_code": 376,
		"UNTERM Chinese Formal": "以色列国",
		"UNTERM French Formal": "l'État d'Israël",
		"UNTERM Russian Short": "Израиль",
		M49: 376,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "إسرائيل",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "دولة إسرائيل",
		"UNTERM Chinese Short": "以色列",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Israel",
		"UNTERM English Formal": "the State of Israel",
		official_name_cn: "以色列",
		official_name_en: "Israel",
		"ISO4217-currency_country_name": "ISRAEL",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "إسرائيل",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Израиль",
		"Global Name": "World",
		Capital: "Jerusalem",
		Continent: "AS",
		TLD: ".il",
		Languages: "he,ar-IL,en-IL,",
		"Geoname ID": 294640,
		"CLDR display name": "Israel",
		EDGAR: "L3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q801",
	},
	{
		FIFA: "ITA",
		Dial: 39,
		"ISO3166-1-Alpha-3": "ITA",
		MARC: "it",
		is_independent: "Yes",
		"ISO3166-1-numeric": 380,
		GAUL: 122,
		FIPS: "IT",
		WMO: "IY",
		"ISO3166-1-Alpha-2": "IT",
		ITU: "I",
		IOC: "ITA",
		DS: "I",
		"UNTERM Spanish Formal": "la República Italiana",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Italie",
		"UNTERM French Short": "Italie (l')",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Итальянская Республика",
		"UNTERM English Short": "Italy",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Italia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "意大利共和国",
		"UNTERM French Formal": "la République italienne",
		"UNTERM Russian Short": "Италия",
		M49: 380,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "إيطاليا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية إيطاليا",
		"UNTERM Chinese Short": "意大利",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Italia",
		"UNTERM English Formal": "the Republic of Italy",
		official_name_cn: "意大利",
		official_name_en: "Italy",
		"ISO4217-currency_country_name": "ITALY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "إيطاليا",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Италия",
		"Global Name": "World",
		Capital: "Rome",
		Continent: "EU",
		TLD: ".it",
		Languages: "it-IT,de-IT,fr-IT,sc,ca,co,sl",
		"Geoname ID": 3175395,
		"CLDR display name": "Itali",
		EDGAR: "L6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q38",
	},
	{
		FIFA: "JAM",
		Dial: "1-876",
		"ISO3166-1-Alpha-3": "JAM",
		MARC: "jm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 388,
		GAUL: 123,
		FIPS: "JM",
		WMO: "JM",
		"ISO3166-1-Alpha-2": "JM",
		ITU: "JMC",
		IOC: "JAM",
		DS: "JA",
		"UNTERM Spanish Formal": "Jamaica",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Jamaïque",
		"UNTERM French Short": "Jamaïque (la)",
		"ISO4217-currency_name": "Jamaican Dollar",
		"UNTERM Russian Formal": "Ямайка",
		"UNTERM English Short": "Jamaica",
		"ISO4217-currency_alphabetic_code": "JMD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Jamaica",
		"ISO4217-currency_numeric_code": 388,
		"UNTERM Chinese Formal": "牙买加",
		"UNTERM French Formal": "la Jamaïque",
		"UNTERM Russian Short": "Ямайка",
		M49: 388,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جامايكا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جامايكا",
		"UNTERM Chinese Short": "牙买加",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Jamaica",
		"UNTERM English Formal": "Jamaica",
		official_name_cn: "牙买加",
		official_name_en: "Jamaica",
		"ISO4217-currency_country_name": "JAMAICA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "جامايكا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Ямайка",
		"Global Name": "World",
		Capital: "Kingston",
		Continent: null,
		TLD: ".jm",
		Languages: "en-JM",
		"Geoname ID": 3489940,
		"CLDR display name": "Jamaica",
		EDGAR: "L8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q766",
	},
	{
		FIFA: "JOR",
		Dial: 962,
		"ISO3166-1-Alpha-3": "JOR",
		MARC: "jo",
		is_independent: "Yes",
		"ISO3166-1-numeric": 400,
		GAUL: 130,
		FIPS: "JO",
		WMO: "JD",
		"ISO3166-1-Alpha-2": "JO",
		ITU: "JOR",
		IOC: "JOR",
		DS: "HKJ",
		"UNTERM Spanish Formal": "el Reino Hachemita de Jordania",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Jordanie",
		"UNTERM French Short": "Jordanie (la)",
		"ISO4217-currency_name": "Jordanian Dinar",
		"UNTERM Russian Formal": "Иорданское Хашимитское Королевство",
		"UNTERM English Short": "Jordan",
		"ISO4217-currency_alphabetic_code": "JOD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Jordania",
		"ISO4217-currency_numeric_code": 400,
		"UNTERM Chinese Formal": "约旦哈希姆王国",
		"UNTERM French Formal": "le Royaume hachémite de Jordanie",
		"UNTERM Russian Short": "Иордания",
		M49: 400,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "الأردن",
		"ISO4217-currency_minor_unit": 3,
		"UNTERM Arabic Formal": "المملكة الأردنية الهاشمية",
		"UNTERM Chinese Short": "约旦",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Jordania",
		"UNTERM English Formal": "the Hashemite Kingdom of Jordan",
		official_name_cn: "约旦",
		official_name_en: "Jordan",
		"ISO4217-currency_country_name": "JORDAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "الأردن",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Иордания",
		"Global Name": "World",
		Capital: "Amman",
		Continent: "AS",
		TLD: ".jo",
		Languages: "ar-JO,en",
		"Geoname ID": 248816,
		"CLDR display name": "Jordan",
		EDGAR: "M2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q810",
	},
	{
		FIFA: "JPN",
		Dial: 81,
		"ISO3166-1-Alpha-3": "JPN",
		MARC: "ja",
		is_independent: "Yes",
		"ISO3166-1-numeric": 392,
		GAUL: 126,
		FIPS: "JA",
		WMO: "JP",
		"ISO3166-1-Alpha-2": "JP",
		ITU: "J",
		IOC: "JPN",
		DS: "J",
		"UNTERM Spanish Formal": "el Japón",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Japon",
		"UNTERM French Short": "Japon (le)",
		"ISO4217-currency_name": "Yen",
		"UNTERM Russian Formal": "Япония",
		"UNTERM English Short": "Japan",
		"ISO4217-currency_alphabetic_code": "JPY",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Japón (el)",
		"ISO4217-currency_numeric_code": 392,
		"UNTERM Chinese Formal": "日本国",
		"UNTERM French Formal": "le Japon",
		"UNTERM Russian Short": "Япония",
		M49: 392,
		"Sub-region Code": 30,
		"Region Code": 142,
		official_name_ar: "اليابان",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "اليابان",
		"UNTERM Chinese Short": "日本",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Japón",
		"UNTERM English Formal": "Japan",
		official_name_cn: "日本",
		official_name_en: "Japan",
		"ISO4217-currency_country_name": "JAPAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "اليابان",
		"Sub-region Name": "Eastern Asia",
		official_name_ru: "Япония",
		"Global Name": "World",
		Capital: "Tokyo",
		Continent: "AS",
		TLD: ".jp",
		Languages: "ja",
		"Geoname ID": 1861060,
		"CLDR display name": "Jepun",
		EDGAR: "M0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q17",
	},
	{
		FIFA: "KAZ",
		Dial: 7,
		"ISO3166-1-Alpha-3": "KAZ",
		MARC: "kz",
		is_independent: "Yes",
		"ISO3166-1-numeric": 398,
		GAUL: 132,
		FIPS: "KZ",
		WMO: "KZ",
		"ISO3166-1-Alpha-2": "KZ",
		ITU: "KAZ",
		IOC: "KAZ",
		DS: "KZ",
		"UNTERM Spanish Formal": "la República de Kazajstán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Kazakhstan",
		"UNTERM French Short": "Kazakhstan (le)",
		"ISO4217-currency_name": "Tenge",
		"UNTERM Russian Formal": "Республика Казахстан",
		"UNTERM English Short": "Kazakhstan",
		"ISO4217-currency_alphabetic_code": "KZT",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Kazajstán",
		"ISO4217-currency_numeric_code": 398,
		"UNTERM Chinese Formal": "哈萨克斯坦共和国",
		"UNTERM French Formal": "la République du Kazakhstan",
		"UNTERM Russian Short": "Казахстан",
		M49: 398,
		"Sub-region Code": 143,
		"Region Code": 142,
		official_name_ar: "كازاخستان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية كازاخستان",
		"UNTERM Chinese Short": "哈萨克斯坦",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Kazajstán",
		"UNTERM English Formal": "the Republic of Kazakhstan",
		official_name_cn: "哈萨克斯坦",
		official_name_en: "Kazakhstan",
		"ISO4217-currency_country_name": "KAZAKHSTAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "كازاخستان",
		"Sub-region Name": "Central Asia",
		official_name_ru: "Казахстан",
		"Global Name": "World",
		Capital: "Nur-Sultan",
		Continent: "AS",
		TLD: ".kz",
		Languages: "kk,ru",
		"Geoname ID": 1522867,
		"CLDR display name": "Kazakhstan",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q232",
	},
	{
		FIFA: "KEN",
		Dial: 254,
		"ISO3166-1-Alpha-3": "KEN",
		MARC: "ke",
		is_independent: "Yes",
		"ISO3166-1-numeric": 404,
		GAUL: 133,
		FIPS: "KE",
		WMO: "KN",
		"ISO3166-1-Alpha-2": "KE",
		ITU: "KEN",
		IOC: "KEN",
		DS: "EAK",
		"UNTERM Spanish Formal": "la República de Kenya",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Kenya",
		"UNTERM French Short": "Kenya (le)",
		"ISO4217-currency_name": "Kenyan Shilling",
		"UNTERM Russian Formal": "Республика Кения",
		"UNTERM English Short": "Kenya",
		"ISO4217-currency_alphabetic_code": "KES",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Kenya",
		"ISO4217-currency_numeric_code": 404,
		"UNTERM Chinese Formal": "肯尼亚共和国",
		"UNTERM French Formal": "la République du Kenya",
		"UNTERM Russian Short": "Кения",
		M49: 404,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "كينيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية كينيا",
		"UNTERM Chinese Short": "肯尼亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Kenya",
		"UNTERM English Formal": "the Republic of Kenya",
		official_name_cn: "肯尼亚",
		official_name_en: "Kenya",
		"ISO4217-currency_country_name": "KENYA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "كينيا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Кения",
		"Global Name": "World",
		Capital: "Nairobi",
		Continent: "AF",
		TLD: ".ke",
		Languages: "en-KE,sw-KE",
		"Geoname ID": 192950,
		"CLDR display name": "Kenya",
		EDGAR: "M3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q114",
	},
	{
		FIFA: "KGZ",
		Dial: 996,
		"ISO3166-1-Alpha-3": "KGZ",
		MARC: "kg",
		is_independent: "Yes",
		"ISO3166-1-numeric": 417,
		GAUL: 138,
		FIPS: "KG",
		WMO: "KG",
		"ISO3166-1-Alpha-2": "KG",
		ITU: "KGZ",
		IOC: "KGZ",
		DS: "KS",
		"UNTERM Spanish Formal": "la República Kirguisa",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Kirghizistan",
		"UNTERM French Short": "Kirghizistan (le)",
		"ISO4217-currency_name": "Som",
		"UNTERM Russian Formal": "Кыргызская Республика",
		"UNTERM English Short": "Kyrgyzstan",
		"ISO4217-currency_alphabetic_code": "KGS",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Kirguistán",
		"ISO4217-currency_numeric_code": 417,
		"UNTERM Chinese Formal": "吉尔吉斯共和国",
		"UNTERM French Formal": "la République kirghize",
		"UNTERM Russian Short": "Кыргызстан",
		M49: 417,
		"Sub-region Code": 143,
		"Region Code": 142,
		official_name_ar: "قيرغيزستان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية قيرغيزستان",
		"UNTERM Chinese Short": "吉尔吉斯斯坦",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Kirguistán",
		"UNTERM English Formal": "the Kyrgyz Republic",
		official_name_cn: "吉尔吉斯斯坦",
		official_name_en: "Kyrgyzstan",
		"ISO4217-currency_country_name": "KYRGYZSTAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "قيرغيزستان",
		"Sub-region Name": "Central Asia",
		official_name_ru: "Кыргызстан",
		"Global Name": "World",
		Capital: "Bishkek",
		Continent: "AS",
		TLD: ".kg",
		Languages: "ky,uz,ru",
		"Geoname ID": 1527747,
		"CLDR display name": "Kyrgyzstan",
		EDGAR: "1N",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q813",
	},
	{
		FIFA: "KIR",
		Dial: 686,
		"ISO3166-1-Alpha-3": "KIR",
		MARC: "gb",
		is_independent: "Yes",
		"ISO3166-1-numeric": 296,
		GAUL: 135,
		FIPS: "KR",
		WMO: "KB",
		"ISO3166-1-Alpha-2": "KI",
		ITU: "KIR",
		IOC: "KIR",
		DS: null,
		"UNTERM Spanish Formal": "la República de Kiribati",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Kiribati",
		"UNTERM French Short": "Kiribati",
		"ISO4217-currency_name": "Australian Dollar",
		"UNTERM Russian Formal": "Республика Кирибати",
		"UNTERM English Short": "Kiribati",
		"ISO4217-currency_alphabetic_code": "AUD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Kiribati",
		"ISO4217-currency_numeric_code": 36,
		"UNTERM Chinese Formal": "基里巴斯共和国",
		"UNTERM French Formal": "la République de Kiribati",
		"UNTERM Russian Short": "Кирибати",
		M49: 296,
		"Sub-region Code": 57,
		"Region Code": 9,
		official_name_ar: "كيريباس",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية كيريباس",
		"UNTERM Chinese Short": "基里巴斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Kiribati",
		"UNTERM English Formal": "the Republic of Kiribati",
		official_name_cn: "基里巴斯",
		official_name_en: "Kiribati",
		"ISO4217-currency_country_name": "KIRIBATI",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "كيريباس",
		"Sub-region Name": "Micronesia",
		official_name_ru: "Кирибати",
		"Global Name": "World",
		Capital: "Tarawa",
		Continent: "OC",
		TLD: ".ki",
		Languages: "en-KI,gil",
		"Geoname ID": 4030945,
		"CLDR display name": "Kiribati",
		EDGAR: "J2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q710",
	},
	{
		FIFA: "KOR",
		Dial: 82,
		"ISO3166-1-Alpha-3": "KOR",
		MARC: "ko",
		is_independent: "Yes",
		"ISO3166-1-numeric": 410,
		GAUL: 202,
		FIPS: "KS",
		WMO: "KO",
		"ISO3166-1-Alpha-2": "KR",
		ITU: "KOR",
		IOC: "KOR",
		DS: "ROK",
		"UNTERM Spanish Formal": "la República de Corea",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "République de Corée",
		"UNTERM French Short": "République de Corée (la)",
		"ISO4217-currency_name": "Won",
		"UNTERM Russian Formal": "Республика Корея",
		"UNTERM English Short": "Republic of Korea (the)",
		"ISO4217-currency_alphabetic_code": "KRW",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "República de Corea (la)",
		"ISO4217-currency_numeric_code": 410,
		"UNTERM Chinese Formal": "大韩民国",
		"UNTERM French Formal": "la République de Corée",
		"UNTERM Russian Short": "Республика Корея",
		M49: 410,
		"Sub-region Code": 30,
		"Region Code": 142,
		official_name_ar: "جمهورية كوريا",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية كوريا",
		"UNTERM Chinese Short": "大韩民国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "República de Corea",
		"UNTERM English Formal": "the Republic of Korea",
		official_name_cn: "大韩民国",
		official_name_en: "Republic of Korea",
		"ISO4217-currency_country_name": "KOREA (THE REPUBLIC OF)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "جمهورية كوريا",
		"Sub-region Name": "Eastern Asia",
		official_name_ru: "Республика Корея",
		"Global Name": "World",
		Capital: "Seoul",
		Continent: "AS",
		TLD: ".kr",
		Languages: "ko-KR,en",
		"Geoname ID": 1835841,
		"CLDR display name": "Korea Selatan",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q884",
	},
	{
		FIFA: "KSA",
		Dial: 966,
		"ISO3166-1-Alpha-3": "SAU",
		MARC: "su",
		is_independent: "Yes",
		"ISO3166-1-numeric": 682,
		GAUL: 215,
		FIPS: "SA",
		WMO: "SD",
		"ISO3166-1-Alpha-2": "SA",
		ITU: "ARS",
		IOC: "KSA",
		DS: "SA",
		"UNTERM Spanish Formal": "el Reino de la Arabia Saudita",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Arabie saoudite",
		"UNTERM French Short": "Arabie saoudite (l')",
		"ISO4217-currency_name": "Saudi Riyal",
		"UNTERM Russian Formal": "Королевство Саудовская Аравия",
		"UNTERM English Short": "Saudi Arabia",
		"ISO4217-currency_alphabetic_code": "SAR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Arabia Saudita (la)",
		"ISO4217-currency_numeric_code": 682,
		"UNTERM Chinese Formal": "沙特阿拉伯王国",
		"UNTERM French Formal": "le Royaume d'Arabie saoudite",
		"UNTERM Russian Short": "Саудовская Аравия",
		M49: 682,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "المملكة العربية السعودية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "المملكة العربية السعودية",
		"UNTERM Chinese Short": "沙特阿拉伯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Arabia Saudita",
		"UNTERM English Formal": "the Kingdom of Saudi Arabia",
		official_name_cn: "沙特阿拉伯",
		official_name_en: "Saudi Arabia",
		"ISO4217-currency_country_name": "SAUDI ARABIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "المملكة العربية السعودية",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Саудовская Аравия",
		"Global Name": "World",
		Capital: "Riyadh",
		Continent: "AS",
		TLD: ".sa",
		Languages: "ar-SA",
		"Geoname ID": 102358,
		"CLDR display name": "Arab Saudi",
		EDGAR: "T0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q851",
	},
	{
		FIFA: "KUW",
		Dial: 965,
		"ISO3166-1-Alpha-3": "KWT",
		MARC: "ku",
		is_independent: "Yes",
		"ISO3166-1-numeric": 414,
		GAUL: 137,
		FIPS: "KU",
		WMO: "KW",
		"ISO3166-1-Alpha-2": "KW",
		ITU: "KWT",
		IOC: "KUW",
		DS: "KWT",
		"UNTERM Spanish Formal": "el Estado de Kuwait",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Koweït",
		"UNTERM French Short": "Koweït (le)",
		"ISO4217-currency_name": "Kuwaiti Dinar",
		"UNTERM Russian Formal": "Государство Кувейт",
		"UNTERM English Short": "Kuwait",
		"ISO4217-currency_alphabetic_code": "KWD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Kuwait",
		"ISO4217-currency_numeric_code": 414,
		"UNTERM Chinese Formal": "科威特国",
		"UNTERM French Formal": "l'État du Koweït",
		"UNTERM Russian Short": "Кувейт",
		M49: 414,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "الكويت",
		"ISO4217-currency_minor_unit": 3,
		"UNTERM Arabic Formal": "دولة الكويت",
		"UNTERM Chinese Short": "科威特",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Kuwait",
		"UNTERM English Formal": "the State of Kuwait",
		official_name_cn: "科威特",
		official_name_en: "Kuwait",
		"ISO4217-currency_country_name": "KUWAIT",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "الكويت",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Кувейт",
		"Global Name": "World",
		Capital: "Kuwait City",
		Continent: "AS",
		TLD: ".kw",
		Languages: "ar-KW,en",
		"Geoname ID": 285570,
		"CLDR display name": "Kuwait",
		EDGAR: "M6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q817",
	},
	{
		FIFA: "LAO",
		Dial: 856,
		"ISO3166-1-Alpha-3": "LAO",
		MARC: "ls",
		is_independent: "Yes",
		"ISO3166-1-numeric": 418,
		GAUL: 139,
		FIPS: "LA",
		WMO: "LA",
		"ISO3166-1-Alpha-2": "LA",
		ITU: "LAO",
		IOC: "LAO",
		DS: "LAO",
		"UNTERM Spanish Formal": "la República Democrática Popular Lao",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "République démocratique populaire lao",
		"UNTERM French Short": "République démocratique populaire lao (la)",
		"ISO4217-currency_name": "Lao Kip",
		"UNTERM Russian Formal": "Лаосская Народно-Демократическая Республика",
		"UNTERM English Short": "Lao People's Democratic Republic (the)",
		"ISO4217-currency_alphabetic_code": "LAK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "República Democrática Popular Lao (la)",
		"ISO4217-currency_numeric_code": 418,
		"UNTERM Chinese Formal": "老挝人民民主共和国",
		"UNTERM French Formal": "la République démocratique populaire lao",
		"UNTERM Russian Short": "Лаосская Народно-Демократическая Республика",
		M49: 418,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "جمهورية لاو الديمقراطية الشعبية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية لاو الديمقراطية الشعبية",
		"UNTERM Chinese Short": "老挝人民民主共和国",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "República Democrática Popular Lao",
		"UNTERM English Formal": "the Lao People's Democratic Republic",
		official_name_cn: "老挝人民民主共和国",
		official_name_en: "Lao People's Democratic Republic",
		"ISO4217-currency_country_name": "LAO PEOPLE’S DEMOCRATIC REPUBLIC",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Asia",
		"UNTERM Arabic Short": "جمهورية لاو الديمقراطية الشعبية",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Лаосская Народно-Демократическая Республика",
		"Global Name": "World",
		Capital: "Vientiane",
		Continent: "AS",
		TLD: ".la",
		Languages: "lo,fr,en",
		"Geoname ID": 1655842,
		"CLDR display name": "Laos",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q819",
	},
	{
		FIFA: "LBR",
		Dial: 231,
		"ISO3166-1-Alpha-3": "LBR",
		MARC: "lb",
		is_independent: "Yes",
		"ISO3166-1-numeric": 430,
		GAUL: 144,
		FIPS: "LI",
		WMO: "LI",
		"ISO3166-1-Alpha-2": "LR",
		ITU: "LBR",
		IOC: "LBR",
		DS: "LB",
		"UNTERM Spanish Formal": "la República de Liberia",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Libéria",
		"UNTERM French Short": "Libéria (le)",
		"ISO4217-currency_name": "Liberian Dollar",
		"UNTERM Russian Formal": "Республика Либерия",
		"UNTERM English Short": "Liberia",
		"ISO4217-currency_alphabetic_code": "LRD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Liberia",
		"ISO4217-currency_numeric_code": 430,
		"UNTERM Chinese Formal": "利比里亚共和国",
		"UNTERM French Formal": "la République du Libéria",
		"UNTERM Russian Short": "Либерия",
		M49: 430,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "ليبريا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية ليبريا",
		"UNTERM Chinese Short": "利比里亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Liberia",
		"UNTERM English Formal": "the Republic of Liberia",
		official_name_cn: "利比里亚",
		official_name_en: "Liberia",
		"ISO4217-currency_country_name": "LIBERIA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "ليبريا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Либерия",
		"Global Name": "World",
		Capital: "Monrovia",
		Continent: "AF",
		TLD: ".lr",
		Languages: "en-LR",
		"Geoname ID": 2275384,
		"CLDR display name": "Liberia",
		EDGAR: "N0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1014",
	},
	{
		FIFA: "LBY",
		Dial: 218,
		"ISO3166-1-Alpha-3": "LBY",
		MARC: "ly",
		is_independent: "Yes",
		"ISO3166-1-numeric": 434,
		GAUL: 145,
		FIPS: "LY",
		WMO: "LY",
		"ISO3166-1-Alpha-2": "LY",
		ITU: "LBY",
		IOC: "LBA",
		DS: "LAR",
		"UNTERM Spanish Formal": "el Estado de Libia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Libye",
		"UNTERM French Short": "Libye (la)",
		"ISO4217-currency_name": "Libyan Dinar",
		"UNTERM Russian Formal": "Государство Ливия",
		"UNTERM English Short": "Libya",
		"ISO4217-currency_alphabetic_code": "LYD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Libia",
		"ISO4217-currency_numeric_code": 434,
		"UNTERM Chinese Formal": "利比亚国",
		"UNTERM French Formal": "l'État de Libye",
		"UNTERM Russian Short": "Ливия",
		M49: 434,
		"Sub-region Code": 15,
		"Region Code": 2,
		official_name_ar: "ليبيا",
		"ISO4217-currency_minor_unit": 3,
		"UNTERM Arabic Formal": "دولة ليبيا",
		"UNTERM Chinese Short": "利比亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Libia",
		"UNTERM English Formal": "the State of Libya",
		official_name_cn: "利比亚",
		official_name_en: "Libya",
		"ISO4217-currency_country_name": "LIBYA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "ليبيا",
		"Sub-region Name": "Northern Africa",
		official_name_ru: "Ливия",
		"Global Name": "World",
		Capital: "Tripoli",
		Continent: "AF",
		TLD: ".ly",
		Languages: "ar-LY,it,en",
		"Geoname ID": 2215636,
		"CLDR display name": "Libya",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1016",
	},
	{
		FIFA: "LCA",
		Dial: "1-758",
		"ISO3166-1-Alpha-3": "LCA",
		MARC: "xk",
		is_independent: "Yes",
		"ISO3166-1-numeric": 662,
		GAUL: 209,
		FIPS: "ST",
		WMO: "LC",
		"ISO3166-1-Alpha-2": "LC",
		ITU: "LCA",
		IOC: "LCA",
		DS: "WL",
		"UNTERM Spanish Formal": "Santa Lucía",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Sainte-Lucie",
		"UNTERM French Short": "Sainte-Lucie",
		"ISO4217-currency_name": "East Caribbean Dollar",
		"UNTERM Russian Formal": "Сент-Люсия",
		"UNTERM English Short": "Saint Lucia",
		"ISO4217-currency_alphabetic_code": "XCD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Santa Lucía",
		"ISO4217-currency_numeric_code": 951,
		"UNTERM Chinese Formal": "圣卢西亚",
		"UNTERM French Formal": "Sainte-Lucie",
		"UNTERM Russian Short": "Сент-Люсия",
		M49: 662,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "سانت لوسيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "سانت لوسيا",
		"UNTERM Chinese Short": "圣卢西亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Santa Lucía",
		"UNTERM English Formal": "Saint Lucia",
		official_name_cn: "圣卢西亚",
		official_name_en: "Saint Lucia",
		"ISO4217-currency_country_name": "SAINT LUCIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "سانت لوسيا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Сент-Люсия",
		"Global Name": "World",
		Capital: "Castries",
		Continent: null,
		TLD: ".lc",
		Languages: "en-LC",
		"Geoname ID": 3576468,
		"CLDR display name": "Saint Lucia",
		EDGAR: "U9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q760",
	},
	{
		FIFA: "LES",
		Dial: 266,
		"ISO3166-1-Alpha-3": "LSO",
		MARC: "lo",
		is_independent: "Yes",
		"ISO3166-1-numeric": 426,
		GAUL: 142,
		FIPS: "LT",
		WMO: "LS",
		"ISO3166-1-Alpha-2": "LS",
		ITU: "LSO",
		IOC: "LES",
		DS: "LS",
		"UNTERM Spanish Formal": "el Reino de Lesotho",
		"Global Code": 1,
		"Intermediate Region Code": 18,
		official_name_fr: "Lesotho",
		"UNTERM French Short": "Lesotho (le)",
		"ISO4217-currency_name": "Loti,Rand",
		"UNTERM Russian Formal": "Королевство Лесото",
		"UNTERM English Short": "Lesotho",
		"ISO4217-currency_alphabetic_code": "LSL,ZAR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Lesotho",
		"ISO4217-currency_numeric_code": "426,710",
		"UNTERM Chinese Formal": "莱索托王国",
		"UNTERM French Formal": "le Royaume du Lesotho",
		"UNTERM Russian Short": "Лесото",
		M49: 426,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "ليسوتو",
		"ISO4217-currency_minor_unit": "2,2",
		"UNTERM Arabic Formal": "مملكة ليسوتو",
		"UNTERM Chinese Short": "莱索托",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Southern Africa",
		official_name_es: "Lesotho",
		"UNTERM English Formal": "the Kingdom of Lesotho",
		official_name_cn: "莱索托",
		official_name_en: "Lesotho",
		"ISO4217-currency_country_name": "LESOTHO",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "ليسوتو",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Лесото",
		"Global Name": "World",
		Capital: "Maseru",
		Continent: "AF",
		TLD: ".ls",
		Languages: "en-LS,st,zu,xh",
		"Geoname ID": 932692,
		"CLDR display name": "Lesotho",
		EDGAR: "M9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1013",
	},
	{
		FIFA: "LIB",
		Dial: 961,
		"ISO3166-1-Alpha-3": "LBN",
		MARC: "le",
		is_independent: "Yes",
		"ISO3166-1-numeric": 422,
		GAUL: 141,
		FIPS: "LE",
		WMO: "LB",
		"ISO3166-1-Alpha-2": "LB",
		ITU: "LBN",
		IOC: "LIB",
		DS: "RL",
		"UNTERM Spanish Formal": "la República Libanesa",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Liban",
		"UNTERM French Short": "Liban (le)",
		"ISO4217-currency_name": "Lebanese Pound",
		"UNTERM Russian Formal": "Ливанская Республика",
		"UNTERM English Short": "Lebanon",
		"ISO4217-currency_alphabetic_code": "LBP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Líbano (el)",
		"ISO4217-currency_numeric_code": 422,
		"UNTERM Chinese Formal": "黎巴嫩共和国",
		"UNTERM French Formal": "la République libanaise",
		"UNTERM Russian Short": "Ливан",
		M49: 422,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "لبنان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية اللبنانية",
		"UNTERM Chinese Short": "黎巴嫩",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Líbano",
		"UNTERM English Formal": "the Lebanese Republic",
		official_name_cn: "黎巴嫩",
		official_name_en: "Lebanon",
		"ISO4217-currency_country_name": "LEBANON",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "لبنان",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Ливан",
		"Global Name": "World",
		Capital: "Beirut",
		Continent: "AS",
		TLD: ".lb",
		Languages: "ar-LB,fr-LB,en,hy",
		"Geoname ID": 272103,
		"CLDR display name": "Lubnan",
		EDGAR: "M8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q822",
	},
	{
		FIFA: "LIE",
		Dial: 423,
		"ISO3166-1-Alpha-3": "LIE",
		MARC: "lh",
		is_independent: "Yes",
		"ISO3166-1-numeric": 438,
		GAUL: 146,
		FIPS: "LS",
		WMO: null,
		"ISO3166-1-Alpha-2": "LI",
		ITU: "LIE",
		IOC: "LIE",
		DS: "FL",
		"UNTERM Spanish Formal": "el Principado de Liechtenstein",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Liechtenstein",
		"UNTERM French Short": "Liechtenstein (le)",
		"ISO4217-currency_name": "Swiss Franc",
		"UNTERM Russian Formal": "Княжество Лихтенштейн",
		"UNTERM English Short": "Liechtenstein",
		"ISO4217-currency_alphabetic_code": "CHF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Liechtenstein",
		"ISO4217-currency_numeric_code": 756,
		"UNTERM Chinese Formal": "列支敦士登公国",
		"UNTERM French Formal": "la Principauté du Liechtenstein",
		"UNTERM Russian Short": "Лихтенштейн",
		M49: 438,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "ليختنشتاين",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "إمارة ليختنشتاين",
		"UNTERM Chinese Short": "列支敦士登",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Liechtenstein",
		"UNTERM English Formal": "the Principality of Liechtenstein",
		official_name_cn: "列支敦士登",
		official_name_en: "Liechtenstein",
		"ISO4217-currency_country_name": "LIECHTENSTEIN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "ليختنشتاين",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Лихтенштейн",
		"Global Name": "World",
		Capital: "Vaduz",
		Continent: "EU",
		TLD: ".li",
		Languages: "de-LI",
		"Geoname ID": 3042058,
		"CLDR display name": "Liechtenstein",
		EDGAR: "N2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q347",
	},
	{
		FIFA: "LTU",
		Dial: 370,
		"ISO3166-1-Alpha-3": "LTU",
		MARC: "li",
		is_independent: "Yes",
		"ISO3166-1-numeric": 440,
		GAUL: 147,
		FIPS: "LH",
		WMO: "LT",
		"ISO3166-1-Alpha-2": "LT",
		ITU: "LTU",
		IOC: "LTU",
		DS: "LT",
		"UNTERM Spanish Formal": "la República de Lituania",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Lituanie",
		"UNTERM French Short": "Lituanie (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Литовская Республика",
		"UNTERM English Short": "Lithuania",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Lituania",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "立陶宛共和国",
		"UNTERM French Formal": "la République de Lituanie",
		"UNTERM Russian Short": "Литва",
		M49: 440,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "ليتوانيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية ليتوانيا",
		"UNTERM Chinese Short": "立陶宛",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Lituania",
		"UNTERM English Formal": "the Republic of Lithuania",
		official_name_cn: "立陶宛",
		official_name_en: "Lithuania",
		"ISO4217-currency_country_name": "LITHUANIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "ليتوانيا",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Литва",
		"Global Name": "World",
		Capital: "Vilnius",
		Continent: "EU",
		TLD: ".lt",
		Languages: "lt,ru,pl",
		"Geoname ID": 597427,
		"CLDR display name": "Lithuania",
		EDGAR: "1Q",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q37",
	},
	{
		FIFA: "LUX",
		Dial: 352,
		"ISO3166-1-Alpha-3": "LUX",
		MARC: "lu",
		is_independent: "Yes",
		"ISO3166-1-numeric": 442,
		GAUL: 148,
		FIPS: "LU",
		WMO: "BX",
		"ISO3166-1-Alpha-2": "LU",
		ITU: "LUX",
		IOC: "LUX",
		DS: "L",
		"UNTERM Spanish Formal": "el Gran Ducado de Luxemburgo",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Luxembourg",
		"UNTERM French Short": "Luxembourg (le)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Великое Герцогство Люксембург",
		"UNTERM English Short": "Luxembourg",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Luxemburgo",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "卢森堡大公国",
		"UNTERM French Formal": "le Grand-Duché de Luxembourg",
		"UNTERM Russian Short": "Люксембург",
		M49: 442,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "لكسمبرغ",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "دوقية لكسمبرغ الكبرى",
		"UNTERM Chinese Short": "卢森堡",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Luxemburgo",
		"UNTERM English Formal": "the Grand Duchy of Luxembourg",
		official_name_cn: "卢森堡",
		official_name_en: "Luxembourg",
		"ISO4217-currency_country_name": "LUXEMBOURG",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "لكسمبرغ",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Люксембург",
		"Global Name": "World",
		Capital: "Luxembourg",
		Continent: "EU",
		TLD: ".lu",
		Languages: "lb,de-LU,fr-LU",
		"Geoname ID": 2960313,
		"CLDR display name": "Luxembourg",
		EDGAR: "N4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q32",
	},
	{
		FIFA: "LVA",
		Dial: 371,
		"ISO3166-1-Alpha-3": "LVA",
		MARC: "lv",
		is_independent: "Yes",
		"ISO3166-1-numeric": 428,
		GAUL: 140,
		FIPS: "LG",
		WMO: "LV",
		"ISO3166-1-Alpha-2": "LV",
		ITU: "LVA",
		IOC: "LAT",
		DS: "LV",
		"UNTERM Spanish Formal": "la República de Letonia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Lettonie",
		"UNTERM French Short": "Lettonie (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Латвийская Республика",
		"UNTERM English Short": "Latvia",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Letonia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "拉脱维亚共和国",
		"UNTERM French Formal": "la République de Lettonie",
		"UNTERM Russian Short": "Латвия",
		M49: 428,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "لاتفيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية لاتفيا",
		"UNTERM Chinese Short": "拉脱维亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Letonia",
		"UNTERM English Formal": "the Republic of Latvia",
		official_name_cn: "拉脱维亚",
		official_name_en: "Latvia",
		"ISO4217-currency_country_name": "LATVIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "لاتفيا",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Латвия",
		"Global Name": "World",
		Capital: "Riga",
		Continent: "EU",
		TLD: ".lv",
		Languages: "lv,ru,lt",
		"Geoname ID": 458258,
		"CLDR display name": "Latvia",
		EDGAR: "1R",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q211",
	},
	{
		FIFA: "MAC",
		Dial: 853,
		"ISO3166-1-Alpha-3": "MAC",
		MARC: null,
		is_independent: "Part of CN",
		"ISO3166-1-numeric": 446,
		GAUL: 149,
		FIPS: "MC",
		WMO: "MU",
		"ISO3166-1-Alpha-2": "MO",
		ITU: "MAC",
		IOC: "MAC",
		DS: "MO",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Chine, région administrative spéciale de Macao",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Pataca",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "MOP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 446,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 446,
		"Sub-region Code": 30,
		"Region Code": 142,
		official_name_ar: "الصين، منطقة ماكاو الإدارية الخاصة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "China, región administrativa especial de Macao",
		"UNTERM English Formal": null,
		official_name_cn: "中国澳门特别行政区",
		official_name_en: "China, Macao Special Administrative Region",
		"ISO4217-currency_country_name": "MACAO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Eastern Asia",
		official_name_ru: "Китай, Специальный административный район Макао",
		"Global Name": "World",
		Capital: "Macao",
		Continent: "AS",
		TLD: ".mo",
		Languages: "zh,zh-MO,pt",
		"Geoname ID": 1821275,
		"CLDR display name": "Macau",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q14773",
	},
	{
		FIFA: "MAD",
		Dial: 261,
		"ISO3166-1-Alpha-3": "MDG",
		MARC: "mg",
		is_independent: "Yes",
		"ISO3166-1-numeric": 450,
		GAUL: 150,
		FIPS: "MA",
		WMO: "MG",
		"ISO3166-1-Alpha-2": "MG",
		ITU: "MDG",
		IOC: "MAD",
		DS: "RM",
		"UNTERM Spanish Formal": "la República de Madagascar",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Madagascar",
		"UNTERM French Short": "Madagascar",
		"ISO4217-currency_name": "Malagasy Ariary",
		"UNTERM Russian Formal": "Республика Мадагаскар",
		"UNTERM English Short": "Madagascar",
		"ISO4217-currency_alphabetic_code": "MGA",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Madagascar",
		"ISO4217-currency_numeric_code": 969,
		"UNTERM Chinese Formal": "马达加斯加共和国",
		"UNTERM French Formal": "la République de Madagascar",
		"UNTERM Russian Short": "Мадагаскар",
		M49: 450,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "مدغشقر",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية مدغشقر",
		"UNTERM Chinese Short": "马达加斯加",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Madagascar",
		"UNTERM English Formal": "the Republic of Madagascar",
		official_name_cn: "马达加斯加",
		official_name_en: "Madagascar",
		"ISO4217-currency_country_name": "MADAGASCAR",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "مدغشقر",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Мадагаскар",
		"Global Name": "World",
		Capital: "Antananarivo",
		Continent: "AF",
		TLD: ".mg",
		Languages: "fr-MG,mg",
		"Geoname ID": 1062947,
		"CLDR display name": "Madagaskar",
		EDGAR: "N6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1019",
	},
	{
		FIFA: "MAR",
		Dial: 212,
		"ISO3166-1-Alpha-3": "MAR",
		MARC: "mr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 504,
		GAUL: 169,
		FIPS: "MO",
		WMO: "MC",
		"ISO3166-1-Alpha-2": "MA",
		ITU: "MRC",
		IOC: "MAR",
		DS: "MA",
		"UNTERM Spanish Formal": "el Reino de Marruecos",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Maroc",
		"UNTERM French Short": "Maroc (le)",
		"ISO4217-currency_name": "Moroccan Dirham",
		"UNTERM Russian Formal": "Королевство Марокко",
		"UNTERM English Short": "Morocco",
		"ISO4217-currency_alphabetic_code": "MAD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Marruecos",
		"ISO4217-currency_numeric_code": 504,
		"UNTERM Chinese Formal": "摩洛哥王国",
		"UNTERM French Formal": "le Royaume du Maroc",
		"UNTERM Russian Short": "Марокко",
		M49: 504,
		"Sub-region Code": 15,
		"Region Code": 2,
		official_name_ar: "المغرب",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "المملكة المغربية",
		"UNTERM Chinese Short": "摩洛哥",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Marruecos",
		"UNTERM English Formal": "the Kingdom of Morocco",
		official_name_cn: "摩洛哥",
		official_name_en: "Morocco",
		"ISO4217-currency_country_name": "MOROCCO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "المغرب",
		"Sub-region Name": "Northern Africa",
		official_name_ru: "Марокко",
		"Global Name": "World",
		Capital: "Rabat",
		Continent: "AF",
		TLD: ".ma",
		Languages: "ar-MA,ber,fr",
		"Geoname ID": 2542007,
		"CLDR display name": "Maghribi",
		EDGAR: "P2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1028",
	},
	{
		FIFA: "MAS",
		Dial: 60,
		"ISO3166-1-Alpha-3": "MYS",
		MARC: "my",
		is_independent: "Yes",
		"ISO3166-1-numeric": 458,
		GAUL: 153,
		FIPS: "MY",
		WMO: "MS",
		"ISO3166-1-Alpha-2": "MY",
		ITU: "MLA",
		IOC: "MAS",
		DS: "MAL",
		"UNTERM Spanish Formal": "Malasia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Malaisie",
		"UNTERM French Short": "Malaisie (la)",
		"ISO4217-currency_name": "Malaysian Ringgit",
		"UNTERM Russian Formal": "Малайзия",
		"UNTERM English Short": "Malaysia",
		"ISO4217-currency_alphabetic_code": "MYR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Malasia",
		"ISO4217-currency_numeric_code": 458,
		"UNTERM Chinese Formal": "马来西亚",
		"UNTERM French Formal": "la Malaisie",
		"UNTERM Russian Short": "Малайзия",
		M49: 458,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "ماليزيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "ماليزيا",
		"UNTERM Chinese Short": "马来西亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Malasia",
		"UNTERM English Formal": "Malaysia",
		official_name_cn: "马来西亚",
		official_name_en: "Malaysia",
		"ISO4217-currency_country_name": "MALAYSIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "ماليزيا",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Малайзия",
		"Global Name": "World",
		Capital: "Kuala Lumpur",
		Continent: "AS",
		TLD: ".my",
		Languages: "ms-MY,en,zh,ta,te,ml,pa,th",
		"Geoname ID": 1733045,
		"CLDR display name": "Malaysia",
		EDGAR: "N8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q833",
	},
	{
		FIFA: "MDA",
		Dial: 373,
		"ISO3166-1-Alpha-3": "MDA",
		MARC: "mv",
		is_independent: "Yes",
		"ISO3166-1-numeric": 498,
		GAUL: 165,
		FIPS: "MD",
		WMO: "RM",
		"ISO3166-1-Alpha-2": "MD",
		ITU: "MDA",
		IOC: "MDA",
		DS: "MD",
		"UNTERM Spanish Formal": "la República de Moldova",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "République de Moldova",
		"UNTERM French Short": "République de Moldova (la)",
		"ISO4217-currency_name": "Moldovan Leu",
		"UNTERM Russian Formal": "Республика Молдова",
		"UNTERM English Short": "Republic of Moldova (the)",
		"ISO4217-currency_alphabetic_code": "MDL",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "República de Moldova (la)",
		"ISO4217-currency_numeric_code": 498,
		"UNTERM Chinese Formal": "摩尔多瓦共和国",
		"UNTERM French Formal": "la République de Moldova",
		"UNTERM Russian Short": "Республика Молдова",
		M49: 498,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "جمهورية مولدوفا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية مولدوفا",
		"UNTERM Chinese Short": "摩尔多瓦共和国",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "República de Moldova",
		"UNTERM English Formal": "the Republic of Moldova",
		official_name_cn: "摩尔多瓦共和国",
		official_name_en: "Republic of Moldova",
		"ISO4217-currency_country_name": "MOLDOVA (THE REPUBLIC OF)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "جمهورية مولدوفا",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Республика Молдова",
		"Global Name": "World",
		Capital: "Chisinau",
		Continent: "EU",
		TLD: ".md",
		Languages: "ro,ru,gag,tr",
		"Geoname ID": 617790,
		"CLDR display name": "Moldova",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q217",
	},
	{
		FIFA: "MDV",
		Dial: 960,
		"ISO3166-1-Alpha-3": "MDV",
		MARC: "xc",
		is_independent: "Yes",
		"ISO3166-1-numeric": 462,
		GAUL: 154,
		FIPS: "MV",
		WMO: "MV",
		"ISO3166-1-Alpha-2": "MV",
		ITU: "MLD",
		IOC: "MDV",
		DS: "MV",
		"UNTERM Spanish Formal": "la República de Maldivas",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Maldives",
		"UNTERM French Short": "Maldives (les)",
		"ISO4217-currency_name": "Rufiyaa",
		"UNTERM Russian Formal": "Мальдивская Республика",
		"UNTERM English Short": "Maldives",
		"ISO4217-currency_alphabetic_code": "MVR",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Maldivas",
		"ISO4217-currency_numeric_code": 462,
		"UNTERM Chinese Formal": "马尔代夫共和国",
		"UNTERM French Formal": "la République des Maldives",
		"UNTERM Russian Short": "Мальдивские Острова",
		M49: 462,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "ملديف",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية ملديف",
		"UNTERM Chinese Short": "马尔代夫",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Maldivas",
		"UNTERM English Formal": "the Republic of Maldives",
		official_name_cn: "马尔代夫",
		official_name_en: "Maldives",
		"ISO4217-currency_country_name": "MALDIVES",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "ملديف",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Мальдивские Острова",
		"Global Name": "World",
		Capital: "Male",
		Continent: "AS",
		TLD: ".mv",
		Languages: "dv,en",
		"Geoname ID": 1282028,
		"CLDR display name": "Maldives",
		EDGAR: "N9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q826",
	},
	{
		FIFA: "MEX",
		Dial: 52,
		"ISO3166-1-Alpha-3": "MEX",
		MARC: "mx",
		is_independent: "Yes",
		"ISO3166-1-numeric": 484,
		GAUL: 162,
		FIPS: "MX",
		WMO: "MX",
		"ISO3166-1-Alpha-2": "MX",
		ITU: "MEX",
		IOC: "MEX",
		DS: "MEX",
		"UNTERM Spanish Formal": "los Estados Unidos Mexicanos",
		"Global Code": 1,
		"Intermediate Region Code": 13,
		official_name_fr: "Mexique",
		"UNTERM French Short": "Mexique (le)",
		"ISO4217-currency_name": "Mexican Peso",
		"UNTERM Russian Formal": "Мексиканские Соединенные Штаты",
		"UNTERM English Short": "Mexico",
		"ISO4217-currency_alphabetic_code": "MXN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "México",
		"ISO4217-currency_numeric_code": 484,
		"UNTERM Chinese Formal": "墨西哥合众国",
		"UNTERM French Formal": "les États-Unis du Mexique",
		"UNTERM Russian Short": "Мексика",
		M49: 484,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "المكسيك",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الولايات المتحدة المكسيكية",
		"UNTERM Chinese Short": "墨西哥",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Central America",
		official_name_es: "México",
		"UNTERM English Formal": "the United Mexican States",
		official_name_cn: "墨西哥",
		official_name_en: "Mexico",
		"ISO4217-currency_country_name": "MEXICO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "المكسيك",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Мексика",
		"Global Name": "World",
		Capital: "Mexico City",
		Continent: null,
		TLD: ".mx",
		Languages: "es-MX",
		"Geoname ID": 3996063,
		"CLDR display name": "Mexico",
		EDGAR: "O5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q96",
	},
	{
		FIFA: "MHL",
		Dial: 692,
		"ISO3166-1-Alpha-3": "MHL",
		MARC: "xe",
		is_independent: "Yes",
		"ISO3166-1-numeric": 584,
		GAUL: 157,
		FIPS: "RM",
		WMO: "MH",
		"ISO3166-1-Alpha-2": "MH",
		ITU: "MHL",
		IOC: "MSH",
		DS: null,
		"UNTERM Spanish Formal": "la República de las Islas Marshall",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles Marshall",
		"UNTERM French Short": "Îles Marshall (les)",
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": "Республика Маршалловы Острова",
		"UNTERM English Short": "Marshall Islands (the)",
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Islas Marshall (las)",
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": "马绍尔群岛共和国",
		"UNTERM French Formal": "la République des Îles Marshall",
		"UNTERM Russian Short": "Маршалловы Острова",
		M49: 584,
		"Sub-region Code": 57,
		"Region Code": 9,
		official_name_ar: "جزر مارشال",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية جزر مارشال",
		"UNTERM Chinese Short": "马绍尔群岛",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Marshall",
		"UNTERM English Formal": "the Republic of the Marshall Islands",
		official_name_cn: "马绍尔群岛",
		official_name_en: "Marshall Islands",
		"ISO4217-currency_country_name": "MARSHALL ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "جزر مارشال",
		"Sub-region Name": "Micronesia",
		official_name_ru: "Маршалловы Острова",
		"Global Name": "World",
		Capital: "Majuro",
		Continent: "OC",
		TLD: ".mh",
		Languages: "mh,en-MH",
		"Geoname ID": 2080185,
		"CLDR display name": "Kepulauan Marshall",
		EDGAR: "1T",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q709",
	},
	{
		FIFA: "MKD",
		Dial: 389,
		"ISO3166-1-Alpha-3": "MKD",
		MARC: "xn",
		is_independent: "Yes",
		"ISO3166-1-numeric": 807,
		GAUL: 241,
		FIPS: "MK",
		WMO: "MJ",
		"ISO3166-1-Alpha-2": "MK",
		ITU: "MKD",
		IOC: "MKD",
		DS: "MK",
		"UNTERM Spanish Formal": "la República de Macedonia del Norte",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Macédoine du Nord",
		"UNTERM French Short": "Macédoine du Nord (la)",
		"ISO4217-currency_name": "Denar",
		"UNTERM Russian Formal": "Республика Северная Македония",
		"UNTERM English Short": "North Macedonia",
		"ISO4217-currency_alphabetic_code": "MKD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Macedonia del Norte",
		"ISO4217-currency_numeric_code": 807,
		"UNTERM Chinese Formal": "北马其顿共和国",
		"UNTERM French Formal": "la République de Macédoine du Nord",
		"UNTERM Russian Short": "Северная Македония",
		M49: 807,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "مقدونيا الشمالية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية مقدونيا الشمالية",
		"UNTERM Chinese Short": "北马其顿",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Macedonia del Norte",
		"UNTERM English Formal": "the Republic of North Macedonia",
		official_name_cn: "北马其顿",
		official_name_en: "North Macedonia",
		"ISO4217-currency_country_name": "NORTH MACEDONIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "مقدونيا الشمالية",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Северная Македония",
		"Global Name": "World",
		Capital: "Skopje",
		Continent: "EU",
		TLD: ".mk",
		Languages: "mk,sq,tr,rmm,sr",
		"Geoname ID": 718075,
		"CLDR display name": "Macedonia Utara",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q221",
	},
	{
		FIFA: "MLI",
		Dial: 223,
		"ISO3166-1-Alpha-3": "MLI",
		MARC: "ml",
		is_independent: "Yes",
		"ISO3166-1-numeric": 466,
		GAUL: 155,
		FIPS: "ML",
		WMO: "MI",
		"ISO3166-1-Alpha-2": "ML",
		ITU: "MLI",
		IOC: "MLI",
		DS: "RMM",
		"UNTERM Spanish Formal": "la República de Malí",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Mali",
		"UNTERM French Short": "Mali (le)",
		"ISO4217-currency_name": "CFA Franc BCEAO",
		"UNTERM Russian Formal": "Республика Мали",
		"UNTERM English Short": "Mali",
		"ISO4217-currency_alphabetic_code": "XOF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Malí",
		"ISO4217-currency_numeric_code": 952,
		"UNTERM Chinese Formal": "马里共和国",
		"UNTERM French Formal": "la République du Mali",
		"UNTERM Russian Short": "Мали",
		M49: 466,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "مالي",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية مالي",
		"UNTERM Chinese Short": "马里",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Malí",
		"UNTERM English Formal": "the Republic of Mali",
		official_name_cn: "马里",
		official_name_en: "Mali",
		"ISO4217-currency_country_name": "MALI",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "مالي",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Мали",
		"Global Name": "World",
		Capital: "Bamako",
		Continent: "AF",
		TLD: ".ml",
		Languages: "fr-ML,bm",
		"Geoname ID": 2453866,
		"CLDR display name": "Mali",
		EDGAR: "O0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q912",
	},
	{
		FIFA: "MLT",
		Dial: 356,
		"ISO3166-1-Alpha-3": "MLT",
		MARC: "mm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 470,
		GAUL: 156,
		FIPS: "MT",
		WMO: "ML",
		"ISO3166-1-Alpha-2": "MT",
		ITU: "MLT",
		IOC: "MLT",
		DS: "M",
		"UNTERM Spanish Formal": "la República de Malta",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Malte",
		"UNTERM French Short": "Malte",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Республика Мальта",
		"UNTERM English Short": "Malta",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Malta",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "马耳他共和国",
		"UNTERM French Formal": "la République de Malte",
		"UNTERM Russian Short": "Мальта",
		M49: 470,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "مالطة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية مالطة",
		"UNTERM Chinese Short": "马耳他",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Malta",
		"UNTERM English Formal": "the Republic of Malta",
		official_name_cn: "马耳他",
		official_name_en: "Malta",
		"ISO4217-currency_country_name": "MALTA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "مالطة",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Мальта",
		"Global Name": "World",
		Capital: "Valletta",
		Continent: "EU",
		TLD: ".mt",
		Languages: "mt,en-MT",
		"Geoname ID": 2562770,
		"CLDR display name": "Malta",
		EDGAR: "O1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q233",
	},
	{
		FIFA: "MNE",
		Dial: 382,
		"ISO3166-1-Alpha-3": "MNE",
		MARC: "mo",
		is_independent: "Yes",
		"ISO3166-1-numeric": 499,
		GAUL: 2647,
		FIPS: "MJ",
		WMO: null,
		"ISO3166-1-Alpha-2": "ME",
		ITU: "MNE",
		IOC: "MGO",
		DS: "MNE",
		"UNTERM Spanish Formal": "Montenegro",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Monténégro",
		"UNTERM French Short": "Monténégro (le)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Черногория",
		"UNTERM English Short": "Montenegro",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Montenegro",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "黑山",
		"UNTERM French Formal": "le Monténégro",
		"UNTERM Russian Short": "Черногория",
		M49: 499,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "الجبل الأسود",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجبل الأسود",
		"UNTERM Chinese Short": "黑山",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Montenegro",
		"UNTERM English Formal": "Montenegro",
		official_name_cn: "黑山",
		official_name_en: "Montenegro",
		"ISO4217-currency_country_name": "MONTENEGRO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "الجبل الأسود",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Черногория",
		"Global Name": "World",
		Capital: "Podgorica",
		Continent: "EU",
		TLD: ".me",
		Languages: "sr,hu,bs,sq,hr,rom",
		"Geoname ID": 3194884,
		"CLDR display name": "Montenegro",
		EDGAR: "Z5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q236",
	},
	{
		FIFA: "MNG",
		Dial: 976,
		"ISO3166-1-Alpha-3": "MNG",
		MARC: "mp",
		is_independent: "Yes",
		"ISO3166-1-numeric": 496,
		GAUL: 167,
		FIPS: "MG",
		WMO: "MO",
		"ISO3166-1-Alpha-2": "MN",
		ITU: "MNG",
		IOC: "MGL",
		DS: "MGL",
		"UNTERM Spanish Formal": "Mongolia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Mongolie",
		"UNTERM French Short": "Mongolie (la)",
		"ISO4217-currency_name": "Tugrik",
		"UNTERM Russian Formal": "Монголия",
		"UNTERM English Short": "Mongolia",
		"ISO4217-currency_alphabetic_code": "MNT",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Mongolia",
		"ISO4217-currency_numeric_code": 496,
		"UNTERM Chinese Formal": "蒙古国",
		"UNTERM French Formal": "la Mongolie",
		"UNTERM Russian Short": "Монголия",
		M49: 496,
		"Sub-region Code": 30,
		"Region Code": 142,
		official_name_ar: "منغوليا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "منغوليا",
		"UNTERM Chinese Short": "蒙古",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Mongolia",
		"UNTERM English Formal": "Mongolia",
		official_name_cn: "蒙古",
		official_name_en: "Mongolia",
		"ISO4217-currency_country_name": "MONGOLIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "منغوليا",
		"Sub-region Name": "Eastern Asia",
		official_name_ru: "Монголия",
		"Global Name": "World",
		Capital: "Ulaanbaatar",
		Continent: "AS",
		TLD: ".mn",
		Languages: "mn,ru",
		"Geoname ID": 2029969,
		"CLDR display name": "Mongolia",
		EDGAR: "P0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q711",
	},
	{
		FIFA: "MON",
		Dial: 377,
		"ISO3166-1-Alpha-3": "MCO",
		MARC: "mc",
		is_independent: "Yes",
		"ISO3166-1-numeric": 492,
		GAUL: 166,
		FIPS: "MN",
		WMO: null,
		"ISO3166-1-Alpha-2": "MC",
		ITU: "MCO",
		IOC: "MON",
		DS: "MC",
		"UNTERM Spanish Formal": "el Principado de Mónaco",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Monaco",
		"UNTERM French Short": "Monaco",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Княжество Монако",
		"UNTERM English Short": "Monaco",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Mónaco",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "摩纳哥公国",
		"UNTERM French Formal": "la Principauté de Monaco",
		"UNTERM Russian Short": "Монако",
		M49: 492,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "موناكو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "إمارة موناكو",
		"UNTERM Chinese Short": "摩纳哥",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Mónaco",
		"UNTERM English Formal": "the Principality of Monaco",
		official_name_cn: "摩纳哥",
		official_name_en: "Monaco",
		"ISO4217-currency_country_name": "MONACO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "موناكو",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Монако",
		"Global Name": "World",
		Capital: "Monaco",
		Continent: "EU",
		TLD: ".mc",
		Languages: "fr-MC,en,it",
		"Geoname ID": 2993457,
		"CLDR display name": "Monaco",
		EDGAR: "O9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q235",
	},
	{
		FIFA: "MOZ",
		Dial: 258,
		"ISO3166-1-Alpha-3": "MOZ",
		MARC: "mz",
		is_independent: "Yes",
		"ISO3166-1-numeric": 508,
		GAUL: 170,
		FIPS: "MZ",
		WMO: "MZ",
		"ISO3166-1-Alpha-2": "MZ",
		ITU: "MOZ",
		IOC: "MOZ",
		DS: "MOC",
		"UNTERM Spanish Formal": "la República de Mozambique",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Mozambique",
		"UNTERM French Short": "Mozambique (le)",
		"ISO4217-currency_name": "Mozambique Metical",
		"UNTERM Russian Formal": "Республика Мозамбик",
		"UNTERM English Short": "Mozambique",
		"ISO4217-currency_alphabetic_code": "MZN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Mozambique",
		"ISO4217-currency_numeric_code": 943,
		"UNTERM Chinese Formal": "莫桑比克共和国",
		"UNTERM French Formal": "la République du Mozambique",
		"UNTERM Russian Short": "Мозамбик",
		M49: 508,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "موزامبيق",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية موزامبيق",
		"UNTERM Chinese Short": "莫桑比克",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Mozambique",
		"UNTERM English Formal": "the Republic of Mozambique",
		official_name_cn: "莫桑比克",
		official_name_en: "Mozambique",
		"ISO4217-currency_country_name": "MOZAMBIQUE",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "موزامبيق",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Мозамбик",
		"Global Name": "World",
		Capital: "Maputo",
		Continent: "AF",
		TLD: ".mz",
		Languages: "pt-MZ,vmw",
		"Geoname ID": 1036973,
		"CLDR display name": "Mozambique",
		EDGAR: "P3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1029",
	},
	{
		FIFA: "MRI",
		Dial: 230,
		"ISO3166-1-Alpha-3": "MUS",
		MARC: "mf",
		is_independent: "Yes",
		"ISO3166-1-numeric": 480,
		GAUL: 160,
		FIPS: "MP",
		WMO: "MA",
		"ISO3166-1-Alpha-2": "MU",
		ITU: "MAU",
		IOC: "MRI",
		DS: "MS",
		"UNTERM Spanish Formal": "la República de Mauricio",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Maurice",
		"UNTERM French Short": "Maurice",
		"ISO4217-currency_name": "Mauritius Rupee",
		"UNTERM Russian Formal": "Республика Маврикий",
		"UNTERM English Short": "Mauritius",
		"ISO4217-currency_alphabetic_code": "MUR",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Mauricio",
		"ISO4217-currency_numeric_code": 480,
		"UNTERM Chinese Formal": "毛里求斯共和国",
		"UNTERM French Formal": "la République de Maurice",
		"UNTERM Russian Short": "Маврикий",
		M49: 480,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "موريشيوس",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية موريشيوس",
		"UNTERM Chinese Short": "毛里求斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Mauricio",
		"UNTERM English Formal": "the Republic of Mauritius",
		official_name_cn: "毛里求斯",
		official_name_en: "Mauritius",
		"ISO4217-currency_country_name": "MAURITIUS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "موريشيوس",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Маврикий",
		"Global Name": "World",
		Capital: "Port Louis",
		Continent: "AF",
		TLD: ".mu",
		Languages: "en-MU,bho,fr",
		"Geoname ID": 934292,
		"CLDR display name": "Mauritius",
		EDGAR: "O4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1027",
	},
	{
		FIFA: "MSR",
		Dial: "1-664",
		"ISO3166-1-Alpha-3": "MSR",
		MARC: "mj",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 500,
		GAUL: 168,
		FIPS: "MH",
		WMO: null,
		"ISO3166-1-Alpha-2": "MS",
		ITU: "MSR",
		IOC: "MNT",
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Montserrat",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "East Caribbean Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "XCD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 951,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 500,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "مونتسيرات",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Montserrat",
		"UNTERM English Formal": null,
		official_name_cn: "蒙特塞拉特",
		official_name_en: "Montserrat",
		"ISO4217-currency_country_name": "MONTSERRAT",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Монтсеррат",
		"Global Name": "World",
		Capital: "Plymouth",
		Continent: null,
		TLD: ".ms",
		Languages: "en-MS",
		"Geoname ID": 3578097,
		"CLDR display name": "Montserrat",
		EDGAR: "P1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q13353",
	},
	{
		FIFA: "MTN",
		Dial: 222,
		"ISO3166-1-Alpha-3": "MRT",
		MARC: "mu",
		is_independent: "Yes",
		"ISO3166-1-numeric": 478,
		GAUL: 159,
		FIPS: "MR",
		WMO: "MT",
		"ISO3166-1-Alpha-2": "MR",
		ITU: "MTN",
		IOC: "MTN",
		DS: "RIM",
		"UNTERM Spanish Formal": "la República Islámica de Mauritania",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Mauritanie",
		"UNTERM French Short": "Mauritanie (la)",
		"ISO4217-currency_name": "Ouguiya",
		"UNTERM Russian Formal": "Исламская Республика Мавритания",
		"UNTERM English Short": "Mauritania",
		"ISO4217-currency_alphabetic_code": "MRU",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Mauritania",
		"ISO4217-currency_numeric_code": 929,
		"UNTERM Chinese Formal": "毛里塔尼亚伊斯兰共和国",
		"UNTERM French Formal": "la République islamique de Mauritanie",
		"UNTERM Russian Short": "Мавритания",
		M49: 478,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "موريتانيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية الإسلامية الموريتانية",
		"UNTERM Chinese Short": "毛里塔尼亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Mauritania",
		"UNTERM English Formal": "the Islamic Republic of Mauritania",
		official_name_cn: "毛里塔尼亚",
		official_name_en: "Mauritania",
		"ISO4217-currency_country_name": "MAURITANIA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "موريتانيا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Мавритания",
		"Global Name": "World",
		Capital: "Nouakchott",
		Continent: "AF",
		TLD: ".mr",
		Languages: "ar-MR,fuc,snk,fr,mey,wo",
		"Geoname ID": 2378080,
		"CLDR display name": "Mauritania",
		EDGAR: "O3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1025",
	},
	{
		FIFA: "MTQ",
		Dial: 596,
		"ISO3166-1-Alpha-3": "MTQ",
		MARC: "mq",
		is_independent: "Part of FR",
		"ISO3166-1-numeric": 474,
		GAUL: 158,
		FIPS: "MB",
		WMO: "MR",
		"ISO3166-1-Alpha-2": "MQ",
		ITU: "MRT",
		IOC: "MRT",
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Martinique",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 474,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "مارتينيك",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Martinica",
		"UNTERM English Formal": null,
		official_name_cn: "马提尼克",
		official_name_en: "Martinique",
		"ISO4217-currency_country_name": "MARTINIQUE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Мартиника",
		"Global Name": "World",
		Capital: "Fort-de-France",
		Continent: null,
		TLD: ".mq",
		Languages: "fr-MQ",
		"Geoname ID": 3570311,
		"CLDR display name": "Martinique",
		EDGAR: "O2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q17054",
	},
	{
		FIFA: "MWI",
		Dial: 265,
		"ISO3166-1-Alpha-3": "MWI",
		MARC: "mw",
		is_independent: "Yes",
		"ISO3166-1-numeric": 454,
		GAUL: 152,
		FIPS: "MI",
		WMO: "MW",
		"ISO3166-1-Alpha-2": "MW",
		ITU: "MWI",
		IOC: "MAW",
		DS: "MW",
		"UNTERM Spanish Formal": "la República de Malawi",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Malawi",
		"UNTERM French Short": "Malawi (le)",
		"ISO4217-currency_name": "Malawi Kwacha",
		"UNTERM Russian Formal": "Республика Малави",
		"UNTERM English Short": "Malawi",
		"ISO4217-currency_alphabetic_code": "MWK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Malawi",
		"ISO4217-currency_numeric_code": 454,
		"UNTERM Chinese Formal": "马拉维共和国",
		"UNTERM French Formal": "la République du Malawi",
		"UNTERM Russian Short": "Малави",
		M49: 454,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "ملاوي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية ملاوي",
		"UNTERM Chinese Short": "马拉维",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Malawi",
		"UNTERM English Formal": "the Republic of Malawi",
		official_name_cn: "马拉维",
		official_name_en: "Malawi",
		"ISO4217-currency_country_name": "MALAWI",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "ملاوي",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Малави",
		"Global Name": "World",
		Capital: "Lilongwe",
		Continent: "AF",
		TLD: ".mw",
		Languages: "ny,yao,tum,swk",
		"Geoname ID": 927384,
		"CLDR display name": "Malawi",
		EDGAR: "N7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1020",
	},
	{
		FIFA: "MYA",
		Dial: 95,
		"ISO3166-1-Alpha-3": "MMR",
		MARC: "br",
		is_independent: "Yes",
		"ISO3166-1-numeric": 104,
		GAUL: 171,
		FIPS: "BM",
		WMO: "BM",
		"ISO3166-1-Alpha-2": "MM",
		ITU: "MYA",
		IOC: "MYA",
		DS: "BUR",
		"UNTERM Spanish Formal": "la República de la Unión de Myanmar",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Myanmar",
		"UNTERM French Short": "Myanmar (le)",
		"ISO4217-currency_name": "Kyat",
		"UNTERM Russian Formal": "Республика Союз Мьянма",
		"UNTERM English Short": "Myanmar",
		"ISO4217-currency_alphabetic_code": "MMK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Myanmar",
		"ISO4217-currency_numeric_code": 104,
		"UNTERM Chinese Formal": "缅甸联邦共和国",
		"UNTERM French Formal": "la République de l'Union du Myanmar",
		"UNTERM Russian Short": "Мьянма",
		M49: 104,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "ميانمار",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية اتحاد ميانمار",
		"UNTERM Chinese Short": "缅甸",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Myanmar",
		"UNTERM English Formal": "the Republic of the Union of Myanmar",
		official_name_cn: "缅甸",
		official_name_en: "Myanmar",
		"ISO4217-currency_country_name": "MYANMAR",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Asia",
		"UNTERM Arabic Short": "ميانمار",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Мьянма",
		"Global Name": "World",
		Capital: "Nay Pyi Taw",
		Continent: "AS",
		TLD: ".mm",
		Languages: "my",
		"Geoname ID": 1327865,
		"CLDR display name": "Myanmar (Burma)",
		EDGAR: "E1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q836",
	},
	{
		FIFA: "MYT",
		Dial: 262,
		"ISO3166-1-Alpha-3": "MYT",
		MARC: "ot",
		is_independent: "Part of FR",
		"ISO3166-1-numeric": 175,
		GAUL: 161,
		FIPS: "MF",
		WMO: null,
		"ISO3166-1-Alpha-2": "YT",
		ITU: "MYT",
		IOC: "MAY",
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Mayotte",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 175,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "مايوت",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Mayotte",
		"UNTERM English Formal": null,
		official_name_cn: "马约特",
		official_name_en: "Mayotte",
		"ISO4217-currency_country_name": "MAYOTTE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Остров Майотта",
		"Global Name": "World",
		Capital: "Mamoudzou",
		Continent: "AF",
		TLD: ".yt",
		Languages: "fr-YT",
		"Geoname ID": 1024031,
		"CLDR display name": "Mayotte",
		EDGAR: "2P",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q17063",
	},
	{
		FIFA: "NAM",
		Dial: 264,
		"ISO3166-1-Alpha-3": "NAM",
		MARC: "sx",
		is_independent: "Yes",
		"ISO3166-1-numeric": 516,
		GAUL: 172,
		FIPS: "WA",
		WMO: "NM",
		"ISO3166-1-Alpha-2": null,
		ITU: "NMB",
		IOC: "NAM",
		DS: "NAM",
		"UNTERM Spanish Formal": "la República de Namibia",
		"Global Code": 1,
		"Intermediate Region Code": 18,
		official_name_fr: "Namibie",
		"UNTERM French Short": "Namibie (la)",
		"ISO4217-currency_name": "Namibia Dollar,Rand",
		"UNTERM Russian Formal": "Республика Намибия",
		"UNTERM English Short": "Namibia",
		"ISO4217-currency_alphabetic_code": "NAD,ZAR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Namibia",
		"ISO4217-currency_numeric_code": "516,710",
		"UNTERM Chinese Formal": "纳米比亚共和国",
		"UNTERM French Formal": "la République de Namibie",
		"UNTERM Russian Short": "Намибия",
		M49: 516,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "ناميبيا",
		"ISO4217-currency_minor_unit": "2,2",
		"UNTERM Arabic Formal": "جمهورية ناميبيا",
		"UNTERM Chinese Short": "纳米比亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Southern Africa",
		official_name_es: "Namibia",
		"UNTERM English Formal": "the Republic of Namibia",
		official_name_cn: "纳米比亚",
		official_name_en: "Namibia",
		"ISO4217-currency_country_name": "NAMIBIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "ناميبيا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Намибия",
		"Global Name": "World",
		Capital: "Windhoek",
		Continent: "AF",
		TLD: ".na",
		Languages: "en-NA,af,de,hz,naq",
		"Geoname ID": 3355338,
		"CLDR display name": "Namibia",
		EDGAR: "T6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1030",
	},
	{
		FIFA: "NCA",
		Dial: 505,
		"ISO3166-1-Alpha-3": "NIC",
		MARC: "nq",
		is_independent: "Yes",
		"ISO3166-1-numeric": 558,
		GAUL: 180,
		FIPS: "NU",
		WMO: "NK",
		"ISO3166-1-Alpha-2": "NI",
		ITU: "NCG",
		IOC: "NCA",
		DS: "NIC",
		"UNTERM Spanish Formal": "la República de Nicaragua",
		"Global Code": 1,
		"Intermediate Region Code": 13,
		official_name_fr: "Nicaragua",
		"UNTERM French Short": "Nicaragua (le)",
		"ISO4217-currency_name": "Cordoba Oro",
		"UNTERM Russian Formal": "Республика Никарагуа",
		"UNTERM English Short": "Nicaragua",
		"ISO4217-currency_alphabetic_code": "NIO",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Nicaragua",
		"ISO4217-currency_numeric_code": 558,
		"UNTERM Chinese Formal": "尼加拉瓜共和国",
		"UNTERM French Formal": "la République du Nicaragua",
		"UNTERM Russian Short": "Никарагуа",
		M49: 558,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "نيكاراغوا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية نيكاراغوا",
		"UNTERM Chinese Short": "尼加拉瓜",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Central America",
		official_name_es: "Nicaragua",
		"UNTERM English Formal": "the Republic of Nicaragua",
		official_name_cn: "尼加拉瓜",
		official_name_en: "Nicaragua",
		"ISO4217-currency_country_name": "NICARAGUA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "نيكاراغوا",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Никарагуа",
		"Global Name": "World",
		Capital: "Managua",
		Continent: null,
		TLD: ".ni",
		Languages: "es-NI,en",
		"Geoname ID": 3617476,
		"CLDR display name": "Nicaragua",
		EDGAR: "Q3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q811",
	},
	{
		FIFA: "NCL",
		Dial: 687,
		"ISO3166-1-Alpha-3": "NCL",
		MARC: "nl",
		is_independent: "Territory of FR",
		"ISO3166-1-numeric": 540,
		GAUL: 178,
		FIPS: "NC",
		WMO: "NC",
		"ISO3166-1-Alpha-2": "NC",
		ITU: "NCL",
		IOC: "NCD",
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Nouvelle-Calédonie",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "CFP Franc",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "XPF",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 953,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 540,
		"Sub-region Code": 54,
		"Region Code": 9,
		official_name_ar: "كاليدونيا الجديدة",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Nueva Caledonia",
		"UNTERM English Formal": null,
		official_name_cn: "新喀里多尼亚",
		official_name_en: "New Caledonia",
		"ISO4217-currency_country_name": "NEW CALEDONIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Melanesia",
		official_name_ru: "Новая Каледония",
		"Global Name": "World",
		Capital: "Noumea",
		Continent: "OC",
		TLD: ".nc",
		Languages: "fr-NC",
		"Geoname ID": 2139685,
		"CLDR display name": "New Caledonia",
		EDGAR: "1W",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q33788",
	},
	{
		FIFA: "NED",
		Dial: 31,
		"ISO3166-1-Alpha-3": "NLD",
		MARC: "ne",
		is_independent: "Yes",
		"ISO3166-1-numeric": 528,
		GAUL: 177,
		FIPS: "NL",
		WMO: "NL",
		"ISO3166-1-Alpha-2": "NL",
		ITU: "HOL",
		IOC: "NED",
		DS: "NL",
		"UNTERM Spanish Formal": "el Reino de los Países Bajos",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Pays-Bas (Royaume des)",
		"UNTERM French Short": "Pays-Bas (les)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Королевство Нидерландов",
		"UNTERM English Short": "Netherlands (the)",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Países Bajos (los)",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "荷兰王国",
		"UNTERM French Formal": "le Royaume des Pays-Bas",
		"UNTERM Russian Short": "Нидерланды",
		M49: 528,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "هولندا (مملكة _)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة هولندا",
		"UNTERM Chinese Short": "荷兰",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Países Bajos (Reino de los)",
		"UNTERM English Formal": "the Kingdom of the Netherlands",
		official_name_cn: "荷兰王国",
		official_name_en: "Netherlands",
		"ISO4217-currency_country_name": "NETHERLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "هولندا",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Нидерланды (Королевство)",
		"Global Name": "World",
		Capital: "Amsterdam",
		Continent: "EU",
		TLD: ".nl",
		Languages: "nl-NL,fy-NL",
		"Geoname ID": 2750405,
		"CLDR display name": "Belanda",
		EDGAR: "P7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q55",
	},
	{
		FIFA: "NED",
		Dial: 31,
		"ISO3166-1-Alpha-3": "NLD",
		MARC: "ne",
		is_independent: "Yes",
		"ISO3166-1-numeric": 528,
		GAUL: 177,
		FIPS: "NL",
		WMO: "NL",
		"ISO3166-1-Alpha-2": "NL",
		ITU: "HOL",
		IOC: "NED",
		DS: "NL",
		"UNTERM Spanish Formal": "el Reino de los Países Bajos",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Pays-Bas (Royaume des)",
		"UNTERM French Short": "Pays-Bas (les)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Королевство Нидерландов",
		"UNTERM English Short": "Netherlands (the)",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Países Bajos (los)",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "荷兰王国",
		"UNTERM French Formal": "le Royaume des Pays-Bas",
		"UNTERM Russian Short": "Нидерланды",
		M49: 528,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "هولندا (مملكة _)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة هولندا",
		"UNTERM Chinese Short": "荷兰",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Países Bajos (Reino de los)",
		"UNTERM English Formal": "the Kingdom of the Netherlands",
		official_name_cn: "荷兰王国",
		official_name_en: "Netherlands",
		"ISO4217-currency_country_name": "NETHERLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "هولندا",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Нидерланды (Королевство)",
		"Global Name": "World",
		Capital: "Amsterdam",
		Continent: "EU",
		TLD: ".nl",
		Languages: "nl-NL,fy-NL",
		"Geoname ID": 2750405,
		"CLDR display name": "Belanda",
		EDGAR: "P7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q29999",
	},
	{
		FIFA: "NEP",
		Dial: 977,
		"ISO3166-1-Alpha-3": "NPL",
		MARC: "np",
		is_independent: "Yes",
		"ISO3166-1-numeric": 524,
		GAUL: 175,
		FIPS: "NP",
		WMO: "NP",
		"ISO3166-1-Alpha-2": "NP",
		ITU: "NPL",
		IOC: "NEP",
		DS: "NEP",
		"UNTERM Spanish Formal": "Nepal",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Népal",
		"UNTERM French Short": "Népal (le)",
		"ISO4217-currency_name": "Nepalese Rupee",
		"UNTERM Russian Formal": "Непал",
		"UNTERM English Short": "Nepal",
		"ISO4217-currency_alphabetic_code": "NPR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Nepal",
		"ISO4217-currency_numeric_code": 524,
		"UNTERM Chinese Formal": "尼泊尔",
		"UNTERM French Formal": "le Népal",
		"UNTERM Russian Short": "Непал",
		M49: 524,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "نيبال",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "نيبال",
		"UNTERM Chinese Short": "尼泊尔",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Nepal",
		"UNTERM English Formal": "Nepal",
		official_name_cn: "尼泊尔",
		official_name_en: "Nepal",
		"ISO4217-currency_country_name": "NEPAL",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Asia",
		"UNTERM Arabic Short": "نيبال",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Непал",
		"Global Name": "World",
		Capital: "Kathmandu",
		Continent: "AS",
		TLD: ".np",
		Languages: "ne,en",
		"Geoname ID": 1282988,
		"CLDR display name": "Nepal",
		EDGAR: "P6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q837",
	},
	{
		FIFA: "NFK",
		Dial: 672,
		"ISO3166-1-Alpha-3": "NFK",
		MARC: "nx",
		is_independent: "Territory of AU",
		"ISO3166-1-numeric": 574,
		GAUL: 184,
		FIPS: "NF",
		WMO: "NF",
		"ISO3166-1-Alpha-2": "NF",
		ITU: "NFK",
		IOC: "NFI",
		DS: "AUS",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Île Norfolk",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Australian Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "AUD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 36,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 574,
		"Sub-region Code": 53,
		"Region Code": 9,
		official_name_ar: "جزيرة نورفولك",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Isla Norfolk",
		"UNTERM English Formal": null,
		official_name_cn: "诺福克岛",
		official_name_en: "Norfolk Island",
		"ISO4217-currency_country_name": "NORFOLK ISLAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Australia and New Zealand",
		official_name_ru: "Остров Норфолк",
		"Global Name": "World",
		Capital: "Kingston",
		Continent: "OC",
		TLD: ".nf",
		Languages: "en-NF",
		"Geoname ID": 2155115,
		"CLDR display name": "Pulau Norfolk",
		EDGAR: "Q7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q31057",
	},
	{
		FIFA: "NGA",
		Dial: 234,
		"ISO3166-1-Alpha-3": "NGA",
		MARC: "nr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 566,
		GAUL: 182,
		FIPS: "NI",
		WMO: "NI",
		"ISO3166-1-Alpha-2": "NG",
		ITU: "NIG",
		IOC: "NGR",
		DS: "WAN",
		"UNTERM Spanish Formal": "la República Federal de Nigeria",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Nigéria",
		"UNTERM French Short": "Nigéria (le)",
		"ISO4217-currency_name": "Naira",
		"UNTERM Russian Formal": "Федеративная Республика Нигерия",
		"UNTERM English Short": "Nigeria",
		"ISO4217-currency_alphabetic_code": "NGN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Nigeria",
		"ISO4217-currency_numeric_code": 566,
		"UNTERM Chinese Formal": "尼日利亚联邦共和国",
		"UNTERM French Formal": "la République fédérale du Nigéria",
		"UNTERM Russian Short": "Нигерия",
		M49: 566,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "نيجيريا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية نيجيريا الاتحادية",
		"UNTERM Chinese Short": "尼日利亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Nigeria",
		"UNTERM English Formal": "the Federal Republic of Nigeria",
		official_name_cn: "尼日利亚",
		official_name_en: "Nigeria",
		"ISO4217-currency_country_name": "NIGERIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "نيجيريا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Нигерия",
		"Global Name": "World",
		Capital: "Abuja",
		Continent: "AF",
		TLD: ".ng",
		Languages: "en-NG,ha,yo,ig,ff",
		"Geoname ID": 2328926,
		"CLDR display name": "Nigeria",
		EDGAR: "Q5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1033",
	},
	{
		FIFA: "NIG",
		Dial: 227,
		"ISO3166-1-Alpha-3": "NER",
		MARC: "ng",
		is_independent: "Yes",
		"ISO3166-1-numeric": 562,
		GAUL: 181,
		FIPS: "NG",
		WMO: "NR",
		"ISO3166-1-Alpha-2": "NE",
		ITU: "NGR",
		IOC: "NIG",
		DS: "RN",
		"UNTERM Spanish Formal": "la República del Níger",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Niger",
		"UNTERM French Short": "Niger (le)",
		"ISO4217-currency_name": "CFA Franc BCEAO",
		"UNTERM Russian Formal": "Республика Нигер",
		"UNTERM English Short": "Niger (the)",
		"ISO4217-currency_alphabetic_code": "XOF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Níger (el)",
		"ISO4217-currency_numeric_code": 952,
		"UNTERM Chinese Formal": "尼日尔共和国",
		"UNTERM French Formal": "la République du Niger",
		"UNTERM Russian Short": "Нигер",
		M49: 562,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "النيجر",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية النيجر",
		"UNTERM Chinese Short": "尼日尔",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Níger",
		"UNTERM English Formal": "the Republic of the Niger",
		official_name_cn: "尼日尔",
		official_name_en: "Niger",
		"ISO4217-currency_country_name": "NIGER",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "النيجر",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Нигер",
		"Global Name": "World",
		Capital: "Niamey",
		Continent: "AF",
		TLD: ".ne",
		Languages: "fr-NE,ha,kr,dje",
		"Geoname ID": 2440476,
		"CLDR display name": "Niger",
		EDGAR: "Q4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1032",
	},
	{
		FIFA: "NIU",
		Dial: 683,
		"ISO3166-1-Alpha-3": "NIU",
		MARC: "xh",
		is_independent: "Associated with NZ",
		"ISO3166-1-numeric": 570,
		GAUL: 183,
		FIPS: "NE",
		WMO: null,
		"ISO3166-1-Alpha-2": "NU",
		ITU: "NIU",
		IOC: "NIU",
		DS: "NZ",
		"UNTERM Spanish Formal": "Niue **",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Nioué",
		"UNTERM French Short": "Nioué **",
		"ISO4217-currency_name": "New Zealand Dollar",
		"UNTERM Russian Formal": "Ниуэ **",
		"UNTERM English Short": "Niue **",
		"ISO4217-currency_alphabetic_code": "NZD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Niue **",
		"ISO4217-currency_numeric_code": 554,
		"UNTERM Chinese Formal": "纽埃 **",
		"UNTERM French Formal": "Nioué **",
		"UNTERM Russian Short": "Ниуэ **",
		M49: 570,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "نيوي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "نيوي **",
		"UNTERM Chinese Short": "纽埃 **",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Niue",
		"UNTERM English Formal": "Niue **",
		official_name_cn: "纽埃",
		official_name_en: "Niue",
		"ISO4217-currency_country_name": "NIUE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "نيوي **",
		"Sub-region Name": "Polynesia",
		official_name_ru: "Ниуэ",
		"Global Name": "World",
		Capital: "Alofi",
		Continent: "OC",
		TLD: ".nu",
		Languages: "niu,en-NU",
		"Geoname ID": 4036232,
		"CLDR display name": "Niue",
		EDGAR: "Q6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q34020",
	},
	{
		FIFA: "NMI",
		Dial: "1-670",
		"ISO3166-1-Alpha-3": "MNP",
		MARC: "nw",
		is_independent: "Commonwealth of US",
		"ISO3166-1-numeric": 580,
		GAUL: 185,
		FIPS: "CQ",
		WMO: "MY",
		"ISO3166-1-Alpha-2": "MP",
		ITU: "MRA",
		IOC: "NMA",
		DS: "USA",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles Mariannes du Nord",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 580,
		"Sub-region Code": 57,
		"Region Code": 9,
		official_name_ar: "جزر ماريانا الشمالية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Marianas Septentrionales",
		"UNTERM English Formal": null,
		official_name_cn: "北马里亚纳群岛",
		official_name_en: "Northern Mariana Islands",
		"ISO4217-currency_country_name": "NORTHERN MARIANA ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Micronesia",
		official_name_ru: "Северные Марианские острова",
		"Global Name": "World",
		Capital: "Saipan",
		Continent: "OC",
		TLD: ".mp",
		Languages: "fil,tl,zh,ch-MP,en-MP",
		"Geoname ID": 4041468,
		"CLDR display name": "Kepulauan Mariana Utara",
		EDGAR: "1V",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q16644",
	},
	{
		FIFA: "NOR",
		Dial: 47,
		"ISO3166-1-Alpha-3": "NOR",
		MARC: "no",
		is_independent: "Yes",
		"ISO3166-1-numeric": 578,
		GAUL: 186,
		FIPS: "NO",
		WMO: "NO",
		"ISO3166-1-Alpha-2": "NO",
		ITU: "NOR",
		IOC: "NOR",
		DS: "N",
		"UNTERM Spanish Formal": "el Reino de Noruega",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Norvège",
		"UNTERM French Short": "Norvège (la)",
		"ISO4217-currency_name": "Norwegian Krone",
		"UNTERM Russian Formal": "Королевство Норвегия",
		"UNTERM English Short": "Norway",
		"ISO4217-currency_alphabetic_code": "NOK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Noruega",
		"ISO4217-currency_numeric_code": 578,
		"UNTERM Chinese Formal": "挪威王国",
		"UNTERM French Formal": "le Royaume de Norvège",
		"UNTERM Russian Short": "Норвегия",
		M49: 578,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "النرويج",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة النرويج",
		"UNTERM Chinese Short": "挪威",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Noruega",
		"UNTERM English Formal": "the Kingdom of Norway",
		official_name_cn: "挪威",
		official_name_en: "Norway",
		"ISO4217-currency_country_name": "NORWAY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "النرويج",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Норвегия",
		"Global Name": "World",
		Capital: "Oslo",
		Continent: "EU",
		TLD: ".no",
		Languages: "no,nb,nn,se,fi",
		"Geoname ID": 3144096,
		"CLDR display name": "Norway",
		EDGAR: "Q8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q20",
	},
	{
		FIFA: "NRU",
		Dial: 674,
		"ISO3166-1-Alpha-3": "NRU",
		MARC: "nu",
		is_independent: "Yes",
		"ISO3166-1-numeric": 520,
		GAUL: 173,
		FIPS: "NR",
		WMO: "NW",
		"ISO3166-1-Alpha-2": "NR",
		ITU: "NRU",
		IOC: "NRU",
		DS: "NAU",
		"UNTERM Spanish Formal": "la República de Nauru",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Nauru",
		"UNTERM French Short": "Nauru",
		"ISO4217-currency_name": "Australian Dollar",
		"UNTERM Russian Formal": "Республика Науру",
		"UNTERM English Short": "Nauru",
		"ISO4217-currency_alphabetic_code": "AUD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Nauru",
		"ISO4217-currency_numeric_code": 36,
		"UNTERM Chinese Formal": "瑙鲁共和国",
		"UNTERM French Formal": "la République de Nauru",
		"UNTERM Russian Short": "Науру",
		M49: 520,
		"Sub-region Code": 57,
		"Region Code": 9,
		official_name_ar: "ناورو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية ناورو",
		"UNTERM Chinese Short": "瑙鲁",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Nauru",
		"UNTERM English Formal": "the Republic of Nauru",
		official_name_cn: "瑙鲁",
		official_name_en: "Nauru",
		"ISO4217-currency_country_name": "NAURU",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "ناورو",
		"Sub-region Name": "Micronesia",
		official_name_ru: "Науру",
		"Global Name": "World",
		Capital: "Yaren",
		Continent: "OC",
		TLD: ".nr",
		Languages: "na,en-NR",
		"Geoname ID": 2110425,
		"CLDR display name": "Nauru",
		EDGAR: "P5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q697",
	},
	{
		FIFA: "NZL",
		Dial: 64,
		"ISO3166-1-Alpha-3": "NZL",
		MARC: "nz",
		is_independent: "Yes",
		"ISO3166-1-numeric": 554,
		GAUL: 179,
		FIPS: "NZ",
		WMO: "NZ",
		"ISO3166-1-Alpha-2": "NZ",
		ITU: "NZL",
		IOC: "NZL",
		DS: "NZ",
		"UNTERM Spanish Formal": "Nueva Zelandia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Nouvelle-Zélande",
		"UNTERM French Short": "Nouvelle-Zélande (la)",
		"ISO4217-currency_name": "New Zealand Dollar",
		"UNTERM Russian Formal": "Новая Зеландия",
		"UNTERM English Short": "New Zealand",
		"ISO4217-currency_alphabetic_code": "NZD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Nueva Zelandia",
		"ISO4217-currency_numeric_code": 554,
		"UNTERM Chinese Formal": "新西兰",
		"UNTERM French Formal": "la Nouvelle-Zélande",
		"UNTERM Russian Short": "Новая Зеландия",
		M49: 554,
		"Sub-region Code": 53,
		"Region Code": 9,
		official_name_ar: "نيوزيلندا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "نيوزيلندا",
		"UNTERM Chinese Short": "新西兰",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Nueva Zelandia",
		"UNTERM English Formal": "New Zealand",
		official_name_cn: "新西兰",
		official_name_en: "New Zealand",
		"ISO4217-currency_country_name": "NEW ZEALAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "نيوزيلندا",
		"Sub-region Name": "Australia and New Zealand",
		official_name_ru: "Новая Зеландия",
		"Global Name": "World",
		Capital: "Wellington",
		Continent: "OC",
		TLD: ".nz",
		Languages: "en-NZ,mi",
		"Geoname ID": 2186224,
		"CLDR display name": "New Zealand",
		EDGAR: "Q2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q664",
	},
	{
		FIFA: "OMA",
		Dial: 968,
		"ISO3166-1-Alpha-3": "OMN",
		MARC: "mk",
		is_independent: "Yes",
		"ISO3166-1-numeric": 512,
		GAUL: 187,
		FIPS: "MU",
		WMO: "OM",
		"ISO3166-1-Alpha-2": "OM",
		ITU: "OMA",
		IOC: "OMA",
		DS: null,
		"UNTERM Spanish Formal": "la Sultanía de Omán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Oman",
		"UNTERM French Short": "Oman",
		"ISO4217-currency_name": "Rial Omani",
		"UNTERM Russian Formal": "Султанат Оман",
		"UNTERM English Short": "Oman",
		"ISO4217-currency_alphabetic_code": "OMR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Omán",
		"ISO4217-currency_numeric_code": 512,
		"UNTERM Chinese Formal": "阿曼苏丹国",
		"UNTERM French Formal": "le Sultanat d'Oman",
		"UNTERM Russian Short": "Оман",
		M49: 512,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "عمان",
		"ISO4217-currency_minor_unit": 3,
		"UNTERM Arabic Formal": "سلطنة عمان",
		"UNTERM Chinese Short": "阿曼",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Omán",
		"UNTERM English Formal": "the Sultanate of Oman",
		official_name_cn: "阿曼",
		official_name_en: "Oman",
		"ISO4217-currency_country_name": "OMAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "عمان",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Оман",
		"Global Name": "World",
		Capital: "Muscat",
		Continent: "AS",
		TLD: ".om",
		Languages: "ar-OM,en,bal,ur",
		"Geoname ID": 286963,
		"CLDR display name": "Oman",
		EDGAR: "P4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q842",
	},
	{
		FIFA: "PAK",
		Dial: 92,
		"ISO3166-1-Alpha-3": "PAK",
		MARC: "pk",
		is_independent: "Yes",
		"ISO3166-1-numeric": 586,
		GAUL: 188,
		FIPS: "PK",
		WMO: "PK",
		"ISO3166-1-Alpha-2": "PK",
		ITU: "PAK",
		IOC: "PAK",
		DS: "PK",
		"UNTERM Spanish Formal": "la República Islámica del Pakistán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Pakistan",
		"UNTERM French Short": "Pakistan (le)",
		"ISO4217-currency_name": "Pakistan Rupee",
		"UNTERM Russian Formal": "Исламская Республика Пакистан",
		"UNTERM English Short": "Pakistan",
		"ISO4217-currency_alphabetic_code": "PKR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Pakistán (el)",
		"ISO4217-currency_numeric_code": 586,
		"UNTERM Chinese Formal": "巴基斯坦伊斯兰共和国",
		"UNTERM French Formal": "la République islamique du Pakistan",
		"UNTERM Russian Short": "Пакистан",
		M49: 586,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "باكستان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية باكستان الإسلامية",
		"UNTERM Chinese Short": "巴基斯坦",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Pakistán",
		"UNTERM English Formal": "the Islamic Republic of Pakistan",
		official_name_cn: "巴基斯坦",
		official_name_en: "Pakistan",
		"ISO4217-currency_country_name": "PAKISTAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "باكستان",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Пакистан",
		"Global Name": "World",
		Capital: "Islamabad",
		Continent: "AS",
		TLD: ".pk",
		Languages: "ur-PK,en-PK,pa,sd,ps,brh",
		"Geoname ID": 1168579,
		"CLDR display name": "Pakistan",
		EDGAR: "R0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q843",
	},
	{
		FIFA: "PAN",
		Dial: 507,
		"ISO3166-1-Alpha-3": "PAN",
		MARC: "pn",
		is_independent: "Yes",
		"ISO3166-1-numeric": 591,
		GAUL: 191,
		FIPS: "PM",
		WMO: "PM",
		"ISO3166-1-Alpha-2": "PA",
		ITU: "PNR",
		IOC: "PAN",
		DS: "PA",
		"UNTERM Spanish Formal": "la República de Panamá",
		"Global Code": 1,
		"Intermediate Region Code": 13,
		official_name_fr: "Panama",
		"UNTERM French Short": "Panama (le)",
		"ISO4217-currency_name": "Balboa,US Dollar",
		"UNTERM Russian Formal": "Республика Панама",
		"UNTERM English Short": "Panama",
		"ISO4217-currency_alphabetic_code": "PAB,USD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Panamá",
		"ISO4217-currency_numeric_code": "590,840",
		"UNTERM Chinese Formal": "巴拿马共和国",
		"UNTERM French Formal": "la République du Panama",
		"UNTERM Russian Short": "Панама",
		M49: 591,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "بنما",
		"ISO4217-currency_minor_unit": "2,2",
		"UNTERM Arabic Formal": "جمهورية بنما",
		"UNTERM Chinese Short": "巴拿马",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Central America",
		official_name_es: "Panamá",
		"UNTERM English Formal": "the Republic of Panama",
		official_name_cn: "巴拿马",
		official_name_en: "Panama",
		"ISO4217-currency_country_name": "PANAMA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "بنما",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Панама",
		"Global Name": "World",
		Capital: "Panama City",
		Continent: null,
		TLD: ".pa",
		Languages: "es-PA,en",
		"Geoname ID": 3703430,
		"CLDR display name": "Panama",
		EDGAR: "R1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q804",
	},
	{
		FIFA: "PAR",
		Dial: 595,
		"ISO3166-1-Alpha-3": "PRY",
		MARC: "py",
		is_independent: "Yes",
		"ISO3166-1-numeric": 600,
		GAUL: 194,
		FIPS: "PA",
		WMO: "PY",
		"ISO3166-1-Alpha-2": "PY",
		ITU: "PRG",
		IOC: "PAR",
		DS: "PY",
		"UNTERM Spanish Formal": "la República del Paraguay",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Paraguay",
		"UNTERM French Short": "Paraguay (le)",
		"ISO4217-currency_name": "Guarani",
		"UNTERM Russian Formal": "Республика Парагвай",
		"UNTERM English Short": "Paraguay",
		"ISO4217-currency_alphabetic_code": "PYG",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Paraguay (el)",
		"ISO4217-currency_numeric_code": 600,
		"UNTERM Chinese Formal": "巴拉圭共和国",
		"UNTERM French Formal": "la République du Paraguay",
		"UNTERM Russian Short": "Парагвай",
		M49: 600,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "باراغواي",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية باراغواي",
		"UNTERM Chinese Short": "巴拉圭",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "South America",
		official_name_es: "Paraguay",
		"UNTERM English Formal": "the Republic of Paraguay",
		official_name_cn: "巴拉圭",
		official_name_en: "Paraguay",
		"ISO4217-currency_country_name": "PARAGUAY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "باراغواي",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Парагвай",
		"Global Name": "World",
		Capital: "Asuncion",
		Continent: "SA",
		TLD: ".py",
		Languages: "es-PY,gn",
		"Geoname ID": 3437598,
		"CLDR display name": "Paraguay",
		EDGAR: "R4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q733",
	},
	{
		FIFA: "PCN",
		Dial: 870,
		"ISO3166-1-Alpha-3": "PCN",
		MARC: "pc",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 612,
		GAUL: 197,
		FIPS: "PC",
		WMO: "PT",
		"ISO3166-1-Alpha-2": "PN",
		ITU: "PTC",
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Pitcairn",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "New Zealand Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "NZD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 554,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 612,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "بيتكرن",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Pitcairn",
		"UNTERM English Formal": null,
		official_name_cn: "皮特凯恩",
		official_name_en: "Pitcairn",
		"ISO4217-currency_country_name": "PITCAIRN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Polynesia",
		official_name_ru: "Питкэрн",
		"Global Name": "World",
		Capital: "Adamstown",
		Continent: "OC",
		TLD: ".pn",
		Languages: "en-PN",
		"Geoname ID": 4030699,
		"CLDR display name": "Kepulauan Pitcairn",
		EDGAR: "R8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q35672",
	},
	{
		FIFA: "PER",
		Dial: 51,
		"ISO3166-1-Alpha-3": "PER",
		MARC: "pe",
		is_independent: "Yes",
		"ISO3166-1-numeric": 604,
		GAUL: 195,
		FIPS: "PE",
		WMO: "PR",
		"ISO3166-1-Alpha-2": "PE",
		ITU: "PRU",
		IOC: "PER",
		DS: "PE",
		"UNTERM Spanish Formal": "la República del Perú",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Pérou",
		"UNTERM French Short": "Pérou (le)",
		"ISO4217-currency_name": "Sol",
		"UNTERM Russian Formal": "Республика Перу",
		"UNTERM English Short": "Peru",
		"ISO4217-currency_alphabetic_code": "PEN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Perú (el)",
		"ISO4217-currency_numeric_code": 604,
		"UNTERM Chinese Formal": "秘鲁共和国",
		"UNTERM French Formal": "la République du Pérou",
		"UNTERM Russian Short": "Перу",
		M49: 604,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "بيرو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية بيرو",
		"UNTERM Chinese Short": "秘鲁",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Perú",
		"UNTERM English Formal": "the Republic of Peru",
		official_name_cn: "秘鲁",
		official_name_en: "Peru",
		"ISO4217-currency_country_name": "PERU",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "بيرو",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Перу",
		"Global Name": "World",
		Capital: "Lima",
		Continent: "SA",
		TLD: ".pe",
		Languages: "es-PE,qu,ay",
		"Geoname ID": 3932488,
		"CLDR display name": "Peru",
		EDGAR: "R5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q419",
	},
	{
		FIFA: "PHI",
		Dial: 63,
		"ISO3166-1-Alpha-3": "PHL",
		MARC: "ph",
		is_independent: "Yes",
		"ISO3166-1-numeric": 608,
		GAUL: 196,
		FIPS: "RP",
		WMO: "PH",
		"ISO3166-1-Alpha-2": "PH",
		ITU: "PHL",
		IOC: "PHI",
		DS: "RP",
		"UNTERM Spanish Formal": "la República de Filipinas",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Philippines",
		"UNTERM French Short": "Philippines (les)",
		"ISO4217-currency_name": "Philippine Peso",
		"UNTERM Russian Formal": "Республика Филиппины",
		"UNTERM English Short": "Philippines (the)",
		"ISO4217-currency_alphabetic_code": "PHP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Filipinas",
		"ISO4217-currency_numeric_code": 608,
		"UNTERM Chinese Formal": "菲律宾共和国",
		"UNTERM French Formal": "la République des Philippines",
		"UNTERM Russian Short": "Филиппины",
		M49: 608,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "الفلبين",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية الفلبين",
		"UNTERM Chinese Short": "菲律宾",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Filipinas",
		"UNTERM English Formal": "the Republic of the Philippines",
		official_name_cn: "菲律宾",
		official_name_en: "Philippines",
		"ISO4217-currency_country_name": "PHILIPPINES",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "الفلبين",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Филиппины",
		"Global Name": "World",
		Capital: "Manila",
		Continent: "AS",
		TLD: ".ph",
		Languages:
			"tl,en-PH,fil,ceb,ilo,hil,war,pam,bik,bcl,pag,mrw,tsg,mdh,cbk,krj,sgd,msb,akl,ibg,yka,mta,abx",
		"Geoname ID": 1694008,
		"CLDR display name": "Filipina",
		EDGAR: "R6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q928",
	},
	{
		FIFA: "PLE",
		Dial: 970,
		"ISO3166-1-Alpha-3": "PSE",
		MARC: "gzwj",
		is_independent: "In contention",
		"ISO3166-1-numeric": 275,
		GAUL: 91267,
		FIPS: "GZWE",
		WMO: null,
		"ISO3166-1-Alpha-2": "PS",
		ITU: null,
		IOC: "PLE",
		DS: null,
		"UNTERM Spanish Formal": "el Estado de Palestina *",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "État de Palestine",
		"UNTERM French Short": "État de Palestine (l') *",
		"ISO4217-currency_name": "No universal currency",
		"UNTERM Russian Formal": "Государство Палестина *",
		"UNTERM English Short": "State of Palestine (the) *",
		"ISO4217-currency_alphabetic_code": null,
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Estado de Palestina (el) *",
		"ISO4217-currency_numeric_code": null,
		"UNTERM Chinese Formal": "巴勒斯坦国 *",
		"UNTERM French Formal": "l'État de Palestine *",
		"UNTERM Russian Short": "Государство Палестина *",
		M49: 275,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "دولة فلسطين",
		"ISO4217-currency_minor_unit": null,
		"UNTERM Arabic Formal": "دولة فلسطين *",
		"UNTERM Chinese Short": "巴勒斯坦国 *",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Estado de Palestina",
		"UNTERM English Formal": "the State of Palestine *",
		official_name_cn: "巴勒斯坦国",
		official_name_en: "State of Palestine",
		"ISO4217-currency_country_name": "PALESTINE, STATE OF",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "دولة فلسطين *",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Государство Палестина",
		"Global Name": "World",
		Capital: "East Jerusalem",
		Continent: "AS",
		TLD: ".ps",
		Languages: "ar-PS",
		"Geoname ID": 6254930,
		"CLDR display name": "Palestin",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q219060",
	},
	{
		FIFA: "PLW",
		Dial: 680,
		"ISO3166-1-Alpha-3": "PLW",
		MARC: "pw",
		is_independent: "Yes",
		"ISO3166-1-numeric": 585,
		GAUL: 189,
		FIPS: "PS",
		WMO: null,
		"ISO3166-1-Alpha-2": "PW",
		ITU: "PLW",
		IOC: "PLW",
		DS: null,
		"UNTERM Spanish Formal": "la República de Palau",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Palaos",
		"UNTERM French Short": "Palaos (les)",
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": "Республика Палау",
		"UNTERM English Short": "Palau",
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Palau",
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": "帕劳共和国",
		"UNTERM French Formal": "la République des Palaos",
		"UNTERM Russian Short": "Палау",
		M49: 585,
		"Sub-region Code": 57,
		"Region Code": 9,
		official_name_ar: "بالاو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية بالاو",
		"UNTERM Chinese Short": "帕劳",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Palau",
		"UNTERM English Formal": "the Republic of Palau",
		official_name_cn: "帕劳",
		official_name_en: "Palau",
		"ISO4217-currency_country_name": "PALAU",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "بالاو",
		"Sub-region Name": "Micronesia",
		official_name_ru: "Палау",
		"Global Name": "World",
		Capital: "Melekeok",
		Continent: "OC",
		TLD: ".pw",
		Languages: "pau,sov,en-PW,tox,ja,fil,zh",
		"Geoname ID": 1559582,
		"CLDR display name": "Palau",
		EDGAR: "1Y",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q695",
	},
	{
		FIFA: "PNG",
		Dial: 675,
		"ISO3166-1-Alpha-3": "PNG",
		MARC: "pp",
		is_independent: "Yes",
		"ISO3166-1-numeric": 598,
		GAUL: 192,
		FIPS: "PP",
		WMO: "NG",
		"ISO3166-1-Alpha-2": "PG",
		ITU: "PNG",
		IOC: "PNG",
		DS: "PNG",
		"UNTERM Spanish Formal": "el Estado Independiente de Papua Nueva Guinea",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Papouasie-Nouvelle-Guinée",
		"UNTERM French Short": "Papouasie-Nouvelle-Guinée (la)",
		"ISO4217-currency_name": "Kina",
		"UNTERM Russian Formal": "Независимое государство Папуа — Новая Гвинея",
		"UNTERM English Short": "Papua New Guinea",
		"ISO4217-currency_alphabetic_code": "PGK",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Papua Nueva Guinea",
		"ISO4217-currency_numeric_code": 598,
		"UNTERM Chinese Formal": "巴布亚新几内亚独立国",
		"UNTERM French Formal": "l'État indépendant de Papouasie-Nouvelle-Guinée",
		"UNTERM Russian Short": "Папуа — Новая Гвинея",
		M49: 598,
		"Sub-region Code": 54,
		"Region Code": 9,
		official_name_ar: "بابوا غينيا الجديدة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "دولة بابوا غينيا الجديدة المستقلة",
		"UNTERM Chinese Short": "巴布亚新几内亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Papua Nueva Guinea",
		"UNTERM English Formal": "the Independent State of Papua New Guinea",
		official_name_cn: "巴布亚新几内亚",
		official_name_en: "Papua New Guinea",
		"ISO4217-currency_country_name": "PAPUA NEW GUINEA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "بابوا غينيا الجديدة",
		"Sub-region Name": "Melanesia",
		official_name_ru: "Папуа-Новая Гвинея",
		"Global Name": "World",
		Capital: "Port Moresby",
		Continent: "OC",
		TLD: ".pg",
		Languages: "en-PG,ho,meu,tpi",
		"Geoname ID": 2088628,
		"CLDR display name": "Papua New Guinea",
		EDGAR: "R2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q691",
	},
	{
		FIFA: "POL",
		Dial: 48,
		"ISO3166-1-Alpha-3": "POL",
		MARC: "pl",
		is_independent: "Yes",
		"ISO3166-1-numeric": 616,
		GAUL: 198,
		FIPS: "PL",
		WMO: "PL",
		"ISO3166-1-Alpha-2": "PL",
		ITU: "POL",
		IOC: "POL",
		DS: "PL",
		"UNTERM Spanish Formal": "la República de Polonia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Pologne",
		"UNTERM French Short": "Pologne (la)",
		"ISO4217-currency_name": "Zloty",
		"UNTERM Russian Formal": "Республика Польша",
		"UNTERM English Short": "Poland",
		"ISO4217-currency_alphabetic_code": "PLN",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Polonia",
		"ISO4217-currency_numeric_code": 985,
		"UNTERM Chinese Formal": "波兰共和国",
		"UNTERM French Formal": "la République de Pologne",
		"UNTERM Russian Short": "Польша",
		M49: 616,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "بولندا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية بولندا",
		"UNTERM Chinese Short": "波兰",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Polonia",
		"UNTERM English Formal": "the Republic of Poland",
		official_name_cn: "波兰",
		official_name_en: "Poland",
		"ISO4217-currency_country_name": "POLAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "بولندا",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Польша",
		"Global Name": "World",
		Capital: "Warsaw",
		Continent: "EU",
		TLD: ".pl",
		Languages: "pl",
		"Geoname ID": 798544,
		"CLDR display name": "Poland",
		EDGAR: "R9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q36",
	},
	{
		FIFA: "POR",
		Dial: 351,
		"ISO3166-1-Alpha-3": "PRT",
		MARC: "po",
		is_independent: "Yes",
		"ISO3166-1-numeric": 620,
		GAUL: 199,
		FIPS: "PO",
		WMO: "PO",
		"ISO3166-1-Alpha-2": "PT",
		ITU: "POR",
		IOC: "POR",
		DS: "P",
		"UNTERM Spanish Formal": "la República Portuguesa",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Portugal",
		"UNTERM French Short": "Portugal (le)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Португальская Республика",
		"UNTERM English Short": "Portugal",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Portugal",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "葡萄牙共和国",
		"UNTERM French Formal": "la République portugaise",
		"UNTERM Russian Short": "Португалия",
		M49: 620,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "البرتغال",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية البرتغال",
		"UNTERM Chinese Short": "葡萄牙",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Portugal",
		"UNTERM English Formal": "the Portuguese Republic",
		official_name_cn: "葡萄牙",
		official_name_en: "Portugal",
		"ISO4217-currency_country_name": "PORTUGAL",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "البرتغال",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Португалия",
		"Global Name": "World",
		Capital: "Lisbon",
		Continent: "EU",
		TLD: ".pt",
		Languages: "pt-PT,mwl",
		"Geoname ID": 2264397,
		"CLDR display name": "Portugal",
		EDGAR: "S1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q45",
	},
	{
		FIFA: "PRK",
		Dial: 850,
		"ISO3166-1-Alpha-3": "PRK",
		MARC: "kn",
		is_independent: "Yes",
		"ISO3166-1-numeric": 408,
		GAUL: 67,
		FIPS: "KN",
		WMO: "KR",
		"ISO3166-1-Alpha-2": "KP",
		ITU: "KRE",
		IOC: "PRK",
		DS: null,
		"UNTERM Spanish Formal": "la República Popular Democrática de Corea",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "République populaire démocratique de Corée",
		"UNTERM French Short": "République populaire démocratique de Corée (la)",
		"ISO4217-currency_name": "North Korean Won",
		"UNTERM Russian Formal": "Корейская Народно-Демократическая Республика",
		"UNTERM English Short": "Democratic People's Republic of Korea (the)",
		"ISO4217-currency_alphabetic_code": "KPW",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "República Popular Democrática de Corea (la)",
		"ISO4217-currency_numeric_code": 408,
		"UNTERM Chinese Formal": "朝鲜民主主义人民共和国",
		"UNTERM French Formal": "la République populaire démocratique de Corée",
		"UNTERM Russian Short": "Корейская Народно-Демократическая Республика",
		M49: 408,
		"Sub-region Code": 30,
		"Region Code": 142,
		official_name_ar: "جمهورية كوريا الشعبية الديمقراطية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية كوريا الشعبية الديمقراطية",
		"UNTERM Chinese Short": "朝鲜民主主义人民共和国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "República Popular Democrática de Corea",
		"UNTERM English Formal": "the Democratic People's Republic of Korea",
		official_name_cn: "朝鲜民主主义人民共和国",
		official_name_en: "Democratic People's Republic of Korea",
		"ISO4217-currency_country_name":
			"KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "جمهورية كوريا الشعبية الديمقراطية",
		"Sub-region Name": "Eastern Asia",
		official_name_ru: "Корейская Народно-Демократическая Республика",
		"Global Name": "World",
		Capital: "Pyongyang",
		Continent: "AS",
		TLD: ".kp",
		Languages: "ko-KP",
		"Geoname ID": 1873107,
		"CLDR display name": "Korea Utara",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q423",
	},
	{
		FIFA: "PUR",
		Dial: 1,
		"ISO3166-1-Alpha-3": "PRI",
		MARC: "pr",
		is_independent: "Commonwealth of US",
		"ISO3166-1-numeric": 630,
		GAUL: 200,
		FIPS: "RQ",
		WMO: "PU",
		"ISO3166-1-Alpha-2": "PR",
		ITU: "PTR",
		IOC: "PUR",
		DS: "USA",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Porto Rico",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 630,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "بورتوريكو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Puerto Rico",
		"UNTERM English Formal": null,
		official_name_cn: "波多黎各",
		official_name_en: "Puerto Rico",
		"ISO4217-currency_country_name": "PUERTO RICO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Пуэрто-Рико",
		"Global Name": "World",
		Capital: "San Juan",
		Continent: null,
		TLD: ".pr",
		Languages: "en-PR,es-PR",
		"Geoname ID": 4566966,
		"CLDR display name": "Puerto Rico",
		EDGAR: "PR",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1183",
	},
	{
		FIFA: "QAT",
		Dial: 974,
		"ISO3166-1-Alpha-3": "QAT",
		MARC: "qa",
		is_independent: "Yes",
		"ISO3166-1-numeric": 634,
		GAUL: 201,
		FIPS: "QA",
		WMO: "QT",
		"ISO3166-1-Alpha-2": "QA",
		ITU: "QAT",
		IOC: "QAT",
		DS: "Q",
		"UNTERM Spanish Formal": "el Estado de Qatar",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Qatar",
		"UNTERM French Short": "Qatar (le)",
		"ISO4217-currency_name": "Qatari Rial",
		"UNTERM Russian Formal": "Государство Катар",
		"UNTERM English Short": "Qatar",
		"ISO4217-currency_alphabetic_code": "QAR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Qatar",
		"ISO4217-currency_numeric_code": 634,
		"UNTERM Chinese Formal": "卡塔尔国",
		"UNTERM French Formal": "l'État du Qatar",
		"UNTERM Russian Short": "Катар",
		M49: 634,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "قطر",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "دولة قطر",
		"UNTERM Chinese Short": "卡塔尔",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Qatar",
		"UNTERM English Formal": "the State of Qatar",
		official_name_cn: "卡塔尔",
		official_name_en: "Qatar",
		"ISO4217-currency_country_name": "QATAR",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "قطر",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Катар",
		"Global Name": "World",
		Capital: "Doha",
		Continent: "AS",
		TLD: ".qa",
		Languages: "ar-QA,es",
		"Geoname ID": 289688,
		"CLDR display name": "Qatar",
		EDGAR: "S3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q846",
	},
	{
		FIFA: "REU",
		Dial: 262,
		"ISO3166-1-Alpha-3": "REU",
		MARC: "re",
		is_independent: "Part of FR",
		"ISO3166-1-numeric": 638,
		GAUL: 206,
		FIPS: "RE",
		WMO: "RE",
		"ISO3166-1-Alpha-2": "RE",
		ITU: "REU",
		IOC: "REU",
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Réunion",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 638,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "ريونيون",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Reunión",
		"UNTERM English Formal": null,
		official_name_cn: "留尼汪",
		official_name_en: "Réunion",
		"ISO4217-currency_country_name": "RÉUNION",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Реюньон",
		"Global Name": "World",
		Capital: "Saint-Denis",
		Continent: "AF",
		TLD: ".re",
		Languages: "fr-RE",
		"Geoname ID": 935317,
		"CLDR display name": "Reunion",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q17070",
	},
	{
		FIFA: "ROS3",
		Dial: 672,
		"ISO3166-1-Alpha-3": "ATA",
		MARC: "ay",
		is_independent: "International",
		"ISO3166-1-numeric": 10,
		GAUL: 10,
		FIPS: "AY",
		WMO: "AA",
		"ISO3166-1-Alpha-2": "AQ",
		ITU: null,
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Antarctique",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "No universal currency",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": null,
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": null,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 10,
		"Sub-region Code": null,
		"Region Code": null,
		official_name_ar: "أنتاركتيكا",
		"ISO4217-currency_minor_unit": null,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Antártida",
		"UNTERM English Formal": null,
		official_name_cn: "南极洲",
		official_name_en: "Antarctica",
		"ISO4217-currency_country_name": "ANTARCTICA",
		"Least Developed Countries (LDC)": null,
		"Region Name": null,
		"UNTERM Arabic Short": null,
		"Sub-region Name": null,
		official_name_ru: "Антарктике",
		"Global Name": "World",
		Capital: null,
		Continent: "AN",
		TLD: ".aq",
		Languages: null,
		"Geoname ID": 6697173,
		"CLDR display name": "Antartika",
		EDGAR: "B8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q21590062",
	},
	{
		FIFA: "ROU",
		Dial: 40,
		"ISO3166-1-Alpha-3": "ROU",
		MARC: "rm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 642,
		GAUL: 203,
		FIPS: "RO",
		WMO: "RO",
		"ISO3166-1-Alpha-2": "RO",
		ITU: "ROU",
		IOC: "ROU",
		DS: "RO",
		"UNTERM Spanish Formal": "Rumania",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Roumanie",
		"UNTERM French Short": "Roumanie (la)",
		"ISO4217-currency_name": "Romanian Leu",
		"UNTERM Russian Formal": "Румыния",
		"UNTERM English Short": "Romania",
		"ISO4217-currency_alphabetic_code": "RON",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Rumania",
		"ISO4217-currency_numeric_code": 946,
		"UNTERM Chinese Formal": "罗马尼亚",
		"UNTERM French Formal": "la Roumanie",
		"UNTERM Russian Short": "Румыния",
		M49: 642,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "رومانيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "رومانيا",
		"UNTERM Chinese Short": "罗马尼亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Rumania",
		"UNTERM English Formal": "Romania",
		official_name_cn: "罗马尼亚",
		official_name_en: "Romania",
		"ISO4217-currency_country_name": "ROMANIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "رومانيا",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Румыния",
		"Global Name": "World",
		Capital: "Bucharest",
		Continent: "EU",
		TLD: ".ro",
		Languages: "ro,hu,rom",
		"Geoname ID": 798549,
		"CLDR display name": "Romania",
		EDGAR: "S5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q218",
	},
	{
		FIFA: "RSA",
		Dial: 27,
		"ISO3166-1-Alpha-3": "ZAF",
		MARC: "sa",
		is_independent: "Yes",
		"ISO3166-1-numeric": 710,
		GAUL: 227,
		FIPS: "SF",
		WMO: "ZA",
		"ISO3166-1-Alpha-2": "ZA",
		ITU: "AFS",
		IOC: "RSA",
		DS: "ZA",
		"UNTERM Spanish Formal": "la República de Sudáfrica",
		"Global Code": 1,
		"Intermediate Region Code": 18,
		official_name_fr: "Afrique du Sud",
		"UNTERM French Short": "Afrique du Sud (l')",
		"ISO4217-currency_name": "Rand",
		"UNTERM Russian Formal": "Южно-Африканская Республика",
		"UNTERM English Short": "South Africa",
		"ISO4217-currency_alphabetic_code": "ZAR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Sudáfrica",
		"ISO4217-currency_numeric_code": 710,
		"UNTERM Chinese Formal": "南非共和国",
		"UNTERM French Formal": "la République sud-africaine",
		"UNTERM Russian Short": "Южная Африка",
		M49: 710,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "جنوب أفريقيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية جنوب أفريقيا",
		"UNTERM Chinese Short": "南非",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Southern Africa",
		official_name_es: "Sudáfrica",
		"UNTERM English Formal": "the Republic of South Africa",
		official_name_cn: "南非",
		official_name_en: "South Africa",
		"ISO4217-currency_country_name": "SOUTH AFRICA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "جنوب أفريقيا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Южная Африка",
		"Global Name": "World",
		Capital: "Pretoria",
		Continent: "AF",
		TLD: ".za",
		Languages: "zu,xh,af,nso,en-ZA,tn,st,ts,ss,ve,nr",
		"Geoname ID": 953987,
		"CLDR display name": "Afrika Selatan",
		EDGAR: "T3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q258",
	},
	{
		FIFA: "RUS",
		Dial: 7,
		"ISO3166-1-Alpha-3": "RUS",
		MARC: "ru",
		is_independent: "Yes",
		"ISO3166-1-numeric": 643,
		GAUL: 204,
		FIPS: "RS",
		WMO: "RS",
		"ISO3166-1-Alpha-2": "RU",
		ITU: "RUS",
		IOC: "RUS",
		DS: "RUS",
		"UNTERM Spanish Formal": "la Federación de Rusia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Fédération de Russie",
		"UNTERM French Short": "Fédération de Russie (la)",
		"ISO4217-currency_name": "Russian Ruble",
		"UNTERM Russian Formal": "Российская Федерация",
		"UNTERM English Short": "Russian Federation (the)",
		"ISO4217-currency_alphabetic_code": "RUB",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Federación de Rusia (la)",
		"ISO4217-currency_numeric_code": 643,
		"UNTERM Chinese Formal": "俄罗斯联邦",
		"UNTERM French Formal": "la Fédération de Russie",
		"UNTERM Russian Short": "Российская Федерация",
		M49: 643,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "الاتحاد الروسي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الاتحاد الروسي",
		"UNTERM Chinese Short": "俄罗斯联邦",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Federación de Rusia",
		"UNTERM English Formal": "the Russian Federation",
		official_name_cn: "俄罗斯联邦",
		official_name_en: "Russian Federation",
		"ISO4217-currency_country_name": "RUSSIAN FEDERATION",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "الاتحاد الروسي",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Российская Федерация",
		"Global Name": "World",
		Capital: "Moscow",
		Continent: "EU",
		TLD: ".ru",
		Languages:
			"ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,kbd,krc,av,sah,nog",
		"Geoname ID": 2017370,
		"CLDR display name": "Rusia",
		EDGAR: "1Z",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q159",
	},
	{
		FIFA: "RWA",
		Dial: 250,
		"ISO3166-1-Alpha-3": "RWA",
		MARC: "rw",
		is_independent: "Yes",
		"ISO3166-1-numeric": 646,
		GAUL: 205,
		FIPS: "RW",
		WMO: "RW",
		"ISO3166-1-Alpha-2": "RW",
		ITU: "RRW",
		IOC: "RWA",
		DS: "RWA",
		"UNTERM Spanish Formal": "la República de Rwanda",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Rwanda",
		"UNTERM French Short": "Rwanda (le)",
		"ISO4217-currency_name": "Rwanda Franc",
		"UNTERM Russian Formal": "Республика Руанда",
		"UNTERM English Short": "Rwanda",
		"ISO4217-currency_alphabetic_code": "RWF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Rwanda",
		"ISO4217-currency_numeric_code": 646,
		"UNTERM Chinese Formal": "卢旺达共和国",
		"UNTERM French Formal": "la République du Rwanda",
		"UNTERM Russian Short": "Руанда",
		M49: 646,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "رواندا",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية رواندا",
		"UNTERM Chinese Short": "卢旺达",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Rwanda",
		"UNTERM English Formal": "the Republic of Rwanda",
		official_name_cn: "卢旺达",
		official_name_en: "Rwanda",
		"ISO4217-currency_country_name": "RWANDA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "رواندا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Руанда",
		"Global Name": "World",
		Capital: "Kigali",
		Continent: "AF",
		TLD: ".rw",
		Languages: "rw,en-RW,fr-RW,sw",
		"Geoname ID": 49518,
		"CLDR display name": "Rwanda",
		EDGAR: "S6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1037",
	},
	{
		FIFA: "SAH",
		Dial: 212,
		"ISO3166-1-Alpha-3": "ESH",
		MARC: "ss",
		is_independent: "In contention",
		"ISO3166-1-numeric": 732,
		GAUL: 268,
		FIPS: "WI",
		WMO: null,
		"ISO3166-1-Alpha-2": "EH",
		ITU: "AOE",
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Sahara occidental",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Moroccan Dirham",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "MAD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 504,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 732,
		"Sub-region Code": 15,
		"Region Code": 2,
		official_name_ar: "الصحراء الغربية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Sáhara Occidental",
		"UNTERM English Formal": null,
		official_name_cn: "西撒哈拉",
		official_name_en: "Western Sahara",
		"ISO4217-currency_country_name": "WESTERN SAHARA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern Africa",
		official_name_ru: "Западная Сахара",
		"Global Name": "World",
		Capital: "El-Aaiun",
		Continent: "AF",
		TLD: ".eh",
		Languages: "ar,mey",
		"Geoname ID": 2461445,
		"CLDR display name": "Sahara Barat",
		EDGAR: "U5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q40362",
	},
	{
		FIFA: "SAH",
		Dial: 212,
		"ISO3166-1-Alpha-3": "ESH",
		MARC: "ss",
		is_independent: "In contention",
		"ISO3166-1-numeric": 732,
		GAUL: 268,
		FIPS: "WI",
		WMO: null,
		"ISO3166-1-Alpha-2": "EH",
		ITU: "AOE",
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Sahara occidental",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Moroccan Dirham",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "MAD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 504,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 732,
		"Sub-region Code": 15,
		"Region Code": 2,
		official_name_ar: "الصحراء الغربية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Sáhara Occidental",
		"UNTERM English Formal": null,
		official_name_cn: "西撒哈拉",
		official_name_en: "Western Sahara",
		"ISO4217-currency_country_name": "WESTERN SAHARA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern Africa",
		official_name_ru: "Западная Сахара",
		"Global Name": "World",
		Capital: "El-Aaiun",
		Continent: "AF",
		TLD: ".eh",
		Languages: "ar,mey",
		"Geoname ID": 2461445,
		"CLDR display name": "Sahara Barat",
		EDGAR: "U5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q6250",
	},
	{
		FIFA: "SAM",
		Dial: 685,
		"ISO3166-1-Alpha-3": "WSM",
		MARC: "ws",
		is_independent: "Yes",
		"ISO3166-1-numeric": 882,
		GAUL: 212,
		FIPS: "WS",
		WMO: "ZM",
		"ISO3166-1-Alpha-2": "WS",
		ITU: "SMO",
		IOC: "SAM",
		DS: "WS",
		"UNTERM Spanish Formal": "el Estado Independiente de Samoa",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Samoa",
		"UNTERM French Short": "Samoa (le)",
		"ISO4217-currency_name": "Tala",
		"UNTERM Russian Formal": "Независимое Государство Самоа",
		"UNTERM English Short": "Samoa",
		"ISO4217-currency_alphabetic_code": "WST",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Samoa",
		"ISO4217-currency_numeric_code": 882,
		"UNTERM Chinese Formal": "萨摩亚独立国",
		"UNTERM French Formal": "l'État indépendant du Samoa",
		"UNTERM Russian Short": "Самоа",
		M49: 882,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "ساموا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "دولة ساموا المستقلة",
		"UNTERM Chinese Short": "萨摩亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Samoa",
		"UNTERM English Formal": "the Independent State of Samoa",
		official_name_cn: "萨摩亚",
		official_name_en: "Samoa",
		"ISO4217-currency_country_name": "SAMOA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "ساموا",
		"Sub-region Name": "Polynesia",
		official_name_ru: "Самоа",
		"Global Name": "World",
		Capital: "Apia",
		Continent: "OC",
		TLD: ".ws",
		Languages: "sm,en-WS",
		"Geoname ID": 4034894,
		"CLDR display name": "Samoa",
		EDGAR: "Y0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q683",
	},
	{
		FIFA: "SEN",
		Dial: 221,
		"ISO3166-1-Alpha-3": "SEN",
		MARC: "sg",
		is_independent: "Yes",
		"ISO3166-1-numeric": 686,
		GAUL: 217,
		FIPS: "SG",
		WMO: "SG",
		"ISO3166-1-Alpha-2": "SN",
		ITU: "SEN",
		IOC: "SEN",
		DS: "SN",
		"UNTERM Spanish Formal": "la República del Senegal",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Sénégal",
		"UNTERM French Short": "Sénégal (le)",
		"ISO4217-currency_name": "CFA Franc BCEAO",
		"UNTERM Russian Formal": "Республика Сенегал",
		"UNTERM English Short": "Senegal",
		"ISO4217-currency_alphabetic_code": "XOF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Senegal (el)",
		"ISO4217-currency_numeric_code": 952,
		"UNTERM Chinese Formal": "塞内加尔共和国",
		"UNTERM French Formal": "la République du Sénégal",
		"UNTERM Russian Short": "Сенегал",
		M49: 686,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "السنغال",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية السنغال",
		"UNTERM Chinese Short": "塞内加尔",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Senegal",
		"UNTERM English Formal": "the Republic of Senegal",
		official_name_cn: "塞内加尔",
		official_name_en: "Senegal",
		"ISO4217-currency_country_name": "SENEGAL",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "السنغال",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Сенегал",
		"Global Name": "World",
		Capital: "Dakar",
		Continent: "AF",
		TLD: ".sn",
		Languages: "fr-SN,wo,fuc,mnk",
		"Geoname ID": 2245662,
		"CLDR display name": "Senegal",
		EDGAR: "T1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1041",
	},
	{
		FIFA: "SEY",
		Dial: 248,
		"ISO3166-1-Alpha-3": "SYC",
		MARC: "se",
		is_independent: "Yes",
		"ISO3166-1-numeric": 690,
		GAUL: 220,
		FIPS: "SE",
		WMO: "SC",
		"ISO3166-1-Alpha-2": "SC",
		ITU: "SEY",
		IOC: "SEY",
		DS: "SY",
		"UNTERM Spanish Formal": "la República de Seychelles",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Seychelles",
		"UNTERM French Short": "Seychelles (les)",
		"ISO4217-currency_name": "Seychelles Rupee",
		"UNTERM Russian Formal": "Республика Сейшельские Острова",
		"UNTERM English Short": "Seychelles",
		"ISO4217-currency_alphabetic_code": "SCR",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Seychelles",
		"ISO4217-currency_numeric_code": 690,
		"UNTERM Chinese Formal": "塞舌尔共和国",
		"UNTERM French Formal": "la République des Seychelles",
		"UNTERM Russian Short": "Сейшельские Острова",
		M49: 690,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "سيشيل",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سيشيل",
		"UNTERM Chinese Short": "塞舌尔",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Seychelles",
		"UNTERM English Formal": "the Republic of Seychelles",
		official_name_cn: "塞舌尔",
		official_name_en: "Seychelles",
		"ISO4217-currency_country_name": "SEYCHELLES",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "سيشيل",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Сейшельские Острова",
		"Global Name": "World",
		Capital: "Victoria",
		Continent: "AF",
		TLD: ".sc",
		Languages: "en-SC,fr-SC",
		"Geoname ID": 241170,
		"CLDR display name": "Seychelles",
		EDGAR: "T2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1042",
	},
	{
		FIFA: "SEY",
		Dial: 248,
		"ISO3166-1-Alpha-3": "SYC",
		MARC: "se",
		is_independent: "Yes",
		"ISO3166-1-numeric": 690,
		GAUL: 220,
		FIPS: "SE",
		WMO: "SC",
		"ISO3166-1-Alpha-2": "SC",
		ITU: "SEY",
		IOC: "SEY",
		DS: "SY",
		"UNTERM Spanish Formal": "la República de Seychelles",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Seychelles",
		"UNTERM French Short": "Seychelles (les)",
		"ISO4217-currency_name": "Seychelles Rupee",
		"UNTERM Russian Formal": "Республика Сейшельские Острова",
		"UNTERM English Short": "Seychelles",
		"ISO4217-currency_alphabetic_code": "SCR",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Seychelles",
		"ISO4217-currency_numeric_code": 690,
		"UNTERM Chinese Formal": "塞舌尔共和国",
		"UNTERM French Formal": "la République des Seychelles",
		"UNTERM Russian Short": "Сейшельские Острова",
		M49: 690,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "سيشيل",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سيشيل",
		"UNTERM Chinese Short": "塞舌尔",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Seychelles",
		"UNTERM English Formal": "the Republic of Seychelles",
		official_name_cn: "塞舌尔",
		official_name_en: "Seychelles",
		"ISO4217-currency_country_name": "SEYCHELLES",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "سيشيل",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Сейшельские Острова",
		"Global Name": "World",
		Capital: "Victoria",
		Continent: "AF",
		TLD: ".sc",
		Languages: "en-SC,fr-SC",
		"Geoname ID": 241170,
		"CLDR display name": "Seychelles",
		EDGAR: "T2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q117089833",
	},
	{
		FIFA: "SHN",
		Dial: "290 n",
		"ISO3166-1-Alpha-3": "SHN",
		MARC: "xj",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 654,
		GAUL: 207,
		FIPS: "SH",
		WMO: "HE",
		"ISO3166-1-Alpha-2": "SH",
		ITU: "SHN",
		IOC: "HEL",
		DS: "SH",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Sainte-Hélène",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Saint Helena Pound",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "SHP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 654,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 654,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "سانت هيلانة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Santa Elena",
		"UNTERM English Formal": null,
		official_name_cn: "圣赫勒拿",
		official_name_en: "Saint Helena",
		"ISO4217-currency_country_name":
			"SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Остров Святой Елены",
		"Global Name": "World",
		Capital: "Jamestown",
		Continent: "AF",
		TLD: ".sh",
		Languages: "en-SH",
		"Geoname ID": 3370751,
		"CLDR display name": "Saint Helena",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q192184",
	},
	{
		FIFA: "SIN",
		Dial: 65,
		"ISO3166-1-Alpha-3": "SGP",
		MARC: "si",
		is_independent: "Yes",
		"ISO3166-1-numeric": 702,
		GAUL: 222,
		FIPS: "SN",
		WMO: "SR",
		"ISO3166-1-Alpha-2": "SG",
		ITU: "SNG",
		IOC: "SIN",
		DS: "SGP",
		"UNTERM Spanish Formal": "la República de Singapur",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Singapour",
		"UNTERM French Short": "Singapour",
		"ISO4217-currency_name": "Singapore Dollar",
		"UNTERM Russian Formal": "Республика Сингапур",
		"UNTERM English Short": "Singapore",
		"ISO4217-currency_alphabetic_code": "SGD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Singapur",
		"ISO4217-currency_numeric_code": 702,
		"UNTERM Chinese Formal": "新加坡共和国",
		"UNTERM French Formal": "la République de Singapour",
		"UNTERM Russian Short": "Сингапур",
		M49: 702,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "سنغافورة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سنغافورة",
		"UNTERM Chinese Short": "新加坡",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Singapur",
		"UNTERM English Formal": "the Republic of Singapore",
		official_name_cn: "新加坡",
		official_name_en: "Singapore",
		"ISO4217-currency_country_name": "SINGAPORE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "سنغافورة",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Сингапур",
		"Global Name": "World",
		Capital: "Singapore",
		Continent: "AS",
		TLD: ".sg",
		Languages: "cmn,en-SG,ms-SG,ta-SG,zh-SG",
		"Geoname ID": 1880251,
		"CLDR display name": "Singapura",
		EDGAR: "U0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q334",
	},
	{
		FIFA: "SKN",
		Dial: "1-869",
		"ISO3166-1-Alpha-3": "KNA",
		MARC: "xd",
		is_independent: "Yes",
		"ISO3166-1-numeric": 659,
		GAUL: 208,
		FIPS: "SC",
		WMO: "AT",
		"ISO3166-1-Alpha-2": "KN",
		ITU: "KNA",
		IOC: "SKN",
		DS: "KN",
		"UNTERM Spanish Formal": "Saint Kitts y Nevis",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Saint-Kitts-et-Nevis",
		"UNTERM French Short": "Saint-Kitts-et-Nevis",
		"ISO4217-currency_name": "East Caribbean Dollar",
		"UNTERM Russian Formal": "Сент-Китс и Невис",
		"UNTERM English Short": "Saint Kitts and Nevis",
		"ISO4217-currency_alphabetic_code": "XCD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Saint Kitts y Nevis",
		"ISO4217-currency_numeric_code": 951,
		"UNTERM Chinese Formal": "圣基茨和尼维斯",
		"UNTERM French Formal": "Saint-Kitts-et-Nevis",
		"UNTERM Russian Short": "Сент-Китс и Невис",
		M49: 659,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "سانت كيتس ونيفس",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "سانت كيتس ونيفس",
		"UNTERM Chinese Short": "圣基茨和尼维斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Saint Kitts y Nevis",
		"UNTERM English Formal": "Saint Kitts and Nevis",
		official_name_cn: "圣基茨和尼维斯",
		official_name_en: "Saint Kitts and Nevis",
		"ISO4217-currency_country_name": "SAINT KITTS AND NEVIS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "سانت كيتس ونيفس",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Сент-Китс и Невис",
		"Global Name": "World",
		Capital: "Basseterre",
		Continent: null,
		TLD: ".kn",
		Languages: "en-KN",
		"Geoname ID": 3575174,
		"CLDR display name": "Saint Kitts dan Nevis",
		EDGAR: "U7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q763",
	},
	{
		FIFA: "SLE",
		Dial: 232,
		"ISO3166-1-Alpha-3": "SLE",
		MARC: "sl",
		is_independent: "Yes",
		"ISO3166-1-numeric": 694,
		GAUL: 221,
		FIPS: "SL",
		WMO: "SL",
		"ISO3166-1-Alpha-2": "SL",
		ITU: "SRL",
		IOC: "SLE",
		DS: "WAL",
		"UNTERM Spanish Formal": "la República de Sierra Leona",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Sierra Leone",
		"UNTERM French Short": "Sierra Leone (la)",
		"ISO4217-currency_name": "Leone",
		"UNTERM Russian Formal": "Республика Сьерра-Леоне",
		"UNTERM English Short": "Sierra Leone",
		"ISO4217-currency_alphabetic_code": "SLE",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Sierra Leona",
		"ISO4217-currency_numeric_code": 925,
		"UNTERM Chinese Formal": "塞拉利昂共和国",
		"UNTERM French Formal": "la République de Sierra Leone",
		"UNTERM Russian Short": "Сьерра-Леоне",
		M49: 694,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "سيراليون",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سيراليون",
		"UNTERM Chinese Short": "塞拉利昂",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Sierra Leona",
		"UNTERM English Formal": "the Republic of Sierra Leone",
		official_name_cn: "塞拉利昂",
		official_name_en: "Sierra Leone",
		"ISO4217-currency_country_name": "SIERRA LEONE",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "سيراليون",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Сьерра-Леоне",
		"Global Name": "World",
		Capital: "Freetown",
		Continent: "AF",
		TLD: ".sl",
		Languages: "en-SL,men,tem",
		"Geoname ID": 2403846,
		"CLDR display name": "Sierra Leone",
		EDGAR: "T8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1044",
	},
	{
		FIFA: "SLV",
		Dial: 503,
		"ISO3166-1-Alpha-3": "SLV",
		MARC: "es",
		is_independent: "Yes",
		"ISO3166-1-numeric": 222,
		GAUL: 75,
		FIPS: "ES",
		WMO: "ES",
		"ISO3166-1-Alpha-2": "SV",
		ITU: "SLV",
		IOC: "ESA",
		DS: "ES",
		"UNTERM Spanish Formal": "la República de El Salvador",
		"Global Code": 1,
		"Intermediate Region Code": 13,
		official_name_fr: "El Salvador",
		"UNTERM French Short": "El Salvador",
		"ISO4217-currency_name": "El Salvador Colon,US Dollar",
		"UNTERM Russian Formal": "Республика Эль-Сальвадор",
		"UNTERM English Short": "El Salvador",
		"ISO4217-currency_alphabetic_code": "SVC,USD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "El Salvador",
		"ISO4217-currency_numeric_code": "222,840",
		"UNTERM Chinese Formal": "萨尔瓦多共和国",
		"UNTERM French Formal": "la République d'El Salvador",
		"UNTERM Russian Short": "Сальвадор",
		M49: 222,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "السلفادور",
		"ISO4217-currency_minor_unit": "2,2",
		"UNTERM Arabic Formal": "جمهورية السلفادور",
		"UNTERM Chinese Short": "萨尔瓦多",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Central America",
		official_name_es: "El Salvador",
		"UNTERM English Formal": "the Republic of El Salvador",
		official_name_cn: "萨尔瓦多",
		official_name_en: "El Salvador",
		"ISO4217-currency_country_name": "EL SALVADOR",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "السلفادور",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Сальвадор",
		"Global Name": "World",
		Capital: "San Salvador",
		Continent: null,
		TLD: ".sv",
		Languages: "es-SV",
		"Geoname ID": 3585968,
		"CLDR display name": "El Salvador",
		EDGAR: "H3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q792",
	},
	{
		FIFA: "SMR",
		Dial: 378,
		"ISO3166-1-Alpha-3": "SMR",
		MARC: "sm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 674,
		GAUL: 213,
		FIPS: "SM",
		WMO: null,
		"ISO3166-1-Alpha-2": "SM",
		ITU: "SMR",
		IOC: "SMR",
		DS: "RSM",
		"UNTERM Spanish Formal": "la República de San Marino",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Saint-Marin",
		"UNTERM French Short": "Saint-Marin",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Республика Сан-Марино",
		"UNTERM English Short": "San Marino",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "San Marino",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "圣马力诺共和国",
		"UNTERM French Formal": "la République de Saint-Marin",
		"UNTERM Russian Short": "Сан-Марино",
		M49: 674,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "سان مارينو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سان مارينو",
		"UNTERM Chinese Short": "圣马力诺",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "San Marino",
		"UNTERM English Formal": "the Republic of San Marino",
		official_name_cn: "圣马力诺",
		official_name_en: "San Marino",
		"ISO4217-currency_country_name": "SAN MARINO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "سان مارينو",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Сан-Марино",
		"Global Name": "World",
		Capital: "San Marino",
		Continent: "EU",
		TLD: ".sm",
		Languages: "it-SM",
		"Geoname ID": 3168068,
		"CLDR display name": "San Marino",
		EDGAR: "S8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q238",
	},
	{
		FIFA: "SOL",
		Dial: 677,
		"ISO3166-1-Alpha-3": "SLB",
		MARC: "bp",
		is_independent: "Yes",
		"ISO3166-1-numeric": 90,
		GAUL: 225,
		FIPS: "BP",
		WMO: "SO",
		"ISO3166-1-Alpha-2": "SB",
		ITU: "SLM",
		IOC: "SOL",
		DS: "SB",
		"UNTERM Spanish Formal": "las Islas Salomón",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles Salomon",
		"UNTERM French Short": "Îles Salomon (les)",
		"ISO4217-currency_name": "Solomon Islands Dollar",
		"UNTERM Russian Formal": "Соломоновы Острова",
		"UNTERM English Short": "Solomon Islands",
		"ISO4217-currency_alphabetic_code": "SBD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Islas Salomón (las)",
		"ISO4217-currency_numeric_code": 90,
		"UNTERM Chinese Formal": "所罗门群岛",
		"UNTERM French Formal": "les Îles Salomon",
		"UNTERM Russian Short": "Соломоновы Острова",
		M49: 90,
		"Sub-region Code": 54,
		"Region Code": 9,
		official_name_ar: "جزر سليمان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جزر سليمان",
		"UNTERM Chinese Short": "所罗门群岛",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Salomón",
		"UNTERM English Formal": "Solomon Islands",
		official_name_cn: "所罗门群岛",
		official_name_en: "Solomon Islands",
		"ISO4217-currency_country_name": "SOLOMON ISLANDS",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "جزر سليمان",
		"Sub-region Name": "Melanesia",
		official_name_ru: "Соломоновы Острова",
		"Global Name": "World",
		Capital: "Honiara",
		Continent: "OC",
		TLD: ".sb",
		Languages: "en-SB,tpi",
		"Geoname ID": 2103350,
		"CLDR display name": "Kepulauan Solomon",
		EDGAR: "D7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q685",
	},
	{
		FIFA: "SOM",
		Dial: 252,
		"ISO3166-1-Alpha-3": "SOM",
		MARC: "so",
		is_independent: "Yes",
		"ISO3166-1-numeric": 706,
		GAUL: 226,
		FIPS: "SO",
		WMO: "SI",
		"ISO3166-1-Alpha-2": "SO",
		ITU: "SOM",
		IOC: "SOM",
		DS: "SO",
		"UNTERM Spanish Formal": "la República Federal de Somalia",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Somalie",
		"UNTERM French Short": "Somalie (la)",
		"ISO4217-currency_name": "Somali Shilling",
		"UNTERM Russian Formal": "Федеративная Республика Сомали",
		"UNTERM English Short": "Somalia",
		"ISO4217-currency_alphabetic_code": "SOS",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Somalia",
		"ISO4217-currency_numeric_code": 706,
		"UNTERM Chinese Formal": "索马里联邦共和国",
		"UNTERM French Formal": "la République fédérale de Somalie",
		"UNTERM Russian Short": "Сомали",
		M49: 706,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "الصومال",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية الصومال الاتحادية",
		"UNTERM Chinese Short": "索马里",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Somalia",
		"UNTERM English Formal": "the Federal Republic of Somalia",
		official_name_cn: "索马里",
		official_name_en: "Somalia",
		"ISO4217-currency_country_name": "SOMALIA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "الصومال",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Сомали",
		"Global Name": "World",
		Capital: "Mogadishu",
		Continent: "AF",
		TLD: ".so",
		Languages: "so-SO,ar-SO,it,en-SO",
		"Geoname ID": 51537,
		"CLDR display name": "Somalia",
		EDGAR: "U1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1045",
	},
	{
		FIFA: "SPM",
		Dial: 508,
		"ISO3166-1-Alpha-3": "SPM",
		MARC: "xl",
		is_independent: "Part of FR",
		"ISO3166-1-numeric": 666,
		GAUL: 210,
		FIPS: "SB",
		WMO: "FP",
		"ISO3166-1-Alpha-2": "PM",
		ITU: "SPM",
		IOC: "SPM",
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Saint-Pierre-et-Miquelon",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 666,
		"Sub-region Code": 21,
		"Region Code": 19,
		official_name_ar: "سان بيير وميكلون",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "San Pedro y Miquelón",
		"UNTERM English Formal": null,
		official_name_cn: "圣皮埃尔和密克隆",
		official_name_en: "Saint Pierre and Miquelon",
		"ISO4217-currency_country_name": "SAINT PIERRE AND MIQUELON",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern America",
		official_name_ru: "Сен-Пьер и Микелон",
		"Global Name": "World",
		Capital: "Saint-Pierre",
		Continent: null,
		TLD: ".pm",
		Languages: "fr-PM",
		"Geoname ID": 3424932,
		"CLDR display name": "Saint Pierre dan Miquelon",
		EDGAR: "V0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q34617",
	},
	{
		FIFA: "SRB",
		Dial: "381 p",
		"ISO3166-1-Alpha-3": "SRB",
		MARC: "rb",
		is_independent: "Yes",
		"ISO3166-1-numeric": 688,
		GAUL: 2648,
		FIPS: "RIKV",
		WMO: "YG",
		"ISO3166-1-Alpha-2": "RS",
		ITU: "SRB",
		IOC: "SRB",
		DS: "SRB",
		"UNTERM Spanish Formal": "la República de Serbia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Serbie",
		"UNTERM French Short": "Serbie (la)",
		"ISO4217-currency_name": "Serbian Dinar",
		"UNTERM Russian Formal": "Республика Сербия",
		"UNTERM English Short": "Serbia",
		"ISO4217-currency_alphabetic_code": "RSD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Serbia",
		"ISO4217-currency_numeric_code": 941,
		"UNTERM Chinese Formal": "塞尔维亚共和国",
		"UNTERM French Formal": "la République de Serbie",
		"UNTERM Russian Short": "Сербия",
		M49: 688,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "صربيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية صربيا",
		"UNTERM Chinese Short": "塞尔维亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Serbia",
		"UNTERM English Formal": "the Republic of Serbia",
		official_name_cn: "塞尔维亚",
		official_name_en: "Serbia",
		"ISO4217-currency_country_name": "SERBIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "صربيا",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Сербия",
		"Global Name": "World",
		Capital: "Belgrade",
		Continent: "EU",
		TLD: ".rs",
		Languages: "sr,hu,bs,rom",
		"Geoname ID": 6290252,
		"CLDR display name": "Serbia",
		EDGAR: "Z2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q403",
	},
	{
		FIFA: "SRI",
		Dial: 94,
		"ISO3166-1-Alpha-3": "LKA",
		MARC: "ce",
		is_independent: "Yes",
		"ISO3166-1-numeric": 144,
		GAUL: 231,
		FIPS: "CE",
		WMO: "SB",
		"ISO3166-1-Alpha-2": "LK",
		ITU: "CLN",
		IOC: "SRI",
		DS: "CL",
		"UNTERM Spanish Formal": "la República Socialista Democrática de Sri Lanka",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Sri Lanka",
		"UNTERM French Short": "Sri Lanka",
		"ISO4217-currency_name": "Sri Lanka Rupee",
		"UNTERM Russian Formal":
			"Демократическая Социалистическая Республика Шри-Ланка",
		"UNTERM English Short": "Sri Lanka",
		"ISO4217-currency_alphabetic_code": "LKR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Sri Lanka",
		"ISO4217-currency_numeric_code": 144,
		"UNTERM Chinese Formal": "斯里兰卡民主社会主义共和国",
		"UNTERM French Formal":
			"la République socialiste démocratique de Sri Lanka",
		"UNTERM Russian Short": "Шри-Ланка",
		M49: 144,
		"Sub-region Code": 34,
		"Region Code": 142,
		official_name_ar: "سري لانكا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سري لانكا الاشتراكية الديمقراطية",
		"UNTERM Chinese Short": "斯里兰卡",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Sri Lanka",
		"UNTERM English Formal": "the Democratic Socialist Republic of Sri Lanka",
		official_name_cn: "斯里兰卡",
		official_name_en: "Sri Lanka",
		"ISO4217-currency_country_name": "SRI LANKA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "سري لانكا",
		"Sub-region Name": "Southern Asia",
		official_name_ru: "Шри-Ланка",
		"Global Name": "World",
		Capital: "Colombo",
		Continent: "AS",
		TLD: ".lk",
		Languages: "si,ta,en",
		"Geoname ID": 1227603,
		"CLDR display name": "Sri Lanka",
		EDGAR: "F1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q854",
	},
	{
		FIFA: "STP",
		Dial: 239,
		"ISO3166-1-Alpha-3": "STP",
		MARC: "sf",
		is_independent: "Yes",
		"ISO3166-1-numeric": 678,
		GAUL: 214,
		FIPS: "TP",
		WMO: "TP",
		"ISO3166-1-Alpha-2": "ST",
		ITU: "STP",
		IOC: "STP",
		DS: "ST",
		"UNTERM Spanish Formal":
			"la República Democrática de Santo Tomé y Príncipe",
		"Global Code": 1,
		"Intermediate Region Code": 17,
		official_name_fr: "Sao Tomé-et-Principe",
		"UNTERM French Short": "Sao Tomé-et-Principe",
		"ISO4217-currency_name": "Dobra",
		"UNTERM Russian Formal": "Демократическая Республика Сан-Томе и Принсипи",
		"UNTERM English Short": "Sao Tome and Principe",
		"ISO4217-currency_alphabetic_code": "STN",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Santo Tomé y Príncipe",
		"ISO4217-currency_numeric_code": 930,
		"UNTERM Chinese Formal": "圣多美和普林西比民主共和国",
		"UNTERM French Formal":
			"la République démocratique de Sao Tomé-et-Principe",
		"UNTERM Russian Short": "Сан-Томе и Принсипи",
		M49: 678,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "سان تومي وبرينسيبي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سان تومي وبرينسيبي الديمقراطية",
		"UNTERM Chinese Short": "圣多美和普林西比",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Middle Africa",
		official_name_es: "Santo Tomé y Príncipe",
		"UNTERM English Formal": "the Democratic Republic of Sao Tome and Principe",
		official_name_cn: "圣多美和普林西比",
		official_name_en: "Sao Tome and Principe",
		"ISO4217-currency_country_name": "SAO TOME AND PRINCIPE",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "سان تومي وبرينسيبي",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Сан-Томе и Принсипи",
		"Global Name": "World",
		Capital: "Sao Tome",
		Continent: "AF",
		TLD: ".st",
		Languages: "pt-ST",
		"Geoname ID": 2410758,
		"CLDR display name": "Sao Tome dan Principe",
		EDGAR: "S9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1039",
	},
	{
		FIFA: "SUD",
		Dial: 249,
		"ISO3166-1-Alpha-3": "SDN",
		MARC: "sj",
		is_independent: "Yes",
		"ISO3166-1-numeric": 729,
		GAUL: 40764,
		FIPS: "SU",
		WMO: "SU",
		"ISO3166-1-Alpha-2": "SD",
		ITU: "SDN",
		IOC: "SUD",
		DS: "SUD",
		"UNTERM Spanish Formal": "la República del Sudán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Soudan",
		"UNTERM French Short": "Soudan (le)",
		"ISO4217-currency_name": "Sudanese Pound",
		"UNTERM Russian Formal": "Республика Судан",
		"UNTERM English Short": "Sudan (the)",
		"ISO4217-currency_alphabetic_code": "SDG",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Sudán (el)",
		"ISO4217-currency_numeric_code": 938,
		"UNTERM Chinese Formal": "苏丹共和国",
		"UNTERM French Formal": "la République du Soudan",
		"UNTERM Russian Short": "Судан",
		M49: 729,
		"Sub-region Code": 15,
		"Region Code": 2,
		official_name_ar: "السودان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية السودان",
		"UNTERM Chinese Short": "苏丹",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Sudán",
		"UNTERM English Formal": "the Republic of the Sudan",
		official_name_cn: "苏丹",
		official_name_en: "Sudan",
		"ISO4217-currency_country_name": "SUDAN",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "السودان",
		"Sub-region Name": "Northern Africa",
		official_name_ru: "Судан",
		"Global Name": "World",
		Capital: "Khartoum",
		Continent: "AF",
		TLD: ".sd",
		Languages: "ar-SD,en,fia",
		"Geoname ID": 366755,
		"CLDR display name": "Sudan",
		EDGAR: "V2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1049",
	},
	{
		FIFA: "SUI",
		Dial: 41,
		"ISO3166-1-Alpha-3": "CHE",
		MARC: "sz",
		is_independent: "Yes",
		"ISO3166-1-numeric": 756,
		GAUL: 237,
		FIPS: "SZ",
		WMO: "SW",
		"ISO3166-1-Alpha-2": "CH",
		ITU: "SUI",
		IOC: "SUI",
		DS: "CH",
		"UNTERM Spanish Formal": "la Confederación Suiza",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Suisse",
		"UNTERM French Short": "Suisse (la)",
		"ISO4217-currency_name": "Swiss Franc",
		"UNTERM Russian Formal": "Швейцарская Конфедерация",
		"UNTERM English Short": "Switzerland",
		"ISO4217-currency_alphabetic_code": "CHF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Suiza",
		"ISO4217-currency_numeric_code": 756,
		"UNTERM Chinese Formal": "瑞士联邦",
		"UNTERM French Formal": "la Confédération suisse",
		"UNTERM Russian Short": "Швейцария",
		M49: 756,
		"Sub-region Code": 155,
		"Region Code": 150,
		official_name_ar: "سويسرا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الاتحاد السويسري",
		"UNTERM Chinese Short": "瑞士",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Suiza",
		"UNTERM English Formal": "the Swiss Confederation",
		official_name_cn: "瑞士",
		official_name_en: "Switzerland",
		"ISO4217-currency_country_name": "SWITZERLAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "سويسرا",
		"Sub-region Name": "Western Europe",
		official_name_ru: "Швейцария",
		"Global Name": "World",
		Capital: "Bern",
		Continent: "EU",
		TLD: ".ch",
		Languages: "de-CH,fr-CH,it-CH,rm",
		"Geoname ID": 2658434,
		"CLDR display name": "Switzerland",
		EDGAR: "V8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q39",
	},
	{
		FIFA: "SUR",
		Dial: 597,
		"ISO3166-1-Alpha-3": "SUR",
		MARC: "sr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 740,
		GAUL: 233,
		FIPS: "NS",
		WMO: "SM",
		"ISO3166-1-Alpha-2": "SR",
		ITU: "SUR",
		IOC: "SUR",
		DS: "SME",
		"UNTERM Spanish Formal": "la República de Suriname",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Suriname",
		"UNTERM French Short": "Suriname (le)",
		"ISO4217-currency_name": "Surinam Dollar",
		"UNTERM Russian Formal": "Республика Суринам",
		"UNTERM English Short": "Suriname",
		"ISO4217-currency_alphabetic_code": "SRD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Suriname",
		"ISO4217-currency_numeric_code": 968,
		"UNTERM Chinese Formal": "苏里南共和国",
		"UNTERM French Formal": "la République du Suriname",
		"UNTERM Russian Short": "Суринам",
		M49: 740,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "سورينام",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سورينام",
		"UNTERM Chinese Short": "苏里南",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Suriname",
		"UNTERM English Formal": "the Republic of Suriname",
		official_name_cn: "苏里南",
		official_name_en: "Suriname",
		"ISO4217-currency_country_name": "SURINAME",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "سورينام",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Суринам",
		"Global Name": "World",
		Capital: "Paramaribo",
		Continent: "SA",
		TLD: ".sr",
		Languages: "nl-SR,en,srn,hns,jv",
		"Geoname ID": 3382998,
		"CLDR display name": "Surinam",
		EDGAR: "V3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q730",
	},
	{
		FIFA: "SVK",
		Dial: 421,
		"ISO3166-1-Alpha-3": "SVK",
		MARC: "xo",
		is_independent: "Yes",
		"ISO3166-1-numeric": 703,
		GAUL: 223,
		FIPS: "LO",
		WMO: "SQ",
		"ISO3166-1-Alpha-2": "SK",
		ITU: "SVK",
		IOC: "SVK",
		DS: "SK",
		"UNTERM Spanish Formal": "la República Eslovaca",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Slovaquie",
		"UNTERM French Short": "Slovaquie (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Словацкая Республика",
		"UNTERM English Short": "Slovakia",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Eslovaquia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "斯洛伐克共和国",
		"UNTERM French Formal": "la République slovaque",
		"UNTERM Russian Short": "Словакия",
		M49: 703,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "سلوفاكيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية السلوفاكية",
		"UNTERM Chinese Short": "斯洛伐克",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Eslovaquia",
		"UNTERM English Formal": "the Slovak Republic",
		official_name_cn: "斯洛伐克",
		official_name_en: "Slovakia",
		"ISO4217-currency_country_name": "SLOVAKIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "سلوفاكيا",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Словакия",
		"Global Name": "World",
		Capital: "Bratislava",
		Continent: "EU",
		TLD: ".sk",
		Languages: "sk,hu",
		"Geoname ID": 3057568,
		"CLDR display name": "Slovakia",
		EDGAR: "2B",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q214",
	},
	{
		FIFA: "SVN",
		Dial: 386,
		"ISO3166-1-Alpha-3": "SVN",
		MARC: "xv",
		is_independent: "Yes",
		"ISO3166-1-numeric": 705,
		GAUL: 224,
		FIPS: "SI",
		WMO: "LJ",
		"ISO3166-1-Alpha-2": "SI",
		ITU: "SVN",
		IOC: "SLO",
		DS: "SLO",
		"UNTERM Spanish Formal": "la República de Eslovenia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Slovénie",
		"UNTERM French Short": "Slovénie (la)",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Республика Словения",
		"UNTERM English Short": "Slovenia",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Eslovenia",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "斯洛文尼亚共和国",
		"UNTERM French Formal": "la République de Slovénie",
		"UNTERM Russian Short": "Словения",
		M49: 705,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "سلوفينيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية سلوفينيا",
		"UNTERM Chinese Short": "斯洛文尼亚",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Eslovenia",
		"UNTERM English Formal": "the Republic of Slovenia",
		official_name_cn: "斯洛文尼亚",
		official_name_en: "Slovenia",
		"ISO4217-currency_country_name": "SLOVENIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "سلوفينيا",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Словения",
		"Global Name": "World",
		Capital: "Ljubljana",
		Continent: "EU",
		TLD: ".si",
		Languages: "sl,sh",
		"Geoname ID": 3190538,
		"CLDR display name": "Slovenia",
		EDGAR: "2A",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q215",
	},
	{
		FIFA: "SWE",
		Dial: 46,
		"ISO3166-1-Alpha-3": "SWE",
		MARC: "sw",
		is_independent: "Yes",
		"ISO3166-1-numeric": 752,
		GAUL: 236,
		FIPS: "SW",
		WMO: "SN",
		"ISO3166-1-Alpha-2": "SE",
		ITU: "S",
		IOC: "SWE",
		DS: "S",
		"UNTERM Spanish Formal": "el Reino de Suecia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Suède",
		"UNTERM French Short": "Suède (la)",
		"ISO4217-currency_name": "Swedish Krona",
		"UNTERM Russian Formal": "Королевство Швеция",
		"UNTERM English Short": "Sweden",
		"ISO4217-currency_alphabetic_code": "SEK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Suecia",
		"ISO4217-currency_numeric_code": 752,
		"UNTERM Chinese Formal": "瑞典王国",
		"UNTERM French Formal": "le Royaume de Suède",
		"UNTERM Russian Short": "Швеция",
		M49: 752,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "السويد",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة السويد",
		"UNTERM Chinese Short": "瑞典",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Suecia",
		"UNTERM English Formal": "the Kingdom of Sweden",
		official_name_cn: "瑞典",
		official_name_en: "Sweden",
		"ISO4217-currency_country_name": "SWEDEN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "السويد",
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Швеция",
		"Global Name": "World",
		Capital: "Stockholm",
		Continent: "EU",
		TLD: ".se",
		Languages: "sv-SE,se,sma,fi-SE",
		"Geoname ID": 2661886,
		"CLDR display name": "Sweden",
		EDGAR: "V7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q34",
	},
	{
		FIFA: "SWZ",
		Dial: 268,
		"ISO3166-1-Alpha-3": "SWZ",
		MARC: "sq",
		is_independent: "Yes",
		"ISO3166-1-numeric": 748,
		GAUL: 235,
		FIPS: "WZ",
		WMO: "SV",
		"ISO3166-1-Alpha-2": "SZ",
		ITU: "SWZ",
		IOC: "SWZ",
		DS: "SD",
		"UNTERM Spanish Formal": "el Reino de Eswatini",
		"Global Code": 1,
		"Intermediate Region Code": 18,
		official_name_fr: "Eswatini",
		"UNTERM French Short": "Eswatini (l')",
		"ISO4217-currency_name": "Lilangeni",
		"UNTERM Russian Formal": "Королевство Эсватини",
		"UNTERM English Short": "Eswatini",
		"ISO4217-currency_alphabetic_code": "SZL",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Eswatini",
		"ISO4217-currency_numeric_code": 748,
		"UNTERM Chinese Formal": "斯威士兰王国",
		"UNTERM French Formal": "le Royaume d’Eswatini",
		"UNTERM Russian Short": "Эсватини",
		M49: 748,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "إسواتيني",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة إسواتيني",
		"UNTERM Chinese Short": "斯威士兰",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Southern Africa",
		official_name_es: "Eswatini",
		"UNTERM English Formal": "the Kingdom of Eswatini",
		official_name_cn: "斯威士兰",
		official_name_en: "Eswatini",
		"ISO4217-currency_country_name": "ESWATINI",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "إسواتيني",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Эсватини",
		"Global Name": "World",
		Capital: "Mbabane",
		Continent: "AF",
		TLD: ".sz",
		Languages: "en-SZ,ss-SZ",
		"Geoname ID": 934841,
		"CLDR display name": "Eswatini",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1050",
	},
	{
		FIFA: "SYR",
		Dial: 963,
		"ISO3166-1-Alpha-3": "SYR",
		MARC: "sy",
		is_independent: "Yes",
		"ISO3166-1-numeric": 760,
		GAUL: 238,
		FIPS: "SY",
		WMO: "SY",
		"ISO3166-1-Alpha-2": "SY",
		ITU: "SYR",
		IOC: "SYR",
		DS: "SYR",
		"UNTERM Spanish Formal": "la República Árabe Siria",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "République arabe syrienne",
		"UNTERM French Short": "République arabe syrienne (la)",
		"ISO4217-currency_name": "Syrian Pound",
		"UNTERM Russian Formal": "Сирийская Арабская Республика",
		"UNTERM English Short": "Syrian Arab Republic (the)",
		"ISO4217-currency_alphabetic_code": "SYP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "República Árabe Siria (la)",
		"ISO4217-currency_numeric_code": 760,
		"UNTERM Chinese Formal": "阿拉伯叙利亚共和国",
		"UNTERM French Formal": "la République arabe syrienne",
		"UNTERM Russian Short": "Сирийская Арабская Республика",
		M49: 760,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "الجمهورية العربية السورية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية العربية السورية",
		"UNTERM Chinese Short": "阿拉伯叙利亚共和国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "República Árabe Siria",
		"UNTERM English Formal": "the Syrian Arab Republic",
		official_name_cn: "阿拉伯叙利亚共和国",
		official_name_en: "Syrian Arab Republic",
		"ISO4217-currency_country_name": "SYRIAN ARAB REPUBLIC",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "الجمهورية العربية السورية",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Сирийская Арабская Республика",
		"Global Name": "World",
		Capital: "Damascus",
		Continent: "AS",
		TLD: ".sy",
		Languages: "ar-SY,ku,hy,arc,fr,en",
		"Geoname ID": 163843,
		"CLDR display name": "Syria",
		EDGAR: "V9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q858",
	},
	{
		FIFA: "TAH2",
		Dial: 689,
		"ISO3166-1-Alpha-3": "PYF",
		MARC: "fp",
		is_independent: "Territory of FR",
		"ISO3166-1-numeric": 258,
		GAUL: 87,
		FIPS: "FP",
		WMO: "PF",
		"ISO3166-1-Alpha-2": "PF",
		ITU: "OCE",
		IOC: "FPO",
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Polynésie française",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "CFP Franc",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "XPF",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 953,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 258,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "بولينيزيا الفرنسية",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Polinesia Francesa",
		"UNTERM English Formal": null,
		official_name_cn: "法属波利尼西亚",
		official_name_en: "French Polynesia",
		"ISO4217-currency_country_name": "FRENCH POLYNESIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Polynesia",
		official_name_ru: "Французская Полинезия",
		"Global Name": "World",
		Capital: "Papeete",
		Continent: "OC",
		TLD: ".pf",
		Languages: "fr-PF,ty",
		"Geoname ID": 4030656,
		"CLDR display name": "Polinesia Perancis",
		EDGAR: "I4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q30971",
	},
	{
		FIFA: "TAN",
		Dial: 255,
		"ISO3166-1-Alpha-3": "TZA",
		MARC: "tz",
		is_independent: "Yes",
		"ISO3166-1-numeric": 834,
		GAUL: 257,
		FIPS: "TZ",
		WMO: "TN",
		"ISO3166-1-Alpha-2": "TZ",
		ITU: "TZA",
		IOC: "TAN",
		DS: "EAT",
		"UNTERM Spanish Formal": "la República Unida de Tanzanía",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "République-Unie de Tanzanie",
		"UNTERM French Short": "République-Unie de Tanzanie (la)",
		"ISO4217-currency_name": "Tanzanian Shilling",
		"UNTERM Russian Formal": "Объединенная Республика Танзания",
		"UNTERM English Short": "United Republic of Tanzania (the)",
		"ISO4217-currency_alphabetic_code": "TZS",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "República Unida de Tanzanía (la)",
		"ISO4217-currency_numeric_code": 834,
		"UNTERM Chinese Formal": "坦桑尼亚联合共和国",
		"UNTERM French Formal": "la République-Unie de Tanzanie",
		"UNTERM Russian Short": "Объединенная Республика Танзания",
		M49: 834,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "جمهورية تنزانيا المتحدة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية تنزانيا المتحدة",
		"UNTERM Chinese Short": "坦桑尼亚联合共和国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "República Unida de Tanzanía",
		"UNTERM English Formal": "the United Republic of Tanzania",
		official_name_cn: "坦桑尼亚联合共和国",
		official_name_en: "United Republic of Tanzania",
		"ISO4217-currency_country_name": "TANZANIA, UNITED REPUBLIC OF",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "جمهورية تنزانيا المتحدة",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Объединенная Республика Танзания",
		"Global Name": "World",
		Capital: "Dodoma",
		Continent: "AF",
		TLD: ".tz",
		Languages: "sw-TZ,en,ar",
		"Geoname ID": 149590,
		"CLDR display name": "Tanzania",
		EDGAR: "W0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q924",
	},
	{
		FIFA: "TCA",
		Dial: "1-649",
		"ISO3166-1-Alpha-3": "TCA",
		MARC: "tc",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 796,
		GAUL: 251,
		FIPS: "TK",
		WMO: "TI",
		"ISO3166-1-Alpha-2": "TC",
		ITU: "TCA",
		IOC: "TKS",
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Îles Turques-et-Caïques",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 796,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جزر تركس وكايكوس",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Islas Turcas y Caicos",
		"UNTERM English Formal": null,
		official_name_cn: "特克斯和凯科斯群岛",
		official_name_en: "Turks and Caicos Islands",
		"ISO4217-currency_country_name": "TURKS AND CAICOS ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Острова Теркс и Кайкос",
		"Global Name": "World",
		Capital: "Cockburn Town",
		Continent: null,
		TLD: ".tc",
		Languages: "en-TC",
		"Geoname ID": 3576916,
		"CLDR display name": "Kepulauan Turks dan Caicos",
		EDGAR: "W7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q18221",
	},
	{
		FIFA: "TGA",
		Dial: 676,
		"ISO3166-1-Alpha-3": "TON",
		MARC: "to",
		is_independent: "Yes",
		"ISO3166-1-numeric": 776,
		GAUL: 245,
		FIPS: "TN",
		WMO: "TO",
		"ISO3166-1-Alpha-2": "TO",
		ITU: "TON",
		IOC: "TGA",
		DS: "TO",
		"UNTERM Spanish Formal": "el Reino de Tonga",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Tonga",
		"UNTERM French Short": "Tonga (les)",
		"ISO4217-currency_name": "Pa’anga",
		"UNTERM Russian Formal": "Королевство Тонга",
		"UNTERM English Short": "Tonga",
		"ISO4217-currency_alphabetic_code": "TOP",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Tonga",
		"ISO4217-currency_numeric_code": 776,
		"UNTERM Chinese Formal": "汤加王国",
		"UNTERM French Formal": "le Royaume des Tonga",
		"UNTERM Russian Short": "Тонга",
		M49: 776,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "تونغا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة تونغا",
		"UNTERM Chinese Short": "汤加",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Tonga",
		"UNTERM English Formal": "the Kingdom of Tonga",
		official_name_cn: "汤加",
		official_name_en: "Tonga",
		"ISO4217-currency_country_name": "TONGA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "تونغا",
		"Sub-region Name": "Polynesia",
		official_name_ru: "Тонга",
		"Global Name": "World",
		Capital: "Nuku'alofa",
		Continent: "OC",
		TLD: ".to",
		Languages: "to,en-TO",
		"Geoname ID": 4032283,
		"CLDR display name": "Tonga",
		EDGAR: "W4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q678",
	},
	{
		FIFA: "THA",
		Dial: 66,
		"ISO3166-1-Alpha-3": "THA",
		MARC: "th",
		is_independent: "Yes",
		"ISO3166-1-numeric": 764,
		GAUL: 240,
		FIPS: "TH",
		WMO: "TH",
		"ISO3166-1-Alpha-2": "TH",
		ITU: "THA",
		IOC: "THA",
		DS: "T",
		"UNTERM Spanish Formal": "el Reino de Tailandia",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Thaïlande",
		"UNTERM French Short": "Thaïlande (la)",
		"ISO4217-currency_name": "Baht",
		"UNTERM Russian Formal": "Королевство Таиланд",
		"UNTERM English Short": "Thailand",
		"ISO4217-currency_alphabetic_code": "THB",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Tailandia",
		"ISO4217-currency_numeric_code": 764,
		"UNTERM Chinese Formal": "泰王国",
		"UNTERM French Formal": "le Royaume de Thaïlande",
		"UNTERM Russian Short": "Таиланд",
		M49: 764,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "تايلند",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "مملكة تايلند",
		"UNTERM Chinese Short": "泰国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Tailandia",
		"UNTERM English Formal": "the Kingdom of Thailand",
		official_name_cn: "泰国",
		official_name_en: "Thailand",
		"ISO4217-currency_country_name": "THAILAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "تايلند",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Таиланд",
		"Global Name": "World",
		Capital: "Bangkok",
		Continent: "AS",
		TLD: ".th",
		Languages: "th,en",
		"Geoname ID": 1605651,
		"CLDR display name": "Thailand",
		EDGAR: "W1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q869",
	},
	{
		FIFA: "TJK",
		Dial: 992,
		"ISO3166-1-Alpha-3": "TJK",
		MARC: "ta",
		is_independent: "Yes",
		"ISO3166-1-numeric": 762,
		GAUL: 239,
		FIPS: "TI",
		WMO: "TA",
		"ISO3166-1-Alpha-2": "TJ",
		ITU: "TJK",
		IOC: "TJK",
		DS: "TJ",
		"UNTERM Spanish Formal": "la República de Tayikistán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Tadjikistan",
		"UNTERM French Short": "Tadjikistan (le)",
		"ISO4217-currency_name": "Somoni",
		"UNTERM Russian Formal": "Республика Таджикистан",
		"UNTERM English Short": "Tajikistan",
		"ISO4217-currency_alphabetic_code": "TJS",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Tayikistán",
		"ISO4217-currency_numeric_code": 972,
		"UNTERM Chinese Formal": "塔吉克斯坦共和国",
		"UNTERM French Formal": "la République du Tadjikistan",
		"UNTERM Russian Short": "Таджикистан",
		M49: 762,
		"Sub-region Code": 143,
		"Region Code": 142,
		official_name_ar: "طاجيكستان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية طاجيكستان",
		"UNTERM Chinese Short": "塔吉克斯坦",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Tayikistán",
		"UNTERM English Formal": "the Republic of Tajikistan",
		official_name_cn: "塔吉克斯坦",
		official_name_en: "Tajikistan",
		"ISO4217-currency_country_name": "TAJIKISTAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "طاجيكستان",
		"Sub-region Name": "Central Asia",
		official_name_ru: "Таджикистан",
		"Global Name": "World",
		Capital: "Dushanbe",
		Continent: "AS",
		TLD: ".tj",
		Languages: "tg,ru",
		"Geoname ID": 1220409,
		"CLDR display name": "Tajikistan",
		EDGAR: "2D",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q863",
	},
	{
		FIFA: "TKL",
		Dial: 690,
		"ISO3166-1-Alpha-3": "TKL",
		MARC: "tl",
		is_independent: "Territory of NZ",
		"ISO3166-1-numeric": 772,
		GAUL: 244,
		FIPS: "TL",
		WMO: "TK",
		"ISO3166-1-Alpha-2": "TK",
		ITU: "TKL",
		IOC: null,
		DS: "NZ",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Tokélaou",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "New Zealand Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "NZD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 554,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 772,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "توكيلاو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Tokelau",
		"UNTERM English Formal": null,
		official_name_cn: "托克劳",
		official_name_en: "Tokelau",
		"ISO4217-currency_country_name": "TOKELAU",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Polynesia",
		official_name_ru: "Токелау",
		"Global Name": "World",
		Capital: null,
		Continent: "OC",
		TLD: ".tk",
		Languages: "tkl,en-TK",
		"Geoname ID": 4031074,
		"CLDR display name": "Tokelau",
		EDGAR: "W3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q36823",
	},
	{
		FIFA: "TKM",
		Dial: 993,
		"ISO3166-1-Alpha-3": "TKM",
		MARC: "tk",
		is_independent: "Yes",
		"ISO3166-1-numeric": 795,
		GAUL: 250,
		FIPS: "TX",
		WMO: "TR",
		"ISO3166-1-Alpha-2": "TM",
		ITU: "TKM",
		IOC: "TKM",
		DS: "TM",
		"UNTERM Spanish Formal": "Turkmenistán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Turkménistan",
		"UNTERM French Short": "Turkménistan (le)",
		"ISO4217-currency_name": "Turkmenistan New Manat",
		"UNTERM Russian Formal": "Туркменистан",
		"UNTERM English Short": "Turkmenistan",
		"ISO4217-currency_alphabetic_code": "TMT",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Turkmenistán",
		"ISO4217-currency_numeric_code": 934,
		"UNTERM Chinese Formal": "土库曼斯坦",
		"UNTERM French Formal": "le Turkménistan",
		"UNTERM Russian Short": "Туркменистан",
		M49: 795,
		"Sub-region Code": 143,
		"Region Code": 142,
		official_name_ar: "تركمانستان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "تركمانستان",
		"UNTERM Chinese Short": "土库曼斯坦",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Turkmenistán",
		"UNTERM English Formal": "Turkmenistan",
		official_name_cn: "土库曼斯坦",
		official_name_en: "Turkmenistan",
		"ISO4217-currency_country_name": "TURKMENISTAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "تركمانستان",
		"Sub-region Name": "Central Asia",
		official_name_ru: "Туркменистан",
		"Global Name": "World",
		Capital: "Ashgabat",
		Continent: "AS",
		TLD: ".tm",
		Languages: "tk,ru,uz",
		"Geoname ID": 1218197,
		"CLDR display name": "Turkmenistan",
		EDGAR: "2E",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q874",
	},
	{
		FIFA: "TLS",
		Dial: 670,
		"ISO3166-1-Alpha-3": "TLS",
		MARC: "em",
		is_independent: "Yes",
		"ISO3166-1-numeric": 626,
		GAUL: 242,
		FIPS: "TT",
		WMO: "TM",
		"ISO3166-1-Alpha-2": "TL",
		ITU: "TLS",
		IOC: "TLS",
		DS: "RI",
		"UNTERM Spanish Formal": "la República Democrática de Timor-Leste",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Timor-Leste",
		"UNTERM French Short": "Timor-Leste (le)",
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": "Демократическая Республика Тимор-Лешти",
		"UNTERM English Short": "Timor-Leste",
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Timor-Leste",
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": "东帝汶民主共和国",
		"UNTERM French Formal": "la République démocratique du Timor-Leste",
		"UNTERM Russian Short": "Тимор-Лешти",
		M49: 626,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "تيمور - ليشتي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية تيمور - ليشتي الديمقراطية",
		"UNTERM Chinese Short": "东帝汶",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Timor-Leste",
		"UNTERM English Formal": "the Democratic Republic of Timor-Leste",
		official_name_cn: "东帝汶",
		official_name_en: "Timor-Leste",
		"ISO4217-currency_country_name": "TIMOR-LESTE",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Asia",
		"UNTERM Arabic Short": "تيمور- ليشتي",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Тимор-Лешти",
		"Global Name": "World",
		Capital: "Dili",
		Continent: "OC",
		TLD: ".tl",
		Languages: "tet,pt-TL,id,en",
		"Geoname ID": 1966436,
		"CLDR display name": "Timor-Leste",
		EDGAR: "Z3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q574",
	},
	{
		FIFA: "TOG",
		Dial: 228,
		"ISO3166-1-Alpha-3": "TGO",
		MARC: "tg",
		is_independent: "Yes",
		"ISO3166-1-numeric": 768,
		GAUL: 243,
		FIPS: "TO",
		WMO: "TG",
		"ISO3166-1-Alpha-2": "TG",
		ITU: "TGO",
		IOC: "TOG",
		DS: "TG",
		"UNTERM Spanish Formal": "la República Togolesa",
		"Global Code": 1,
		"Intermediate Region Code": 11,
		official_name_fr: "Togo",
		"UNTERM French Short": "Togo (le)",
		"ISO4217-currency_name": "CFA Franc BCEAO",
		"UNTERM Russian Formal": "Тоголезская Республика",
		"UNTERM English Short": "Togo",
		"ISO4217-currency_alphabetic_code": "XOF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Togo (el)",
		"ISO4217-currency_numeric_code": 952,
		"UNTERM Chinese Formal": "多哥共和国",
		"UNTERM French Formal": "la République togolaise",
		"UNTERM Russian Short": "Того",
		M49: 768,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "توغو",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية توغو",
		"UNTERM Chinese Short": "多哥",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Western Africa",
		official_name_es: "Togo",
		"UNTERM English Formal": "the Togolese Republic",
		official_name_cn: "多哥",
		official_name_en: "Togo",
		"ISO4217-currency_country_name": "TOGO",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "توغو",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Того",
		"Global Name": "World",
		Capital: "Lome",
		Continent: "AF",
		TLD: ".tg",
		Languages: "fr-TG,ee,hna,kbp,dag,ha",
		"Geoname ID": 2363686,
		"CLDR display name": "Togo",
		EDGAR: "W2",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q945",
	},
	{
		FIFA: "TPE",
		Dial: 886,
		"ISO3166-1-Alpha-3": "TWN",
		MARC: "ch",
		is_independent: "Yes",
		"ISO3166-1-numeric": 158,
		GAUL: 925,
		FIPS: "TW",
		WMO: null,
		"ISO3166-1-Alpha-2": "TW",
		ITU: null,
		IOC: "TPE",
		DS: "RC",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Taïwan",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "New Taiwan Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "TWD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 901,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 158,
		"Sub-region Code": 30,
		"Region Code": 142,
		official_name_ar: "تايوان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Taiwán",
		"UNTERM English Formal": null,
		official_name_cn: "台湾",
		official_name_en: "Taiwan",
		"ISO4217-currency_country_name": "TAIWAN (PROVINCE OF CHINA)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Eastern Asia",
		official_name_ru: "Тайвань",
		"Global Name": "World",
		Capital: "Taipei",
		Continent: "AS",
		TLD: ".tw",
		Languages: "zh-TW,zh,nan,hak",
		"Geoname ID": 1668284,
		"CLDR display name": "Taiwan",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q865",
	},
	{
		FIFA: "TRI",
		Dial: "1-868",
		"ISO3166-1-Alpha-3": "TTO",
		MARC: "tr",
		is_independent: "Yes",
		"ISO3166-1-numeric": 780,
		GAUL: 246,
		FIPS: "TD",
		WMO: "TD",
		"ISO3166-1-Alpha-2": "TT",
		ITU: "TRD",
		IOC: "TTO",
		DS: "TT",
		"UNTERM Spanish Formal": "la República de Trinidad y Tabago",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Trinité-et-Tobago",
		"UNTERM French Short": "Trinité-et-Tobago (la)",
		"ISO4217-currency_name": "Trinidad and Tobago Dollar",
		"UNTERM Russian Formal": "Республика Тринидад и Тобаго",
		"UNTERM English Short": "Trinidad and Tobago",
		"ISO4217-currency_alphabetic_code": "TTD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Trinidad y Tabago",
		"ISO4217-currency_numeric_code": 780,
		"UNTERM Chinese Formal": "特立尼达和多巴哥共和国",
		"UNTERM French Formal": "la République de Trinité-et-Tobago",
		"UNTERM Russian Short": "Тринидад и Тобаго",
		M49: 780,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "ترينيداد وتوباغو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية ترينيداد وتوباغو",
		"UNTERM Chinese Short": "特立尼达和多巴哥",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Trinidad y Tabago",
		"UNTERM English Formal": "the Republic of Trinidad and Tobago",
		official_name_cn: "特立尼达和多巴哥",
		official_name_en: "Trinidad and Tobago",
		"ISO4217-currency_country_name": "TRINIDAD AND TOBAGO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "ترينيداد وتوباغو",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Тринидад и Тобаго",
		"Global Name": "World",
		Capital: "Port of Spain",
		Continent: null,
		TLD: ".tt",
		Languages: "en-TT,hns,fr,es,zh",
		"Geoname ID": 3573591,
		"CLDR display name": "Trinidad dan Tobago",
		EDGAR: "W5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q754",
	},
	{
		FIFA: "TUN",
		Dial: 216,
		"ISO3166-1-Alpha-3": "TUN",
		MARC: "ti",
		is_independent: "Yes",
		"ISO3166-1-numeric": 788,
		GAUL: 248,
		FIPS: "TS",
		WMO: "TS",
		"ISO3166-1-Alpha-2": "TN",
		ITU: "TUN",
		IOC: "TUN",
		DS: "TN",
		"UNTERM Spanish Formal": "la República de Túnez",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Tunisie",
		"UNTERM French Short": "Tunisie (la)",
		"ISO4217-currency_name": "Tunisian Dinar",
		"UNTERM Russian Formal": "Тунисская Республика",
		"UNTERM English Short": "Tunisia",
		"ISO4217-currency_alphabetic_code": "TND",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Túnez",
		"ISO4217-currency_numeric_code": 788,
		"UNTERM Chinese Formal": "突尼斯共和国",
		"UNTERM French Formal": "la République tunisienne",
		"UNTERM Russian Short": "Тунис",
		M49: 788,
		"Sub-region Code": 15,
		"Region Code": 2,
		official_name_ar: "تونس",
		"ISO4217-currency_minor_unit": 3,
		"UNTERM Arabic Formal": "الجمهورية التونسية",
		"UNTERM Chinese Short": "突尼斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Túnez",
		"UNTERM English Formal": "the Republic of Tunisia",
		official_name_cn: "突尼斯",
		official_name_en: "Tunisia",
		"ISO4217-currency_country_name": "TUNISIA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "تونس",
		"Sub-region Name": "Northern Africa",
		official_name_ru: "Тунис",
		"Global Name": "World",
		Capital: "Tunis",
		Continent: "AF",
		TLD: ".tn",
		Languages: "ar-TN,fr",
		"Geoname ID": 2464461,
		"CLDR display name": "Tunisia",
		EDGAR: "W6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q948",
	},
	{
		FIFA: "TUR",
		Dial: 90,
		"ISO3166-1-Alpha-3": "TUR",
		MARC: "tu",
		is_independent: "Yes",
		"ISO3166-1-numeric": 792,
		GAUL: 249,
		FIPS: "TU",
		WMO: "TU",
		"ISO3166-1-Alpha-2": "TR",
		ITU: "TUR",
		IOC: "TUR",
		DS: "TR",
		"UNTERM Spanish Formal": "la República de Turquía",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Türkiye",
		"UNTERM French Short": "Turquie (la)",
		"ISO4217-currency_name": "Turkish Lira",
		"UNTERM Russian Formal": "Турецкая Республика",
		"UNTERM English Short": "Turkey",
		"ISO4217-currency_alphabetic_code": "TRY",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Turquía",
		"ISO4217-currency_numeric_code": 949,
		"UNTERM Chinese Formal": "土耳其共和国",
		"UNTERM French Formal": "la République turque",
		"UNTERM Russian Short": "Турция",
		M49: 792,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "تركيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية تركيا",
		"UNTERM Chinese Short": "土耳其",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Türkiye",
		"UNTERM English Formal": "the Republic of Turkey",
		official_name_cn: "土耳其",
		official_name_en: "Turkey",
		"ISO4217-currency_country_name": "TÜRKİYE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "تركيا",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Турция",
		"Global Name": "World",
		Capital: "Ankara",
		Continent: "AS",
		TLD: ".tr",
		Languages: "tr-TR,ku,diq,az,av",
		"Geoname ID": 298795,
		"CLDR display name": "Turkiye",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q43",
	},
	{
		FIFA: "TUV",
		Dial: 688,
		"ISO3166-1-Alpha-3": "TUV",
		MARC: "tv",
		is_independent: "Yes",
		"ISO3166-1-numeric": 798,
		GAUL: 252,
		FIPS: "TV",
		WMO: "TV",
		"ISO3166-1-Alpha-2": "TV",
		ITU: "TUV",
		IOC: "TUV",
		DS: "TV",
		"UNTERM Spanish Formal": "Tuvalu",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Tuvalu",
		"UNTERM French Short": "Tuvalu (les)",
		"ISO4217-currency_name": "Australian Dollar",
		"UNTERM Russian Formal": "Тувалу",
		"UNTERM English Short": "Tuvalu",
		"ISO4217-currency_alphabetic_code": "AUD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Tuvalu",
		"ISO4217-currency_numeric_code": 36,
		"UNTERM Chinese Formal": "图瓦卢",
		"UNTERM French Formal": "les Tuvalu",
		"UNTERM Russian Short": "Тувалу",
		M49: 798,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "توفالو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "توفالو",
		"UNTERM Chinese Short": "图瓦卢",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Tuvalu",
		"UNTERM English Formal": "Tuvalu",
		official_name_cn: "图瓦卢",
		official_name_en: "Tuvalu",
		"ISO4217-currency_country_name": "TUVALU",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "توفالو",
		"Sub-region Name": "Polynesia",
		official_name_ru: "Тувалу",
		"Global Name": "World",
		Capital: "Funafuti",
		Continent: "OC",
		TLD: ".tv",
		Languages: "tvl,en,sm,gil",
		"Geoname ID": 2110297,
		"CLDR display name": "Tuvalu",
		EDGAR: "2G",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q672",
	},
	{
		FIFA: "UAE",
		Dial: 971,
		"ISO3166-1-Alpha-3": "ARE",
		MARC: "ts",
		is_independent: "Yes",
		"ISO3166-1-numeric": 784,
		GAUL: 255,
		FIPS: "AE",
		WMO: "ER",
		"ISO3166-1-Alpha-2": "AE",
		ITU: "UAE",
		IOC: "UAE",
		DS: null,
		"UNTERM Spanish Formal": "los Emiratos Árabes Unidos",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Émirats arabes unis",
		"UNTERM French Short": "Émirats arabes unis (les)",
		"ISO4217-currency_name": "UAE Dirham",
		"UNTERM Russian Formal": "Объединенные Арабские Эмираты",
		"UNTERM English Short": "United Arab Emirates (the)",
		"ISO4217-currency_alphabetic_code": "AED",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Emiratos Árabes Unidos (los)",
		"ISO4217-currency_numeric_code": 784,
		"UNTERM Chinese Formal": "阿拉伯联合酋长国",
		"UNTERM French Formal": "les Émirats arabes unis",
		"UNTERM Russian Short": "Объединенные Арабские Эмираты",
		M49: 784,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "الإمارات العربية المتحدة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الإمارات العربية المتحدة",
		"UNTERM Chinese Short": "阿拉伯联合酋长国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Emiratos Árabes Unidos",
		"UNTERM English Formal": "the United Arab Emirates",
		official_name_cn: "阿拉伯联合酋长国",
		official_name_en: "United Arab Emirates",
		"ISO4217-currency_country_name": "UNITED ARAB EMIRATES",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "الإمارات العربية المتحدة",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Объединенные Арабские Эмираты",
		"Global Name": "World",
		Capital: "Abu Dhabi",
		Continent: "AS",
		TLD: ".ae",
		Languages: "ar-AE,fa,en,hi,ur",
		"Geoname ID": 290557,
		"CLDR display name": "Emiriah Arab Bersatu",
		EDGAR: "C0",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q878",
	},
	{
		FIFA: "UGA",
		Dial: 256,
		"ISO3166-1-Alpha-3": "UGA",
		MARC: "ug",
		is_independent: "Yes",
		"ISO3166-1-numeric": 800,
		GAUL: 253,
		FIPS: "UG",
		WMO: "UG",
		"ISO3166-1-Alpha-2": "UG",
		ITU: "UGA",
		IOC: "UGA",
		DS: "EAU",
		"UNTERM Spanish Formal": "la República de Uganda",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Ouganda",
		"UNTERM French Short": "Ouganda (l')",
		"ISO4217-currency_name": "Uganda Shilling",
		"UNTERM Russian Formal": "Республика Уганда",
		"UNTERM English Short": "Uganda",
		"ISO4217-currency_alphabetic_code": "UGX",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Uganda",
		"ISO4217-currency_numeric_code": 800,
		"UNTERM Chinese Formal": "乌干达共和国",
		"UNTERM French Formal": "la République de l'Ouganda",
		"UNTERM Russian Short": "Уганда",
		M49: 800,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "أوغندا",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية أوغندا",
		"UNTERM Chinese Short": "乌干达",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Uganda",
		"UNTERM English Formal": "the Republic of Uganda",
		official_name_cn: "乌干达",
		official_name_en: "Uganda",
		"ISO4217-currency_country_name": "UGANDA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "أوغندا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Уганда",
		"Global Name": "World",
		Capital: "Kampala",
		Continent: "AF",
		TLD: ".ug",
		Languages: "en-UG,lg,sw,ar",
		"Geoname ID": 226074,
		"CLDR display name": "Uganda",
		EDGAR: "W9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q1036",
	},
	{
		FIFA: "UKR",
		Dial: 380,
		"ISO3166-1-Alpha-3": "UKR",
		MARC: "un",
		is_independent: "Yes",
		"ISO3166-1-numeric": 804,
		GAUL: 254,
		FIPS: "UP",
		WMO: "UR",
		"ISO3166-1-Alpha-2": "UA",
		ITU: "UKR",
		IOC: "UKR",
		DS: "UA",
		"UNTERM Spanish Formal": "Ucrania",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Ukraine",
		"UNTERM French Short": "Ukraine (l')",
		"ISO4217-currency_name": "Hryvnia",
		"UNTERM Russian Formal": "Украина",
		"UNTERM English Short": "Ukraine",
		"ISO4217-currency_alphabetic_code": "UAH",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Ucrania",
		"ISO4217-currency_numeric_code": 980,
		"UNTERM Chinese Formal": "乌克兰",
		"UNTERM French Formal": "l'Ukraine",
		"UNTERM Russian Short": "Украина",
		M49: 804,
		"Sub-region Code": 151,
		"Region Code": 150,
		official_name_ar: "أوكرانيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "أوكرانيا",
		"UNTERM Chinese Short": "乌克兰",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Ucrania",
		"UNTERM English Formal": "Ukraine",
		official_name_cn: "乌克兰",
		official_name_en: "Ukraine",
		"ISO4217-currency_country_name": "UKRAINE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "أوكرانيا",
		"Sub-region Name": "Eastern Europe",
		official_name_ru: "Украина",
		"Global Name": "World",
		Capital: "Kyiv",
		Continent: "EU",
		TLD: ".ua",
		Languages: "uk,ru-UA,rom,pl,hu",
		"Geoname ID": 690791,
		"CLDR display name": "Ukraine",
		EDGAR: "2H",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q212",
	},
	{
		FIFA: "URU",
		Dial: 598,
		"ISO3166-1-Alpha-3": "URY",
		MARC: "uy",
		is_independent: "Yes",
		"ISO3166-1-numeric": 858,
		GAUL: 260,
		FIPS: "UY",
		WMO: "UY",
		"ISO3166-1-Alpha-2": "UY",
		ITU: "URG",
		IOC: "URU",
		DS: "ROU",
		"UNTERM Spanish Formal": "la República Oriental del Uruguay",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Uruguay",
		"UNTERM French Short": "Uruguay (l')",
		"ISO4217-currency_name": "Peso Uruguayo,Unidad Previsional",
		"UNTERM Russian Formal": "Восточная Республика Уругвай",
		"UNTERM English Short": "Uruguay",
		"ISO4217-currency_alphabetic_code": "UYU,UYW",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Uruguay (el)",
		"ISO4217-currency_numeric_code": "858,927",
		"UNTERM Chinese Formal": "乌拉圭东岸共和国",
		"UNTERM French Formal": "la République orientale de l'Uruguay",
		"UNTERM Russian Short": "Уругвай",
		M49: 858,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "أوروغواي",
		"ISO4217-currency_minor_unit": "2,4",
		"UNTERM Arabic Formal": "جمهورية أوروغواي الشرقية",
		"UNTERM Chinese Short": "乌拉圭",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Uruguay",
		"UNTERM English Formal": "the Eastern Republic of Uruguay",
		official_name_cn: "乌拉圭",
		official_name_en: "Uruguay",
		"ISO4217-currency_country_name": "URUGUAY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "أوروغواي",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Уругвай",
		"Global Name": "World",
		Capital: "Montevideo",
		Continent: "SA",
		TLD: ".uy",
		Languages: "es-UY",
		"Geoname ID": 3439705,
		"CLDR display name": "Uruguay",
		EDGAR: "X3",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q77",
	},
	{
		FIFA: "USA",
		Dial: 1,
		"ISO3166-1-Alpha-3": "USA",
		MARC: "xxu",
		is_independent: "Yes",
		"ISO3166-1-numeric": 840,
		GAUL: 259,
		FIPS: "US",
		WMO: "US",
		"ISO3166-1-Alpha-2": "US",
		ITU: "USA",
		IOC: "USA",
		DS: "USA",
		"UNTERM Spanish Formal": "los Estados Unidos de América",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "États-Unis d’Amérique",
		"UNTERM French Short": "États-Unis d'Amérique (les)",
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": "Соединенные Штаты Америки",
		"UNTERM English Short": "United States of America (the)",
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Estados Unidos de América (los)",
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": "美利坚合众国",
		"UNTERM French Formal": "les États-Unis d'Amérique",
		"UNTERM Russian Short": "Соединенные Штаты Америки",
		M49: 840,
		"Sub-region Code": 21,
		"Region Code": 19,
		official_name_ar: "الولايات المتحدة الأمريكية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الولايات المتحدة الأمريكية",
		"UNTERM Chinese Short": "美利坚合众国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Estados Unidos de América",
		"UNTERM English Formal": "the United States of America",
		official_name_cn: "美利坚合众国",
		official_name_en: "United States of America",
		"ISO4217-currency_country_name": "UNITED STATES OF AMERICA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "الولايات المتحدة الأمريكية",
		"Sub-region Name": "Northern America",
		official_name_ru: "Соединенные Штаты Америки",
		"Global Name": "World",
		Capital: "Washington",
		Continent: null,
		TLD: ".us",
		Languages: "en-US,es-US,haw,fr",
		"Geoname ID": 6252001,
		"CLDR display name": "A.S",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q30",
	},
	{
		FIFA: "UZB",
		Dial: 998,
		"ISO3166-1-Alpha-3": "UZB",
		MARC: "uz",
		is_independent: "Yes",
		"ISO3166-1-numeric": 860,
		GAUL: 261,
		FIPS: "UZ",
		WMO: "UZ",
		"ISO3166-1-Alpha-2": "UZ",
		ITU: "UZB",
		IOC: "UZB",
		DS: "UZ",
		"UNTERM Spanish Formal": "la República de Uzbekistán",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Ouzbékistan",
		"UNTERM French Short": "Ouzbékistan (l')",
		"ISO4217-currency_name": "Uzbekistan Sum",
		"UNTERM Russian Formal": "Республика Узбекистан",
		"UNTERM English Short": "Uzbekistan",
		"ISO4217-currency_alphabetic_code": "UZS",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Uzbekistán",
		"ISO4217-currency_numeric_code": 860,
		"UNTERM Chinese Formal": "乌兹别克斯坦共和国",
		"UNTERM French Formal": "la République d'Ouzbékistan",
		"UNTERM Russian Short": "Узбекистан",
		M49: 860,
		"Sub-region Code": 143,
		"Region Code": 142,
		official_name_ar: "أوزبكستان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية أوزبكستان",
		"UNTERM Chinese Short": "乌兹别克斯坦",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": null,
		official_name_es: "Uzbekistán",
		"UNTERM English Formal": "the Republic of Uzbekistan",
		official_name_cn: "乌兹别克斯坦",
		official_name_en: "Uzbekistan",
		"ISO4217-currency_country_name": "UZBEKISTAN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "أوزبكستان",
		"Sub-region Name": "Central Asia",
		official_name_ru: "Узбекистан",
		"Global Name": "World",
		Capital: "Tashkent",
		Continent: "AS",
		TLD: ".uz",
		Languages: "uz,ru,tg",
		"Geoname ID": 1512440,
		"CLDR display name": "Uzbekistan",
		EDGAR: "2K",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q265",
	},
	{
		FIFA: "VAN",
		Dial: 678,
		"ISO3166-1-Alpha-3": "VUT",
		MARC: "nn",
		is_independent: "Yes",
		"ISO3166-1-numeric": 548,
		GAUL: 262,
		FIPS: "NH",
		WMO: "NV",
		"ISO3166-1-Alpha-2": "VU",
		ITU: "VUT",
		IOC: "VAN",
		DS: "VU",
		"UNTERM Spanish Formal": "la República de Vanuatu",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Vanuatu",
		"UNTERM French Short": "Vanuatu",
		"ISO4217-currency_name": "Vatu",
		"UNTERM Russian Formal": "Республика Вануату",
		"UNTERM English Short": "Vanuatu",
		"ISO4217-currency_alphabetic_code": "VUV",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "Vanuatu",
		"ISO4217-currency_numeric_code": 548,
		"UNTERM Chinese Formal": "瓦努阿图共和国",
		"UNTERM French Formal": "la République de Vanuatu",
		"UNTERM Russian Short": "Вануату",
		M49: 548,
		"Sub-region Code": 54,
		"Region Code": 9,
		official_name_ar: "فانواتو",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية فانواتو",
		"UNTERM Chinese Short": "瓦努阿图",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Vanuatu",
		"UNTERM English Formal": "the Republic of Vanuatu",
		official_name_cn: "瓦努阿图",
		official_name_en: "Vanuatu",
		"ISO4217-currency_country_name": "VANUATU",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": "فانواتو",
		"Sub-region Name": "Melanesia",
		official_name_ru: "Вануату",
		"Global Name": "World",
		Capital: "Port Vila",
		Continent: "OC",
		TLD: ".vu",
		Languages: "bi,en-VU,fr-VU",
		"Geoname ID": 2134431,
		"CLDR display name": "Vanuatu",
		EDGAR: "2L",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q686",
	},
	{
		FIFA: "VAT",
		Dial: "39-06",
		"ISO3166-1-Alpha-3": "VAT",
		MARC: "vc",
		is_independent: "Yes",
		"ISO3166-1-numeric": 336,
		GAUL: 110,
		FIPS: "VT",
		WMO: null,
		"ISO3166-1-Alpha-2": "VA",
		ITU: "CVA",
		IOC: null,
		DS: "V",
		"UNTERM Spanish Formal": "la Santa Sede *",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Saint-Siège",
		"UNTERM French Short": "Saint-Siège (le) *",
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": "Святой Престол *",
		"UNTERM English Short": "Holy See (the) *",
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Santa Sede (la) *",
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": "罗马教廷 *",
		"UNTERM French Formal": "le Saint-Siège *",
		"UNTERM Russian Short": "Святой Престол *",
		M49: 336,
		"Sub-region Code": 39,
		"Region Code": 150,
		official_name_ar: "الكرسي الرسولي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الكرسي الرسولي *",
		"UNTERM Chinese Short": "罗马教廷 *",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Santa Sede",
		"UNTERM English Formal": "the Holy See *",
		official_name_cn: "教廷",
		official_name_en: "Holy See",
		"ISO4217-currency_country_name": "HOLY SEE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "الكرسي الرسولي *",
		"Sub-region Name": "Southern Europe",
		official_name_ru: "Святой Престол",
		"Global Name": "World",
		Capital: "Vatican City",
		Continent: "EU",
		TLD: ".va",
		Languages: "la,it,fr",
		"Geoname ID": 3164670,
		"CLDR display name": "Kota Vatican",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q237",
	},
	{
		FIFA: "VEN",
		Dial: 58,
		"ISO3166-1-Alpha-3": "VEN",
		MARC: "ve",
		is_independent: "Yes",
		"ISO3166-1-numeric": 862,
		GAUL: 263,
		FIPS: "VE",
		WMO: "VN",
		"ISO3166-1-Alpha-2": "VE",
		ITU: "VEN",
		IOC: "VEN",
		DS: "YV",
		"UNTERM Spanish Formal": "la República Bolivariana de Venezuela",
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Venezuela (République bolivarienne du)",
		"UNTERM French Short": "Venezuela (République bolivarienne du)",
		"ISO4217-currency_name": "Bolívar Soberano,Bolívar Soberano",
		"UNTERM Russian Formal": "Боливарианская Республика Венесуэла",
		"UNTERM English Short": "Venezuela (Bolivarian Republic of)",
		"ISO4217-currency_alphabetic_code": "VES,VED",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Venezuela (República Bolivariana de)",
		"ISO4217-currency_numeric_code": "928,926",
		"UNTERM Chinese Formal": "委内瑞拉玻利瓦尔共和国",
		"UNTERM French Formal": "la République bolivarienne du Venezuela",
		"UNTERM Russian Short": "Венесуэла (Боливарианская Республика)",
		M49: 862,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "فنزويلا (جمهورية - البوليفارية)",
		"ISO4217-currency_minor_unit": "2,2",
		"UNTERM Arabic Formal": "جمهورية فنزويلا البوليفارية",
		"UNTERM Chinese Short": "委内瑞拉玻利瓦尔共和国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Venezuela (República Bolivariana de)",
		"UNTERM English Formal": "the Bolivarian Republic of Venezuela",
		official_name_cn: "委内瑞拉玻利瓦尔共和国",
		official_name_en: "Venezuela (Bolivarian Republic of)",
		"ISO4217-currency_country_name": "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "فنزويلا (جمهورية - البوليفارية)",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Венесуэла (Боливарианская Республика)",
		"Global Name": "World",
		Capital: "Caracas",
		Continent: "SA",
		TLD: ".ve",
		Languages: "es-VE",
		"Geoname ID": 3625428,
		"CLDR display name": "Venezuela",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q717",
	},
	{
		FIFA: "VGB",
		Dial: "1-284",
		"ISO3166-1-Alpha-3": "VGB",
		MARC: "vb",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 92,
		GAUL: 39,
		FIPS: "VI",
		WMO: "VI",
		"ISO3166-1-Alpha-2": "VG",
		ITU: "VRG",
		IOC: "IVB",
		DS: "BVI",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Îles Vierges britanniques",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 92,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جزر فرجن البريطانية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Islas Vírgenes Británicas",
		"UNTERM English Formal": null,
		official_name_cn: "英属维尔京群岛",
		official_name_en: "British Virgin Islands",
		"ISO4217-currency_country_name": "VIRGIN ISLANDS (BRITISH)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Британские Виргинские острова",
		"Global Name": "World",
		Capital: "Road Town",
		Continent: null,
		TLD: ".vg",
		Languages: "en-VG",
		"Geoname ID": 3577718,
		"CLDR display name": "Kepulauan Virgin British",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q25305",
	},
	{
		FIFA: "VIE",
		Dial: 84,
		"ISO3166-1-Alpha-3": "VNM",
		MARC: "vm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 704,
		GAUL: 264,
		FIPS: "VM",
		WMO: "VS",
		"ISO3166-1-Alpha-2": "VN",
		ITU: "VTN",
		IOC: "VIE",
		DS: "VN",
		"UNTERM Spanish Formal": "la República Socialista de Viet Nam",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Viet Nam",
		"UNTERM French Short": "Viet Nam (le)",
		"ISO4217-currency_name": "Dong",
		"UNTERM Russian Formal": "Социалистическая Республика Вьетнам",
		"UNTERM English Short": "Viet Nam",
		"ISO4217-currency_alphabetic_code": "VND",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Viet Nam",
		"ISO4217-currency_numeric_code": 704,
		"UNTERM Chinese Formal": "越南社会主义共和国",
		"UNTERM French Formal": "la République socialiste du Viet Nam",
		"UNTERM Russian Short": "Вьетнам",
		M49: 704,
		"Sub-region Code": 35,
		"Region Code": 142,
		official_name_ar: "فييت نام",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": "جمهورية فييت نام الاشتراكية",
		"UNTERM Chinese Short": "越南",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Viet Nam",
		"UNTERM English Formal": "the Socialist Republic of Viet Nam",
		official_name_cn: "越南",
		official_name_en: "Viet Nam",
		"ISO4217-currency_country_name": "VIET NAM",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Asia",
		"UNTERM Arabic Short": "فييت نام",
		"Sub-region Name": "South-eastern Asia",
		official_name_ru: "Вьетнам",
		"Global Name": "World",
		Capital: "Hanoi",
		Continent: "AS",
		TLD: ".vn",
		Languages: "vi,en,fr,zh,km",
		"Geoname ID": 1562822,
		"CLDR display name": "Vietnam",
		EDGAR: "Q1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q881",
	},
	{
		FIFA: "VIN",
		Dial: "1-784",
		"ISO3166-1-Alpha-3": "VCT",
		MARC: "xm",
		is_independent: "Yes",
		"ISO3166-1-numeric": 670,
		GAUL: 211,
		FIPS: "VC",
		WMO: "VG",
		"ISO3166-1-Alpha-2": "VC",
		ITU: "VCT",
		IOC: "VIN",
		DS: "WV",
		"UNTERM Spanish Formal": "San Vicente y las Granadinas",
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Saint-Vincent-et-les Grenadines",
		"UNTERM French Short": "Saint-Vincent-et-les Grenadines",
		"ISO4217-currency_name": "East Caribbean Dollar",
		"UNTERM Russian Formal": "Сент-Винсент и Гренадины",
		"UNTERM English Short": "Saint Vincent and the Grenadines",
		"ISO4217-currency_alphabetic_code": "XCD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": "San Vicente y las Granadinas",
		"ISO4217-currency_numeric_code": 951,
		"UNTERM Chinese Formal": "圣文森特和格林纳丁斯",
		"UNTERM French Formal": "Saint-Vincent-et-les Grenadines",
		"UNTERM Russian Short": "Сент-Винсент и Гренадины",
		M49: 670,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "سانت فنسنت وجزر غرينادين",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "سانت فنسنت وجزر غرينادين",
		"UNTERM Chinese Short": "圣文森特和格林纳丁斯",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "San Vicente y las Granadinas",
		"UNTERM English Formal": "Saint Vincent and the Grenadines",
		official_name_cn: "圣文森特和格林纳丁斯",
		official_name_en: "Saint Vincent and the Grenadines",
		"ISO4217-currency_country_name": "SAINT VINCENT AND THE GRENADINES",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": "سانت فنسنت وجزر غرينادين",
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Сент-Винсент и Гренадины",
		"Global Name": "World",
		Capital: "Kingstown",
		Continent: null,
		TLD: ".vc",
		Languages: "en-VC,fr",
		"Geoname ID": 3577815,
		"CLDR display name": "Saint Vincent dan Grenadines",
		EDGAR: "V1",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q757",
	},
	{
		FIFA: "VIR",
		Dial: "1-340",
		"ISO3166-1-Alpha-3": "VIR",
		MARC: "vi",
		is_independent: "Territory of US",
		"ISO3166-1-numeric": 850,
		GAUL: 258,
		FIPS: "VQ",
		WMO: "VI",
		"ISO3166-1-Alpha-2": "VI",
		ITU: "VIR",
		IOC: "ISV",
		DS: "USA",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Îles Vierges américaines",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 850,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جزر فرجن التابعة للولايات المتحدة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Islas Vírgenes de los Estados Unidos",
		"UNTERM English Formal": null,
		official_name_cn: "美属维尔京群岛",
		official_name_en: "United States Virgin Islands",
		"ISO4217-currency_country_name": "VIRGIN ISLANDS (U.S.)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Виргинские острова Соединенных Штатов",
		"Global Name": "World",
		Capital: "Charlotte Amalie",
		Continent: null,
		TLD: ".vi",
		Languages: "en-VI",
		"Geoname ID": 4796775,
		"CLDR display name": "Kepulauan Virgin A.S.",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q11703",
	},
	{
		FIFA: "WLF",
		Dial: 681,
		"ISO3166-1-Alpha-3": "WLF",
		MARC: "wf",
		is_independent: "Territory of FR",
		"ISO3166-1-numeric": 876,
		GAUL: 266,
		FIPS: "WF",
		WMO: "FW",
		"ISO3166-1-Alpha-2": "WF",
		ITU: "WAL",
		IOC: "WAF",
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles Wallis-et-Futuna",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "CFP Franc",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "XPF",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 953,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 876,
		"Sub-region Code": 61,
		"Region Code": 9,
		official_name_ar: "جزر واليس وفوتونا",
		"ISO4217-currency_minor_unit": 0,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Wallis y Futuna",
		"UNTERM English Formal": null,
		official_name_cn: "瓦利斯群岛和富图纳群岛",
		official_name_en: "Wallis and Futuna Islands",
		"ISO4217-currency_country_name": "WALLIS AND FUTUNA",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Polynesia",
		official_name_ru: "Острова Уоллис и Футуна",
		"Global Name": "World",
		Capital: "Mata Utu",
		Continent: "OC",
		TLD: ".wf",
		Languages: "wls,fud,fr-WF",
		"Geoname ID": 4034749,
		"CLDR display name": "Wallis dan Futuna",
		EDGAR: "X8",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q35555",
	},
	{
		FIFA: "YEM",
		Dial: 967,
		"ISO3166-1-Alpha-3": "YEM",
		MARC: "ye",
		is_independent: "Yes",
		"ISO3166-1-numeric": 887,
		GAUL: 269,
		FIPS: "YM",
		WMO: "YE",
		"ISO3166-1-Alpha-2": "YE",
		ITU: "YEM",
		IOC: "YEM",
		DS: "YAR",
		"UNTERM Spanish Formal": "la República del Yemen",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Yémen",
		"UNTERM French Short": "Yémen (le)",
		"ISO4217-currency_name": "Yemeni Rial",
		"UNTERM Russian Formal": "Йеменская Республика",
		"UNTERM English Short": "Yemen",
		"ISO4217-currency_alphabetic_code": "YER",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Yemen (el)",
		"ISO4217-currency_numeric_code": 886,
		"UNTERM Chinese Formal": "也门共和国",
		"UNTERM French Formal": "la République du Yémen",
		"UNTERM Russian Short": "Йемен",
		M49: 887,
		"Sub-region Code": 145,
		"Region Code": 142,
		official_name_ar: "اليمن",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "الجمهورية اليمنية",
		"UNTERM Chinese Short": "也门",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Yemen",
		"UNTERM English Formal": "the Republic of Yemen",
		official_name_cn: "也门",
		official_name_en: "Yemen",
		"ISO4217-currency_country_name": "YEMEN",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Asia",
		"UNTERM Arabic Short": "اليمن",
		"Sub-region Name": "Western Asia",
		official_name_ru: "Йемен",
		"Global Name": "World",
		Capital: "Sanaa",
		Continent: "AS",
		TLD: ".ye",
		Languages: "ar-YE",
		"Geoname ID": 69543,
		"CLDR display name": "Yaman",
		EDGAR: "T7",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q805",
	},
	{
		FIFA: "ZAM",
		Dial: 260,
		"ISO3166-1-Alpha-3": "ZMB",
		MARC: "za",
		is_independent: "Yes",
		"ISO3166-1-numeric": 894,
		GAUL: 270,
		FIPS: "ZA",
		WMO: "ZB",
		"ISO3166-1-Alpha-2": "ZM",
		ITU: "ZMB",
		IOC: "ZAM",
		DS: "Z",
		"UNTERM Spanish Formal": "la República de Zambia",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Zambie",
		"UNTERM French Short": "Zambie (la)",
		"ISO4217-currency_name": "Zambian Kwacha",
		"UNTERM Russian Formal": "Республика Замбия",
		"UNTERM English Short": "Zambia",
		"ISO4217-currency_alphabetic_code": "ZMW",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Zambia",
		"ISO4217-currency_numeric_code": 967,
		"UNTERM Chinese Formal": "赞比亚共和国",
		"UNTERM French Formal": "la République de Zambie",
		"UNTERM Russian Short": "Замбия",
		M49: 894,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "زامبيا",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية زامبيا",
		"UNTERM Chinese Short": "赞比亚",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Zambia",
		"UNTERM English Formal": "the Republic of Zambia",
		official_name_cn: "赞比亚",
		official_name_en: "Zambia",
		"ISO4217-currency_country_name": "ZAMBIA",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "زامبيا",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Замбия",
		"Global Name": "World",
		Capital: "Lusaka",
		Continent: "AF",
		TLD: ".zm",
		Languages: "en-ZM,bem,loz,lun,lue,ny,toi",
		"Geoname ID": 895949,
		"CLDR display name": "Zambia",
		EDGAR: "Y4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q953",
	},
	{
		FIFA: "ZIM",
		Dial: 263,
		"ISO3166-1-Alpha-3": "ZWE",
		MARC: "rh",
		is_independent: "Yes",
		"ISO3166-1-numeric": 716,
		GAUL: 271,
		FIPS: "ZI",
		WMO: "ZW",
		"ISO3166-1-Alpha-2": "ZW",
		ITU: "ZWE",
		IOC: "ZIM",
		DS: "ZW",
		"UNTERM Spanish Formal": "la República de Zimbabwe",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Zimbabwe",
		"UNTERM French Short": "Zimbabwe (le)",
		"ISO4217-currency_name": "Zimbabwe Gold",
		"UNTERM Russian Formal": "Республика Зимбабве",
		"UNTERM English Short": "Zimbabwe",
		"ISO4217-currency_alphabetic_code": "ZWG",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Zimbabwe",
		"ISO4217-currency_numeric_code": 924,
		"UNTERM Chinese Formal": "津巴布韦共和国",
		"UNTERM French Formal": "la République du Zimbabwe",
		"UNTERM Russian Short": "Зимбабве",
		M49: 716,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "زمبابوي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية زمبابوي",
		"UNTERM Chinese Short": "津巴布韦",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Zimbabwe",
		"UNTERM English Formal": "the Republic of Zimbabwe",
		official_name_cn: "津巴布韦",
		official_name_en: "Zimbabwe",
		"ISO4217-currency_country_name": "ZIMBABWE",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": "زمبابوي",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Зимбабве",
		"Global Name": "World",
		Capital: "Harare",
		Continent: "AF",
		TLD: ".zw",
		Languages: "en-ZW,sn,nr,nd",
		"Geoname ID": 878675,
		"CLDR display name": "Zimbabwe",
		EDGAR: "Y5",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q954",
	},
	{
		FIFA: null,
		Dial: 47,
		"ISO3166-1-Alpha-3": "BVT",
		MARC: "bv",
		is_independent: "Territory of NO",
		"ISO3166-1-numeric": 74,
		GAUL: 36,
		FIPS: "BV",
		WMO: "BV",
		"ISO3166-1-Alpha-2": "BV",
		ITU: null,
		IOC: null,
		DS: "BV",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Île Bouvet",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Norwegian Krone",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "NOK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 578,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 74,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جزيرة بوفيت",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Isla Bouvet",
		"UNTERM English Formal": null,
		official_name_cn: "布维岛",
		official_name_en: "Bouvet Island",
		"ISO4217-currency_country_name": "BOUVET ISLAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Остров Буве",
		"Global Name": "World",
		Capital: null,
		Continent: "AN",
		TLD: ".bv",
		Languages: null,
		"Geoname ID": 3371123,
		"CLDR display name": "Pulau Bouvet",
		EDGAR: "D4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q23408",
	},
	{
		FIFA: null,
		Dial: 246,
		"ISO3166-1-Alpha-3": "IOT",
		MARC: "bi",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 86,
		GAUL: 38,
		FIPS: "IO",
		WMO: null,
		"ISO3166-1-Alpha-2": "IO",
		ITU: "BIO",
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Territoire britannique de l'océan Indien",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 86,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "المحيط الهندي الإقليم البريطاني في",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Territorio Británico del Océano Índico",
		"UNTERM English Formal": null,
		official_name_cn: "英属印度洋领土",
		official_name_en: "British Indian Ocean Territory",
		"ISO4217-currency_country_name": "BRITISH INDIAN OCEAN TERRITORY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Британская территория в Индийском океане",
		"Global Name": "World",
		Capital: "Diego Garcia",
		Continent: "AS",
		TLD: ".io",
		Languages: "en-IO",
		"Geoname ID": 1282588,
		"CLDR display name": "Wilayah Lautan Hindi British",
		EDGAR: "D6",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q43448",
	},
	{
		FIFA: null,
		Dial: 599,
		"ISO3166-1-Alpha-3": "CUW",
		MARC: "co",
		is_independent: "Part of NL",
		"ISO3166-1-numeric": 531,
		GAUL: null,
		FIPS: "UC",
		WMO: null,
		"ISO3166-1-Alpha-2": "CW",
		ITU: null,
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Curaçao",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Netherlands Antillean Guilder",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "ANG",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 532,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 531,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "كوراساو",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "Curazao",
		"UNTERM English Formal": null,
		official_name_cn: "库拉索",
		official_name_en: "Curaçao",
		"ISO4217-currency_country_name": "CURAÇAO",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Кюрасао",
		"Global Name": "World",
		Capital: "Willemstad",
		Continent: null,
		TLD: ".cw",
		Languages: "nl,pap",
		"Geoname ID": 7626836,
		"CLDR display name": "Curacao",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q25279",
	},
	{
		FIFA: null,
		Dial: 262,
		"ISO3166-1-Alpha-3": "ATF",
		MARC: "fs",
		is_independent: "Territory of FR",
		"ISO3166-1-numeric": 260,
		GAUL: 88,
		FIPS: "FS",
		WMO: null,
		"ISO3166-1-Alpha-2": "TF",
		ITU: null,
		IOC: null,
		DS: "F",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Terres australes françaises",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 260,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "الأراضي الفرنسية الجنوبية الجنوبية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Territorio de las Tierras Australes Francesas",
		"UNTERM English Formal": null,
		official_name_cn: "法属南方领地",
		official_name_en: "French Southern Territories",
		"ISO4217-currency_country_name": "FRENCH SOUTHERN TERRITORIES",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Africa",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Южные земли (французская заморская территория)",
		"Global Name": "World",
		Capital: "Port-aux-Francais",
		Continent: "AN",
		TLD: ".tf",
		Languages: "fr",
		"Geoname ID": 1546748,
		"CLDR display name": "Wilayah Selatan Perancis",
		EDGAR: "2C",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q129003",
	},
	{
		FIFA: null,
		Dial: 672,
		"ISO3166-1-Alpha-3": "HMD",
		MARC: "hm",
		is_independent: "Territory of AU",
		"ISO3166-1-numeric": 334,
		GAUL: 109,
		FIPS: "HM",
		WMO: null,
		"ISO3166-1-Alpha-2": "HM",
		ITU: null,
		IOC: null,
		DS: "AUS",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Île Heard-et-Îles MacDonald",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Australian Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "AUD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 36,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 334,
		"Sub-region Code": 53,
		"Region Code": 9,
		official_name_ar: "جزيرة هيرد وجزر ماكدونالد",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Heard y McDonald",
		"UNTERM English Formal": null,
		official_name_cn: "赫德岛和麦克唐纳岛",
		official_name_en: "Heard Island and McDonald Islands",
		"ISO4217-currency_country_name": "HEARD ISLAND AND MCDONALD ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Australia and New Zealand",
		official_name_ru: "Остров Херд и острова Макдональд",
		"Global Name": "World",
		Capital: null,
		Continent: "AN",
		TLD: ".hm",
		Languages: null,
		"Geoname ID": 1547314,
		"CLDR display name": "Kepulauan Heard & McDonald",
		EDGAR: "K4",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q131198",
	},
	{
		FIFA: null,
		Dial: 590,
		"ISO3166-1-Alpha-3": "BLM",
		MARC: "sc",
		is_independent: "Part of FR",
		"ISO3166-1-numeric": 652,
		GAUL: null,
		FIPS: "TB",
		WMO: null,
		"ISO3166-1-Alpha-2": "BL",
		ITU: null,
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Saint-Barthélemy",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 652,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "سان بارتليمي",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "San Barthélemy",
		"UNTERM English Formal": null,
		official_name_cn: "圣巴泰勒米",
		official_name_en: "Saint Barthélemy",
		"ISO4217-currency_country_name": "SAINT BARTHÉLEMY",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Сен-Бартелеми",
		"Global Name": "World",
		Capital: "Gustavia",
		Continent: null,
		TLD: ".gp",
		Languages: "fr",
		"Geoname ID": 3578476,
		"CLDR display name": "St. Barthelemy",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q25362",
	},
	{
		FIFA: null,
		Dial: 590,
		"ISO3166-1-Alpha-3": "MAF",
		MARC: "st",
		is_independent: "Part of FR",
		"ISO3166-1-numeric": 663,
		GAUL: null,
		FIPS: "RN",
		WMO: null,
		"ISO3166-1-Alpha-2": "MF",
		ITU: null,
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Saint-Martin (partie française)",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Euro",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "EUR",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 978,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 663,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "سانت مارتن (الجزء الفرنسي)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "San Martín (parte francesa)",
		"UNTERM English Formal": null,
		official_name_cn: "圣马丁（法属）",
		official_name_en: "Saint Martin (French Part)",
		"ISO4217-currency_country_name": "SAINT MARTIN (FRENCH PART)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Сен-Мартен (французская часть)",
		"Global Name": "World",
		Capital: "Marigot",
		Continent: null,
		TLD: ".gp",
		Languages: "fr",
		"Geoname ID": 3578421,
		"CLDR display name": "Saint Martin",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q126125",
	},
	{
		FIFA: null,
		Dial: "1-721",
		"ISO3166-1-Alpha-3": "SXM",
		MARC: "sn",
		is_independent: "Part of NL",
		"ISO3166-1-numeric": 534,
		GAUL: null,
		FIPS: "NN",
		WMO: null,
		"ISO3166-1-Alpha-2": "SX",
		ITU: null,
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 29,
		official_name_fr: "Saint-Martin (partie néerlandaise)",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Netherlands Antillean Guilder",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "ANG",
		"Small Island Developing States (SIDS)": "x",
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 532,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 534,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "سانت مارتن (الجزء الهولندي)",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "Caribbean",
		official_name_es: "San Martín (parte Holandesa)",
		"UNTERM English Formal": null,
		official_name_cn: "圣马丁（荷属）",
		official_name_en: "Sint Maarten (Dutch part)",
		"ISO4217-currency_country_name": "SINT MAARTEN (DUTCH PART)",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Синт-Мартен (нидерландская часть)",
		"Global Name": "World",
		Capital: "Philipsburg",
		Continent: null,
		TLD: ".sx",
		Languages: "nl,en",
		"Geoname ID": 7609695,
		"CLDR display name": "Sint Maarten",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q26273",
	},
	{
		FIFA: null,
		Dial: 500,
		"ISO3166-1-Alpha-3": "SGS",
		MARC: "xs",
		is_independent: "Territory of GB",
		"ISO3166-1-numeric": 239,
		GAUL: 228,
		FIPS: "SX",
		WMO: null,
		"ISO3166-1-Alpha-2": "GS",
		ITU: null,
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": 5,
		official_name_fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "No universal currency",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": null,
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": null,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 239,
		"Sub-region Code": 419,
		"Region Code": 19,
		official_name_ar: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
		"ISO4217-currency_minor_unit": null,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": "South America",
		official_name_es: "Georgia del Sur y las Islas Sandwich del Sur",
		"UNTERM English Formal": null,
		official_name_cn: "南乔治亚岛和南桑德韦奇岛",
		official_name_en: "South Georgia and the South Sandwich Islands",
		"ISO4217-currency_country_name":
			"SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Americas",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Latin America and the Caribbean",
		official_name_ru: "Южная Джорджия и Южные Сандвичевы острова",
		"Global Name": "World",
		Capital: "Grytviken",
		Continent: "AN",
		TLD: ".gs",
		Languages: "en",
		"Geoname ID": 3474415,
		"CLDR display name": "Kepulauan Georgia Selatan & Sandwich Selatan",
		EDGAR: "1L",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q35086",
	},
	{
		FIFA: null,
		Dial: 211,
		"ISO3166-1-Alpha-3": "SSD",
		MARC: "sd",
		is_independent: "Yes",
		"ISO3166-1-numeric": 728,
		GAUL: null,
		FIPS: "OD",
		WMO: null,
		"ISO3166-1-Alpha-2": "SS",
		ITU: "SSD",
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": "la República de Sudán del Sur",
		"Global Code": 1,
		"Intermediate Region Code": 14,
		official_name_fr: "Soudan du Sud",
		"UNTERM French Short": "Soudan du Sud (le)",
		"ISO4217-currency_name": "South Sudanese Pound",
		"UNTERM Russian Formal": "Республика Южный Судан",
		"UNTERM English Short": "South Sudan",
		"ISO4217-currency_alphabetic_code": "SSP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": "Sudán del Sur",
		"ISO4217-currency_numeric_code": 728,
		"UNTERM Chinese Formal": "南苏丹共和国",
		"UNTERM French Formal": "la République du Soudan du Sud",
		"UNTERM Russian Short": "Южный Судан",
		M49: 728,
		"Sub-region Code": 202,
		"Region Code": 2,
		official_name_ar: "جنوب السودان",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": "جمهورية جنوب السودان",
		"UNTERM Chinese Short": "南苏丹",
		"Land Locked Developing Countries (LLDC)": "x",
		"Intermediate Region Name": "Eastern Africa",
		official_name_es: "Sudán del Sur",
		"UNTERM English Formal": "the Republic of South Sudan",
		official_name_cn: "南苏丹",
		official_name_en: "South Sudan",
		"ISO4217-currency_country_name": "SOUTH SUDAN",
		"Least Developed Countries (LDC)": "x",
		"Region Name": "Africa",
		"UNTERM Arabic Short": "جنوب السودان",
		"Sub-region Name": "Sub-Saharan Africa",
		official_name_ru: "Южный Судан",
		"Global Name": "World",
		Capital: "Juba",
		Continent: "AF",
		TLD: ".ss",
		Languages: "en",
		"Geoname ID": 7909807,
		"CLDR display name": "Sudan Selatan",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q958",
	},
	{
		FIFA: null,
		Dial: 47,
		"ISO3166-1-Alpha-3": "SJM",
		MARC: null,
		is_independent: "Territory of NO",
		"ISO3166-1-numeric": 744,
		GAUL: 234,
		FIPS: "SVJN",
		WMO: "SZ",
		"ISO3166-1-Alpha-2": "SJ",
		ITU: "NOR",
		IOC: null,
		DS: null,
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles Svalbard-et-Jan Mayen",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "Norwegian Krone",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "NOK",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 578,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 744,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "جزيرتي سفالبارد وجان مايِن",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas Svalbard y Jan Mayen",
		"UNTERM English Formal": null,
		official_name_cn: "斯瓦尔巴群岛和扬马延岛",
		official_name_en: "Svalbard and Jan Mayen Islands",
		"ISO4217-currency_country_name": "SVALBARD AND JAN MAYEN",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Northern Europe",
		official_name_ru: "Острова Свальбард и Ян-Майен",
		"Global Name": "World",
		Capital: "Longyearbyen",
		Continent: "EU",
		TLD: ".sj",
		Languages: "no,ru",
		"Geoname ID": 607072,
		"CLDR display name": "Svalbard dan Jan Mayen",
		EDGAR: "L9",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q842829",
	},
	{
		FIFA: 1,
		Dial: 44,
		"ISO3166-1-Alpha-3": "GBR",
		MARC: "xxk",
		is_independent: "Yes",
		"ISO3166-1-numeric": 826,
		GAUL: 256,
		FIPS: "UK",
		WMO: "UK",
		"ISO3166-1-Alpha-2": "GB",
		ITU: "G",
		IOC: "GBR",
		DS: "GB",
		"UNTERM Spanish Formal":
			"el Reino Unido de Gran Bretaña e Irlanda del Norte",
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Royaume-Uni de Grande-Bretagne et d’Irlande du Nord",
		"UNTERM French Short":
			"Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
		"ISO4217-currency_name": "Pound Sterling",
		"UNTERM Russian Formal":
			"Соединенное Королевство Великобритании и Северной Ирландии",
		"UNTERM English Short":
			"United Kingdom of Great Britain and Northern Ireland (the)",
		"ISO4217-currency_alphabetic_code": "GBP",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short":
			"Reino Unido de Gran Bretaña e Irlanda del Norte (el)",
		"ISO4217-currency_numeric_code": 826,
		"UNTERM Chinese Formal": "大不列颠及北爱尔兰联合王国",
		"UNTERM French Formal":
			"le Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
		"UNTERM Russian Short":
			"Соединенное Королевство Великобритании и Северной Ирландии",
		M49: 826,
		"Sub-region Code": 154,
		"Region Code": 150,
		official_name_ar: "المملكة المتحدة لبريطانيا العظمى وآيرلندا الشمالية",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal":
			"المملكة المتحدة لبريطانيا العظمى وأيرلندا الشمالية",
		"UNTERM Chinese Short": "大不列颠及北爱尔兰联合王国",
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Reino Unido de Gran Bretaña e Irlanda del Norte",
		"UNTERM English Formal":
			"the United Kingdom of Great Britain and Northern Ireland",
		official_name_cn: "大不列颠及北爱尔兰联合王国",
		official_name_en: "United Kingdom of Great Britain and Northern Ireland",
		"ISO4217-currency_country_name":
			"UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Europe",
		"UNTERM Arabic Short": "المملكة المتحدة لبريطانيا العظمى وأيرلندا الشمالية",
		"Sub-region Name": "Northern Europe",
		official_name_ru:
			"Соединенное Королевство Великобритании и Северной Ирландии",
		"Global Name": "World",
		Capital: "London",
		Continent: "EU",
		TLD: ".uk",
		Languages: "en-GB,cy-GB,gd",
		"Geoname ID": 2635167,
		"CLDR display name": "UK",
		EDGAR: null,
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q145",
	},
	{
		FIFA: null,
		Dial: null,
		"ISO3166-1-Alpha-3": "UMI",
		MARC: "b",
		is_independent: "Territories of US",
		"ISO3166-1-numeric": 581,
		GAUL: null,
		FIPS: "a",
		WMO: null,
		"ISO3166-1-Alpha-2": "UM",
		ITU: null,
		IOC: null,
		DS: "USA",
		"UNTERM Spanish Formal": null,
		"Global Code": 1,
		"Intermediate Region Code": null,
		official_name_fr: "Îles mineures éloignées des États-Unis",
		"UNTERM French Short": null,
		"ISO4217-currency_name": "US Dollar",
		"UNTERM Russian Formal": null,
		"UNTERM English Short": null,
		"ISO4217-currency_alphabetic_code": "USD",
		"Small Island Developing States (SIDS)": null,
		"UNTERM Spanish Short": null,
		"ISO4217-currency_numeric_code": 840,
		"UNTERM Chinese Formal": null,
		"UNTERM French Formal": null,
		"UNTERM Russian Short": null,
		M49: 581,
		"Sub-region Code": 57,
		"Region Code": 9,
		official_name_ar: "نائية التابعة للولايات المتحدة",
		"ISO4217-currency_minor_unit": 2,
		"UNTERM Arabic Formal": null,
		"UNTERM Chinese Short": null,
		"Land Locked Developing Countries (LLDC)": null,
		"Intermediate Region Name": null,
		official_name_es: "Islas menores alejadas de Estados Unidos",
		"UNTERM English Formal": null,
		official_name_cn: "美国本土外小岛屿",
		official_name_en: "United States Minor Outlying Islands",
		"ISO4217-currency_country_name": "UNITED STATES MINOR OUTLYING ISLANDS",
		"Least Developed Countries (LDC)": null,
		"Region Name": "Oceania",
		"UNTERM Arabic Short": null,
		"Sub-region Name": "Micronesia",
		official_name_ru: "Внешние малые острова Соединенных Штатов",
		"Global Name": "World",
		Capital: null,
		Continent: "OC",
		TLD: ".um",
		Languages: "en-UM",
		"Geoname ID": 5854968,
		"CLDR display name": "Kepulauan Terpencil A.S.",
		EDGAR: "2J",
		wikidata_id:
			"https://www.wikidata.org/wiki/https://www.wikidata.org/wiki/Q16645",
	},
];
