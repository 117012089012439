import { forwardRef } from "@chakra-ui/react";
import React from "react";

export interface NavAccountProps {
  accountName?: string;
  imageUrl?: string;
}

/**
 * The NavAccount component is used to display the user's account information in the navbar.
 *
 * @returns
 */
export const NavAccount = forwardRef(
  ({ accountName, imageUrl }: NavAccountProps, ref) => {
    return (
      <div ref={ref} className='px-4 py-2 rounded-lg'>
        <div className='grid grid-cols-[1fr] lg:grid-cols-[36px_2fr] items-center w-full'>
          <div className='flex-shrink-0'>
            {imageUrl && (
              <img
                className='w-8 h-8 rounded-full'
                src={imageUrl}
                alt='your avatar'
              />
            )}
          </div>
          <div className='ml-3 hidden lg:block'>
            <p className='text-sm leading-5 font-medium text-primary-500'>
              {accountName || "Account"}
            </p>
            <p className='text-xs text-primary-300'>Pro</p>
          </div>
        </div>
      </div>
    );
  }
);
