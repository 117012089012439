import {
	Button,
	ButtonProps,
	Heading,
	Modal,
	ModalContent,
	ModalOverlay,
	Stack,
	StackProps,
	Text,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import ConfirmationImage from "../assets/registration-confirmation.svg";
import { useNavigate } from "react-router-dom";

export interface ConfirmationLayoutProps extends StackProps {}

export const ConfirmationLayout = ({
	children,
	...rest
}: PropsWithChildren<ConfirmationLayoutProps>) => {
	return (
		<Stack w="full" align="center" spacing={8} {...rest}>
			{children}
		</Stack>
	);
};

export const ConfirmationLayoutHeader = ({
	children,
	backTo,
}: PropsWithChildren<{
	backTo?: string;
	isLoading?: boolean;
}>) => {
	return (
		<Stack width="full">
			<Stack
				direction="row"
				justify={backTo ? "space-between" : "flex-end"}
				align="center"
				p={5}
			></Stack>
			{children && (
				<Stack width="full">
					<Stack direction="row" px={5} py={2}>
						<Heading
							as="h2"
							fontSize={{
								base: "xl",
								lg: "2xl",
							}}
							fontWeight="medium"
						>
							{children}
						</Heading>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};

export const ConfirmationLayoutContent = ({
	children,
	...rest
}: PropsWithChildren<StackProps & {}>) => {
	return (
		<Stack width="full" p={5} {...rest}>
			{children}
		</Stack>
	);
};

export const ConfirmationLayoutFooter = ({
	children,
	...rest
}: PropsWithChildren<ButtonProps>) => {
	return (
		<Stack width="full" p={5}>
			<Button size="lg" width="full" colorScheme="brand" {...rest}>
				{children}
			</Button>
		</Stack>
	);
};

export const RegistrationConfirmation = () => {
	return (
		<Modal isOpen onClose={() => {}} isCentered size={"xl"}>
			<ModalOverlay />
			<ModalContent rounded={0} pb={6} px={4}>
				<ConfirmationLayout>
					<ConfirmationLayoutHeader />
					<ConfirmationLayoutContent>
						<Stack spacing={6}>
							<img src={ConfirmationImage} alt="check icon" />
							<Stack>
								<Heading
									as="h2"
									size="xl"
									textAlign="center"
									fontWeight={500}
									textColor={"#153243"}
								>
									Congratulations!
								</Heading>
								<Text
									fontSize="lg"
									textAlign="center"
									fontWeight={500}
									textColor={"#5E5E5E"}
								>
									You have completed your registration successfully.
								</Text>
							</Stack>

							<Stack w={"full"}>
								<Button
									w={"full"}
									colorScheme="brand"
									rounded={0}
									size="lg"
									p={8}
									fontWeight={500}
									onClick={() => {
										window.open("/my-portfolio", "_self");
									}}
								>
									Start my portfolio
								</Button>
							</Stack>
						</Stack>
					</ConfirmationLayoutContent>
				</ConfirmationLayout>
			</ModalContent>
		</Modal>
	);
};

type IDVerificationSubmissionConfirmationProps = {
	closeConfirmationModal: () => void;
};

export const IDVerificationSubmissionConfirmation = ({
	closeConfirmationModal,
}: IDVerificationSubmissionConfirmationProps) => {
	const navigate = useNavigate();

	return (
		<Modal isOpen onClose={() => closeConfirmationModal} isCentered size={"xl"}>
			<ModalOverlay />
			<ModalContent rounded={0} pb={6} px={4}>
				<ConfirmationLayout>
					<ConfirmationLayoutHeader />
					<ConfirmationLayoutContent>
						<Stack spacing={6}>
							<img src={ConfirmationImage} alt="check icon" />
							<Stack>
								<Heading
									as="h2"
									size="xl"
									textAlign="center"
									fontWeight={500}
									textColor={"#153243"}
								>
									All done!
								</Heading>
								<Text
									fontSize="lg"
									textAlign="center"
									fontWeight={500}
									textColor={"#5E5E5E"}
								>
									You have successfully submitted your ID verification. Your
									details will be reviewed and verified shortly.
								</Text>
							</Stack>

							<div className="grid grid-cols-2 gap-4">
								<Button
									colorScheme="prussianBlue"
									rounded={0}
									size="lg"
									p={{
										base: 5,
										lg: 8,
									}}
									fontWeight={500}
									onClick={() => {
										navigate(-1);
									}}
								>
									Go back
								</Button>
								<Button
									colorScheme="red"
									rounded={0}
									size="lg"
									variant={"outline"}
									p={{
										base: 5,
										lg: 8,
									}}
									fontWeight={500}
									onClick={closeConfirmationModal}
								>
									Close
								</Button>
							</div>
						</Stack>
					</ConfirmationLayoutContent>
				</ConfirmationLayout>
			</ModalContent>
		</Modal>
	);
};
