import { gql } from "@apollo/client";

export const GET_PRO_TRADE_LEVEL_PAYMENT = gql`
	query GetProTradeLevelPayment($tradeLevelId: String!, $proId: String!) {
		proTradeLevelPayment(tradeLevelId: $tradeLevelId, proId: $proId) {
			id
			affectedTradeRequirements {
				id
				name
				price
			}
			payments {
				id
				amount
				checkout
			}
			discount {
				id
				code
				description
				value
				isRevoked(proId: $proId)
			}
			isPaid
		}
	}
`;

export const GET_PRO_TRADE_LEVEL_PAYMENTS = gql`
	query GetProTradeLevelPayments($tradeLevelId: String!, $proId: String!) {
		proTradeLevelPayments(tradeLevelId: $tradeLevelId, proId: $proId) {
			id
			affectedTradeRequirements {
				id
				name
				price
			}
			payments {
				id
				amount
				checkout
			}
			discount {
				id
				code
				description
				value
				isRevoked(proId: $proId)
			}
			isPaid
			createdAt
		}
	}
`;

export const GET_RETRY_DISCOUNTS = gql`
	query GetRetryDiscounts($tradeRequirementId: String!) {
		availableRetryDiscounts(tradeRequirementId: $tradeRequirementId) {
			id
			code
			description
			value
		}
	}
`;
