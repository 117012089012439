import { createContext, PropsWithChildren, useState } from "react";
import { BannerDataType, PopUpModal } from "../types";
import { MeansOfIdentification } from "../__generated__/graphql";

export interface RawComputedImage {
	image_type_id: Number; // as recommended here: https://docs.smileidentity.com/products/core-libraries#images-required
	image: String; // base64 encoded string of image
}
export interface CompleteProfileContextData {
	dateOfBirth?: string;
	setDateOfBirth: (dateOfBirth: string) => void;
	selectedIdType?: MeansOfIdentification;
	setSelectedIdType: (idType: MeansOfIdentification) => void;
	idImageUrl?: string;
	setIdImageUrl: (idImage?: string) => void;
	idNumber?: string;
	setIdNumber: (idNumber: string) => void;
	images: string[];
	setImages: (images: string[]) => void;
	rawComputedImages: RawComputedImage[];
	setRawComputedImages: (rawComputedImages: RawComputedImage[]) => void;
	clearContext: () => void;
	banners: BannerDataType[];
	setBanners: (bannerData: BannerDataType[]) => void;
	popUpModal: PopUpModal;
	setPopUpModal: (popUpModalData: PopUpModal) => void;
	clientBackgroundCheckFormCompleted: boolean;
	setClientBackgroundCheckFormCompleted: (
		clientBackgroundCheckFormCompleted: boolean
	) => void;
}

export const CompleteProfileContext = createContext<CompleteProfileContextData>(
	{
		dateOfBirth: undefined,
		setDateOfBirth: () => {},
		selectedIdType: undefined,
		setSelectedIdType: () => {},
		idImageUrl: undefined,
		setIdImageUrl: () => {},
		images: [],
		setImages: () => {},
		setIdNumber: () => {},
		rawComputedImages: [],
		setRawComputedImages: () => {},
		clearContext: () => {},
		banners: [],
		setBanners: () => {},
		popUpModal: { isVisible: false, modalType: "" },
		setPopUpModal: () => {},
		clientBackgroundCheckFormCompleted: false,
		setClientBackgroundCheckFormCompleted: () => {},
	}
);

export const CompleteProfileProvider = ({ children }: PropsWithChildren) => {
	const [dateOfBirth, setDateOfBirth] = useState<string>();
	const [selectedIdType, setSelectedIdType] = useState<MeansOfIdentification>();
	const [images, setImages] = useState<string[]>([]);
	const [idImageUrl, setIdImageUrl] = useState<string>();
	const [idNumber, setIdNumber] = useState<string>();
	const [rawComputedImages, setRawComputedImages] = useState<
		RawComputedImage[]
	>([]);
	const [banners, setBanners] = useState<BannerDataType[]>([]);
	const [popUpModal, setPopUpModal] = useState<PopUpModal>({
		isVisible: false,
		modalType: "",
	});
	const [
		clientBackgroundCheckFormCompleted,
		setClientBackgroundCheckFormCompleted,
	] = useState<boolean>(false);

	const clearContext = () => {
		setIdImageUrl(undefined);
		setImages([]);
		setIdNumber(undefined);
		setRawComputedImages([]);
	};

	return (
		<CompleteProfileContext.Provider
			value={{
				dateOfBirth,
				setDateOfBirth,
				selectedIdType,
				setSelectedIdType,
				idImageUrl,
				setIdImageUrl,
				images,
				setImages,
				idNumber,
				setIdNumber,
				rawComputedImages,
				setRawComputedImages,
				clearContext,
				banners,
				setBanners,
				popUpModal,
				setPopUpModal,
				clientBackgroundCheckFormCompleted,
				setClientBackgroundCheckFormCompleted,
			}}
		>
			{children}
		</CompleteProfileContext.Provider>
	);
};
