import { Icon } from "@chakra-ui/react";
import clsx from "clsx";
import React from "react";
import { IconType } from "react-icons";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";

export interface MobileNavItemProps {
  name: string;
  icon: IconType;
  path: string;
  onClick?: () => void;
  locked?: boolean;
  active?: boolean;
}

export const MobileNavItem = ({
  icon,
  name,
  path,
  locked,
  active,
  onClick,
}: MobileNavItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!locked) {
      navigate(path);

      onClick?.();
    }
  };

  return (
    <div
      className='flex flex-col justify-center w-full items-center pr-8 cursor-pointer'
      onClick={handleClick}
    >
      <div className='flex'>
        <Icon
          as={icon}
          w={active ? "8" : "6"}
          h={active ? "8" : "6"}
          className={styles.icon}
          color={active ? "brand.500" : "primary.200"}
        />
        <p
          className={clsx(
            "ml-2 text-lg text-primary-200 font-medium",
            active && "!text-accent-500 text-2xl !font-bold"
          )}
        >
          {name}
        </p>
      </div>
      {active && <div className='w-16 h-1 bg-accent-500 mt-1' />}
    </div>
  );
};
