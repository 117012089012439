import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Stack,
} from "@chakra-ui/react";
import { IoChevronBackOutline } from "react-icons/io5";
import CertificateIcon from "../../assets/certificate-icon.png";
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { gql } from "src/__generated__";
import { isBefore } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const ADD_CERTIFICATION = gql(`
  mutation AddPortfolioCertification(
    $name: String!
    $issuedBy: String!
    $issuerCertificationId: String
    $issuedAt: DateTimeISO!
  ) {
    addPortfolioCertification(
      name: $name
      issuedBy: $issuedBy
      issuerCertificationId: $issuerCertificationId
      issuedAt: $issuedAt
    )
  }
`);

const formSchema = z.object({
	name: z.string().min(3),
	issuedBy: z.string().min(3),
	issuerCertificationId: z.string().optional(),
	issuedAt: z
		.string()
		.refine((value) => {
			if (!value) return false;

			return true;
		}, "Date is required")
		.refine((value) => {
			const date = new Date(value);

			return isBefore(date, new Date());
		}, "Date must be today or in the past"),
});

export const AddCertification = () => {
	const navigate = useNavigate();

	const [addCertificationItem, addCertificationItemHandle] = useMutation(
		ADD_CERTIFICATION,
		{
			onCompleted: () => {
				navigate("/my-portfolio");
			},
			refetchQueries: ["MyPortfolio"],
			awaitRefetchQueries: true,
		}
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		addCertificationItem({
			variables: {
				name: data.name,
				issuedBy: data.issuedBy,
				issuerCertificationId: data.issuerCertificationId,
				issuedAt: new Date(data.issuedAt),
			},
		});
	};
	return (
		<>
			<Stack spacing={0} h={"90vh"}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col h-full justify-between"
				>
					<Stack
						spacing={0}
						px={{
							base: 8,
							lg: 20,
						}}
					>
						<HStack
							borderBottom={"1px"}
							borderColor={"#E7E7E7"}
							py={6}
							textColor={"#212121"}
							mb={{
								base: 4,
								lg: 8,
							}}
						>
							<Link to="/my-portfolio">
								<div className="hover:cursor-pointer flex items-center gap-3">
									<IoChevronBackOutline />
									<span className="text-base lg:text-lg">Back</span>
								</div>
							</Link>
						</HStack>

						<div className="overflow-y-scroll grid grid-cols-1 lg:grid-cols-2 lg:border-[1px] border-[#C9C9C9] lg:px-12">
							<div className="hidden lg:block py-8">
								<div className="mb-8 bg-[#EDEDED] w-[72px] h-[72px] rounded-full flex items-center justify-center p-6">
									<img src={CertificateIcon} alt="Certificate Icon" />
								</div>
								<div className="flex flex-col w-[70%]">
									<h2 className="mb-2 text-xl lg:text-2xl font-medium text-[#121212] font-[AeonikPro]">
										Upload a certificate
									</h2>
									<p className="text-lg lg:text-xl text-[#464646]">
										Provide your details in the required fields.
									</p>
								</div>
							</div>
							<div className="border-l-[0] lg:border-l-[1px] border-[#E7E7E7] lg:pl-12 py-8 flex flex-col gap-6">
								<div className="flex flex-col gap-6 lg:gap-8">
									<FormControl isInvalid={!!errors.name}>
										<FormLabel htmlFor="name">Name</FormLabel>
										<Input
											id="name"
											placeholder="Example: Health and Safety Certification"
											{...register("name")}
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>{errors.name?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={!!errors.issuerCertificationId}>
										<FormLabel htmlFor="issuedBy">Certification ID</FormLabel>
										<Input
											id="issuerCertificationId"
											placeholder="Example: 101223456"
											{...register("issuerCertificationId")}
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>
											{errors.issuerCertificationId?.message}
										</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={!!errors.issuedBy}>
										<FormLabel htmlFor="issuedBy">Issuer</FormLabel>
										<Input
											id="issuer"
											placeholder="Example: UK ECITB"
											{...register("issuedBy")}
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>
											{errors.issuedBy?.message}
										</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={!!errors.issuedAt}>
										<FormLabel htmlFor="issuedAt">Date of Issue</FormLabel>
										<Input
											id="issuedAt"
											{...register("issuedAt")}
											type="date"
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>
											{errors.issuedAt?.message}
										</FormErrorMessage>
									</FormControl>
								</div>
							</div>
						</div>
					</Stack>
					<Stack
						py={{
							base: 2,
							lg: 4,
						}}
						px={{
							base: 8,
							lg: 20,
						}}
						borderTop={"1px"}
						borderColor={"#E7E7E7"}
						alignItems={"end"}
					>
						<Button
							type="submit"
							colorScheme="brand"
							rounded={0}
							px={8}
							py={6}
							rightIcon={<FiArrowRight size={"18px"} />}
							isLoading={addCertificationItemHandle.loading}
						>
							Submit
						</Button>
					</Stack>
				</form>
			</Stack>
		</>
	);
};
