import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { TradeRequirementAction } from "../../types";

interface TradeRequirementActionsProps {
  actions: TradeRequirementAction[];
}

export const TradeRequirementActions = ({
  actions,
}: TradeRequirementActionsProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [selectedActionIndex, setSelectedActionIndex] =
    React.useState<number>();

  const handleActionSelect = (actionIndex: number) => {
    setSelectedActionIndex(actionIndex);
  };

  return (
    <>
      <Button
        rightIcon={<ArrowForwardIcon />}
        colorScheme='blue'
        size='sm'
        variant='solid'
        _hover={{ textDecoration: "none" }}
        onClick={onOpen}
      >
        Choose Assessment Type
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Assessment Channel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={8} paddingBottom={4}>
              <RadioGroup
                onChange={(value) => handleActionSelect(Number(value))}
                value={selectedActionIndex?.toString()}
              >
                <Stack spacing={4}>
                  {actions.map((action, index) => (
                    <Radio value={index.toString()}>
                      <Stack spacing={0}>
                        <p className='text-gray-900 font-semibold'>
                          {action.label}
                        </p>
                        <p className='text-sm text-gray-600'>{action.prompt}</p>
                      </Stack>
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>

              <Button
                isDisabled={selectedActionIndex === undefined}
                as={Link}
                rightIcon={<ArrowForwardIcon />}
                colorScheme='green'
                variant='solid'
                _hover={{ textDecoration: "none" }}
                href={actions[selectedActionIndex!]?.url}
                onClick={onClose}
              >
                Start Assessment
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
