import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import { LockedAccount } from "../components/LockedAccount";
import { RequestFailed } from "../components/RequestFailed";
import { useProfile } from "../hooks/useProfile";
import {
	IdentityVerificationIssueCode,
	IdentityVerificationStatus,
} from "../__generated__/graphql";

export const IdentityVerificationGuard = ({ children }: PropsWithChildren) => {
	const {
		loading: profileLoading,
		error: profileError,
		profile,
	} = useProfile();

	if (profileLoading) {
		return <Loading />;
	}

	if (profileError) {
		return <RequestFailed />;
	}

	if (
		profile?.identityVerification?.status ===
			IdentityVerificationStatus.Declined &&
		profile?.identityVerification?.issues &&
		profile?.identityVerification?.issues.length > 0
	) {
		const isDuplicateIdNumber = profile?.identityVerification?.issues.some(
			(issue) => issue.code === IdentityVerificationIssueCode.DuplicateId
		);

		if (isDuplicateIdNumber) {
			return (
				<LockedAccount>
					<p className="text-primary-500">
						The NIN you presented has already been used with a different
						account.
					</p>
				</LockedAccount>
			);
		}
	}

	if (
		!profile?.identityVerification ||
		profile.identityVerification.status === IdentityVerificationStatus.Declined
	) {
		return <Navigate to="/complete-profile" />;
	}

	return <>{children}</>;
};
