import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "./Loading";

export interface RequireAuthenticationProps {}

export const RequireAuthentication = ({
	children,
}: PropsWithChildren<RequireAuthenticationProps>): JSX.Element => {
	const location = useLocation();
	const { isAuthenticated, isLoading } = useAuth0();
	const navigate = useNavigate();

	const srcEmail = new URLSearchParams(window.location.search).get("src_email");

	useEffect(() => {
		/**
		 * If the user is already authenticated, redirect to the page they wanted to visit or the dashboard
		 */
		if (isAuthenticated && !isLoading) {
			const redirect = localStorage.getItem("redirect") || location.pathname;

			if (redirect && redirect !== "/") {
				localStorage.removeItem("redirect");
				return navigate(redirect, {
					replace: true,
				});
			}
		} else if (!isAuthenticated && !isLoading) {
			window.localStorage.setItem("redirect", location.pathname);

			const loginUrl = `/login${srcEmail ? `?src_email=${srcEmail}` : ""}`;

			return navigate(loginUrl, {
				replace: true,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, isLoading, srcEmail]);

	if (isLoading) {
		return <Loading />;
	}

	return <>{children}</>;
};
