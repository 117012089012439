import { Heading, HStack, Stack } from "@chakra-ui/react";
import { QuickActionCard } from "src/components/QuickActionCard";

export const NewDashboard = () => {
	return (
		<div className="flex flex-col gap-8 w-full bg-[#FEFEFE]">
			<Stack>
				<HStack
					borderBottom={"1px"}
					borderColor={"#E4E4E4"}
					py={4}
					textColor={"#212121"}
					mb={{
						base: 4,
						lg: 8,
					}}
				>
					<Heading
						as={"h2"}
						fontWeight={"normal"}
						fontSize={{
							base: "18px",
							lg: "20px",
						}}
					>
						Quick actions
					</Heading>
				</HStack>
				<Stack
					direction={{
						base: "column",
						lg: "row",
					}}
					gap={{
						base: 4,
						lg: 8,
					}}
				>
					<QuickActionCard
						cta="Chat with Aina"
						ctaLink={`${process.env.REACT_APP_ARTISAN_ASSISTANT_WHATSAPP_BOT}`}
						description="Easily create and send professional invoices to your clients through WhatsApp. Save time and get paid faster."
						title="Streamline Your Invoicing"
					/>
					<QuickActionCard
						cta="Get a certification"
						ctaLink="/certificates"
						description="Become a verified LaborHack Pro by earning certifications and showcasing your expertise."
						title="Upgrade Your Skills"
					/>
				</Stack>
			</Stack>
		</div>
	);
};
