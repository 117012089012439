import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import "@builder.io/widgets";
import { PropsWithChildren, useEffect, useState } from "react";
import { Loading } from "./Loading";

// Put your API key here
builder.init(process.env.REACT_APP_BUILDER_KEY || "");

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export const CMS = ({
  path,
}: PropsWithChildren<{
  path: string;
}>) => {
  const isPreviewingInBuilder = useIsPreviewing();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: path,
        })
        .promise();

      setContent(content);
      setLoading(false);
    }
    fetchContent();
  }, [path]);

  // if loading, show a loading indicator
  if (loading) {
    return <Loading />;
  }

  // if no page is found, return a 404 page
  if (!content && !isPreviewingInBuilder) {
    return <div>Not Found 404 Error</div>;
  }

  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent model='page' content={content!} />
    </>
  );
};
