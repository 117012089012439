import { gql, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	useToast,
	Stack,
	FormErrorMessage,
	Select,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Guarantor, Referee } from "../../types";
import { RequestFailed } from "../../components/RequestFailed";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { useBackGroundCheck } from "../../hooks/useBackgroundCheck";
import { CompleteProfileContext } from "../../contexts/complete-profile.context";
import { Helmet } from "react-helmet";
import { countriesWithDialingCodes } from "src/constants";
import {
	extractPhoneNumberPrefix,
	isValidIntlPhoneNumber,
	removePhoneNumberPrefix,
} from "src/utlis/helpers";
import { isValidGuarantor, isValidReferee } from "./constants";

const CREATE_CONTACTS_MUTATION = gql`
	mutation CreateContacts($payload: CreateContactsInput!) {
		createContacts(payload: $payload) {
			id
		}
	}
`;

const isValidEmail = EmailValidator.validate;

export interface BackgroundCheckProps {}

export const BackgroundCheck = () => {
	const toast = useToast();
	const { user, getAccessTokenSilently } = useAuth0();
	const navigate = useNavigate();
	const {
		loading: loadingExistingData,
		backgroundCheckData: existingData,
		refetch,
	} = useBackGroundCheck();
	const { setClientBackgroundCheckFormCompleted } = useContext(
		CompleteProfileContext
	);

	const [createContacts, response] = useMutation(CREATE_CONTACTS_MUTATION, {
		onCompleted: () => {
			// set client form completion status to match actual status from server
			// before banner is shown due to cache returning old data
			setClientBackgroundCheckFormCompleted(formIsCompleted);
			refetch();
			toast({
				title: "Contacts Saved",
				description:
					"Your referees and guarantors have been saved successfully.",
				status: "success",
				duration: 9000,
				isClosable: true,
				position: "top",
				size: "sm",
			});

			getAccessTokenSilently({
				ignoreCache: true,
			});
			navigate("/trades");
		},
	});

	/** Referee and Guarantor states are arrays because
	 * the backend supports maximum of two referees and guarantors and
	 * the frontend was formerly designed to support two referees and two guarantors.
	 */
	const [referees, setReferees] = React.useState<Referee[]>([
		{
			name: "",
			phoneNumber: "",
			email: "",
			companyName: "",
			companyAddress: "",
			jobTitle: "",
			relationship: "",
		},
	]);
	const [guarantors, setGuarantors] = React.useState<Guarantor[]>([
		{
			name: "",
			phoneNumber: "",
			email: "",
			companyName: "",
			companyAddress: "",
			jobTitle: "",
			relationship: "",
		},
	]);

	const [refereePhoneNumberPrefix, setRefereePhoneNumberPrefix] = useState<
		string | undefined
	>("");
	const [guarantorPhoneNumberPrefix, setGuarantorPhoneNumberPrefix] = useState<
		string | undefined
	>("");
	const [fieldsTouched, setFieldsTouched] = React.useState<
		Record<string, boolean>
	>({});

	const assignFieldsTouched = (
		entityType: string,
		index: number,
		entity: Referee | Guarantor
	) => {
		const fieldNames = Object.keys(entity);
		const newFieldsTouched: Record<string, boolean> = {};

		for (let name of fieldNames) {
			if (name !== "__typename") {
				newFieldsTouched[`${entityType}[${index}].${name}`] = !!(
					entity as unknown as Record<string, string>
				)[name];
			}
		}
		return newFieldsTouched;
	};

	useEffect(() => {
		if (!loadingExistingData && existingData) {
			const removeTypeName = (contact: any) => {
				delete contact.__typename;
				return contact;
			};

			// code to chose the first referee and guarantor that is complete or the first one if none is complete
			// for pros that have sumbitted a complete referee or guarantor before the update
			const firstCompleteReferee = existingData.referees.find((referee) => {
				return isValidReferee(referee, refereePhoneNumberPrefix);
			});

			const firstCompleteGuarantor = existingData.guarantors.find(
				(guarantor) => {
					return isValidGuarantor(guarantor, guarantorPhoneNumberPrefix);
				}
			);

			const referee = firstCompleteReferee || existingData.referees[0];
			const guarantor = firstCompleteGuarantor || existingData.guarantors[0];

			setReferees([
				{
					...removeTypeName({ ...referee }),
					...(!!referee.phoneNumber && {
						phoneNumber: removePhoneNumberPrefix(referee.phoneNumber),
					}),
				},
			]);

			setRefereePhoneNumberPrefix(
				extractPhoneNumberPrefix(referee.phoneNumber)
			);

			setGuarantors([
				{
					...removeTypeName({ ...guarantor }),
					...(!!guarantor.phoneNumber && {
						phoneNumber: removePhoneNumberPrefix(guarantor.phoneNumber),
					}),
				},
			]);

			setGuarantorPhoneNumberPrefix(
				extractPhoneNumberPrefix(guarantor.phoneNumber)
			);

			setFieldsTouched({
				...assignFieldsTouched("referees", 0, existingData.referees[0]),
				...assignFieldsTouched("guarantors", 0, existingData.guarantors[0]),
			});
		}
	}, [
		loadingExistingData,
		existingData,
		guarantorPhoneNumberPrefix,
		refereePhoneNumberPrefix,
	]);

	const handleRefereeChange =
		(index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const newReferees: Referee[] = [...referees];
			newReferees[index] = {
				...newReferees[index],
				[e.target.name]: e.target.value,
			};
			setReferees(newReferees);
			setFieldsTouched({
				...fieldsTouched,
				[`referees[${index}].${e.target.name}`]: true,
			});
		};

	const handleGuarantorChange =
		(index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const newGuarantors = [...guarantors];
			newGuarantors[index] = {
				...newGuarantors[index],
				[e.target.name]: e.target.value,
			};
			setGuarantors(newGuarantors);
			setFieldsTouched({
				...fieldsTouched,
				[`guarantors[${index}].${e.target.name}`]: true,
			});
		};

	const isValidInput = (
		input?: string,
		minLength: number = 1,
		maxLength: number = 100
	): boolean => {
		return (
			typeof input === "string" &&
			input.trim().length >= minLength &&
			input.trim().length <= maxLength
		);
	};

	const refereePhoneNumberPrefixPlaceholder = refereePhoneNumberPrefix
		? refereePhoneNumberPrefix
		: "Select";

	const guarantorPhoneNumberPrefixPlaceholder = guarantorPhoneNumberPrefix
		? guarantorPhoneNumberPrefix
		: "Select";

	const inValidInputErrorMessage = "Please provide a valid entry";

	if (!user) {
		return <RequestFailed />;
	}

	if (response.error) {
		return (
			<RequestFailed text={response.error.message} onRetry={response.reset} />
		);
	}

	if (loadingExistingData) {
		return null;
	}

	const handleSubmit = () => {
		const formatRefereePhoneNumber = (phoneNumber?: string) => {
			if (!phoneNumber) {
				return "";
			}
			const prefix = refereePhoneNumberPrefix; // new phone number prefix is updated upon change

			if (prefix) {
				return prefix + phoneNumber;
			}

			return ""; //phone number is not valid
		};
		const formatGuarantorPhoneNumber = (phoneNumber?: string) => {
			if (!phoneNumber) {
				return "";
			}

			const prefix = guarantorPhoneNumberPrefix; // new phone number prefix is updated upon change

			if (prefix) {
				return prefix + phoneNumber;
			}

			return ""; //phone number is not valid
		};
		createContacts({
			variables: {
				payload: {
					referees: [
						{
							...referees[0],
							phoneNumber: formatRefereePhoneNumber(referees[0].phoneNumber),
						},
					],
					guarantors: [
						{
							...guarantors[0],
							phoneNumber: formatGuarantorPhoneNumber(
								guarantors[0].phoneNumber
							),
						},
					],
				},
			},
		});
	};

	const formIsCompleted =
		referees.every((referee) => {
			return isValidReferee(referee, refereePhoneNumberPrefix);
		}) &&
		guarantors.every((guarantor) => {
			return isValidGuarantor(guarantor, guarantorPhoneNumberPrefix);
		});

	const saveButtonIsDisabled = !!(
		((refereePhoneNumberPrefix || referees[0].phoneNumber) &&
			!isValidIntlPhoneNumber(
				`${refereePhoneNumberPrefix}${referees[0].phoneNumber}`
			)) ||
		((guarantorPhoneNumberPrefix || guarantors[0].phoneNumber) &&
			!isValidIntlPhoneNumber(
				`${guarantorPhoneNumberPrefix}${guarantors[0].phoneNumber}`
			)) ||
		(referees[0].email && !isValidEmail(referees[0].email)) ||
		(guarantors[0].email && !isValidEmail(guarantors[0].email))
	);

	return (
		<div className="bg-white p-4 lg:p-8">
			<Helmet>
				<title>Background Check | LaborHack Pro</title>
			</Helmet>
			<h2 className="text-lg md:text-xl text-primary-500 font-bold mb-4 md:mb-8">
				Background Check
			</h2>
			<h4>
				Feel free to complete this form at your own pace. You can save your
				progress at any time and continue later by clicking the save button at
				the bottom of the page.
			</h4>
			<br></br>
			<h4 className="font-semibold text-primary-500">
				Status:{" "}
				{formIsCompleted ? (
					<span className="text-green-600">FORM COMPLETE</span>
				) : (
					<span className="text-red-500">FORM INCOMPLETE</span>
				)}
			</h4>
			<br></br>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 w-full">
				<div>
					<h3 className="font-semibold text-primary-500">Referee Details</h3>
					<p className="text-sm text-primary-300">
						Provide your referees' information
					</p>
				</div>
				<Stack spacing={2} className="px-2 md:px-4">
					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["referees[0].name"] &&
							!isValidInput(referees[0].name)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="refereeName"
							color="primary.500"
						>
							Referee Name
						</FormLabel>
						<Input
							id="refereeName"
							fontSize={"sm"}
							type={"text"}
							name="name"
							value={referees[0].name}
							onChange={handleRefereeChange(0)}
							errorBorderColor="red.500"
							placeholder=""
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>
					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["referees[0].phoneNumber"] &&
							(!isValidIntlPhoneNumber(
								`${refereePhoneNumberPrefix}${referees[0].phoneNumber}`
							) ||
								!isValidInput(refereePhoneNumberPrefix))
						}
						isRequired
					>
						<FormLabel fontSize={"sm"} htmlFor="phone" color="primary.500">
							Referee Phone Number
						</FormLabel>
						<InputGroup>
							<Select
								placeholder={refereePhoneNumberPrefixPlaceholder}
								fontSize={"sm"}
								width="215px"
								maxHeight="85vh"
								value={refereePhoneNumberPrefix}
								onChange={(e) => {
									setRefereePhoneNumberPrefix(() => {
										return !!e.target.value.length ? `+${e.target.value}` : "";
									});
								}}
							>
								{countriesWithDialingCodes.map((country) => (
									<option key={country.name} value={country.value}>
										{country.countryCode} (+{country.value})
									</option>
								))}
							</Select>
							<Input
								id="phone"
								type={"number"}
								fontSize={"sm"}
								name="phoneNumber"
								value={referees[0].phoneNumber}
								onChange={handleRefereeChange(0)}
								errorBorderColor="red.500"
								placeholder="80312345678"
							/>
							<InputRightElement
								children={
									isValidIntlPhoneNumber(
										`${refereePhoneNumberPrefix}${referees[0].phoneNumber}`
									) ? (
										<CheckIcon color={"green.500"} />
									) : (
										<CloseIcon w="3" h="3" color={"red.500"} />
									)
								}
							/>
						</InputGroup>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>
					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["referees[0].email"] &&
							!isValidEmail(referees[0].email)
						}
						isRequired
					>
						<FormLabel fontSize={"sm"} htmlFor="email" color="primary.500">
							Referee Email Address
						</FormLabel>
						<InputGroup>
							<Input
								id="email"
								type={"email"}
								fontSize={"sm"}
								name="email"
								value={referees[0].email}
								errorBorderColor="red.500"
								onChange={handleRefereeChange(0)}
							/>
						</InputGroup>
						<FormErrorMessage>Email is invalid</FormErrorMessage>
					</FormControl>

					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["referees[0].companyName"] &&
							!isValidInput(referees[0].companyName)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="companyName"
							color="primary.500"
						>
							Referee Company
						</FormLabel>
						<Input
							id="companyName"
							fontSize={"sm"}
							type={"text"}
							name="companyName"
							value={referees[0].companyName}
							onChange={handleRefereeChange(0)}
							errorBorderColor="red.500"
							placeholder=""
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>

					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["referees[0].companyAddress"] &&
							!isValidInput(referees[0].companyAddress)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="companyAddress"
							color="primary.500"
						>
							Referee Company Address
						</FormLabel>
						<Input
							id="companyAddress"
							fontSize={"sm"}
							type={"text"}
							name="companyAddress"
							value={referees[0].companyAddress}
							onChange={handleRefereeChange(0)}
							errorBorderColor="red.500"
							placeholder="1234, Main Street"
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>

					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["referees[0].jobTitle"] &&
							!isValidInput(referees[0].jobTitle)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="refereeJobTitle"
							color="primary.500"
						>
							Referee Job Title
						</FormLabel>
						<Input
							id="refereeJobTitle"
							fontSize={"sm"}
							type={"text"}
							name="jobTitle"
							value={referees[0].jobTitle}
							onChange={handleRefereeChange(0)}
							errorBorderColor="red.500"
							placeholder=""
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>

					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["referees[0].relationship"] &&
							!isValidInput(referees[0].relationship)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="relationship"
							color="primary.500"
						>
							Relationship with Referee
						</FormLabel>
						<Input
							id="relationship"
							fontSize={"sm"}
							type={"text"}
							name="relationship"
							value={referees[0].relationship}
							onChange={handleRefereeChange(0)}
							errorBorderColor="red.500"
							placeholder=""
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>
				</Stack>
			</div>

			<div className="my-4 md:my-8 w-full h-0.5 bg-primary-100" />

			<div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 w-full">
				<div>
					<h3 className="font-semibold text-primary-500">Guarantor Details</h3>
					<p className="text-sm text-primary-300">
						Provide your guarantors' information
					</p>
				</div>
				<Stack spacing={2} className="px-2 md:px-4">
					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["guarantors[0].name"] &&
							!isValidInput(guarantors[0].name)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="firstGuarantorName"
							color="primary.500"
						>
							Guarantor Name
						</FormLabel>
						<Input
							id="firstGuarantorName"
							fontSize={"sm"}
							type={"text"}
							name="name"
							value={guarantors[0].name}
							onChange={handleGuarantorChange(0)}
							errorBorderColor="red.500"
							placeholder=""
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>
					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["guarantors[0].phoneNumber"] &&
							!isValidIntlPhoneNumber(
								`${guarantorPhoneNumberPrefix}${guarantors[0].phoneNumber}`
							)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="firstGuarantorPhone"
							color="primary.500"
						>
							Guarantor Phone Number
						</FormLabel>
						<InputGroup>
							<Select
								placeholder={guarantorPhoneNumberPrefixPlaceholder}
								fontSize={"sm"}
								width="215px"
								maxHeight="85vh"
								value={guarantorPhoneNumberPrefix}
								onChange={(e) => {
									return setGuarantorPhoneNumberPrefix(() => {
										console.log({
											guarantorPhoneNumberPrefix,
											new: e.target.value,
										});
										return !!e.target.value.length ? `+${e.target.value}` : "";
									});
								}}
							>
								{countriesWithDialingCodes.map((country) => (
									<option key={country.name} value={country.value}>
										{country.countryCode} (+{country.value})
									</option>
								))}
							</Select>
							<Input
								id="firstGuarantorPhone"
								type={"number"}
								fontSize={"sm"}
								name="phoneNumber"
								value={guarantors[0].phoneNumber}
								onChange={handleGuarantorChange(0)}
								errorBorderColor="red.500"
								placeholder="80312345678"
							/>
							<InputRightElement
								children={
									isValidIntlPhoneNumber(
										`${guarantorPhoneNumberPrefix}${guarantors[0].phoneNumber}`
									) ? (
										<CheckIcon color={"green.500"} />
									) : (
										<CloseIcon w="3" h="3" color={"red.500"} />
									)
								}
							/>
						</InputGroup>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>
					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["guarantors[0].email"] &&
							!isValidEmail(guarantors[0].email)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="firstGuarantorEmail"
							color="primary.500"
						>
							Guarantor Email Address
						</FormLabel>
						<InputGroup>
							<Input
								id="firstGuarantorEmail"
								type={"email"}
								fontSize={"sm"}
								name="email"
								value={guarantors[0].email}
								onChange={handleGuarantorChange(0)}
								errorBorderColor="red.500"
							/>
						</InputGroup>
						<FormErrorMessage>Email is invalid</FormErrorMessage>
					</FormControl>

					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["guarantors[0].companyName"] &&
							!isValidInput(guarantors[0].companyName)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="firstGuarantorCompanyName"
							color="primary.500"
						>
							Guarantor Company
						</FormLabel>
						<Input
							id="firstGuarantorCompanyName"
							fontSize={"sm"}
							type={"text"}
							name="companyName"
							value={guarantors[0].companyName}
							onChange={handleGuarantorChange(0)}
							errorBorderColor="red.500"
							placeholder=""
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>

					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["guarantors[0].companyAddress"] &&
							!isValidInput(guarantors[0].companyAddress)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="firstGuarantorCompanyAddress"
							color="primary.500"
						>
							Guarantor Company Address
						</FormLabel>
						<Input
							id="firstGuarantorCompanyAddress"
							fontSize={"sm"}
							type={"text"}
							name="companyAddress"
							value={guarantors[0].companyAddress}
							onChange={handleGuarantorChange(0)}
							errorBorderColor="red.500"
							placeholder="1234, Main Street"
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>

					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["guarantors[0].jobTitle"] &&
							!isValidInput(guarantors[0].jobTitle)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="guarantorJobTitle"
							color="primary.500"
						>
							Guarantor Job Title
						</FormLabel>
						<Input
							id="guarantorJobTitle"
							fontSize={"sm"}
							type={"text"}
							name="jobTitle"
							value={guarantors[0].jobTitle}
							onChange={handleGuarantorChange(0)}
							errorBorderColor="red.500"
							placeholder=""
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>

					<FormControl
						isDisabled={response.loading}
						isInvalid={
							fieldsTouched["guarantors[0].relationship"] &&
							!isValidInput(guarantors[0].relationship)
						}
						isRequired
					>
						<FormLabel
							fontSize={"sm"}
							htmlFor="guarantorRelationship"
							color="primary.500"
						>
							Relationship with Guarantor
						</FormLabel>
						<Input
							id="guarantorRelationship"
							fontSize={"sm"}
							type={"text"}
							name="relationship"
							value={guarantors[0].relationship}
							onChange={handleGuarantorChange(0)}
							errorBorderColor="red.500"
							placeholder=""
						/>
						<FormErrorMessage>{inValidInputErrorMessage}</FormErrorMessage>
					</FormControl>
				</Stack>
			</div>

			<div className="my-4 md:my-8 w-full h-0.5 bg-primary-100" />
			<div className="flex justify-end">
				<Button
					isLoading={response.loading}
					isDisabled={saveButtonIsDisabled}
					loadingText="Submitting"
					fontSize={"sm"}
					colorScheme={"green"}
					variant={"solid"}
					spinnerPlacement="start"
					onClick={handleSubmit}
				>
					{formIsCompleted ? "Save" : "Save and Continue Later"}
				</Button>
			</div>
		</div>
	);
};
