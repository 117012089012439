import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  CheckIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { MdBook, MdTrendingUp } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { RequestFailed } from "../../components/RequestFailed";
import { TradeLevelCard } from "../../features/trades/TradeLevelCard";
import { useProfile } from "../../hooks/useProfile";
import { Pro, ProTradeLevelDiscount, Trade } from "../../types";
import Markdown from "react-markdown";
import { useToggles } from "../../hooks/useToggles";
import { TradeLevelCardV2 } from "../../features/trades/trade-level-card-v2";
import { ADD_TRADE_TO_PRO } from "src/common/graphql/mutation";

const GET_DISCOUNTS = gql`
	query GetDiscounts($tradeId: String!) {
		availableDiscounts(tradeId: $tradeId) {
			id
			code
			description
			prompt
			value
			applicableTradeLevels {
				id
			}
		}
	}
`;

const GET_TRADE = gql`
	query GetTrade($id: String!, $proId: String!) {
		trade(id: $id) {
			id
			name
			description
			levels {
				id
				isAvailable
				order
				requirements {
					id
					name
					description
					price
					prompt
					type
					actions {
						type
						url
						label
						prompt
						external
					}
					lmsCourseId
					invitationUrl
				}
			}
			currentLevel(proId: $proId)
			category {
				id
			}
		}
	}
`;



export const ViewTrade = () => {
  const { isLoading: togglesIsLoading, toggles } = useToggles();

  const {
    isOpen: basicConfirmationIsOpen,
    onOpen: basicConfirmationOnOpen,
    onClose: basicConfirmationOnClose,
  } = useDisclosure();

  const {
    isOpen: discountWouldBeAppliedConfirmationIsOpen,
    onOpen: discountWouldBeAppliedConfirmationOnOpen,
    onClose: discountWouldBeAppliedConfirmationOnClose,
  } = useDisclosure();

  const basicCancelRef = React.useRef<HTMLButtonElement>(null);

  const discountWouldBeAppliedCancelRef = React.useRef<HTMLButtonElement>(null);

  const { tradeId } = useParams<{ tradeId: string }>();

  const { loading, profile, error } = useProfile();

  const { loading: discountsLoading, data: discountsData } = useQuery<{
    availableDiscounts: ProTradeLevelDiscount[];
  }>(GET_DISCOUNTS, {
    variables: {
      tradeId,
    },
  });

  const [getTrade, { loading: tradeLoading, error: tradeError, data }] =
    useLazyQuery<{
      trade: Trade;
    }>(GET_TRADE, {
      variables: {
        id: tradeId,
        proId: profile?.id,
      },
    });

  const [
    addTradeToPro,
    { loading: addTradeToProLoading, error: addTradeToProError },
  ] = useMutation<{ addTradeToPro: Pro }>(ADD_TRADE_TO_PRO, {
    onCompleted: () => {
      discountWouldBeAppliedConfirmationOnClose();
      basicConfirmationOnClose();
    },
    onError: () => {
      discountWouldBeAppliedConfirmationOnClose();
      basicConfirmationOnClose();
    },
    refetchQueries: [
      "GetMyProfile",
      "GetTrade",
      "GetProTradeRequirementsByLevel",
      "GetProTradeLevelPayments",
      "GetDiscounts",
    ],
  });

  const handleStartTrade = () => {
    addTradeToPro({ variables: { tradeId } });
  };

  useEffect(() => {
    if (profile) {
      getTrade();
    }
  }, [profile, getTrade]);

  if (loading || tradeLoading || discountsLoading || togglesIsLoading)
    return <Loading />;

  if (tradeError || error || !data) return <RequestFailed />;

  const { currentLevel, category } = data.trade;

  const requirements = data.trade.levels.flatMap((level) => level.requirements);

  const enrolledTradeIdsSet = new Set(
    profile?.trades.map((trade) => trade.id) || []
  );

  const isEnrolled = !!(tradeId && enrolledTradeIdsSet.has(tradeId));

  const discount = discountsData?.availableDiscounts[0];

  const isFullyDiscounted = !!(discount && discount.value === 100);

  const handleStartTradeClick = () => {
    if (discount) {
      basicConfirmationOnClose();
      discountWouldBeAppliedConfirmationOnOpen();
    } else {
      handleStartTrade();
    }
  };

  return (
    <>
      <Helmet>
        <title>{data.trade.name} | LaborHack Pro Dashboard</title>
      </Helmet>
      <Link to='/trades'>
        <Button marginBottom={2} variant='ghost' leftIcon={<ArrowBackIcon />}>
          Back
        </Button>
      </Link>

      <AlertDialog
        isOpen={discountWouldBeAppliedConfirmationIsOpen}
        leastDestructiveRef={discountWouldBeAppliedCancelRef}
        onClose={discountWouldBeAppliedConfirmationOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Confirm Discount Usage
            </AlertDialogHeader>

            <AlertDialogBody>
              By proceeding, you'll apply your <strong>only</strong> discount on
              this trade.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={discountWouldBeAppliedCancelRef}
                onClick={discountWouldBeAppliedConfirmationOnClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme='green'
                isLoading={addTradeToProLoading || loading}
                loadingText='Starting trade...'
                onClick={handleStartTrade}
                ml={3}
              >
                Yes, Start Trade
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={basicConfirmationIsOpen}
        leastDestructiveRef={basicCancelRef}
        onClose={basicConfirmationOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Start New Trade
            </AlertDialogHeader>

            <AlertDialogBody>
              {profile?.categoryIds.includes(category.id)
                ? ""
                : "This trade is not in your selected profession. "}
              Are you sure you want to start this trade{" "}
              <strong>({data.trade.name})</strong>?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={basicCancelRef} onClick={basicConfirmationOnClose}>
                Cancel
              </Button>
              <Button
                colorScheme='green'
                isLoading={addTradeToProLoading || loading}
                loadingText='Starting trade...'
                onClick={handleStartTradeClick}
                ml={3}
              >
                Yes, Start Trade
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Container maxW='container.xl' bgColor='white' paddingY='4'>
        <VStack align='start' gap='6'>
          {discount && (
            <VStack
              w='full'
              bgColor='black'
              align='start'
              padding={4}
              style={{
                backgroundImage:
                  "linear-gradient( 109.6deg,  rgba(78,62,255,1) 11.2%, rgba(164,69,255,1) 91.1% )",
              }}
            >
              <Text
                letterSpacing='wide'
                textTransform='uppercase'
                fontSize='xs'
                fontWeight='semibold'
                color='white'
              >
                Limited Time Offer
              </Text>
              <HStack>
                <Text
                  color='white'
                  fontSize='2xl'
                  fontWeight='bold'
                  textTransform='uppercase'
                >
                  {discount.code} 🎉
                </Text>
              </HStack>

              <Text color='white'>{discount.description}</Text>
              <Text color='white'>
                <Markdown>{discount.prompt}</Markdown>
              </Text>
            </VStack>
          )}
          <VStack align='start' gap='2' w='full'>
            <Flex
              className='flex-col md:flex-row justify-between items-start md:items-center'
              gap='2'
              w='full'
            >
              <VStack align='start'>
                <Text
                  letterSpacing='wide'
                  textTransform='uppercase'
                  fontSize='xs'
                  fontWeight='semibold'
                  color='primary.500'
                >
                  Trade Paths
                </Text>
                <Text
                  fontSize='2xl'
                  fontWeight='bold'
                  className='!m-0'
                  color='primary.500'
                >
                  {data.trade.name}
                </Text>
              </VStack>
              {isEnrolled ? (
                <Button isDisabled rightIcon={<CheckIcon />}>
                  Enroled
                </Button>
              ) : (
                <Button
                  isLoading={addTradeToProLoading || loading}
                  loadingText='Starting trade...'
                  rightIcon={
                    addTradeToProError ? <RepeatIcon /> : <ArrowForwardIcon />
                  }
                  colorScheme={addTradeToProError ? "red" : "green"}
                  variant={addTradeToProError ? "ghost" : "solid"}
                  size='sm'
                  onClick={basicConfirmationOnOpen}
                >
                  {addTradeToProError
                    ? "Retry"
                    : isFullyDiscounted
                    ? "Get Started For Free"
                    : "Start Trade"}
                </Button>
              )}
            </Flex>

            <Flex wrap='wrap' gap='2'>
              <HStack
                border='1px'
                rounded='full'
                paddingY='1'
                paddingX='3'
                borderColor='primary.200'
              >
                <Icon as={MdTrendingUp} />
                <Text fontSize='sm' color='primary.500'>
                  {data.trade.levels.length} levels
                </Text>
              </HStack>
              <HStack
                border='1px'
                rounded='full'
                paddingY='1'
                paddingX='3'
                borderColor='primary.200'
              >
                <Icon as={MdBook} />
                <Text fontSize='sm' color='primary.500'>
                  {requirements.length} assessments
                </Text>
              </HStack>
            </Flex>
            <Text fontSize='sm' color='primary.500'>
              {data.trade.description}
            </Text>
          </VStack>
          <VStack align='start' w='full'>
            <Text
              fontSize='xl'
              fontWeight='bold'
              className='!m-0'
              color='primary.500'
            >
              Levels
            </Text>
            <VStack align='start' w='full' spacing='4'>
              {data.trade.levels
                .slice()
                .sort((a, b) => a.order - b.order)
                .filter((level) => level.isAvailable)
                .map((level) => {
                  const isDiscountedLevel =
                    discount?.applicableTradeLevels.find(
                      (tradeLevel) => tradeLevel.id === level.id
                    );

                  return toggles.use_requirement_payment ? (
                    <TradeLevelCardV2
                      key={level.id}
                      level={level}
                      nextLevel={(currentLevel || 0) + 1}
                      isEnrolled={isEnrolled}
                      discount={isDiscountedLevel ? discount : undefined}
                    />
                  ) : (
                    <TradeLevelCard
                      key={level.id}
                      level={level}
                      pro={profile!}
                      nextLevel={(currentLevel || 0) + 1}
                      isEnrolled={isEnrolled}
                      discount={isDiscountedLevel ? discount : undefined}
                    />
                  );
                })}
            </VStack>
          </VStack>
        </VStack>
      </Container>
    </>
  );
};
