import { gql } from "@apollo/client";

export const GET_TRADES = gql`
	query GetTradesWithFilters($filters: ListTradesFilters!) {
		trades(filters: $filters) {
			id
			name
			createdAt
			description
			category {
				id
				name
				proTitle
			}
		}
	}
`;

export const ADD_TRACK = gql`
	mutation AddTrack($track: ProTrack!) {
		addTrackToPro(track: $track) {
			id
			tracks
		}
	}
`;

export const ADD_TRADE_TO_PRO = gql`
	mutation AddTradeToPro($tradeId: String!) {
		addTradeToPro(tradeId: $tradeId) {
			id
			trades {
				id
				name
				description
				levels {
					id
					order
					requirements {
						id
						name
						description
						price
						prompt
						type
					}
				}
			}
		}
	}
`;
