import { gql, useMutation } from "@apollo/client";
import { Button, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { RequestFailed } from "../../components/RequestFailed";
import success from "../../assets/success.svg";

const CONFIRM_TRADE_LEVEL_PAYMENT = gql`
  mutation ConfirmTradeLevelPayment($tradeLevelPaymentId: String!) {
    confirmProTradeLevelPayment(tradeLevelPaymentId: $tradeLevelPaymentId) {
      id
      isPaid
    }
  }
`;

export const ConfirmTradeLevelPayment = () => {
  const navigate = useNavigate();

  const { levelPaymentId, tradeId } = useParams<{
    levelPaymentId: string;
    tradeId: string;
  }>();

  const [
    confirmTradeLevelPayment,
    { loading: confirmTradeLevelPaymentLoading, data, error },
  ] = useMutation(CONFIRM_TRADE_LEVEL_PAYMENT);

  const handleConfirmTradeLevelPayment = useCallback(() => {
    confirmTradeLevelPayment({
      variables: {
        tradeLevelPaymentId: levelPaymentId,
      },
    });
  }, [levelPaymentId, confirmTradeLevelPayment]);

  useEffect(() => {
    if (levelPaymentId) {
      handleConfirmTradeLevelPayment();
    }
  }, [levelPaymentId, handleConfirmTradeLevelPayment]);

  if (error) {
    return <RequestFailed />;
  }

  const goToTrade = () => {
    navigate(`/trades/${tradeId}`);
  };

  return (
    <VStack gap={4}>
      {confirmTradeLevelPaymentLoading && <Text>Confirming payment...</Text>}
      {data?.confirmProTradeLevelPayment.isPaid && (
        <VStack gap={2}>
          <Text fontSize={"xl"} fontWeight='semibold'>
            Payment Confirmed
          </Text>
          <Image src={success} alt='Success' boxSize='150px' />
          <Text fontSize='sm' textAlign='center'>
            Thank you. You can now access the next level of this trade. Click
            the button below to go back to the trade.
          </Text>
        </VStack>
      )}
      <HStack>
        <Button
          leftIcon={<Icon as={MdArrowBack} />}
          isLoading={confirmTradeLevelPaymentLoading}
          onClick={goToTrade}
        >
          Back to Trade
        </Button>
      </HStack>
    </VStack>
  );
};
