import { gql, useQuery } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdCheckCircle,
  MdLock,
} from "react-icons/md";
import { Loading } from "../../components/Loading";
import {
  ProTradeLevelDiscount,
  ProTradeRequirement,
  ProTradeRequirementInterviewEventStatus,
  TradeLevel,
  TradeRequirementType,
} from "../../types";
import { useToggles } from "../../hooks/useToggles";
import { LEVEL_ORDER_MARKETING_NAMES } from "../../constants";
import { TradeRequirementStatus } from "./TradeRequirementCard";
import { TradeRequirementCardV2 } from "./trade-requirement-card-v2";

const GET_PRO_TRADE_REQUIREMENTS_BY_LEVEL = gql`
  query GetProTradeRequirementsByLevel($levelId: String!) {
    proTradeRequirementsByTradeLevel(tradeLevelId: $levelId) {
      id
      tradeLevelPayments {
        id
      }
      requirement {
        id
        name
        passingScore
        lmsId
        lmsCourseId
      }
      attemptsRemaining
      hasPassed
      hasCompletedPayment
      results {
        attemptId
        scores
        passed
      }
      events {
        id
        attemptId
        date
        status
        cancellationUrl
        rescheduleUrl
      }
      scheduledFor
      waived
      canReschedule
    }
  }
`;

interface TradeLevelCardProps {
  level: TradeLevel;
  nextLevel: number;
  isEnrolled?: boolean;
  discount?: ProTradeLevelDiscount;
}

export const TradeLevelCardV2 = ({
  level,
  nextLevel,
  isEnrolled,
  discount,
}: TradeLevelCardProps) => {
  const { isLoading: isLoadingFeatureToggles, toggles } = useToggles();

  const isCurrentLevel = isEnrolled && level.order === nextLevel;

  const [isOpen, { toggle }] = useBoolean(
    (!isEnrolled && level.order === 1) || isCurrentLevel
  );

  const { loading, data, refetch } = useQuery<{
    proTradeRequirementsByTradeLevel: ProTradeRequirement[];
  }>(GET_PRO_TRADE_REQUIREMENTS_BY_LEVEL, {
    variables: {
      levelId: level.id,
    },
    fetchPolicy: "no-cache",
  });

  /**
   * The current level returned by the API is your attained level. So if you are supposed to attempt level 2, the API will return 1.
   */
  const isCompleted = level.order < nextLevel;
  const levelPaymentDisabled = !isEnrolled || level.order > nextLevel;

  const config = {
    borderColor: "primary.150",
  };

  if (isCurrentLevel) {
    config.borderColor = "yellow.300";
  }

  if (isCompleted) {
    config.borderColor = "green.300";
  }

  const requirementsPassed = data?.proTradeRequirementsByTradeLevel.filter(
    (requirement) => requirement.hasPassed && requirement.hasCompletedPayment
  );

  const progress =
    ((requirementsPassed?.length || 0) / level.requirements.length) * 100;

  if (isLoadingFeatureToggles) return <Loading />;

  return (
    <>
      <VStack align='start' gap={2} w='full' cursor='pointer'>
        <HStack
          justify='space-between'
          w='full'
          paddingX='6'
          paddingY='3'
          border='2px'
          borderColor={config.borderColor}
          rounded='full'
          onClick={toggle}
        >
          <Flex>
            <Text fontWeight='bold' mr={1}>{`${
              LEVEL_ORDER_MARKETING_NAMES[level.order].name
            }`}</Text>
            <Text>{`(Level ${level.order})`}</Text>
          </Flex>
          <HStack>
            {isCurrentLevel && (
              <CircularProgress
                value={progress}
                color='yellow.500'
                size='24px'
              />
            )}
            {isCompleted && <Icon as={MdCheckCircle} color='green.500' />}
            {levelPaymentDisabled && <Icon as={MdLock} />}
            {isOpen ? (
              <Icon as={MdArrowDropUp} />
            ) : (
              <Icon as={MdArrowDropDown} />
            )}
          </HStack>
        </HStack>
        {isOpen &&
          (loading ? (
            <Loading />
          ) : (
            <VStack
              w='full'
              align='start'
              paddingX='4'
              paddingY='4'
              border='1px'
              borderColor={config.borderColor}
              rounded='md'
              gap={2}
            >
              {level.order === 1 && (
                <Alert status='info' variant='solid' fontSize='sm'>
                  <AlertIcon />
                  Complete Level 1 to gain access to jobs and start earning
                </Alert>
              )}

              <VStack
                align='start'
                w='full'
                spacing={2}
                divider={
                  <Divider orientation='horizontal' borderColor='primary.200' />
                }
              >
                {level.requirements
                  .slice()
                  .filter((requirement) => {
                    if (!toggles.show_interview_requirements) {
                      return (
                        requirement.type !== TradeRequirementType.INTERVIEW
                      );
                    }

                    return true;
                  })
                  .map((requirement) => {
                    // If the requirement still has attempts left, show the start assessment button
                    const proTradeRequirement =
                      data?.proTradeRequirementsByTradeLevel.find(
                        (proTradeRequirement) =>
                          proTradeRequirement.requirement.id === requirement.id
                      );

                    let hasAttemptsLeft = false;

                    const hasResult = !!proTradeRequirement?.results?.length;

                    if (
                      proTradeRequirement &&
                      proTradeRequirement.attemptsRemaining > 0
                    ) {
                      hasAttemptsLeft = true;
                    }

                    let status: TradeRequirementStatus = "NOT_ENROLLED";

                    const scheduledEvent = proTradeRequirement?.events?.find(
                      (event) =>
                        event.status ===
                        ProTradeRequirementInterviewEventStatus.SCHEDULED
                    );

                    const latestResult =
                      proTradeRequirement?.results?.[
                        proTradeRequirement?.results?.length - 1
                      ];

                    const eventForLatestResult =
                      proTradeRequirement?.events?.find(
                        (event) => event.attemptId === latestResult?.attemptId
                      );

                    if (isEnrolled) {
                      status = "READY";

                      if (hasAttemptsLeft) {
                        status = "READY";
                      }

                      if (proTradeRequirement?.waived) {
                        status = "PASSED";
                      }

                      if (hasResult) {
                        status = proTradeRequirement!.hasPassed
                          ? "PASSED"
                          : "FAILED";
                      }

                      if (
                        eventForLatestResult?.status ===
                        ProTradeRequirementInterviewEventStatus.CANCELLED
                      ) {
                        status = "CANCELLED";
                      }
                    }

                    return (
                      <TradeRequirementCardV2
                        isCurrentLevel={isCurrentLevel}
                        attemptsRemaining={
                          proTradeRequirement
                            ? proTradeRequirement.attemptsRemaining
                            : 0
                        }
                        requirement={requirement}
                        proTradeRequirement={proTradeRequirement}
                        status={status}
                        isFree={!!discount && discount.value === 100}
                        event={scheduledEvent}
                        refresh={() => {
                          refetch();
                        }}
                        key={requirement.id}
                      />
                    );
                  })}
              </VStack>
            </VStack>
          ))}
      </VStack>
    </>
  );
};
