import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowForwardIcon, CalendarIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Badge,
  Button,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { BiRefresh } from "react-icons/bi";
import { MdCheckCircle, MdRemoveCircle } from "react-icons/md";
import { GET_RETRY_DISCOUNTS } from "../../common/graphql/queries";
import {
  ProTradeRequirement,
  ProTradeRequirementInterviewEvent,
  ProTradeRequirementInterviewEventStatus,
  TradeRequirement,
  TradeRequirementAction,
  TradeRequirementActionTypes,
  TradeRequirementType,
} from "../../types";
import { formatAsNaira } from "../../utlis/helpers";
import { TradeRequirementActions } from "./TradeRequirementActions";

export type TradeRequirementStatus =
  | "NOT_ENROLLED"
  | "READY"
  | "PASSED"
  | "FAILED"
  | "CANCELLED";

export interface TradeRequirementCardProps {
  requirement: TradeRequirement;
  proTradeRequirement?: ProTradeRequirement;
  status: TradeRequirementStatus;
  hasAttempt?: boolean;
  attemptsRemaining: number;
  isFree?: boolean;
  isLoading?: boolean;
  isCurrentLevel?: boolean;
  event?: ProTradeRequirementInterviewEvent;
  refresh: () => void;
  unlockLevel: () => void;
}

export const INITIATE_PRO_TRADE_REQUIREMENT_RETAKE = gql`
  mutation InitiateProTradeRequirementRetake($tradeRequirementId: String!) {
    initiateProTradeRequirementRetakeByPro(
      tradeRequirementId: $tradeRequirementId
    ) {
      id
    }
  }
`;

const CANCEL_MY_INTERVIEW = gql`
  mutation CancelMyInterview($tradeRequirementId: String!, $eventId: String!) {
    cancelMyInterviewRequirement(
      tradeRequirementId: $tradeRequirementId
      eventId: $eventId
    ) {
      id
      attemptsRemaining
      hasPassed
      hasCompletedPayment
      results {
        scores
        passed
      }
      events {
        id
        date
        status
        cancellationUrl
        rescheduleUrl
      }
    }
  }
`;

const renderAction = (action: TradeRequirementAction) => {
  if (action.type === TradeRequirementActionTypes.OPEN_URL) {
    return (
      <Button
        as={Link}
        rightIcon={<ArrowForwardIcon />}
        colorScheme='green'
        size='sm'
        variant='solid'
        href={action.url}
        _hover={{ textDecoration: "none" }}
        loadingText='Enrolling...'
      >
        Start Assessment
      </Button>
    );
  }

  return null;
};

export const TradeRequirementCard = ({
  requirement: { id, name, type, price, invitationUrl, actions, prompt },
  proTradeRequirement,
  status,
  attemptsRemaining,
  isFree,
  isLoading,
  isCurrentLevel,
  event,
  refresh,
  unlockLevel,
}: PropsWithChildren<TradeRequirementCardProps>) => {
  const { user } = useAuth0();

  const [cancelInterviewModalOpen, setCancelInterviewModalOpen] =
    useState(false);

  const [scheduleInterviewModalOpen, setScheduleInterviewModalOpen] =
    useState(false);

  const [isScheduled, setIsScheduled] = useState(false);

  const [
    availableRetryDiscount,
    { loading: isDiscountLoading, data: discountData },
  ] = useLazyQuery(GET_RETRY_DISCOUNTS);

  const [
    initiateProTradeRequirementRetake,
    { loading: isRetakeLoading, error: retakeError },
  ] = useMutation(INITIATE_PRO_TRADE_REQUIREMENT_RETAKE, {
    variables: {
      tradeRequirementId: id,
    },
    refetchQueries: ["GetProTradeRequirementsByLevel"],
    awaitRefetchQueries: true,
  });

  const [cancelMyInterview, cancelMyInterviewResponse] = useMutation(
    CANCEL_MY_INTERVIEW,
    {
      variables: {
        tradeRequirementId: id,
        eventId: event?.id,
      },
      refetchQueries: ["GetProTradeRequirementsByLevel"],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setCancelInterviewModalOpen(false);
      },
    }
  );

  useEffect(() => {
    if (status === "FAILED") {
      availableRetryDiscount({
        variables: {
          tradeRequirementId: id,
        },
      });
    }
  }, [status, availableRetryDiscount, id]);

  useEffect(() => {
    function isCalendlyEvent(e: any) {
      return (
        e.origin === "https://calendly.com" &&
        e.data.event &&
        e.data.event.indexOf("calendly.") === 0
      );
    }

    window.addEventListener("message", function (e) {
      if (isCalendlyEvent(e)) {
        if (e.data.event === "calendly.event_scheduled") {
          setIsScheduled(true);
          refresh();
        }
      }
    });

    return () => {
      window.removeEventListener("message", function (e) {
        console.log(e);
      });
    };
  }, [refresh]);

  const discount = discountData?.availableRetryDiscounts[0];

  if (isDiscountLoading) return <Skeleton height='20px' />;

  const handleScheduleInterview = () => {
    window.Calendly.initPopupWidget({
      url: `${invitationUrl}?hide_event_type_details=1`,
      prefill: {
        name: user?.name,
        email: user?.email,
      },
    });
  };

  const renderFooter = () => {
    if (status === "PASSED" && attemptsRemaining >= 0) {
      return null;
    }

    if (attemptsRemaining > 0) {
      if (status === "FAILED" || status === "CANCELLED" || status === "READY") {
        if (type === TradeRequirementType.INTERVIEW) {
          if (isScheduled) {
            return <p className='text-sm'>Interview Scheduled</p>;
          }

          if (
            !event ||
            event.status === ProTradeRequirementInterviewEventStatus.CANCELLED
          ) {
            return (
              <Button
                rightIcon={<CalendarIcon />}
                colorScheme='blue'
                size='sm'
                variant='solid'
                onClick={() => setScheduleInterviewModalOpen(true)}
                _hover={{ textDecoration: "none" }}
              >
                Schedule Interview
              </Button>
            );
          }

          if (
            event.status === ProTradeRequirementInterviewEventStatus.SCHEDULED
          ) {
            return (
              <VStack alignItems='flex-end' spacing={2} my={2}>
                <p className='text-sm'>
                  Interview Scheduled{" "}
                  <strong>
                    {format(new Date(event.date), "dd MMM yyyy, HH:mm")}
                  </strong>
                </p>
                {proTradeRequirement?.canReschedule ? (
                  <HStack spacing={4}>
                    <Button
                      as={Link}
                      rightIcon={<CalendarIcon />}
                      size='sm'
                      variant='link'
                      colorScheme='blue'
                      href={event.rescheduleUrl}
                      isExternal
                    >
                      Reschedule
                    </Button>
                    <Button
                      as={Link}
                      rightIcon={<DeleteIcon />}
                      size='sm'
                      variant='link'
                      href={event.cancellationUrl}
                      colorScheme='red'
                      isExternal
                    >
                      Cancel
                    </Button>
                  </HStack>
                ) : (
                  <Button
                    rightIcon={<DeleteIcon />}
                    size='sm'
                    variant='ghost'
                    colorScheme='red'
                    isLoading={cancelMyInterviewResponse.loading}
                    onClick={() => setCancelInterviewModalOpen(true)}
                  >
                    Cancel Interview
                  </Button>
                )}
              </VStack>
            );
          }
        } else {
          if (actions.length > 1) {
            return <TradeRequirementActions actions={actions} />;
          } else if (actions.length === 1) {
            return renderAction(actions[0]);
          } else {
            return null;
          }
        }
      }
    } else {
      if (discount) {
        return (
          <Button
            rightIcon={retakeError ? <BiRefresh /> : <ArrowForwardIcon />}
            colorScheme={retakeError ? "red" : "green"}
            size='sm'
            variant={retakeError ? "ghost" : "solid"}
            isLoading={isRetakeLoading}
            loadingText='Retaking Assessment...'
            onClick={() => initiateProTradeRequirementRetake()}
          >
            {retakeError ? "Try again" : "Retake Assessment"}
          </Button>
        );
      }

      return (
        <Button
          rightIcon={<ArrowForwardIcon />}
          colorScheme='green'
          size='sm'
          variant='outline'
          isLoading={isLoading}
          loadingText='Getting Payment Link...'
          onClick={unlockLevel}
        >
          Pay Now
        </Button>
      );
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={cancelInterviewModalOpen}
        onClose={() => setCancelInterviewModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Interview Cancellation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Please note that if you cancel your interview, you will be
              required to pay a fee to rebook. Do you wish to proceed to cancel?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='red'
              variant='ghost'
              mr={3}
              onClick={() => setCancelInterviewModalOpen(false)}
            >
              Close
            </Button>
            <Button
              colorScheme='red'
              variant='solid'
              onClick={() => cancelMyInterview()}
              isLoading={cancelMyInterviewResponse.loading}
            >
              Yes, Cancel My Interview
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={scheduleInterviewModalOpen}
        onClose={() => setScheduleInterviewModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Schedule My Interview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Please note that you might not be able to reschedule your
              interview if it is too close to your selected date as we need to
              prepare for the interview. Make sure you choose a date that you
              are sure you can make.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='red'
              variant='ghost'
              mr={3}
              onClick={() => setScheduleInterviewModalOpen(false)}
            >
              Close
            </Button>
            <Button
              rightIcon={<CalendarIcon />}
              onClick={() => {
                handleScheduleInterview();
                setScheduleInterviewModalOpen(false);
              }}
              _hover={{ textDecoration: "none" }}
              colorScheme='green'
              variant='solid'
            >
              Choose Date
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack align='start' w='full' gap={2}>
        {status === "FAILED" && discount && (
          <Alert status='info'>
            <AlertIcon />
            {discount.description}
          </Alert>
        )}

        <VStack align='start'>
          <Text fontWeight='semibold' color='primary.500'>
            {name}
          </Text>
          <Text fontSize='sm' color='primary.500'>
            {prompt}
          </Text>
          {(status === "NOT_ENROLLED" || status === "FAILED") && (
            <HStack>
              <Text
                fontSize='sm'
                color='primary.500'
                textDecoration={isFree ? "line-through" : undefined}
              >
                {formatAsNaira(price)}
              </Text>
              {isFree && <Badge colorScheme='green'>Free</Badge>}
            </HStack>
          )}
        </VStack>
        {status !== "NOT_ENROLLED" && (
          <VStack w='full'>
            <HStack justify='end' w='full' gap={2}>
              {status === "PASSED" && (
                <HStack>
                  <Text fontSize='sm'>Passed</Text>
                  <Icon as={MdCheckCircle} color='green.500' />
                </HStack>
              )}
              {status === "FAILED" && (
                <HStack>
                  <Text fontSize='sm'>Failed</Text>
                  <Icon as={MdRemoveCircle} color='red.500' />
                </HStack>
              )}
              {isCurrentLevel && renderFooter()}
            </HStack>
          </VStack>
        )}
      </VStack>
    </Fragment>
  );
};
