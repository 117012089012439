import { gql, useMutation, useQuery } from "@apollo/client";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
	Alert,
	AlertIcon,
	Button,
	CircularProgress,
	Divider,
	Flex,
	HStack,
	Icon,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useBoolean,
	VStack,
} from "@chakra-ui/react";
import {
	MdArrowDropDown,
	MdArrowDropUp,
	MdCheckCircle,
	MdLock,
} from "react-icons/md";
import { GET_PRO_TRADE_LEVEL_PAYMENTS } from "../../common/graphql/queries";
import { Loading } from "../../components/Loading";
import {
	ProTradeLevelDiscount,
	ProTradeLevelPayment,
	ProTradeRequirement,
	ProTradeRequirementInterviewEventStatus,
	TradeLevel,
	TradeRequirementType,
} from "../../types";
import { TradeLevelCheckout } from "./TradeLevelCheckout";
import { useToggles } from "../../hooks/useToggles";
import { LEVEL_ORDER_MARKETING_NAMES } from "../../constants";
import {
	TradeRequirementCard,
	TradeRequirementStatus,
} from "./TradeRequirementCard";
import { GetMyProfileQuery } from "../../__generated__/graphql";

const INITIATE_PRO_TRADE_LEVEL_PAYMENT = gql`
	mutation InitiateProTradeLevelPayment($tradeLevelId: String!) {
		initiateProTradeLevelPaymentByPro(tradeLevelId: $tradeLevelId) {
			id
			affectedTradeRequirements {
				id
				name
				price
			}
			payments {
				id
				amount
				checkout
			}
			discount {
				id
				code
				description
				value
			}
		}
	}
`;

const REFRESH_PRO_TRADE_LEVEL_PAYMENT = gql`
	mutation RefreshProTradeLevelPayment($tradeLevelPaymentId: String!) {
		refreshProTradeLevelPayment(tradeLevelPaymentId: $tradeLevelPaymentId) {
			id
			affectedTradeRequirements {
				id
				name
				price
			}
			payments {
				id
				amount
				checkout
			}
			discount {
				id
				code
				description
				value
			}
		}
	}
`;

const START_ASSESSEMENT = gql`
	mutation StartAssessment($proTradeRequirementId: String!) {
		startAssessment(proTradeRequirementId: $proTradeRequirementId) {
			id
			requirement {
				id
				lmsId
				lmsCourseId
			}
		}
	}
`;

const GET_PRO_TRADE_REQUIREMENTS_BY_LEVEL = gql`
	query GetProTradeRequirementsByLevel($levelId: String!) {
		proTradeRequirementsByTradeLevel(tradeLevelId: $levelId) {
			id
			tradeLevelPayments {
				id
			}
			requirement {
				id
				name
				passingScore
				lmsId
				lmsCourseId
			}
			attemptsRemaining
			hasPassed
			hasCompletedPayment
			results {
				attemptId
				scores
				passed
			}
			events {
				id
				attemptId
				date
				status
				cancellationUrl
				rescheduleUrl
			}
			scheduledFor
			waived
			canReschedule
		}
	}
`;

interface TradeLevelCardProps {
	level: TradeLevel;
	pro: GetMyProfileQuery["myProInformation"];
	nextLevel: number;
	isEnrolled?: boolean;
	discount?: ProTradeLevelDiscount;
}

export const TradeLevelCard = ({
	level,
	pro,
	nextLevel,
	isEnrolled,
	discount,
}: TradeLevelCardProps) => {
	const { isLoading: isLoadingFeatureToggles, toggles } = useToggles();

	const isCurrentLevel = isEnrolled && level.order === nextLevel;

	const [isOpen, { toggle }] = useBoolean(
		(!isEnrolled && level.order === 1) || isCurrentLevel
	);

	const [
		paymentBreakdownIsOpen,
		{ on: showPaymentBreakdown, off: hidePaymentBreakdown },
	] = useBoolean(false);

	const { loading, data, refetch } = useQuery<{
		proTradeRequirementsByTradeLevel: ProTradeRequirement[];
	}>(GET_PRO_TRADE_REQUIREMENTS_BY_LEVEL, {
		variables: {
			levelId: level.id,
		},
	});

	const [
		refreshProTradeLevelPayment,
		{ loading: refreshProTradeLevelPaymentLoading },
	] = useMutation<{
		refreshProTradeLevelPayment: ProTradeLevelPayment;
	}>(REFRESH_PRO_TRADE_LEVEL_PAYMENT, {
		onCompleted: (data) => {
			showPaymentBreakdown();
		},
	});

	const {
		data: getProTradeLevelPaymentsData,
		refetch: getProTradeLevelPaymentsRefetch,
	} = useQuery<{
		proTradeLevelPayments: ProTradeLevelPayment[];
	}>(GET_PRO_TRADE_LEVEL_PAYMENTS, {
		variables: {
			tradeLevelId: level.id,
			proId: pro.id,
		},
	});

	const [startAssessment] = useMutation(START_ASSESSEMENT);

	const [
		initiateProTradeLevelPayment,
		{ loading: initiateProTradeLevelPaymentLoading },
	] = useMutation<{
		initiateProTradeLevelPaymentByPro: ProTradeLevelPayment;
	}>(INITIATE_PRO_TRADE_LEVEL_PAYMENT, {
		onCompleted: (data) => {
			showPaymentBreakdown();
		},
	});

	/**
	 * The current level returned by the API is your attained level. So if you are supposed to attempt level 2, the API will return 1.
	 */

	const isCompleted = level.order < nextLevel;
	const levelPaymentDisabled = !isEnrolled || level.order > nextLevel;

	const config = {
		borderColor: "primary.150",
	};

	if (isCurrentLevel) {
		config.borderColor = "yellow.300";
	}

	if (isCompleted) {
		config.borderColor = "green.300";
	}

	const handleStartAssessment = (proTradeRequirementId: string) => {
		startAssessment({
			variables: {
				proTradeRequirementId,
			},
		});
	};

	const latestProTradeLevelPayment =
		getProTradeLevelPaymentsData?.proTradeLevelPayments.slice().sort((a, b) => {
			return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
		})[0];

	const levelPaymentAlreadyInitiated = !!latestProTradeLevelPayment;
	const tradeLevelPaymentIsPaid = !!latestProTradeLevelPayment?.isPaid;

	const isLocked =
		!levelPaymentAlreadyInitiated ||
		(levelPaymentAlreadyInitiated && !tradeLevelPaymentIsPaid);

	const unlockLevel = () => {
		if (levelPaymentAlreadyInitiated && !tradeLevelPaymentIsPaid) {
			console.log("refreshing payment", latestProTradeLevelPayment.id);
			return refreshProTradeLevelPayment({
				variables: {
					tradeLevelPaymentId: latestProTradeLevelPayment.id,
				},
			});
		} else {
			return initiateProTradeLevelPayment({
				variables: {
					tradeLevelId: level.id,
				},
			});
		}
	};

	const requirementsPassed = data?.proTradeRequirementsByTradeLevel.filter(
		(requirement) => requirement.hasPassed && requirement.hasCompletedPayment
	);

	const progress =
		((requirementsPassed?.length || 0) / level.requirements.length) * 100;

	const handleContinue = () => {
		refetch();
		getProTradeLevelPaymentsRefetch();
		hidePaymentBreakdown();
	};

	if (isLoadingFeatureToggles) return <Loading />;

	return (
		<>
			<Modal
				isOpen={paymentBreakdownIsOpen}
				onClose={handleContinue}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color="primary.500">Checkout</ModalHeader>

					<ModalBody>
						<TradeLevelCheckout
							tradeLevelId={level.id}
							proId={pro.id}
							onClose={handleContinue}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
			<VStack align="start" gap={2} w="full" cursor="pointer">
				<HStack
					justify="space-between"
					w="full"
					paddingX="6"
					paddingY="3"
					border="2px"
					borderColor={config.borderColor}
					rounded="full"
					onClick={toggle}
				>
					<Flex>
						<Text fontWeight="bold" mr={1}>{`${
							LEVEL_ORDER_MARKETING_NAMES[level.order].name
						}`}</Text>
						<Text>{`(Level ${level.order})`}</Text>
					</Flex>
					<HStack>
						{isCurrentLevel && (
							<CircularProgress
								value={progress}
								color="yellow.500"
								size="24px"
							/>
						)}
						{isCompleted && <Icon as={MdCheckCircle} color="green.500" />}
						{levelPaymentDisabled && <Icon as={MdLock} />}
						{isOpen ? (
							<Icon as={MdArrowDropUp} />
						) : (
							<Icon as={MdArrowDropDown} />
						)}
					</HStack>
				</HStack>
				{isOpen &&
					(loading ? (
						<Loading />
					) : (
						<VStack
							w="full"
							align="start"
							paddingX="4"
							paddingY="4"
							border="1px"
							borderColor={config.borderColor}
							rounded="md"
							gap={2}
						>
							{level.order === 1 && (
								<Alert status="info" variant="solid" fontSize="sm">
									<AlertIcon />
									Complete Level 1 to gain access to jobs and start earning
								</Alert>
							)}
							{
								// If the requirements have not been initiated or all requirements need payment show a unlock banner
								isEnrolled && isLocked && (
									<VStack
										align="start"
										paddingY="4"
										paddingX="4"
										rounded="md"
										bgGradient="linear(to-l, #7928CA, #FF0080)"
									>
										<Text fontWeight="semibold" color="white">
											Level Locked
										</Text>
										<Text fontSize="sm" color="white">
											This level has paid assessments. You can make a payment by
											clicking the button below
										</Text>
										<HStack justify="end" w="full">
											<Button
												rightIcon={<ArrowForwardIcon />}
												colorScheme="brand"
												size="sm"
												variant="ghost"
												color="white"
												isDisabled={levelPaymentDisabled}
												isLoading={initiateProTradeLevelPaymentLoading}
												onClick={unlockLevel}
											>
												Unlock Level
											</Button>
										</HStack>
									</VStack>
								)
							}
							<VStack
								align="start"
								w="full"
								spacing={2}
								divider={
									<Divider orientation="horizontal" borderColor="primary.200" />
								}
							>
								{level.requirements
									.slice()
									.filter((requirement) => {
										if (!toggles.show_interview_requirements) {
											return (
												requirement.type !== TradeRequirementType.INTERVIEW
											);
										}

										return true;
									})
									.map((requirement) => {
										// If the requirement still has attempts left, show the start assessment button
										const proTradeRequirement =
											data?.proTradeRequirementsByTradeLevel.find(
												(proTradeRequirement) =>
													proTradeRequirement.requirement.id === requirement.id
											);

										let hasAttemptsLeft = false;

										const hasResult = !!proTradeRequirement?.results?.length;

										if (
											proTradeRequirement &&
											proTradeRequirement.attemptsRemaining > 0
										) {
											hasAttemptsLeft = true;
										}

										let status: TradeRequirementStatus = "NOT_ENROLLED";

										const scheduledEvent = proTradeRequirement?.events?.find(
											(event) =>
												event.status ===
												ProTradeRequirementInterviewEventStatus.SCHEDULED
										);

										const latestResult =
											proTradeRequirement?.results?.[
												proTradeRequirement?.results?.length - 1
											];

										const eventForLatestResult =
											proTradeRequirement?.events?.find(
												(event) => event.attemptId === latestResult?.attemptId
											);

										if (isEnrolled) {
											status = "READY";

											if (hasAttemptsLeft) {
												status = "READY";
											}

											if (proTradeRequirement?.waived) {
												status = "PASSED";
											}

											if (hasResult) {
												status = proTradeRequirement!.hasPassed
													? "PASSED"
													: "FAILED";
											}

											if (
												eventForLatestResult?.status ===
												ProTradeRequirementInterviewEventStatus.CANCELLED
											) {
												status = "CANCELLED";
											}
										}

										return (
											<TradeRequirementCard
												isCurrentLevel={isCurrentLevel}
												hasAttempt={hasAttemptsLeft}
												attemptsRemaining={
													proTradeRequirement
														? proTradeRequirement.attemptsRemaining
														: 0
												}
												requirement={requirement}
												proTradeRequirement={proTradeRequirement}
												status={status}
												isLoading={
													initiateProTradeLevelPaymentLoading ||
													refreshProTradeLevelPaymentLoading
												}
												unlockLevel={() => {
													toggles.use_discount_for_course_enrolment
														? unlockLevel()
														: unlockLevel().then(() => {
																handleStartAssessment(proTradeRequirement!.id);
														  });
												}}
												isFree={!!discount && discount.value === 100}
												event={scheduledEvent}
												refresh={() => {
													refetch();
												}}
												key={requirement.id}
											/>
										);
									})}
							</VStack>
						</VStack>
					))}
			</VStack>
		</>
	);
};
