import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Loading } from "../../components/Loading";
import { useProApplication } from "../../hooks/useProApplication";
import { logEvent } from "../../utlis/analytics";
import ApplicationFormV2 from "src/components/ApplicationFormV2";
import { CustomNavigate } from "src/App";

export interface ApplyProps {}

export const ApplyV2 = () => {
	useEffect(() => {
		logEvent("page_view");
	}, []);

	/** If the user has a profile, go back home */
	const { loading, proApplication } = useProApplication();

	if (loading) {
		return <Loading />;
	}

	if (proApplication) {
		return <CustomNavigate to="/my-portfolio" />;
	}

	return (
		<>
			<Helmet>
				<title>Registration | LaborHack Pro</title>
			</Helmet>
			<ApplicationFormV2 />
		</>
	);
};
