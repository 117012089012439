import {
	Divider,
	Heading,
	IconButton,
	useDisclosure,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Tag,
	TagLabel,
} from "@chakra-ui/react";
import clsx from "clsx";
import { format } from "date-fns";
import { Edit2, Pin, ShieldCheck } from "lucide-react";
import React from "react";
import { PortfolioWorkHistoryItem } from "../../types";
import { Rating } from "./rating";
import { WorkHistoryEditor } from "./work-history-editor";
import { formatAsNaira, getCappedAmount } from "src/utlis/helpers";

interface WorkHistoryItemProps
	extends React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {
	item: PortfolioWorkHistoryItem;
	editable?: boolean;
	pinned?: boolean;
	canPin?: boolean;

	onPinToggle?: () => void;

	isLoading?: boolean;
}

export function WorkHistoryItem({
	item,
	className,
	editable,
	pinned,
	canPin,
	onPinToggle,
	isLoading,
}: WorkHistoryItemProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { name, description, budget, endedAt, clientReview, verified, id } =
		item;

	return (
		<>
			{!!id && (
				<Modal
					isOpen={isOpen}
					onClose={onClose}
					isCentered
					closeOnOverlayClick
					size="lg"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader className="font-[AeonikPro] !text-2xl">
							Edit Work History
						</ModalHeader>
						<Divider />
						<ModalBody>
							<WorkHistoryEditor item={{ ...item, id }} onClose={onClose} />
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
			<div
				className={clsx(" grid grid-cols-[1fr,32px] border-b py-4", className)}
			>
				<div className={clsx("flex flex-col gap-4 ")}>
					<div className="flex flex-col gap-2 items-start">
						{pinned && (
							<Tag
								size="lg"
								display="flex"
								alignItems="center"
								borderRadius="full"
								bg="brand.50"
								colorScheme="brand"
							>
								<Pin className="w-4 mr-2 rotate-45 fill-brand-500" />
								<TagLabel fontWeight="500">Pinned</TagLabel>
							</Tag>
						)}
						<div className="flex flex-col lg:flex-row items-start lg:items-center  gap-1">
							<Heading as="h4" fontSize={18} className="font-medium m-0">
								{name}
							</Heading>

							{verified && (
								<div className="flex  items-center gap-1">
									<ShieldCheck className="fill-brand-500 stroke-white" />
									<p>Verified</p>
								</div>
							)}
						</div>

						<div className="flex flex-col lg:flex-row gap-1 lg:gap-4">
							<div className="flex items-center gap-2 lg:gap-4">
								{clientReview && (
									<>
										<div className="flex items-center gap-1">
											<Rating size="sm" rating={clientReview.rating} />
											<p>{clientReview.rating.toFixed(2)}</p>
										</div>

										<Divider orientation="vertical" />
									</>
								)}

								{budget ? (
									<p>
										Budget:{" "}
										{editable
											? formatAsNaira(budget, 2)
											: `${formatAsNaira(getCappedAmount(budget), 0)}+`}
									</p>
								) : (
									<p>Budget: N/A</p>
								)}
							</div>

							<Divider orientation="vertical" />
							<p>{format(new Date(endedAt), "MMM dd, yyyy")}</p>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						{description !== name && <p>{description}</p>}
						{clientReview?.feedback && (
							<blockquote className="text-sm text-gray-500">
								<q>{clientReview?.feedback}</q>
							</blockquote>
						)}
					</div>
				</div>
				{!!id && editable && (
					<div className="flex flex-col">
						{(canPin || pinned) && (
							<IconButton
								colorScheme="earthRed"
								variant="ghost"
								aria-label="Pin Work History"
								icon={
									<Pin
										className={clsx("w-5 h-5", {
											"fill-brand-500": item.isPinned,
										})}
									/>
								}
								onClick={onPinToggle}
								isLoading={isLoading}
							/>
						)}
						<IconButton
							colorScheme="earthRed"
							variant="ghost"
							aria-label="Edit Work History"
							icon={<Edit2 className="w-5 h-5" />}
							onClick={onOpen}
						/>
					</div>
				)}
			</div>
		</>
	);
}
