import { useAuth0 } from "@auth0/auth0-react";
import { Button, Icon } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import logo from "../assets/LaborHack.svg";
import { MENU } from "../constants";
import { useProfile } from "../hooks/useProfile";
import { NavAccount } from "./NavAccount";
import { SideNavItem } from "./NavItem";
import { HelpCenter } from "./HelpCenter";
import { useToggles } from "../hooks/useToggles";
import { Loading } from "./Loading";

export interface SideNavProps {}

export const SideNav = () => {
  const location = useLocation();
  const { logout, user } = useAuth0();
  const { profile } = useProfile();

  const { isLoading: isLoadingToggles, toggles } = useToggles();

  if (isLoadingToggles) {
    return <Loading />;
  }

  if (!user) {
    return null;
  }

  /**
   * Use the user's name if it exists, otherwise use the user's nickname.
   */
  const accountName = user.given_name
    ? `${user.given_name} ${user.family_name}`
    : user.nickname; // nickname is the user's email without the domain when email credential is used

  return (
    <div className='w-full h-screen h-[100dvh] bg-white py-4 flex flex-col'>
      <div className='px-6 flex h-8 items-center'>
        <img src={logo} width='120px' alt="laborhack's logo" />
      </div>
      <div className='flex-auto py-8 overflow-auto'>
        {MENU.filter(({ items }) => items.length).map(
          ({ sectionName, items }) => {
            return (
              <div className='my-8' key={sectionName}>
                <h2 className='text-xs font-semibold text-primary-200 ml-8 mb-4'>
                  {sectionName}
                </h2>
                <ul className='list-none m-0 p-0'>
                  {items
                    .filter(({ shouldRender }) =>
                      shouldRender(profile, toggles)
                    )
                    .map(({ name, url, icon }) => {
                      return (
                        <li key={name}>
                          <SideNavItem
                            name={name}
                            icon={icon}
                            path={url}
                            locked={!profile}
                            active={location.pathname.startsWith(url)}
                          />
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          }
        )}
      </div>
      <div className='px-4'>
        <HelpCenter />
      </div>

      <div className='px-4'>
        <NavAccount accountName={accountName} imageUrl={user.picture} />
      </div>
      <div className='my-4 px-4'>
        <Button
          width={"full"}
          rightIcon={<Icon as={BiLogOut} w='6' h='6'></Icon>}
          fontSize={"sm"}
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        >
          Logout
        </Button>
      </div>
    </div>
  );
};
