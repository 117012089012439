import { UseFormRegister } from "react-hook-form";
import { ProLeadSource, ReasonForJoiningLaborHack } from "src/types";
import {
	FormControl,
	FormErrorMessage,
	Input,
	Select,
	Stack,
} from "@chakra-ui/react";
import CertificateIcon from "../assets/certificate-icon.png";
import {
	leadSourceOptions,
	reasonsForJoiningLaborHackOptions,
} from "src/pages/trades/complete-profile/constants";

type OtherInfo = {
	noOfDaysOfWorkPerWeek: number;
	leadSource: ProLeadSource;
	otherLeadSource?: string | undefined;
	reasonForJoiningLaborHack: ReasonForJoiningLaborHack;
	reasonForJoiningLaborHackOtherText?: string | undefined;
};

type OtherInformationFormProps = {
	registerOtherInfo: UseFormRegister<OtherInfo>;
	touchedFields: Partial<
		Readonly<{
			leadSource?: boolean | undefined;
			otherLeadSource?: boolean | undefined;
			reasonForJoiningLaborHack?: boolean | undefined;
			noOfDaysOfWorkPerWeek?: boolean | undefined;
			reasonForJoiningLaborHackOtherText?: boolean | undefined;
		}>
	>;
	formData: OtherInfo;
};

export default function OtherInformation({
	registerOtherInfo,
	touchedFields,
	formData,
}: OtherInformationFormProps) {
	return (
		<div className="overflow-y-scroll grid grid-cols-1 lg:grid-cols-2">
			<div className="hidden lg:block py-8">
				<div className="mb-8 bg-[#EDEDED] w-[72px] h-[72px] rounded-full flex items-center justify-center p-6">
					<img src={CertificateIcon} alt="Certificate Icon" />
				</div>
				<div className="flex flex-col w-[70%]">
					<h2 className="mb-2 text-xl lg:text-2xl font-medium text-[#121212] font-[AeonikPro]">
						Other Information
					</h2>
					<p className="text-lg lg:text-xl text-[#464646]">
						Provide the details in the required fields.
					</p>
				</div>
			</div>
			<div className="border-l-[0] lg:border-l-[1px] border-[#E7E7E7] lg:pl-12 py-8 flex flex-col gap-6">
				<FormControl
					isInvalid={
						!!(
							touchedFields.noOfDaysOfWorkPerWeek &&
							!formData.noOfDaysOfWorkPerWeek
						)
					}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="noOfDaysOfWorkPerWeek"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							How many days do you work per week?
						</label>
						<div className="flex flex-col gap-1">
							<div>
								<Select
									{...registerOtherInfo("noOfDaysOfWorkPerWeek")}
									rounded={0}
									size={{
										base: "md",
										lg: "lg",
									}}
									h={{
										base: 10,
										lg: 16,
									}}
								>
									<option value={""}>Select Option</option>
									{[0, 1, 2, 3, 4, 5, 6, 7].map((value) => {
										return (
											<option key={value} value={value}>
												{value}
											</option>
										);
									})}
								</Select>
							</div>
							<FormErrorMessage>
								Please select the number of days you work per week
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl
					isInvalid={!!(touchedFields.leadSource && !formData.leadSource)}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="leadSource"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							How did you hear about LaborHack?
						</label>
						<div className="flex flex-col gap-1">
							<Select
								{...registerOtherInfo("leadSource")}
								rounded={0}
								size={{
									base: "md",
									lg: "lg",
								}}
								h={{
									base: 10,
									lg: 16,
								}}
							>
								<option value={""}>Select Option</option>
								{leadSourceOptions.map((option) => {
									return (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									);
								})}
							</Select>
							<FormErrorMessage>
								Please select how you heard about LaborHack
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				{formData.leadSource === ProLeadSource.OTHER && (
					<FormControl
						isInvalid={
							!!(
								touchedFields.otherLeadSource &&
								formData.leadSource === ProLeadSource.OTHER &&
								!formData.otherLeadSource
							)
						}
					>
						<div className="flex flex-col gap-4">
							<label
								htmlFor="otherLeadSource"
								className="text-md lg:text-xl text-[#212121] font-medium"
							>
								Please specify how you heard about us
							</label>
							<div className="flex flex-col gap-1">
								<Input
									{...registerOtherInfo("otherLeadSource")}
									type="text"
									id="otherLeadSource"
									rounded={0}
									py={{
										base: 5,
										lg: 8,
									}}
									px={{
										base: 3,
										lg: 5,
									}}
									border={"1px solid #D7D7D7"}
								/>
								<FormErrorMessage>
									Please tell us how you heard about LaborHack
								</FormErrorMessage>
							</div>
						</div>
					</FormControl>
				)}
				<FormControl
					isInvalid={
						!!(
							touchedFields.reasonForJoiningLaborHack &&
							!formData.reasonForJoiningLaborHack
						)
					}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="reasonForJoiningLaborHack"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							Why are you joining LaborHack?
						</label>
						<div className="flex flex-col gap-1">
							<Select
								{...registerOtherInfo("reasonForJoiningLaborHack")}
								rounded={0}
								size={{
									base: "md",
									lg: "lg",
								}}
								h={{
									base: 10,
									lg: 16,
								}}
							>
								<option value={""}>Select Option</option>
								{reasonsForJoiningLaborHackOptions.map(({ label, value }) => {
									return (
										<option key={label} value={value}>
											{label}
										</option>
									);
								})}
							</Select>
							<FormErrorMessage>
								Please select why you want to join LaborHack
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				{formData.reasonForJoiningLaborHack ===
					ReasonForJoiningLaborHack.OTHER && (
					<FormControl
						isInvalid={
							!!(
								touchedFields.reasonForJoiningLaborHackOtherText &&
								formData.reasonForJoiningLaborHack ===
									ReasonForJoiningLaborHack.OTHER &&
								!formData.reasonForJoiningLaborHackOtherText
							)
						}
					>
						<div className="flex flex-col gap-4">
							<label
								htmlFor="reasonForJoiningLaborHackOtherText"
								className="text-md lg:text-xl text-[#212121] font-medium"
							>
								Please specify why you are joining LaborHack
							</label>
							<div className="flex flex-col gap-1">
								<Input
									{...registerOtherInfo("reasonForJoiningLaborHackOtherText")}
									type="text"
									id="reasonForJoiningLaborHackOtherText"
									rounded={0}
									py={{
										base: 5,
										lg: 8,
									}}
									px={{
										base: 3,
										lg: 5,
									}}
									border={"1px solid #D7D7D7"}
								/>
								<FormErrorMessage>
									Please tell us why you are joining LaborHack
								</FormErrorMessage>
							</div>
						</div>
					</FormControl>
				)}
				<Stack w={"full"}>
					<p className="text-sm lg:text-base text-[#464646] lg:w-[75%]">
						By clicking on “Submit”, you have agreed to our{" "}
						<span>
							<a
								href={`${process.env.REACT_APP_HOME_APP_URL}/terms`}
								target="_blank"
								rel="noreferrer"
								className=" !text-accent-500"
							>
								Terms and Conditions
							</a>
						</span>{" "}
						&{" "}
						<span>
							<a
								href={`${process.env.REACT_APP_HOME_APP_URL}/privacy`}
								target="_blank"
								rel="noreferrer"
								className=" !text-accent-500"
							>
								Privacy policy
							</a>
						</span>
					</p>
				</Stack>
			</div>
		</div>
	);
}
