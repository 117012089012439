import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react-dates-overrides.css";

import "./index.css";
import App from "./App";
import { ApolloConfig } from "./components/ApolloConfig";
import {
	ChakraProvider,
	defineStyleConfig,
	extendTheme,
} from "@chakra-ui/react";
import AeonikPro from "./assets/fonts/AeonikPro.ttf";
import { Global } from "@emotion/react";

const Fonts = () => (
	<Global
		styles={`
  @font-face {
    font-family: 'AeonikPro';
    src: url(${AeonikPro}) format('truetype');
    font-weight: 100 900;
  }
`}
	/>
);

const colors = {
	brand: {
		50: "#F9CEC8",
		100: "#F7BDB6",
		200: "#F5ADA3",
		300: "#F39C91",
		400: "#F18C7E",
		500: "#ED6D5C",
		600: "#ED6B5A",
		700: "#EB5A47",
		800: "#E94A35",
		900: "#E73A23",
	},
	primary: {
		100: "#F2F5F6",
		150: "#D9E1E5",
		200: "#99B0BA",
		300: "#7c95a0",
		500: "#003952",
	},
	prussianBlue: {
		50: "#D0D6D9",
		100: "#B1BBC0",
		200: "#8A98A1",
		300: "#637682",
		400: "#3C5462",
		500: "#153243",
		600: "#122A38",
		700: "#0E212D",
		800: "#0B1922",
		900: "#071116",
	},
	earthRed: {
		50: "#F6E9E8",
		100: "#ECCFCF",
		200: "#D9A09F",
		300: "#77372E",
		500: "#4F251F",
	},
};

const Button = defineStyleConfig({
	baseStyle: {
		fontWeight: "500",
		rounded: "none",
		fontFamily: `"Inter", sans-serif`,
		boxSizing: "border-box",
	},
	sizes: {
		xl: {
			fontSize: "3xl",
			py: 8,
		},
	},
	variants: {
		"solid-dark": (props) => {
			return {
				bg: `${props.colorScheme}.500`,
				textColor: "gray.900",
			};
		},
		outline: (props) => ({
			borderColor: `${props.colorScheme}.500`,
			color: `${props.colorScheme}.500`,
		}),
		subtle: (props) => {
			return {
				bg: `${props.colorScheme}.50`,
				textColor: `${props.colorScheme}.500`,
			};
		},
	},
});


const theme = extendTheme({
	colors,
	styles: {
		global: {
			a: {
				fontFamily: `"Inter", sans-serif`,
			},
			button: {
				span: {
					fontFamily: `"Inter", sans-serif`,
				},
			},
		},
	},
	fonts: {
		heading: `'AeonikPro', sans-serif`,
		body: `'Inter', sans-serif`,
	},
	components: {
		Button,
	},
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
				clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
				redirectUri={`${window.location.origin}/login`}
				audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
				scope="openid profile email phone"
			>
				<ChakraProvider theme={theme}>
					<Fonts />
					<ApolloConfig>
						<App />
					</ApolloConfig>
				</ChakraProvider>
			</Auth0Provider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
