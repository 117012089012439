import { useAuth0 } from "@auth0/auth0-react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Icon,
  Button,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import { BiLogOut, BiMenuAltLeft } from "react-icons/bi";
import { useLocation } from "react-router-dom";

import logo from "../assets/LaborHack.svg";
import { MENU } from "../constants";
import { useProfile } from "../hooks/useProfile";
import { MobileNavItem } from "./NavItem";
import { NavAccount } from "./NavAccount";
import { useToggles } from "../hooks/useToggles";
import { Loading } from "./Loading";

export interface NavProps {}

export const Nav = () => {
  const { logout, user } = useAuth0();
  const { profile } = useProfile();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading: isLoadingToggles, toggles } = useToggles();

  if (isLoadingToggles) {
    return <Loading />;
  }

  if (!user) {
    return null;
  }

  /**
   * Use the user's name if it exists, otherwise use the user's email.
   */
  const accountName = user.name;

  return (
    <>
      <div className='w-full py-4 px-4'>
        <div className='flex w-full justify-between lg:justify-end'>
          {/* on smaller screens, the nav would have the logo and a menu button on the left */}
          {/* on large screens only the account section would exist so it would be on the right */}
          <div className='flex items-center lg:hidden'>
            {/* logo and menu button */}
            <Icon
              as={BiMenuAltLeft}
              w={8}
              h={8}
              color='primary.500'
              onClick={onOpen}
            />
            <img
              className='ml-2'
              src={logo}
              alt="laborhack's logo"
              width={120}
            ></img>
          </div>
          <div className='flex items-center'>
            {/* account section */}
            <NavAccount accountName={accountName} imageUrl={user.picture} />
            <IconButton
              variant='unstyled'
              aria-label='Log out'
              icon={<Icon as={BiLogOut} w='6' h='6' />}
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
            />
          </div>
        </div>
      </div>
      <Drawer placement='top' onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <img src={logo} alt="laborhack's logo" width={120} />
          </DrawerHeader>
          <DrawerBody>
            <div className='flex flex-col h-[calc(100vh-120px)]'>
              <div className='flex flex-col justify-center flex-auto'>
                {MENU.map(({ sectionName, items }) => {
                  return (
                    <div key={sectionName}>
                      <ul className='list-none m-0 p-0'>
                        {items
                          .filter(({ shouldRender }) =>
                            shouldRender(profile, toggles)
                          )
                          .map(({ name, url, icon }) => {
                            return (
                              <li key={name} className='mb-4'>
                                <MobileNavItem
                                  name={name}
                                  icon={icon}
                                  path={url}
                                  locked={!profile}
                                  active={location.pathname.startsWith(url)}
                                  onClick={onClose}
                                />
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className='my-4 px-4'>
                <Button
                  width={"full"}
                  rightIcon={<Icon as={BiLogOut} w='6' h='6'></Icon>}
                  fontSize={"sm"}
                  onClick={() =>
                    logout({
                      returnTo: window.location.origin,
                    })
                  }
                >
                  Logout
                </Button>
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
