import React from "react";
import { Box, Heading, Text, Button, VStack, Flex } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../assets/LaborHack.svg";

const NotFound = () => {
  return (
    <Flex
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center" py="8" margin="4">
        <VStack spacing="6" className="my-4">
          <div className="py-4 px-4">
            <img src={logo} alt="laborhack's logo" width={160} />
          </div>
          <Heading as="h1" size="2xl">
            404: Not Found
          </Heading>
          <Text fontSize="lg">
            Sorry, the page you are looking for does not exist.
          </Text>
          <Button
            as={RouterLink}
            to="/trades"
            size="lg"
            color="white"
            bgColor={"brand.500"}
          >
            Go to Trades
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default NotFound;
