import { useContext, useEffect } from "react";
import { CompleteProfileContext } from "../contexts/complete-profile.context";
import { useBackGroundCheck } from "./useBackgroundCheck";
import { useBankAccountInfo } from "./useBankAccountInfo";
import { Guarantor, Referee } from "../types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link } from "@chakra-ui/react";
import { useProfile } from "./useProfile";
import { useTrades } from "./useTrades";

export const isValidRefereeOrGuarantor = (person: Referee | Guarantor) => {
  return (
    person.name &&
    person.phoneNumber &&
    person.email &&
    person.companyName &&
    person.companyAddress &&
    person.jobTitle &&
    person.relationship
  );
};

const useBanner = () => {
  const { loading: profileLoading, profile } = useProfile();
  const { loading: tradesLoading, trades } = useTrades(profile?.id);
  const { setBanners, clientBackgroundCheckFormCompleted } = useContext(
    CompleteProfileContext
  );
  const location = useLocation();
  const { loading: loadingBackgroundCheckData, backgroundCheckData } =
    useBackGroundCheck();
  const { loading: loadingBankAccountInfo, hasProvidedBankAccountInfo } =
    useBankAccountInfo();

  useEffect(() => {
    if (
      loadingBackgroundCheckData ||
      tradesLoading ||
      loadingBankAccountInfo ||
      profileLoading
    ) {
      return;
    }

    const hasAttainedRequiredLevel = trades?.find(
      ({ currentLevel }) => currentLevel && currentLevel > 0
    );

    if (!hasAttainedRequiredLevel) {
      return;
    }

    const hasSubmittedCompleteBackgroundCheckData =
      backgroundCheckData?.referees.every(isValidRefereeOrGuarantor) &&
      backgroundCheckData?.guarantors.every(isValidRefereeOrGuarantor);

    const getBanners = () => {
      const banners = [];
      if (
        !hasSubmittedCompleteBackgroundCheckData &&
        !clientBackgroundCheckFormCompleted
      ) {
        banners.push({
          isVisible: location.pathname.startsWith("/trades"),
          message: (
            <span>
              Please complete your background check information.{" "}
              <Link
                as={RouterLink}
                to="/background-check"
                color="blue.700"
                fontWeight="semibold"
                textDecorationLine="underline"
              >
                Click here
              </Link>
            </span>
          ),
        });
      }

      if (!hasProvidedBankAccountInfo) {
        banners.push({
          isVisible: location.pathname.startsWith("/trades"),
          message: (
            <span>
              Please complete your bank account information.{" "}
              <Link
                as={RouterLink}
                to="/bank-account-information"
                color="blue.700"
                fontWeight="semibold"
                textDecorationLine="underline"
              >
                Click here
              </Link>
            </span>
          ),
        });
      }

      return banners;
    };

    setBanners(getBanners());
  }, [
    loadingBackgroundCheckData,
    backgroundCheckData,
    loadingBankAccountInfo,
    hasProvidedBankAccountInfo,
    setBanners,
    location.pathname,
    tradesLoading,
    profileLoading,
    trades,
    clientBackgroundCheckFormCompleted,
  ]);
};

export default useBanner;
