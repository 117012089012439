import { Button, Stack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useProApplication } from "../hooks/useProApplication";
import { Loading } from "./Loading";
import { RequestFailed } from "./RequestFailed";
import locked from "../assets/locked.svg";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

interface RegionGuardProps extends PropsWithChildren {
  /**
   * The regions that are allowed to access the route.
   */
  regions: string[];
}

/**
 * RegionGuard
 *
 * Restricts access to certain routes based on the user's region.
 *
 * @param props
 * @returns
 */
export const RegionGuard = (props: RegionGuardProps) => {
  const navigate = useNavigate();

  const { loading, error, proApplication } = useProApplication();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <RequestFailed text={error.message} />;
  }

  if (
    !proApplication?.country ||
    !props.regions.includes(proApplication.country)
  ) {
    return (
      <Stack direction='row' w='full' justify='center'>
        <Stack gap={16} maxW='container.sm' alignItems='center'>
          <h1 className='text-xl font-semibold text-primary-500'>
            Access Restricted
          </h1>
          <img
            className='w-48'
            src={locked}
            alt='blurred webpage with a lock icon'
          />
          <Stack alignItems='center' gap={4}>
            <p className='text-center text-primary-500'>
              Sorry, this feature is not yet available in your region. We are
              working hard to make it available soon.
            </p>
            <Stack direction='row'>
              <Button
                onClick={() => {
                  navigate("/");
                }}
                leftIcon={<FaArrowLeft />}
              >
                Back to Dashboard
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return <>{props.children}</>;
};
