import "@smile_identity/smart-camera-web";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CompleteProfileContext } from "../../contexts/complete-profile.context";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { Loading } from "../../components/Loading";
import { RequestFailed } from "../../components/RequestFailed";
import { ID_IMAGE_TYPE, SELFIE_IMAGE_TYPE } from "../../constants";

export const Capture = () => {
  const [error, setError] = useState<string>();

  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  const { selectedIdType, setImages, setRawComputedImages } = useContext(
    CompleteProfileContext
  );

  useEffect(() => {
    if (!selectedIdType) {
      navigate("/complete-profile");
    }
  }, [selectedIdType, navigate]);

  const ref = useRef<any>();

  const handleImagesComputed = async (e: any) => {
    try {
      setUploading(true);

      const { images } = e.detail;

      setRawComputedImages(images);

      const selfie = images.find(
        (image: any) => image.image_type_id === SELFIE_IMAGE_TYPE
      );

      const id = images.find(
        (image: any) => image.image_type_id === ID_IMAGE_TYPE
      );

      if (!selfie || !id) {
        throw new Error("Failed to get images");
      }

      const imageUrls = [selfie.image, id.image].map((image: string) => {
        return `data:image/jpeg;base64,${image}`;
      });

      setImages(imageUrls);

      setUploading(false);

      navigate("/complete-profile");
    } catch (error) {
      setError((error as Error).message);
      setUploading(false);
    }
  };

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      current.addEventListener(
        "imagesComputed",
        debounce(handleImagesComputed, 500)
      );
    }

    return () => {
      if (current) {
        current.removeEventListener("imagesComputed", () => {
          console.log("removed");
        });
      }
    };
  });

  if (uploading) {
    return <Loading />;
  }

  if (error) {
    return (
      <RequestFailed
        text={error}
        onRetry={() => {
          navigate("/complete-profile");
        }}
      />
    );
  }

  // @ts-ignore
  return <smart-camera-web ref={ref} capture-id></smart-camera-web>;
};
