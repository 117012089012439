/**
 * This file is used to define common queries that types are automatically generated for using the codegen script.
 */

import { gql } from "../../__generated__";

export const GET_MY_PORTFOLIO = gql(`
  query MyPortfolio {
    myPortfolio {
      firstName
      lastName
      phone
      email
			handle
      isEmailHidden
      workHistory {
        id
        name
        description
        budget
        startedAt
        endedAt
        images {
					id
					name
					data
					mimeType
					deletedAt
				}
        videos
        clientReview {
          rating
          feedback
        }
        verified
        isPinned
        createdAt
      }
      backgroundCheckStatus
      identityVerificationStatus
      certifications {
        id
        name
        issuedBy
        issuedAt
        issuerCertificationId
        url
        verified
        createdAt
      }
			educationEntries {
				id
				schoolName
				fieldOfStudy
				qualification
				verified
				createdAt
      }
      title
      location
      totalBudget
      averageRating
      averageLaborHackJobsRatings {
        averageAppearanceRating
        averagePunctualityRating
        averagePostServiceCleanUpRating
        averageBehaviorRating
        averageProperToolUsageRating
      }
      ratingCount
      overview
      experience
      portfolioImages {
        id
        mimeType
        data
				deletedAt
      }
      hasCompletedRequiredPortfolioSteps
    }
  }
`);

export const PRO_EXISTS = gql(`
  query ProExists($phoneNumber: String!) {
    proExists(phoneNumber: $phoneNumber)
  }
`);
