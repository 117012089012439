import { MyPortfolioQuery } from "./__generated__/graphql";

export enum Gender {
	MALE = "MALE",
	FEMALE = "FEMALE",
}

export enum IncomeLevel {
	UNDER_30K = "UNDER_30K",
	BETWEEN_30K_AND_50K = "BETWEEN_30K_AND_50K",
	BETWEEN_50K_AND_70K = "BETWEEN_50K_AND_70K",
	BETWEEN_70K_AND_100K = "BETWEEN_70K_AND_100K",
	BETWEEN_100K_AND_150K = "BETWEEN_100K_AND_150K",
	BETWEEN_150K_AND_200K = "BETWEEN_150K_AND_200K",
	OVER_200K = "OVER_200K",
}

export enum YearsOfExperience {
	UNDER_ONE = "UNDER_ONE",
	ONE = "ONE",
	TWO = "TWO",
	THREE = "THREE",
	FOUR = "FOUR",
	FIVE = "FIVE",
	SIX_TO_TEN = "SIX_TO_TEN",
	ABOVE_TEN = "ABOVE_TEN",
}

export enum TrainingType {
	COLLEGE = "COLLEGE",
	POLYTECHNIC = "POLYTECHNIC",
	TRAINING_INSTITUTE = "TRAINING_INSTITUTE",
	APPRENTICE = "APPRENTICE",
	OTHER = "OTHER",
}

export enum ProLeadSource {
	RADIO = "RADIO",
	FROM_A_FRIEND = "FROM_A_FRIEND",
	FLYER = "FLYER",
	SOCIAL_MEDIA = "SOCIAL_MEDIA",
	GOOGLE = "GOOGLE",
	OPL_ACADEMY = "OPL_ACADEMY",
	COMMUNITY_OUTREACH = "COMMUNITY_OUTREACH",
	OTHER = "OTHER",
}

export enum ReasonForJoiningLaborHack {
	MY_PERSONAL_PORTFOLIO = "MY_PERSONAL_PORTFOLIO",
	INVOICING_TOOL = "INVOICING_TOOL",
	TOOL_FINANCING = "TOOL_FINANCING",
	LOCAL_JOBS = "LOCAL_JOBS",
	INTERNATIONAL_JOBS = "INTERNATIONAL_JOBS",
	CERTIFICATIONS = "CERTIFICATIONS",
	OTHER = "OTHER",
}

export enum CertificationRequirementType {
	THEORETICAL_ASSESSMENT = "THEORETICAL_ASSESSMENT",
	PRACTICAL_ASSESSMENT = "PRACTICAL_ASSESSMENT",
	CERTIFICATION = "CERTIFICATION",
}

export enum MeansOfIdentification {
	NIN = "NIN",
	PASSPORT = "PASSPORT",
	DRIVERS_LICENSE = "DRIVERS_LICENSE",
	VOTERS_CARD = "VOTERS_CARD",
	BVN = "BVN",
}

export interface ProApplicationIdentification {
	meansOfIdentification: MeansOfIdentification;
	identificationNumber: string;
	identificationUrl: string;
}

export enum EducationLevel {
	SSCE = "SSCE",
	OND = "OND",
	HND = "HND",
	BACHELORS = "BACHELORS",
	MASTERS = "MASTERS",
	DOCTORATE = "DOCTORATE",
	KCSE = "KCSE",
}

export enum EmploymentStatus {
	EMPLOYED = "EMPLOYED",
	UNEMPLOYED = "UNEMPLOYED",
	SELF_EMPLOYED = "SELF_EMPLOYED",
	STUDENT = "STUDENT",
}

export interface ProApplication {
	id: string;
	userId: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
	address: string;
	stateOfResidence: string;
	country: string;
	gender: Gender;
	categoryId?: string;
	yearsOfExperience?: YearsOfExperience;
	trainingType?: TrainingType;
	incomeLevel?: IncomeLevel;
	numberOfDaysWorkedPerWeek?: number;
	employmentStatus?: EmploymentStatus;
	currentIndustry?: Industry;
	otherOccupationText?: string;
	highestEducationLevel?: EducationLevel;
	leadSource: string;
	profilePictureUrl?: string;
	identification?: ProApplicationIdentification;
	hasInternationalPassport: boolean | null;
	passportNumber: string | null;
	passportIssueDate?: Date;
	passportExpiryDate?: Date;
	passportIssueAuthority?: string;
	dateOfBirth?: Date;
	emergencyContactName?: string;
	emergencyContactPhone?: string;
	yearOfGraduation?: number;
	createdAt: Date;
	updatedAt: Date;
}

export interface CertificationRequirement {
	id: string;
	type: CertificationRequirementType;
	name: string;
	description: string;
	passingScore: number;
	lmsId?: string;
	lmsCourseId?: string;
	order: number;
}

export interface Certification {
	id: string;
	name: string;
	description: string;
	requirements: CertificationRequirement[];
	createdAt: Date;
	updatedAt: Date;
}

export interface Category {
	id: string;
	requestCount: number;
	name: string;
	proTitle?: string;
	subCategoryIds?: string[];
	imageUrl?: string;
	isVisible?: boolean;
}

export enum TradeRequirementType {
	INTERNAL_ASSESSMENT = "INTERNAL_ASSESSMENT",
	EXTERNAL_ASSESSMENT = "EXTERNAL_ASSESSMENT",
	INTERVIEW = "INTERVIEW",
}

export enum TradeRequirementActionTypes {
	OPEN_URL = "OPEN_URL",
}

export interface TradeRequirementAction {
	type: TradeRequirementActionTypes;
	url: string;
	label: string;
	prompt: string;
	external?: boolean;
}

export interface TradeRequirement {
	id: string;
	name: string;
	description: string;
	prompt: string;
	price: number;
	type: TradeRequirementType;
	invitationUrl?: string;
	lmsId?: string;
	lmsCourseId?: string;
	preRequisites?: TradeRequirement[];
	passingScore?: number;
	actions: TradeRequirementAction[];
	createdAt: Date;
	updatedAt: Date;
}

export interface TradeLevel {
	id: string;
	trade: Trade;
	order: number;
	requirements: TradeRequirement[];
	hasAccess?: boolean;
	isAvailable?: boolean;
	createdAt: Date;
	updatedAt: Date;
}

export interface Trade {
	id: string;
	name: string;
	description: string;
	displayPriority?: number;
	imageUrl: string;
	minimumDailyRate: number;
	category: Category;
	certification: Certification;
	levels: TradeLevel[];
	currentLevel?: number;
	createdAt: Date;
}

export interface ProTradeLevelDiscount {
	id: string;
	code: string;
	description: string;
	prompt: string;
	applicableTradeLevels: TradeLevel[];
	autoApplied: boolean;
	limit?: number;
	pros: Pro[];
	useOnce?: boolean;
	value: number;
}

export enum TransactionStatus {
	PENDING,
	SUCCESS,
	FAILURE,
}

export interface Payment {
	id: string;
	transactionId?: string;
	transactionReference: string;
	transactionDate?: Date;
	status: TransactionStatus;
	amount: number;
	checkout: string;
	email: string;
	providerId: string;
}

export interface ProTradeLevelDiscount {
	id: string;
	code: string;
	description: string;
	applicableTradeLevels: TradeLevel[];
	validFrom: Date;
	validUntil?: Date;
	autoApplied: boolean;
	limit?: number;
	pros: Pro[];
	useOnce?: boolean;
	value: number;
	isRevoked: boolean;
}

export interface ProTradeLevelPayment {
	id: string;
	tradeLevel: TradeLevel;
	affectedTradeRequirements: TradeRequirement[];
	pro: Pro;
	payments: Payment[];
	isPaid: boolean;
	discount?: ProTradeLevelDiscount;
	createdAt: Date;
	updatedAt: Date;
}

export enum ProTradeRequirementInterviewEventStatus {
	SCHEDULED = "SCHEDULED",
	RESCHEDULED = "RESCHEDULED",
	CANCELLED = "CANCELLED",
	COMPLETED = "COMPLETED",
}

export interface ProTradeRequirementInterviewEvent {
	id: string;
	attemptId: number;
	date: Date;
	status: ProTradeRequirementInterviewEventStatus;
	cancellationUrl: string;
	rescheduleUrl: string;
}

export interface ProTradeRequirementResult {
	scores: number[];
	attemptId: number;
	submittedAt: Date;
}

export interface ProTradeRequirementPayment {
	id: string;
	proTradeRequirement: ProTradeRequirement;
	discount?: ProTradeLevelDiscount;
	payment?: Payment;
	createdAt: Date;
	updatedAt: Date;
}

/**
 * This keeps track of the state of a trade requirement for a pro.
 *
 * This is used to determine if a pro has completed a trade requirement, keeps scores, payment information, etc.
 */
export interface ProTradeRequirement {
	id: string;
	pro: Pro;
	requirement: TradeRequirement;
	tradeLevelPayments: ProTradeLevelPayment[];
	attemptsRemaining: number;
	hasPassed: boolean;
	hasCompletedPayment: boolean;
	results: ProTradeRequirementResult[];
	events?: ProTradeRequirementInterviewEvent[];
	waived?: boolean;
	canReschedule?: boolean;
}

export interface ProCertificationResult {
	id: string;
	proCertification: ProCertification;
	certificationRequirement: CertificationRequirement;
	score: number;
	submittedAt: Date;
}

export interface ProCertification {
	id: string;
	pro: Pro;
	certification: Certification;
	results: ProCertificationResult[];
	uploadedAt?: Date;
	createdAt: Date;
	updatedAt: Date;
}

export enum IdentityVerificationStatus {
	PENDING = "PENDING",
	VERIFIED = "VERIFIED",
	DECLINED = "DECLINED",
}

export enum IdentityVerificationIssueCode {
	DUPLICATE_ID = "DUPLICATE_ID",
	DOB_MISMATCH = "DOB_MISMATCH",
	NAME_MISMATCH = "NAME_MISMATCH",
	PHONE_MISMATCH = "PHONE_MISMATCH",
}

export interface IdentityVerificationIssue<T> {
	code: T;
	message: string;
}

export interface IdentityVerification {
	id: string;
	pro: Pro;
	issues?: IdentityVerificationIssue<IdentityVerificationIssueCode>[];
	providerIssues?: IdentityVerificationIssue<string>[];
	returnedDateOfBirth?: Date;
	returnedFullName?: string;
	returnedPhoneNumber?: string;
	identificationNumber: string;
	type: MeansOfIdentification;
	status: IdentityVerificationStatus;
	dateOfBirth: Date;
	photo: string;
	identificationDocument: string;
	createdAt: Date;
	updatedAt: Date;
}

export enum ProTrack {
	LOCALGIG = "LOCALGIG",
	STUDYWORK = "STUDYWORK",
}

export interface Pro {
	id: string;

	firstName: string;

	lastName: string;

	phoneNumber: string;

	email: string;

	imageUrl?: string;

	verified: boolean;

	authProviderId?: string;

	userId: string;

	categoryIds: string[];

	trades: Trade[];

	certifications?: ProCertification[];

	identityVerification?: IdentityVerification;

	onboardedAt?: Date;

	tracks: ProTrack[];

	associatedLaborHackEmail: string;

	proApplication?: ProApplication;

	updatedAt: Date;

	createdAt: Date;
}

export interface Referee {
	name: string;
	phoneNumber?: string;
	email: string;
	companyName: string;
	companyAddress: string;
	jobTitle: string;
	relationship: string;
}

export interface Guarantor {
	name: string;
	phoneNumber?: string;
	email: string;
	companyName: string;
	companyAddress: string;
	jobTitle: string;
	relationship: string;
}

export interface BackgroundCheck {
	id: string;
	userId: string;
	referees: Referee[];
	guarantors: Guarantor[];
	isComplete: boolean;
}

export interface BankAccountInformation {
	accountName: string;
	accountNumber: string;
	bankName?: string;
}

export interface TransferRecipient {
	providerId: string;
	recipientCode: string;
	bankInformation?: BankAccountInformation;
}

export interface PaymentProviderData {
	accountNumber: string;
	providers: TransferRecipient[];
}

export interface BannerDataType {
	isVisible: boolean;
	message: JSX.Element | string;
}

export interface PopUpModal {
	isVisible: boolean;
	modalType: string;
}

export enum StudyWorkOpportunityCountry {
	CANADA = "CANADA",
}

export interface StudyWorkOpportunity {
	id: string;
	country: StudyWorkOpportunityCountry;
	description: string;
	price: number;
	createdAt: Date;
	updatedAt: Date;
}

export interface ProStudyWorkOpportunity {
	id: string;
	pro: Pro;
	opportunity: StudyWorkOpportunity;
	payments: Payment[];
	isPaid: boolean;
	basicApplication?: CanadaBasicApplication;
	preferredCourse?: CanadaBasicApplicationCourseOfStudy;
	createdAt: Date;
	updatedAt: Date;
}

export enum CanadaBasicApplicationEducationLevel {
	SSCE = "SSCE",
	OND = "OND",
	HND = "HND",
	BACHELORS = "BACHELORS",
	MASTERS = "MASTERS",
	DOCTORATE = "DOCTORATE",
	KCSE = "KCSE",
}

export enum CanadaBasicApplicationProficiencyTest {
	IELTS = "IELTS",
	TOEFL = "TOEFL",
	PTE = "PTE",
	DUOLINGO = "DUOLINGO",
}

export enum CanadaBasicApplicationCourseOfStudy {
	HEATING_REFRIGERATION_AC_TECHNICIAN = "HEATING_REFRIGERATION_AC_TECHNICIAN",
	CONSTRUCTION_ENGINEERING_TECHNICIAN = "CONSTRUCTION_ENGINEERING_TECHNICIAN",
	CARPENTRY_AND_RENOVATION_TECHNICIAN = "CARPENTRY_AND_RENOVATION_TECHNICIAN",
	ELECTROMECHANICAL_ENGINEERING_TECHNICIAN = "ELECTROMECHANICAL_ENGINEERING_TECHNICIAN",
	CNC_TECHNICIAN = "CNC_TECHNICIAN",
	ELECTRICAL_TECHNIQUES = "ELECTRICAL_TECHNIQUES",
	WELDING_TECHNIQUES = "WELDING_TECHNIQUES",
}

export enum CanadaBasicApplicationCitizenship {
	NIGERIAN = "NIGERIAN",
	OTHER = "OTHER",
}

export interface CanadaBasicApplication {
	id: string;
	pro: Pro;
	educationLevel: CanadaBasicApplicationEducationLevel;
	graduationYear: number;
	countryOfStudy: string;
	intendedStudyResumptionDate: Date;
	intendedCourseOfStudy: CanadaBasicApplicationCourseOfStudy;
	citizenship: CanadaBasicApplicationCitizenship;
	needsLoan: boolean;
	canCoverMinimumDeposit?: boolean;
	contributionAmountInCAD?: number;
	takenEnglishProficiencyTest: boolean;
	testCompletionYear?: number;
	testCompletionScore?: number;
	intendedProficiencyTestDate?: Date;
	hasInternationalPassport: boolean;
	passportNumber?: string;
	passportIssueDate?: Date;
	passportExpiryDate?: Date;
	createdAt: Date;
	updatedAt: Date;
}

export enum Industry {
	Healthcare = "Healthcare",
	TechnologyAndInformationServices = "TechnologyAndInformationServices",
	Education = "Education",
	FinanceAndBanking = "FinanceAndBanking",
	LawAndLegalServices = "LawAndLegalServices",
	Manufacturing = "Manufacturing",
	Retail = "Retail",
	Construction = "Construction",
	HospitalityAndTourism = "HospitalityAndTourism",
	RealEstate = "RealEstate",
	TransportationAndLogistics = "TransportationAndLogistics",
	EnergyAndUtilities = "EnergyAndUtilities",
	MediaAndEntertainment = "MediaAndEntertainment",
	NonprofitAndSocialServices = "NonprofitAndSocialServices",
	GovernmentAndPublicAdministration = "GovernmentAndPublicAdministration",
	AgricultureAndForestry = "AgricultureAndForestry",
	ArtsDesignAndFashion = "ArtsDesignAndFashion",
	FoodAndBeverage = "FoodAndBeverage",
	EnvironmentalServices = "EnvironmentalServices",
	Telecommunications = "Telecommunications",
	MarketingAndAdvertising = "MarketingAndAdvertising",
	PharmaceuticalAndBiotechnology = "PharmaceuticalAndBiotechnology",
	AerospaceAndDefense = "AerospaceAndDefense",
	Automotive = "Automotive",
	Consulting = "Consulting",
	Other = "Other",
}

export interface ProPortfolioJobTask {
	name: string;

	description: string;
}

export interface ProPortfolioCertification {
	name: string;

	issuedBy: string;

	issuedAt: Date;
}

export interface ProPortfolioJob {
	tasks: ProPortfolioJobTask[];
	budget: number | null;

	review: Review | null;

	date: Date;
}

export enum ProPortfolioBadgeStatus {
	NOT_STARTED = "NOT_STARTED",
	PENDING = "PENDING",
	COMPLETED = "COMPLETED",
	DECLINED = "DECLINED",
}

export interface PortfolioWorkHistoryItemClientReview {
	rating: number;
	feedback: string | null;
}

export type PortfolioWorkHistoryItem = NonNullable<
	MyPortfolioQuery["myPortfolio"]
>["workHistory"][number];

export type PortfolioCertification = NonNullable<
	MyPortfolioQuery["myPortfolio"]
>["certifications"][number];

export type PortfolioEducation = NonNullable<
	MyPortfolioQuery["myPortfolio"]
>["educationEntries"][number];

type AverageLaborHackJobsRatings = {
	averageAppearanceRating: number;

	averagePunctualityRating: number;

	averagePostServiceCleanUpRating: number;

	averageBehaviorRating: number;

	averageProperToolUsageRating: number;
};

export interface ProPortfolio {
	firstName: string;
	lastName: string;

	email?: string;

	phone?: string;

	title?: string;

	location: string | null;

	workHistory: PortfolioWorkHistoryItem[];

	certifications: ProPortfolioCertification[];

	identityVerificationStatus: ProPortfolioBadgeStatus;

	backgroundCheckStatus: ProPortfolioBadgeStatus;

	ratingCount: number;

	averageRating: number;

	averageLaborHackJobsRatings: AverageLaborHackJobsRatings;

	totalBudget: number;

	overview: string;

	portfolioImages: PortfolioImage[];
}

interface Rating {
	punctuality: number;

	appearance: number;

	behavior: number;

	properToolUsage: number;

	postServiceCleanUp: number;
}

export interface Review {
	id: string;
	rating: Rating;
	feedback: string;
}

export interface PortfolioImage {
	id: string;

	name: string;

	data: string;

	mimeType: string;
}
