import { countriesWithDialingCodes } from "src/constants";

export const onSelectedFile = (
	event: any,
	setImage: (image: string) => void
) => {
	const file = event.target.files[0];

	const reader = new FileReader();
	reader.onload = (e) => {
		setImage(e.target?.result as string);
	};
	reader.readAsDataURL(file);
};

export const formatAsNaira = (amount: number, passedDecimalPlaces?: number) => {
	const decimalPlaces = passedDecimalPlaces ?? 2;

	return `₦${amount.toLocaleString("en-NG", {
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	})}`;
};

export const getCappedAmount = (amount: number) => {
	const gates = [
		1000, 5000, 10000, 20000, 50000, 100000, 150000, 200000, 300000, 500000,
		1000000, 5000000, 10000000,
	];

	let lastGate = 0;

	for (const gate of gates) {
		if (amount < gate) {
			return lastGate;
		}

		lastGate = gate;
	}

	return lastGate;
};

export const isDate = (str: string): boolean => {
	const date = new Date(str);
	return !isNaN(date.getTime());
};

export const generateProShareId = (email: string) => {
	return `pro.${email.split("@")[0]}`;
};

export const removePhoneNumberPrefix = (phoneNumber: string) => {
	// iterate over each country with dialing code
	for (const country of countriesWithDialingCodes) {
		// constructing the regular expression dynamically to match the dialing code for each country
		const regex = new RegExp(`^\\+${country.value}`);
		// replace the prefix if it matches the dialing code
		phoneNumber = phoneNumber.replace(regex, "");
	}
	return phoneNumber;
};

export const extractPhoneNumberPrefix = (phoneNumber?: string) => {
	if (!phoneNumber) return undefined;
	for (const country of countriesWithDialingCodes) {
		const regex = new RegExp(`^\\+${country.value}`);
		if (regex.test(phoneNumber)) {
			// Return the matched dialing code
			return `+${country.value}`;
		}
	}

	return undefined; // Return undefined if no match is found
};

export const cleanPhoneNumber = (phoneNumberString: string) => {
	let cleaned = phoneNumberString.trim();
	// remove braces, dashes and spaces
	cleaned = cleaned.replace(/-|\s|\(|\)/g, "");

	// remove the country code
	cleaned = removePhoneNumberPrefix(phoneNumberString);

	if (cleaned.startsWith("0")) {
		cleaned = cleaned.replace(/^0/, "");
	}

	return cleaned;
};

const phoneNumberValidatorMap: Record<
	string,
	(phoneNumber: string) => boolean
> = {
	"+234": (phoneNumber: string) => {
		let trimmedNumber = phoneNumber.trim();

		const cleaned = cleanPhoneNumber(trimmedNumber);

		if (cleaned.length && cleaned.length === 10) {
			return true;
		}

		return false;
	},

	default: (phoneNumber: string) => {
		return phoneNumber.length >= 8 && phoneNumber.length <= 15;
	},
};

export const isValidIntlPhoneNumber = (phoneNumberString: string) => {
	const prefix = extractPhoneNumberPrefix(phoneNumberString);

	if (!prefix) {
		return false;
	}

	const validator =
		phoneNumberValidatorMap[prefix] || phoneNumberValidatorMap.default;

	return validator(phoneNumberString);
};
