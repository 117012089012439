import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import logo from "../assets/LaborHack.svg";
import whiteLogo from "../assets/laborhack-white-logo.png";
import Avatar from "../assets/avatar.png";

export interface LayoutProps {}
export const ProWebsiteLayout = ({
	children,
}: PropsWithChildren<LayoutProps>) => {
	return (
		<>
			<div className="grid grid-cols-1 w-full h-screen">
				<div className="flex flex-col max-h-screen">
					{/* main content here */}

					{/* nav here */}
					<div className="w-full bg-[#F9F9F9]">
						<div className="flex w-full items-center justify-between">
							<div className="bg-[#ED6E5C] px-12 py-8 h-full items-center hidden lg:block">
								<img src={whiteLogo} width="120px" alt="laborhack's logo" />
							</div>
							<div className="flex items-center justify-between w-full">
								<div className="flex items-center lg:hidden pl-3">
									<img
										className="ml-2 block lg:hidden"
										src={logo}
										alt="laborhack's logo"
										width={120}
									></img>
								</div>
								<div className="flex items-center gap-4 pr-4 lg:pr-12 py-6 ml-auto">
									{/* account section */}
									<div>
										<img
											src={Avatar}
											alt="avatar"
											className="w-8 h-8 rounded-full"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<main className="max-h-max overflow-y-auto flex-auto bg-[#FEFEFE]">
						<Outlet />
					</main>
				</div>
			</div>
		</>
	);
};
