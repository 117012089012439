import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Button, Link } from "@chakra-ui/react";
import React, { PropsWithChildren, useContext } from "react";
import { Outlet } from "react-router-dom";
import Banner from "./Banner";
import { Nav } from "./Nav";
import { SideNav } from "./SideNav";
import { CompleteProfileContext } from "../contexts/complete-profile.context";
import useBanner from "../hooks/useBanner";
import { PopUpModal } from "./PopUpModal";

export interface LayoutProps {}

export const Layout = ({ children }: PropsWithChildren<LayoutProps>) => {
  const { banners } = useContext(CompleteProfileContext);
  useBanner();

  return (
    <>
      <PopUpModal />
      <Link
        href='https://www.laborhack.com/contact'
        target='_blank'
        className='md:hidden'
      >
        <Button
          leftIcon={<QuestionOutlineIcon />}
          zIndex={999}
          position='fixed'
          bottom={"20px"}
          left={"20px"}
          colorScheme='teal'
          size='sm'
          fontWeight={400}
          boxShadow='lg'
          borderRadius={9999}
        >
          Need help?
        </Button>
      </Link>

      <div className='grid grid-cols-1 lg:grid-cols-[272px_1fr] w-full h-screen'>
        <div className='hidden lg:block'>
          {/* side bar here */}
          <SideNav />
        </div>
        <div className='flex flex-col max-h-screen'>
          {/* main content here */}

          {/* nav here */}
          <div className='lg:hidden'>
            <Nav />
          </div>

          <main className='px-4 py-8 md:px-8  max-h-max overflow-y-auto flex-auto bg-primary-100'>
            {banners.map(({ isVisible, message }) => (
              <Banner isVisible={isVisible} message={message} />
            ))}
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};
