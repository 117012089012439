import { Spinner } from "@chakra-ui/react";

export const Loading = () => {
  return (
    <div className='w-full h-full'>
      <div className='flex justify-center items-center h-full'>
        <Spinner color='brand.500' size='xl' />
      </div>
    </div>
  );
};
