import { useEffect, useState } from "react";

export const fetchToggles = async () => {
  const response = await fetch(
    `https://laborhack-feature-toggles.s3.amazonaws.com/toggles/${
      process.env.REACT_APP_APP_ENV
    }.json?v=${Date.now()}`
  );
  const toggles = await response.json();
  return toggles;
};

export function useToggles() {
  const [toggles, setToggles] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchToggles()
      .then((toggles) => {
        setToggles(toggles);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    toggles,
    isLoading,
  };
}
