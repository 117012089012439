import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Input,
	InputGroup,
	InputLeftAddon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
	Textarea,
} from "@chakra-ui/react";
import { IoChevronBackOutline } from "react-icons/io5";
import CertificateIcon from "../../assets/certificate-icon.png";
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { gql } from "src/__generated__";
import { isBefore } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import React from "react";
import { FaUpload } from "react-icons/fa";

export const ADD_WORK_HISTORY = gql(`
  mutation AddPorfolioWorkHistoryItem(
    $name: String!
    $description: String!
    $startedAt: DateTimeISO
    $images: [JobImage!]!
    $videos: [String!]!
    $budget: Float
    $endedAt: DateTimeISO!
  ) {
    addPorfolioWorkHistoryItem(
      name: $name
      description: $description
      startedAt: $startedAt
      images: $images
      videos: $videos
      budget: $budget
      endedAt: $endedAt
    )
  } 
`);

const formSchema = z.object({
	taskName: z.string().min(3),
	taskDescription: z.string().min(10),
	budget: z.number().min(1).optional(),
	date: z
		.string()
		.transform((value) => {
			if (!value) return null;
			return new Date(value);
		})
		.refine((value) => {
			if (!value) return true;
			return isBefore(value, new Date());
		}, "Date must be today or in the past"),
	image: z
		.object({
			name: z.string(),
			data: z.string(),
			mimeType: z.string(),
		})
		.optional(),
});

export const AddJob = () => {
	const [selectedPortfolioImage, setSelectedPortfolioImage] =
		React.useState("");

	const navigate = useNavigate();

	const [addWorkHistoryItem, addWorkHistoryItemHandle] = useMutation(
		ADD_WORK_HISTORY,
		{
			onCompleted: () => {
				navigate("/my-portfolio");
			},
			refetchQueries: ["MyPortfolio"],
			awaitRefetchQueries: true,
		}
	);

	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		const images = data.image !== undefined ? [data.image] : [];
		addWorkHistoryItem({
			variables: {
				name: data.taskName,
				description: data.taskDescription,
				budget: data.budget,
				startedAt: null,
				images,
				videos: [],
				endedAt: data.date,
			},
		});
	};

	// Handle file change and preview generation
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		const file = e.target.files[0];

		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				if (reader.result && typeof reader.result === "string") {
					// Set preview for UI
					setSelectedPortfolioImage(reader.result);

					const dataObject = {
						name:
							"job-history-image-" +
							reader.result.split(",")[1].substring(0, 5) +
							`-${Math.random() * 1000}`,
						data: reader.result.split(",")[1],
						mimeType: file.type,
					};

					setValue("image", dataObject);
				}
			};

			reader.readAsDataURL(file); // Start reading the file as Base64
		}
	};

	return (
		<>
			<Stack spacing={0}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col space-y-8 lg:space-y-12"
				>
					<Stack
						spacing={0}
						px={{
							base: 8,
							lg: 20,
						}}
					>
						<HStack
							borderBottom={"1px"}
							borderColor={"#E7E7E7"}
							py={6}
							textColor={"#212121"}
							mb={{
								base: 4,
								lg: 8,
							}}
						>
							<Link to="/my-portfolio">
								<div className="hover:cursor-pointer flex items-center gap-3">
									<IoChevronBackOutline />
									<span className="text-base lg:text-lg">Back</span>
								</div>
							</Link>
						</HStack>

						<div className="overflow-y-scroll grid grid-cols-1 lg:grid-cols-2 lg:border-[1px] border-[#C9C9C9] lg:px-12">
							<div className="hidden lg:block py-8">
								<div className="mb-8 bg-[#EDEDED] w-[72px] h-[72px] rounded-full flex items-center justify-center p-6">
									<img src={CertificateIcon} alt="Certificate Icon" />
								</div>
								<div className="flex flex-col w-[70%]">
									<h2 className="mb-2 text-xl lg:text-2xl font-medium text-[#121212] font-[AeonikPro]">
										Upload a job history
									</h2>
									<p className="text-lg lg:text-xl text-[#464646]">
										Provide your personal details in the required fields.
									</p>
								</div>
							</div>
							<div className="border-l-[0] lg:border-l-[1px] border-[#E7E7E7] lg:pl-12 py-8 flex flex-col gap-6">
								<div className="flex flex-col gap-6 lg:gap-8">
									<FormControl isInvalid={!!errors.taskName}>
										<FormLabel htmlFor="firstName">Project Name</FormLabel>
										<Input
											id="taskName"
											placeholder="Example: 2HP Split AC Installation"
											{...register("taskName")}
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>
											{errors.taskName?.message}
										</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={!!errors.taskDescription}>
										<FormLabel htmlFor="taskDescription">
											Project Description
										</FormLabel>
										<Textarea
											id="taskDescription"
											placeholder="Example: I installed a 2HP split AC in the second floor living room."
											{...register("taskDescription")}
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>
											{errors.taskDescription?.message}
										</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={!!errors.date}>
										<FormLabel htmlFor="taskDate">Completion Date</FormLabel>
										<Input
											id="taskDate"
											{...register("date")}
											type="date"
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>{errors.date?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={!!errors.budget}>
										<FormLabel htmlFor="budget">Project Budget</FormLabel>
										<InputGroup>
											<InputLeftAddon rounded={0} border={"1px solid #D7D7D7"}>
												₦
											</InputLeftAddon>
											<NumberInput
												className="w-full"
												onChange={(_, value) => {
													setValue("budget", value);
												}}
												value={watch("budget")}
												step={1000}
												rounded={0}
												border={"#D7D7D7"}
											>
												<NumberInputField rounded={0} />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										</InputGroup>
										<FormErrorMessage>
											{errors.budget?.message}
										</FormErrorMessage>
									</FormControl>
								</div>

								<div className="flex flex-col items-start gap-2">
									<div className="flex flex-col gap-1">
										<Heading as="h4" className="!text-xl !font-medium">
											Media
										</Heading>
										<p className="text-sm text-gray-600">
											Add an image to show prospective clients what you did
										</p>
									</div>
									{selectedPortfolioImage && (
										<div className="flex justify-center items-center w-full">
											<img src={selectedPortfolioImage} alt="Upload Preview" />
										</div>
									)}
									<Box position="relative" display="flex" gap={4}>
										{/* Hidden file input */}
										<Input
											type="file"
											accept="image/*"
											onChange={handleFileChange}
											id="upload-btn"
											hidden
										/>
										<Button
											onClick={() => {
												document.getElementById("upload-btn")?.click();
											}}
											leftIcon={<FaUpload />}
											colorScheme="prussianBlue"
											variant="outline"
										>
											{selectedPortfolioImage ? "Change" : "Choose File"}
										</Button>
									</Box>
								</div>
							</div>
						</div>
					</Stack>
					<Stack
						py={{
							base: 2,
							lg: 4,
						}}
						px={{
							base: 8,
							lg: 20,
						}}
						borderTop={"1px"}
						borderColor={"#E7E7E7"}
						alignItems={"end"}
					>
						<Button
							type="submit"
							colorScheme="brand"
							rounded={0}
							px={8}
							py={6}
							rightIcon={<FiArrowRight size={"18px"} />}
							isLoading={addWorkHistoryItemHandle.loading}
						>
							Submit
						</Button>
					</Stack>
				</form>
			</Stack>
		</>
	);
};
