import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Button, Link, Text, VStack } from "@chakra-ui/react";

export function HelpCenter() {
  return (
    <VStack py={8} px={4} bgColor='blue.700' gap={4} borderRadius={8} mb={4}>
      <Box textAlign='center'>
        <h4 className='font-semibold text-lg text-white'>Help Center</h4>
        <Text fontSize='sm' color='white'>
          We're here to help. If you have any questions, please contact us.
        </Text>
      </Box>

      <Link href='https://www.laborhack.com/contact' target='_blank'>
        <Button
          rightIcon={<ExternalLinkIcon w={5} h={5} />}
          fontSize='sm'
          colorScheme='gray'
          w='full'
        >
          Contact Us
        </Button>
      </Link>
    </VStack>
  );
}
