import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Category } from "../types";

const CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
      proTitle
      isVisible
    }
  }
`;

export const useCategories = () => {
  const { error, data, loading } = useQuery<{
    categories: Category[];
  }>(CATEGORIES);

  const categoriesAsMap = useMemo(() => {
    if (!data?.categories) {
      return {};
    }

    return data.categories.reduce((acc, category) => {
      acc[category.id] = category;
      return acc;
    }, {} as Record<string, Category>);
  }, [data?.categories]);

  return {
    categories: data?.categories,
    categoriesAsMap,
    error,
    loading,
  };
};
