import { Box, HStack, Link, Text, VStack } from "@chakra-ui/react";
import { TbArrowUpRight } from "react-icons/tb";

export function HelpCenterV2() {
	return (
		<VStack
			py={8}
			px={4}
			bgColor="#F4F4F4"
			borderColor={"#D6D6D60"}
			gap={4}
			borderRadius={8}
			borderWidth={1}
		>
			<Box textAlign="left">
				<h4 className="font-medium text-lg text-[#212121]">Help centre</h4>
				<Text fontSize="base" color="#464646">
					We're here to help. Do you have any questions?
				</Text>
			</Box>

			<Box w={"full"}>
				<Link
					w={"full"}
					href="https://www.laborhack.com/contact"
					target="_blank"
					_hover={{ textDecoration: "none" }}
				>
					<HStack
						backgroundColor={"#FEFEFE"}
						_hover={{ backgroundColor: "#464646", textColor: "#FEFEFE" }}
						w="full"
						py={"12px"}
						display={"flex"}
						justifyContent={"center"}
					>
						<Box fontSize="lg" fontWeight="medium" as="span">
							Contact us
						</Box>
						<Box>
							<TbArrowUpRight size={"20px"} />
						</Box>
					</HStack>
				</Link>
			</Box>
		</VStack>
	);
}
