import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import { ApplicationForm } from "../../components/ApplicationForm";
import { Loading } from "../../components/Loading";
import { useProApplication } from "../../hooks/useProApplication";
import { logEvent } from "../../utlis/analytics";

export interface ApplyProps {}

export const Apply = () => {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  /** If the user has a profile, go back home */
  const { loading, proApplication } = useProApplication();

  if (loading) {
    return <Loading />;
  }

  if (proApplication) {
    return <Navigate to='/switch-track' />;
  }

  return (
    <>
      <Helmet>
        <title>Registration | LaborHack Pro</title>
      </Helmet>
      <ApplicationForm />
    </>
  );
};
