import { HStack, Stack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";

export interface PortfolioItemCreatorLayoutProps {
	icon?: string;
	title: string;
	description: string;
}

export const PortfolioItemCreatorLayout = ({
	children,
	icon,
	title,
	description,
}: PropsWithChildren<PortfolioItemCreatorLayoutProps>) => {
	return (
		<Stack spacing={0} h={"90vh"}>
			<Stack
				spacing={0}
				px={{
					base: 8,
					lg: 20,
				}}
			>
				<HStack
					borderBottom={"1px"}
					borderColor={"#E7E7E7"}
					py={6}
					textColor={"#212121"}
					mb={{
						base: 4,
						lg: 8,
					}}
				>
					<RouterLink to="/my-portfolio">
						<div className="hover:cursor-pointer flex items-center gap-3">
							<IoChevronBackOutline />
							<span className="text-base lg:text-lg">Back</span>
						</div>
					</RouterLink>
				</HStack>

				<div className="overflow-y-scroll grid grid-cols-1 lg:grid-cols-2 lg:border-[1px] border-[#C9C9C9] lg:px-12">
					<div className="hidden lg:block py-8">
						{icon && (
							<div className="mb-8 bg-[#EDEDED] w-[72px] h-[72px] rounded-full flex items-center justify-center p-6">
								<img src={icon} alt={title} />
							</div>
						)}
						<div className="flex flex-col w-[70%]">
							<h2 className="mb-2 text-xl lg:text-2xl font-medium text-[#121212] font-[AeonikPro]">
								{title}
							</h2>
							<p className="text-lg lg:text-xl text-[#464646]">{description}</p>
						</div>
					</div>
					<div className="border-l-[0] lg:border-l-[1px] border-[#E7E7E7] lg:pl-12 py-8 flex flex-col gap-6">
						<div className="flex flex-col gap-6 lg:gap-8">{children}</div>
					</div>
				</div>
			</Stack>
		</Stack>
	);
};
