import { useMutation } from "@apollo/client";
import { CheckCircleIcon, CheckIcon } from "@chakra-ui/icons";
import {
	Badge,
	Button,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	ListItem,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	OrderedList,
	Select,
	Stack,
	VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { LockedAccount } from "../../components/LockedAccount";
import { RequestFailed } from "../../components/RequestFailed";
import { IDENTIFICATION_OPTIONS } from "../../constants";
import { CompleteProfileContext } from "../../contexts/complete-profile.context";
import { useProApplication } from "../../hooks/useProApplication";
import { useProfile } from "../../hooks/useProfile";
import { logEvent } from "../../utlis/analytics";
import IdentityVerificationDeclined from "../../components/IdentityVerificationDecline";
import {
	IdentityVerificationIssueCode,
	IdentityVerificationStatus,
	MeansOfIdentification,
} from "../../__generated__/graphql";
import { gql } from "../../__generated__";
import { IDVerificationSubmissionConfirmation } from "src/components/ConfirmationLayout";

const validateIdNumber = (
	idNumber?: string,
	idType?: MeansOfIdentification
) => {
	if (!idNumber || !idType) {
		return false;
	}

	return Boolean(
		IDENTIFICATION_OPTIONS.find(
			(option) => option.value === idType
		)?.regex.test(idNumber)
	);
};

const SUBMIT_IDENTITY_INFORMATION = gql(`
	mutation SubmitIdentityInformation($input: SubmitIdentityInformationInput!) {
		submitIdentityInformation(input: $input) {
			id
			pro {
				id
				identityVerification(showDeclined: true) {
					id
					status
					issues {
						code
					}
				}
			}
			issues {
				code
				message
			}
		}
	}
`);

const getBadgeStatusColour = (status: IdentityVerificationStatus) => {
	switch (status) {
		case IdentityVerificationStatus.Verified:
			return "green";
		case IdentityVerificationStatus.Pending:
			return "yellow";
		case IdentityVerificationStatus.Declined:
			return "red";
		default:
			return "gray";
	}
};

/**
 * This is the page that will be shown to the user if some aspect of their profile is incomplete.
 *
 * It'll show a form with the missing fields, and the user will be able to fill them in.
 */
export const IdentityVerification = () => {
	const [showModal, setShowModal] = useState(false);

	const [showLockedModal, setShowLockedModal] = useState(false);

	useEffect(() => {
		logEvent("page_view");
	}, []);

	const { proApplication } = useProApplication();

	const {
		dateOfBirth,
		setDateOfBirth,
		selectedIdType,
		setSelectedIdType,
		idNumber,
		setIdNumber,
	} = useContext(CompleteProfileContext);

	const [showErrors, setShowErrors] = useState(false);
	const [
		submissionConfirmationModalIsOpen,
		setSubmissionConfirmationModalIsOpen,
	] = useState(false);

	const navigate = useNavigate();

	const hasExistingDateOfBirth = !!proApplication?.dateOfBirth;

	const {
		loading: profileLoading,
		error: profileError,
		profile,
	} = useProfile();

	let identityVerificationIssues;

	const inputsAreDisabled =
		profile?.identityVerification?.status ===
			IdentityVerificationStatus.Verified ||
		profile?.identityVerification?.status ===
			IdentityVerificationStatus.Pending;

	const idNumberIsValid = validateIdNumber(idNumber, selectedIdType);

	const selectedIdTypeName = IDENTIFICATION_OPTIONS.find(
		({ value }) => value === selectedIdType
	)?.label;

	useEffect(() => {
		if (
			profile?.identityVerification?.identificationNumber &&
			profile?.identityVerification?.type
		) {
			setIdNumber(profile.identityVerification.identificationNumber);
			setSelectedIdType(profile.identityVerification.type);
		}
	}, [profile, setIdNumber, setSelectedIdType]);

	const [
		submitIdentityInformation,
		{
			loading: submitIdentityInformationLoading,
			error: submitIdentityInformationError,
			reset: submitIdentityInformationReset,
		},
	] = useMutation(SUBMIT_IDENTITY_INFORMATION, {
		onCompleted: (data) => {
			if (
				data.submitIdentityInformation.issues &&
				data.submitIdentityInformation.issues.length > 0
			) {
				const isDuplicateIdNumber = data.submitIdentityInformation.issues.some(
					(issue) => issue.code === IdentityVerificationIssueCode.DuplicateId
				);

				if (isDuplicateIdNumber) {
					setShowLockedModal(true);
					return;
				}
			}

			setSubmissionConfirmationModalIsOpen(true);
		},
		refetchQueries: ["GetMyProfile", "myProInformation"],
		awaitRefetchQueries: true,
	});

	const closeConfirmationModal = () => {
		setSubmissionConfirmationModalIsOpen(false);
	};

	if (profileLoading) {
		return <Loading />;
	}

	if (profileError) {
		return <RequestFailed />;
	}

	if (
		profile?.identityVerification?.status ===
			IdentityVerificationStatus.Declined &&
		profile?.identityVerification?.issues &&
		profile?.identityVerification?.issues.length > 0
	) {
		identityVerificationIssues = profile?.identityVerification?.issues;

		const isDuplicateIdNumber = profile?.identityVerification?.issues.some(
			(issue) => issue.code === IdentityVerificationIssueCode.DuplicateId
		);

		if (isDuplicateIdNumber) {
			return (
				<LockedAccount>
					<p className="text-primary-500">
						The NIN you presented has already been used with a different
						account.
					</p>
				</LockedAccount>
			);
		}
	}

	const canSubmit = () => {
		if (!hasExistingDateOfBirth && !dateOfBirth) {
			return false;
		}

		if (!idNumberIsValid || !selectedIdType) {
			return false;
		}

		return true;
	};

	const handleSubmit = async () => {
		if (!canSubmit()) {
			setShowErrors(true);
			return;
		}

		if (!idNumber || !selectedIdType) {
			return;
		}

		const input: Record<string, any> = {};

		if (!hasExistingDateOfBirth) {
			input.dateOfBirth = new Date(dateOfBirth!).toISOString(); // the canSubmit() check ensures that dateOfBirth is defined
		}

		input.identificationNumber = idNumber;
		input.type = selectedIdType;

		submitIdentityInformation({
			variables: {
				input: {
					dateOfBirth: !hasExistingDateOfBirth
						? new Date(dateOfBirth!).toISOString()
						: undefined,
					identificationNumber: idNumber,
					type: selectedIdType,
				},
			},
		});
	};

	if (submitIdentityInformationLoading) {
		return <Loading />;
	}

	if (submitIdentityInformationError) {
		return (
			<RequestFailed
				text={submitIdentityInformationError.message}
				onRetry={submitIdentityInformationReset}
			/>
		);
	}

	if (showLockedModal) {
		return (
			<LockedAccount>
				<p>
					The NIN you presented has already been used with a different account.
				</p>
			</LockedAccount>
		);
	}

	const onClose = () => setShowModal(false);

	const handleContinueUpload = () => navigate("capture");

	return (
		<>
			<Helmet>
				<title>Complete Identity Verification | LaborHack Pro</title>
			</Helmet>
			<Modal isOpen={showModal} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>ID Capture Process</ModalHeader>
					<ModalBody fontSize="sm">
						<VStack alignItems="flex-start" gap="2">
							<p>
								You will be required to take a photo of your{" "}
								<strong>
									{selectedIdTypeName} ({idNumber})
								</strong>
								. Please confirm you have it in your possession or use a
								different ID type.
							</p>
							<div>
								<p>What we need from you:</p>
								<OrderedList>
									<ListItem>a photo of your face</ListItem>
									<ListItem>
										a photo of your original identification card.
									</ListItem>
								</OrderedList>
							</div>
							<p>
								<strong>Tip:</strong> For best results, use an area that has
								good lighting and a white background.
							</p>
							<p>Please make sure the images are clear and easy to read.</p>
							<p>
								If the photos are not clear or easy to read, you will NOT be
								able to access the LaborHack certification tests.
							</p>
						</VStack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" variant="ghost" mr={3} onClick={onClose}>
							Change ID
						</Button>
						<Button
							variant="solid"
							colorScheme="green"
							onClick={handleContinueUpload}
							rightIcon={<CheckCircleIcon />}
						>
							I Understand
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{submissionConfirmationModalIsOpen && (
				<IDVerificationSubmissionConfirmation
					closeConfirmationModal={closeConfirmationModal}
				/>
			)}
			<div className="bg-white">
				<div className="flex items-center justify-between">
					<h2 className="text-lg md:text-xl text-primary-500 font-bold mb-4 md:mb-8">
						Identity Verification
					</h2>
					{profile?.identityVerification?.status && (
						<Badge
							colorScheme={getBadgeStatusColour(
								profile?.identityVerification?.status
							)}
						>
							{profile?.identityVerification?.status}
						</Badge>
					)}
				</div>
				{!!identityVerificationIssues && (
					<div className="flex flex-col items-center">
						<IdentityVerificationDeclined issues={identityVerificationIssues} />
					</div>
				)}
				{!proApplication?.identification && (
					<>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 w-full">
							<VStack align="start" gap={2} margin={0}>
								<h3 className="font-semibold text-primary-500">
									Means of Identification
								</h3>
								<VStack align="start" gap={1.5} className="!m-0">
									<p className="text-sm text-primary-300 max-w-md !m-0 p-0">
										Please provide your identification details
									</p>
									<p className="text-sm text-primary-300 max-w-md !m-0 p-0">
										We need this information to confirm your identity and ensure
										LaborHack remains a safe place for our Pros and Businesses.
										We will not share this information with anybody
									</p>
								</VStack>
							</VStack>
							<Stack spacing={4} className="px-2 md:px-4">
								<FormControl
									isRequired
									isInvalid={showErrors && !selectedIdType}
								>
									<FormLabel fontSize={"sm"} as="legend" color="primary.500">
										ID type
									</FormLabel>
									<Select
										isDisabled={inputsAreDisabled}
										placeholder="Select option"
										value={selectedIdType || ""}
										onChange={(event) => {
											setSelectedIdType(
												event.target.value as MeansOfIdentification
											);
										}}
										rounded={0}
									>
										{IDENTIFICATION_OPTIONS.map(({ label, value }) => (
											<option key={value} value={value}>
												{label}
											</option>
										))}
									</Select>
									<FormErrorMessage>
										Please select a valid ID type
									</FormErrorMessage>
								</FormControl>
								{!hasExistingDateOfBirth && (
									<FormControl isRequired as="fieldset">
										<FormLabel fontSize={"sm"} as="legend" color="primary.500">
											Date of Birth
										</FormLabel>
										<Input
											isDisabled={inputsAreDisabled}
											id="dateOfBirth"
											fontSize={"sm"}
											type={"date"}
											alignItems="center"
											value={dateOfBirth}
											onChange={(event) => {
												setDateOfBirth(event.target.value);
											}}
										/>
									</FormControl>
								)}
								<FormControl
									isRequired
									isDisabled={!selectedIdType}
									isInvalid={showErrors && idNumberIsValid === false}
								>
									<FormLabel
										fontSize={"sm"}
										htmlFor="firstName"
										color="primary.500"
									>
										ID Number
									</FormLabel>
									<InputGroup>
										<Input
											id="idNumber"
											isDisabled={inputsAreDisabled}
											fontSize={"sm"}
											type={"text"}
											alignItems="center"
											placeholder={
												selectedIdType
													? `Enter your ${selectedIdTypeName}`
													: "Select ID type first"
											}
											value={idNumber}
											onChange={(event) => {
												setIdNumber(event.target.value);
											}}
											rounded={0}
										/>
										{idNumberIsValid && (
											<InputRightElement
												children={<CheckIcon color="green.500" />}
											/>
										)}
									</InputGroup>
									{idNumberIsValid === false && (
										<FormErrorMessage>
											{selectedIdType
												? `Please enter a valid ${
														IDENTIFICATION_OPTIONS.find(
															({ value }) => value === selectedIdType
														)?.label
												  }`
												: "Please select ID type first"}
										</FormErrorMessage>
									)}{" "}
									{idNumberIsValid === undefined && (
										<FormHelperText>
											The ID number of your means of identification, e.g. your
											passport number, NIN, etc.
										</FormHelperText>
									)}
								</FormControl>
							</Stack>
						</div>

						<div className="my-4 md:my-8 w-full h-0.5 bg-primary-100" />
					</>
				)}
				<HStack justify="end" gap={1}>
					<Button
						loadingText="Submitting"
						isLoading={submitIdentityInformationLoading}
						isDisabled={inputsAreDisabled}
						fontSize={"sm"}
						colorScheme={"green"}
						variant={"solid"}
						spinnerPlacement="start"
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</HStack>
			</div>
		</>
	);
};
