import {
	FormControl,
	FormErrorMessage,
	FormHelperText,
	Input,
	InputGroup,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
} from "@chakra-ui/react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import CertificateIcon from "../assets/certificate-icon.png";
import { YearsOfExperience } from "src/types";
import { Select as CRSelect } from "chakra-react-select";
import { countriesData } from "src/constants";
import { experienceLevelOptions } from "src/pages/trades/complete-profile/constants";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";

type ContactAndProfessionalInfo = {
	country: {
		value: string;
		label: string;
	};
	phoneNumber: string;
	stateOfResidence: string;
	incomeCurrency: {
		value: string;
		label: string;
	};
	averageMonthlyIncome: number;
	yearsOfExperience: YearsOfExperience;
};

type ContactAndProfessionalInformationFormProps = {
	registerContactAndProfessionalInfo: UseFormRegister<ContactAndProfessionalInfo>;
	setValue: UseFormSetValue<ContactAndProfessionalInfo>;
	touchedFields: Partial<
		Readonly<{
			stateOfResidence?: boolean | undefined;
			country?:
				| {
						value?: boolean | undefined;
						label?: boolean | undefined;
				  }
				| undefined;
			yearsOfExperience?: boolean | undefined;
			phoneNumber?: boolean | undefined;
			incomeCurrency?:
				| {
						value?: boolean | undefined;
						label?: boolean | undefined;
				  }
				| undefined;
			averageMonthlyIncome?: boolean | undefined;
		}>
	>;
	control: any;
	formData: ContactAndProfessionalInfo;
};

export default function ContactAndProfessionalInformationForm({
	registerContactAndProfessionalInfo,
	setValue,
	touchedFields,
	formData,
	control,
}: ContactAndProfessionalInformationFormProps) {
	const [userHasChangedCurrency, setUserHasChangedCurrency] =
		useState<boolean>(false);

	useEffect(() => {
		if (formData.country) {
			const selectedCountry = countriesData.find(
				(country) => country.name === formData.country.value
			);

			if (selectedCountry && !userHasChangedCurrency) {
				setValue("incomeCurrency", {
					value: selectedCountry.currency,
					label: selectedCountry.currency,
				});
			}
		}
	}, [formData.country, setValue, userHasChangedCurrency]);

	const customStyles = {
		control: (baseStyles: Record<string, unknown>, state: any) => ({
			...baseStyles,
			height: {
				base: "40px",
				lg: "70px",
			},
			borderRadius: 0,
		}),
	};

	return (
		<div className="overflow-y-scroll grid grid-cols-1 lg:grid-cols-2">
			<div className="hidden lg:block py-8">
				<div className="mb-8 bg-[#EDEDED] w-[72px] h-[72px] rounded-full flex items-center justify-center p-6">
					<img src={CertificateIcon} alt="Certificate Icon" />
				</div>
				<div className="flex flex-col w-[70%]">
					<h2 className="mb-2 text-xl lg:text-2xl font-medium text-[#121212] font-[AeonikPro]">
						Contact and Professional Information
					</h2>
					<p className="text-lg lg:text-xl text-[#464646]">
						Provide your contact and professional details in the required
						fields.
					</p>
				</div>
			</div>
			<div className="border-l-[0] lg:border-l-[1px] border-[#E7E7E7] lg:pl-12 py-8 flex flex-col gap-6">
				<FormControl isInvalid={!!(touchedFields.country && !formData.country)}>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="country"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							Country
						</label>
						<div className="flex flex-col gap-2">
							<Controller
								name="country"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<CRSelect
										id="country"
										placeholder="Select option"
										options={countriesData.map((country) => ({
											label: country.name,
											value: country.name,
										}))}
										size={{
											base: "md",
											lg: "lg",
										}}
										chakraStyles={customStyles}
										{...field}
									/>
								)}
							/>
							<FormErrorMessage>Please select your country</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl
					isInvalid={
						!!(touchedFields.stateOfResidence && !formData.stateOfResidence)
					}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="stateOfResidence"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							State of Residence
						</label>
						<div className="flex flex-col gap-2">
							<Input
								{...registerContactAndProfessionalInfo("stateOfResidence")}
								type="text"
								id="stateOfResidence"
								placeholder="e.g. Lagos"
								rounded={0}
								py={{
									base: 5,
									lg: 8,
								}}
								px={{
									base: 3,
									lg: 5,
								}}
								border={"1px solid #D7D7D7"}
							/>
							<FormErrorMessage>
								Please enter your state of residence
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl
					isInvalid={
						!!(
							touchedFields.averageMonthlyIncome &&
							!formData.averageMonthlyIncome
						)
					}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="incomeLevel"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							What is your average monthly income?
						</label>
						<div>
							<div>
								<InputGroup>
									<NumberInput
										className="w-full"
										onChange={(_, value) => {
											setValue("averageMonthlyIncome", value);
										}}
										value={formData.averageMonthlyIncome}
										id="averageMonthlyIncome"
										placeholder="e.g. 100000"
										rounded={0}
										step={5000}
									>
										<NumberInputField
											rounded={0}
											border={"1px solid #D7D7D7"}
											py={{
												base: 5,
												lg: 8,
											}}
											px={{
												base: 3,
												lg: 5,
											}}
										/>
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</InputGroup>
								<FormHelperText>I earn this monthly on average</FormHelperText>
							</div>
							<FormErrorMessage>
								Please enter your average monthly income
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl
					isInvalid={
						!!(touchedFields.incomeCurrency && !formData.incomeCurrency)
					}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="incomeCurrency"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							What currency do you earn in?
						</label>
						<div className="flex flex-col gap-2">
							<Controller
								name="incomeCurrency"
								control={control}
								render={({ field }) => (
									<CRSelect
										value={field.value}
										id="currencyy"
										placeholder="Select option"
										options={countriesData.map((country) => ({
											label: country.currency,
											value: country.currency,
										}))}
										size={{
											base: "md",
											lg: "lg",
										}}
										chakraStyles={customStyles}
										onChange={(e) => {
											setUserHasChangedCurrency(true);
											field.onChange(e);
										}}
									/>
								)}
							/>
							<FormErrorMessage>
								Please select the currency you earn in
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl
					isInvalid={
						!!(touchedFields.yearsOfExperience && !formData.yearsOfExperience)
					}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="yearsOfExperience"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							How many years of experience do you have?
						</label>
						<div className="flex flex-col gap-1">
							<Select
								{...registerContactAndProfessionalInfo("yearsOfExperience")}
								rounded={0}
								size={{
									base: "md",
									lg: "lg",
								}}
								h={{
									base: 10,
									lg: 16,
								}}
							>
								<option value={""}>Select Option</option>
								{experienceLevelOptions.map((option) => {
									return (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									);
								})}
							</Select>
							<FormErrorMessage>
								Please select your years of experience
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
			</div>
		</div>
	);
}
