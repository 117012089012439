import { useContext, useEffect, useMemo } from "react";
import { CompleteProfileContext } from "../contexts/complete-profile.context";
import { useBackGroundCheck } from "./useBackgroundCheck";
import { useBankAccountInfo } from "./useBankAccountInfo";
import { Guarantor, Referee } from "../types";
import { useLocation } from "react-router-dom";
import { useProfile } from "./useProfile";
import { useTrades } from "./useTrades";
import { gql, useQuery } from "@apollo/client";
import { differenceInDays, formatISO, parseISO } from "date-fns";

const GET_PREFERENCES_BY_USER_ID = gql`
  query GetPreferencesByUserId {
    getPreferencesByUserId {
      hasDisabledTelegramPopUp
    }
  }
`;

// Show pop up once every 3 days at least, since last shown date
export function shouldShowPopUp(currentDate: Date, lastShownDate?: Date) {
  const minimumPopupInterval = 3;

  if (!lastShownDate) {
    return true;
  }

  const difference = differenceInDays(currentDate, lastShownDate);

  if (difference < minimumPopupInterval) {
    return false;
  }
  return true;
}

export const isValidRefereeOrGuarantor = (person: Referee | Guarantor) => {
  return (
    person.name &&
    person.phoneNumber &&
    person.email &&
    person.companyName &&
    person.companyAddress &&
    person.jobTitle &&
    person.relationship
  );
};

const usePopUpModal = () => {
  const { data: preferencesData, loading: preferencesLoading } = useQuery(
    GET_PREFERENCES_BY_USER_ID
  );

  const { loading: profileLoading, profile } = useProfile();
  const { loading: tradesLoading, trades } = useTrades(profile?.id);
  const { setPopUpModal } = useContext(CompleteProfileContext);
  const location = useLocation();
  const { loading: loadingBackgroundCheckData, backgroundCheckData } =
    useBackGroundCheck();
  const { loading: loadingBankAccountInfo, hasProvidedBankAccountInfo } =
    useBankAccountInfo();

  const today = useMemo(() => new Date(), []);
  const lastShownDateString = localStorage.getItem("lastShownDate");
  const lastShownDate = lastShownDateString
    ? parseISO(lastShownDateString)
    : undefined;

  const willShowModalToday = shouldShowPopUp(today, new Date(lastShownDate!));

  useEffect(() => {
    if (
      loadingBackgroundCheckData ||
      tradesLoading ||
      loadingBankAccountInfo ||
      profileLoading ||
      preferencesLoading
    ) {
      return;
    }

    const hasAttainedRequiredLevel = trades?.find(
      ({ currentLevel }) => currentLevel && currentLevel > 0
    );

    if (!hasAttainedRequiredLevel || !willShowModalToday) {
      return;
    }

    const hasDisabledTelegramPopUp =
      preferencesData?.getPreferencesByUserId.hasDisabledTelegramPopUp;

    const hasSubmittedCompleteBackgroundCheckData =
      backgroundCheckData?.referees.every(isValidRefereeOrGuarantor) &&
      backgroundCheckData?.guarantors.every(isValidRefereeOrGuarantor);

    const shouldShowModal =
      hasAttainedRequiredLevel &&
      hasSubmittedCompleteBackgroundCheckData &&
      hasProvidedBankAccountInfo &&
      !hasDisabledTelegramPopUp &&
      location.pathname.startsWith("/trades");

    if (!!shouldShowModal) {
      localStorage.setItem(
        "lastShownDate",
        formatISO(today, { representation: "date" })
      );
    }

    setPopUpModal({
      isVisible: !!shouldShowModal,
      modalType: "JOIN_TELEGRAM_MODAL",
    });
  }, [
    loadingBackgroundCheckData,
    backgroundCheckData,
    loadingBankAccountInfo,
    hasProvidedBankAccountInfo,
    setPopUpModal,
    location.pathname,
    tradesLoading,
    profileLoading,
    trades,
    preferencesLoading,
    preferencesData?.getPreferencesByUserId.hasDisabledTelegramPopUp,
    willShowModalToday,
    today,
  ]);
};

export default usePopUpModal;
