import {
	Button,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	PinInput,
	PinInputField,
} from "@chakra-ui/react";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Button as SButton } from "@/components/ui/button";
import { PropsWithChildren, useEffect, useState } from "react";
import { RequestFailed } from "../../components/RequestFailed";
import { cleanPhoneNumber, isValidIntlPhoneNumber } from "../../utlis/helpers";
import { countriesData } from "../../constants";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SEND_OTP } from "../../common/graphql/typed-mutations";
import { Check, ChevronsUpDown, Loader2 } from "lucide-react";
import { cn } from "../../lib/utils";
import { CheckIcon } from "@chakra-ui/icons";
import { gql } from "../../__generated__";
import { ProAccountType } from "../../__generated__/graphql";
import CertificateIcon from "../../assets/certificate-icon.png";

const GET_PRO_ACCOUNT_TYPE = gql(`
	query GetProAccountType(
		$phoneNumber: String!
	) {
		getProAccountType(phoneNumber: $phoneNumber)
	}
`);

const VERIFY_AND_LINK_USER_TO_PRO = gql(`
	mutation VerifyAndLinkUserToPro(
		$phoneNumber: String!
		$code: String!
	) {
		verifyAndLinkUserToWhatsappGeneratedPro(
			phoneNumber: $phoneNumber
			code: $code
		) {
			id
		}
	}
`);

const Layout = ({ children }: PropsWithChildren) => {
	return (
		<div className="overflow-y-scroll grid grid-cols-1 lg:grid-cols-2">
			<div className="hidden lg:block py-8">
				<div className="mb-8 bg-[#EDEDED] w-[72px] h-[72px] rounded-full flex items-center justify-center p-6">
					<img src={CertificateIcon} alt="Certificate Icon" />
				</div>
				<div className="flex flex-col w-[70%]">
					<h2 className="mb-2 text-xl lg:text-2xl font-medium text-[#121212] font-[AeonikPro]">
						Contact Information
					</h2>
					<p className="text-lg lg:text-xl text-[#464646]">
						Provide your WhatsApp number to continue.
					</p>
				</div>
			</div>
			<div className="border-l-[0] lg:border-l-[1px] border-[#E7E7E7] lg:pl-12 py-8 flex flex-col gap-6">
				{children}
			</div>
		</div>
	);
};

export interface ExistingPhoneNumberProps {
	onProceed: () => void;
	setIsValid: (isValid: boolean) => void;
	initialPhoneNumber?: string;
	addPhoneNumber: (phoneNumber: string) => void;
}

export const ExistingPhoneNumber = ({
	onProceed,
	setIsValid,
	initialPhoneNumber,
	addPhoneNumber,
}: ExistingPhoneNumberProps) => {
	const [open, setOpen] = useState(false);

	const [sendCodeConfirmationOpen, setSendCodeConfirmationOpen] =
		useState(false);

	const [getProAccountType, getProAccountTypeHandle] = useLazyQuery(
		GET_PRO_ACCOUNT_TYPE,
		{
			onCompleted: (data) => {
				if (!data.getProAccountType) {
					handleProceed();
					return;
				}

				if (data.getProAccountType === ProAccountType.WhatsappGenerated) {
					setSendCodeConfirmationOpen(true);
					return;
				}
			},
			fetchPolicy: "network-only",
		}
	);

	const [sendOneTimePasscode, sendOneTimePasscodeHandle] = useMutation(
		SEND_OTP,
		{
			onCompleted: (data) => {
				setSendCodeConfirmationOpen(false);
				setIsSent(true);
			},
		}
	);

	const [verifyAndLinkUserToPro, verifyAndLinkUserToProHandle] = useMutation(
		VERIFY_AND_LINK_USER_TO_PRO,
		{
			onCompleted: () => {
				handleProceed();
			},
			refetchQueries: ["GetMyProfile"],
			awaitRefetchQueries: true,
		}
	);

	const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || "");

	const [isSent, setIsSent] = useState(false);

	const [selectedCountry, setSelectedCountry] = useState<
		(typeof countriesData)[number] | undefined
	>(
		countriesData.find(
			(country) => country.shortName === "NG"
		) as (typeof countriesData)[number]
	);

	const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPhoneNumber(e.target.value);
		setIsValid(false);
	};

	const cleaned = cleanPhoneNumber(phoneNumber);

	const combinedPhoneNumber = `+${
		selectedCountry?.dialingCode
	}${cleanPhoneNumber(phoneNumber)}`;

	const isValidPhoneNumber = isValidIntlPhoneNumber(combinedPhoneNumber);

	useEffect(() => {
		if (isValidPhoneNumber && cleaned) {
			setPhoneNumber(cleaned);
		}
	}, [isValidPhoneNumber, phoneNumber, cleaned]);

	const handleProceed = () => {
		addPhoneNumber(combinedPhoneNumber);
		onProceed();
		setIsValid(true);
	};

	const handleContinue = () => {
		getProAccountType({
			variables: {
				phoneNumber: combinedPhoneNumber,
			},
		});
	};

	const handleSendCode = () => {
		sendOneTimePasscode({
			variables: {
				phoneNumber: combinedPhoneNumber,
			},
		});
	};

	const handleVerifyCode = (oneTimePasscode: string) => {
		verifyAndLinkUserToPro({
			variables: {
				phoneNumber: combinedPhoneNumber,
				code: oneTimePasscode,
			},
		});
	};

	if (
		getProAccountTypeHandle.data?.getProAccountType ===
		ProAccountType.FullyProvisioned
	) {
		return (
			<RequestFailed text="A user already exists. Please log out and sign in with that account to continue" />
		);
	}

	if (sendOneTimePasscodeHandle.error) {
		return (
			<RequestFailed
				text={sendOneTimePasscodeHandle.error.graphQLErrors[0]?.message}
				onRetry={sendOneTimePasscodeHandle.reset}
			/>
		);
	}

	if (verifyAndLinkUserToProHandle.error) {
		return (
			<RequestFailed
				text={verifyAndLinkUserToProHandle.error.graphQLErrors[0]?.message}
				onRetry={verifyAndLinkUserToProHandle.reset}
			/>
		);
	}

	if (isSent) {
		return (
			<Layout>
				<div className="flex flex-col gap-4">
					<FormControl>
						<FormLabel>Please enter the OTP</FormLabel>

						<HStack>
							<PinInput
								isDisabled={verifyAndLinkUserToProHandle.loading}
								onComplete={(value) => {
									handleVerifyCode(value);
								}}
								otp
							>
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
							</PinInput>
							{verifyAndLinkUserToProHandle.loading && (
								<Loader2 className="animate-spin" />
							)}
						</HStack>

						<FormHelperText>Enter a valid OTP. e.g. 123456</FormHelperText>
					</FormControl>

					<div className="flex justify-end">
						<Button
							colorScheme="brand"
							variant="link"
							onClick={() => {
								handleSendCode();
							}}
							isLoading={sendOneTimePasscodeHandle.loading}
							loadingText={"Resending..."}
						>
							Resend code via WhatsApp
						</Button>
					</div>
				</div>
			</Layout>
		);
	}

	return (
		<Layout>
			<Modal
				isOpen={sendCodeConfirmationOpen}
				onClose={() => {
					setSendCodeConfirmationOpen(false);
				}}
			>
				<ModalOverlay />
				<ModalContent rounded="none">
					<ModalHeader>Confirmation</ModalHeader>
					<ModalBody>
						<p>
							You are about to send a verification code to the number{" "}
							<strong>{combinedPhoneNumber}</strong> on WhatsApp. Do you want to
							proceed?
						</p>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="ghost"
							colorScheme="red"
							onClick={() => {
								setSendCodeConfirmationOpen(false);
							}}
							isLoading={sendOneTimePasscodeHandle.loading}
						>
							Cancel
						</Button>
						<Button
							colorScheme="green"
							onClick={() => {
								handleSendCode();
							}}
							isLoading={sendOneTimePasscodeHandle.loading}
						>
							Yes, Send
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<div className="flex flex-col gap-4">
				<FormControl isInvalid={!isValidPhoneNumber}>
					<FormLabel>Phone number (Whatsapp Preferred)</FormLabel>

					<div className="flex items-center gap-0">
						<Popover open={open} onOpenChange={setOpen}>
							<PopoverTrigger asChild>
								<SButton
									variant="outline"
									role="combobox"
									aria-expanded={open}
									className="justify-between rounded-none border border-gray-200 px-2 lg:px-4 py-5 lg:py-8"
								>
									{selectedCountry
										? `${selectedCountry.shortName} (+${selectedCountry.dialingCode})`
										: "Select Country..."}
									<ChevronsUpDown className="ml-1 h-4 w-4 shrink-0 opacity-50" />
								</SButton>
							</PopoverTrigger>
							<PopoverContent className="w-[200px] p-0">
								<Command>
									<CommandInput placeholder="Choose Country" />
									<CommandList>
										<CommandEmpty>No country found.</CommandEmpty>
										<CommandGroup>
											{countriesData
												.filter(
													(
														country
													): country is (typeof countriesData)[number] & {
														shortName: string;
													} => !!country.shortName
												)
												.map((country) => {
													const { key, name, dialingCode, nameAndDialingCode } =
														country;

													return (
														<CommandItem
															key={key}
															value={nameAndDialingCode}
															onSelect={() => {
																setSelectedCountry(country);
																setOpen(false);
															}}
														>
															<Check
																className={cn(
																	"mr-2 h-4 w-4",
																	selectedCountry?.dialingCode === dialingCode
																		? "opacity-100"
																		: "opacity-0"
																)}
															/>
															{name} (+{dialingCode})
														</CommandItem>
													);
												})}
										</CommandGroup>
									</CommandList>
								</Command>
							</PopoverContent>
						</Popover>
						<InputGroup>
							<Input
								placeholder="8034567890"
								type="tel"
								value={phoneNumber}
								onChange={handlePhoneNumberChange}
								rounded={0}
								py={{
									base: 5,
									lg: 8,
								}}
								px={{
									base: 3,
									lg: 5,
								}}
								border={"1px solid #D7D7D7"}
							/>
							{isValidPhoneNumber && (
								<InputRightElement
									py={{
										base: 5,
										lg: 8,
									}}
									px={{
										base: 3,
										lg: 5,
									}}
								>
									<CheckIcon color="green.500" />
								</InputRightElement>
							)}
						</InputGroup>
					</div>

					{!isValidPhoneNumber && (
						<FormErrorMessage>
							Invalid phone number. Please ensure the number is 10 digits long,
							e.g., 8123456789.
						</FormErrorMessage>
					)}
					<FormHelperText>
						NOTE: If you've used our WhatsApp service before, please enter the
						same number.
					</FormHelperText>
				</FormControl>
				<div className="flex justify-end">
					<Button
						isDisabled={!isValidPhoneNumber}
						colorScheme="brand"
						onClick={handleContinue}
						isLoading={getProAccountTypeHandle.loading}
						loadingText="Checking..."
					>
						Continue
					</Button>
				</div>
			</div>
		</Layout>
	);
};
