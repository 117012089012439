import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
} from "@chakra-ui/react";
import { Check, Trash2 } from "lucide-react";
import { PropsWithChildren } from "react";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { format, isBefore } from "date-fns";
import { PortfolioCertification } from "../../types";

const EDIT_CERTIFICATION = gql(`
  mutation EditPortfolioCertification(
    $id: String!
    $name: String
    $issuedBy: String
    $issuerCertificationId: String
    $issuedAt: DateTimeISO
    $url: String
  ) {
    editPortfolioCertification(
      id: $id
      name: $name
      issuedBy: $issuedBy
      issuerCertificationId: $issuerCertificationId
      issuedAt: $issuedAt
      url: $url
    ) {
      id
      name
      issuedBy
      issuerCertificationId
      issuedAt
      url
    }
  }
`);

const REMOVE_CERTIFICATION = gql(`
  mutation RemovePortfolioCertification($id: String!) {
    removePortfolioCertification(id: $id) {
      id
    }
  }
`);

interface CertificationEditorProps {
	onClose: () => void;
	item: PortfolioCertification & { id: string };
}

const formSchema = z.object({
	name: z.string().min(3),
	issuedBy: z.string().min(3),
	issuerCertificationId: z.string().min(3).optional(),
	issuedAt: z.string().refine((value) => {
		if (!value) return true;

		const date = new Date(value);

		return isBefore(date, new Date());
	}, "Date must be today or in the past"),
});

export const CertificationEditor = ({
	onClose,
	item,
}: PropsWithChildren<CertificationEditorProps>) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: item.name,
			issuedBy: item.issuedBy,
			...(item.issuerCertificationId && {
				issuerCertificationId: item.issuerCertificationId,
			}),
			issuedAt: format(new Date(item.issuedAt), "yyyy-MM-dd"),
		},
	});

	const [editCertification, editCertificationHandle] =
		useMutation(EDIT_CERTIFICATION);

	const [removeCertification, removeCertificationHandle] = useMutation(
		REMOVE_CERTIFICATION,
		{
			refetchQueries: ["MyPortfolio"],
			awaitRefetchQueries: true,
		}
	);

	const loading =
		editCertificationHandle.loading || removeCertificationHandle.loading;

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		editCertification({
			variables: {
				id: item.id,
				name: data.name,
				issuedBy: data.issuedBy,
				...(data.issuerCertificationId && {
					issuerCertificationId: data.issuerCertificationId,
				}),
				issuedAt: new Date(data.issuedAt),
			},
		});
	};

	const handleRemove = () => {
		removeCertification({
			variables: {
				id: item.id,
			},
		});
	};

	return (
		<div className="flex flex-col py-4 gap-4 w-full">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-4">
						<FormControl isInvalid={!!errors.name} isRequired>
							<FormLabel htmlFor="name">Name</FormLabel>
							<Input
								id="name"
								placeholder="Example: Health and Safety Certification"
								{...register("name")}
								variant="outline"
							/>
							<FormErrorMessage>{errors.name?.message}</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={!!errors.issuerCertificationId}>
							<FormLabel htmlFor="issuedBy">Certification ID</FormLabel>
							<Input
								id="issuerCertificationId"
								placeholder="Example: 101223456"
								{...register("issuerCertificationId")}
								variant="outline"
							/>
							<FormErrorMessage>
								{errors.issuerCertificationId?.message}
							</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={!!errors.issuedBy} isRequired>
							<FormLabel htmlFor="issuedBy">Issuer</FormLabel>
							<Input
								id="issuer"
								placeholder="UK ECITB"
								{...register("issuedBy")}
								variant="outline"
							/>
							<FormErrorMessage>{errors.issuedBy?.message}</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={!!errors.issuedAt} isRequired>
							<FormLabel htmlFor="issuedAt">Date of Issue</FormLabel>
							<Input
								id="issuedAt"
								{...register("issuedAt")}
								type="date"
								variant="outline"
							/>
							<FormErrorMessage>{errors.issuedAt?.message}</FormErrorMessage>
						</FormControl>
					</div>

					<div className="flex justify-between">
						<Button
							colorScheme="red"
							variant="ghost"
							mr={3}
							onClick={handleRemove}
							rightIcon={<Trash2 className="w-5 h-5" />}
							isLoading={loading}
						>
							Remove
						</Button>
						<div>
							<Button
								variant="ghost"
								mr={3}
								onClick={onClose}
								isLoading={loading}
							>
								Close
							</Button>
							<Button
								type="submit"
								colorScheme="green"
								rightIcon={<Check className="w-5 h-5" />}
								isLoading={loading}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};
