import { FormControl, FormErrorMessage, Input, Select } from "@chakra-ui/react";
import { UseFormRegister } from "react-hook-form";
import CertificateIcon from "../assets/certificate-icon.png";
import { Category, Gender } from "src/types";

type PersonalInfo = {
	firstName: string;
	lastName: string;
	dateOfBirth: string;
	gender: Gender;
	categoryId: string;
	otherCategoryText?: string | undefined;
};

type PersonalInformationFormProps = {
	categories: Category[];
	registerPersonalInfo: UseFormRegister<PersonalInfo>;
	touchedFields: Partial<
		Readonly<{
			firstName?: boolean | undefined;
			lastName?: boolean | undefined;
			dateOfBirth?: boolean | undefined;
			gender?: boolean | undefined;
			categoryId?: boolean | undefined;
			otherCategoryText?: boolean | undefined;
		}>
	>;
	formData: PersonalInfo;
};

export default function PersonalInformationForm({
	categories,
	registerPersonalInfo,
	touchedFields,
	formData,
}: PersonalInformationFormProps) {
	const selectedCategory = formData.categoryId;

	return (
		<div className="overflow-y-scroll grid grid-cols-1 lg:grid-cols-2">
			<div className="hidden lg:block py-8">
				<div className="mb-8 bg-[#EDEDED] w-[72px] h-[72px] rounded-full flex items-center justify-center p-6">
					<img src={CertificateIcon} alt="Certificate Icon" />
				</div>
				<div className="flex flex-col w-[70%]">
					<h2 className="mb-2 text-xl lg:text-2xl font-medium text-[#121212] font-[AeonikPro]">
						Personal Information
					</h2>
					<p className="text-lg lg:text-xl text-[#464646]">
						Provide your personal details in the required fields.
					</p>
				</div>
			</div>
			<div className="border-l-[0] lg:border-l-[1px] border-[#E7E7E7] lg:pl-12 py-8 flex flex-col gap-6">
				<FormControl
					isInvalid={
						!!(
							touchedFields.firstName &&
							(!formData.firstName || formData.firstName.includes(" "))
						)
					}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="firstName"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							First Name
						</label>
						<div className="flex flex-col gap-1">
							<Input
								{...registerPersonalInfo("firstName")}
								type="text"
								id="firstName"
								placeholder="Enter your first name"
								rounded={0}
								py={{
									base: 5,
									lg: 8,
								}}
								px={{
									base: 3,
									lg: 5,
								}}
								border={"1px solid #D7D7D7"}
							/>
							<FormErrorMessage>
								{formData.firstName
									? "First name cannot contain spaces"
									: "Please enter your first name"}
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl
					isInvalid={
						!!(
							touchedFields.lastName &&
							(!formData.lastName || formData.lastName.includes(" "))
						)
					}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="lastName"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							Last Name
						</label>
						<div className="flex flex-col gap-1">
							<Input
								{...registerPersonalInfo("lastName")}
								type="text"
								id="lastName"
								placeholder="Enter your last name"
								rounded={0}
								py={{
									base: 5,
									lg: 8,
								}}
								px={{
									base: 3,
									lg: 5,
								}}
								border={"1px solid #D7D7D7"}
							/>
							<FormErrorMessage>
								{formData.lastName
									? "Last name cannot contain spaces"
									: "Please enter your last name"}
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl
					isInvalid={!!(touchedFields.dateOfBirth && !formData.dateOfBirth)}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="dateOfBirth"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							Date of Birth
						</label>
						<div className="flex flex-col gap-1">
							<Input
								{...registerPersonalInfo("dateOfBirth")}
								type="date"
								id="dateOfBirth"
								max={new Date().toISOString().split("T")[0]}
								placeholder="Enter your date of birth"
								rounded={0}
								py={{
									base: 5,
									lg: 8,
								}}
								px={{
									base: 3,
									lg: 5,
								}}
								border={"1px solid #D7D7D7"}
							/>
							<FormErrorMessage>
								Please enter your date of birth
							</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl isInvalid={!!(touchedFields.gender && !formData.gender)}>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="gender"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							Gender
						</label>
						<div className="flex flex-col gap-1">
							<Select
								{...registerPersonalInfo("gender")}
								rounded={0}
								size={{
									base: "md",
									lg: "lg",
								}}
								h={{
									base: 10,
									lg: 16,
								}}
							>
								<option value={""}>Select Option</option>
								<option value={Gender.MALE}>Male</option>
								<option value={Gender.FEMALE}>Female</option>
							</Select>
							<FormErrorMessage>Please select your gender</FormErrorMessage>
						</div>
					</div>
				</FormControl>
				<FormControl
					isInvalid={!!(touchedFields.categoryId && !formData.categoryId)}
				>
					<div className="flex flex-col gap-4">
						<label
							htmlFor="categoryId"
							className="text-md lg:text-xl text-[#212121] font-medium"
						>
							Profession
						</label>
						<div className="flex flex-col gap-1">
							<Select
								{...registerPersonalInfo("categoryId")}
								rounded={0}
								size={{
									base: "md",
									lg: "lg",
								}}
								h={{
									base: 10,
									lg: 16,
								}}
							>
								<option value={""}>Select Option</option>
								{categories
									?.filter((category) => category.proTitle !== "Other")
									?.map((category) => (
										<option key={category.id} value={category.id}>
											{category.proTitle}
										</option>
									))}
								<option value={"other"}>Other</option>
							</Select>
							<FormErrorMessage>Please select your profession</FormErrorMessage>
						</div>
					</div>
				</FormControl>

				{selectedCategory === "other" && (
					<FormControl
						isInvalid={
							!!(
								touchedFields.otherCategoryText &&
								formData.categoryId === "other" &&
								!formData.otherCategoryText
							)
						}
					>
						<div className="flex flex-col gap-4">
							<label
								htmlFor="otherCategoryText"
								className="text-md lg:text-xl text-[#212121] font-medium"
							>
								Other Profession
							</label>
							<div className="flex flex-col gap-1">
								<Input
									{...registerPersonalInfo("otherCategoryText")}
									type="text"
									id="otherCategoryText"
									placeholder="Enter your profession"
									rounded={0}
									py={{
										base: 5,
										lg: 8,
									}}
									px={{
										base: 3,
										lg: 5,
									}}
									border={"1px solid #D7D7D7"}
								/>
								<FormErrorMessage>
									Please enter your profession
								</FormErrorMessage>
							</div>
						</div>
					</FormControl>
				)}
			</div>
		</div>
	);
}
