import {
	Center,
	Flex,
	HStack,
	Skeleton,
	Tag,
	Text,
	VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { RequestFailed } from "../../components/RequestFailed";
import { TradeCard } from "../../features/trades/TradeCard";
import { useCategories } from "../../hooks/useCategories";
import { useProfile } from "../../hooks/useProfile";
import { useTrades } from "../../hooks/useTrades";
import { Trade } from "../../types";
import { ProTrack } from "../../__generated__/graphql";

export const Trades = () => {
	const navigate = useNavigate();

	const {
		loading: profileLoading,
		error: profileError,
		profile,
	} = useProfile();

	const {
		loading: tradesLoading,
		error: tradesError,
		trades,
	} = useTrades(profile?.id);

	const {
		loading: categoriesLoading,
		error: categoriesError,
		categories,
	} = useCategories();

	useEffect(() => {
		if (profileLoading || !profile) return;

		if (!profile.tracks?.includes(ProTrack.Localgig)) {
			navigate("/how-it-works");
		}
	}, [profile, navigate, profileLoading]);

	const loading = profileLoading || tradesLoading || categoriesLoading;

	const error = profileError || tradesError || categoriesError;

	const { enrolledTrades, otherTrades, yourTrades, showYourTrades } =
		useMemo(() => {
			const enrolledTradeIdsSet = new Set(
				profile?.trades.map((trade) => trade.id) || []
			);

			const categoryIdsSet = new Set(profile?.categoryIds || []);

			const enrolledTrades: Trade[] = [];
			const otherTrades: Trade[] = [];
			const yourTrades: Trade[] = [];

			let yourTradeIsEnrolled = false;

			const tradesSortedByDisplayPriority = trades?.slice().sort((a, b) => {
				/**
				 * The lower display priority value is shown first.
				 */

				const aPriority = a.displayPriority || Number.MAX_SAFE_INTEGER;
				const bPriority = b.displayPriority || Number.MAX_SAFE_INTEGER;

				if (aPriority < bPriority) return -1;

				if (aPriority > bPriority) return 1;

				return 0;
			});

			tradesSortedByDisplayPriority?.forEach((trade) => {
				if (enrolledTradeIdsSet.has(trade.id)) {
					enrolledTrades.push(trade);

					if (categoryIdsSet.has(trade.category.id)) {
						yourTradeIsEnrolled = true;
					}
				} else if (categoryIdsSet.has(trade.category.id)) {
					yourTrades.push(trade);
				} else {
					otherTrades.push(trade);
				}
			});

			const allYourTradesAreEnrolled =
				yourTradeIsEnrolled && yourTrades.length === 0;

			/**
			 * Sort enrolled trades by order of trades in profile.
			 */

			enrolledTrades.sort((a, b) => {
				const aIndex = profile!.trades.findIndex(
					(profileTrade) => profileTrade.id === a.id
				);

				const bIndex = profile!.trades.findIndex(
					(profileTrade) => profileTrade.id === b.id
				);

				if (aIndex > bIndex) return -1;

				if (aIndex < bIndex) return 1;

				return 0;
			});

			return {
				enrolledTrades,
				otherTrades,
				yourTrades,
				showYourTrades: !allYourTradesAreEnrolled,
			};
		}, [trades, profile]);

	if (error) {
		return <RequestFailed text={error.message} />;
	}

	const myCategory = categories?.find(
		({ id }) => profile?.categoryIds[0] === id
	);

	return (
		<>
			<Helmet>
				<title>Trades | LaborHack Pro Dashboard</title>
			</Helmet>

			<VStack align="start" gap={12} w="full" py={4}>
				<Text fontSize="2xl" fontWeight="semibold" color="primary.500">
					Hi, {profile?.firstName}!
				</Text>
				{enrolledTrades.length && (
					<VStack align="start" w="full">
						<HStack align="start" w="full">
							<Text
								fontSize="md"
								fontWeight="semibold"
								className="!m-0"
								color="primary.500"
							>
								In Progress
							</Text>
						</HStack>
						{loading && <Skeleton height="20px" width="full" />}
						<Flex wrap="wrap" gap="2" w="full">
							{enrolledTrades?.map((trade) => (
								<TradeCard key={trade.id} trade={trade} isEnrolled />
							))}
						</Flex>
					</VStack>
				)}

				{showYourTrades && (
					<VStack align="start" w="full">
						{loading && <Skeleton height="20px" width="full" />}

						<VStack align="start" spacing={1}>
							<HStack align="start" w="full">
								<Text
									fontSize="md"
									fontWeight="semibold"
									className="!m-0"
									color="primary.500"
								>
									Trades for:
								</Text>
								<Tag colorScheme={"blue"} borderRadius="full">
									{myCategory?.proTitle}
								</Tag>
							</HStack>
							{!!yourTrades.length && (
								<Text fontSize="sm" color="primary.300" margin={0}>
									Select a Trade to start earning
								</Text>
							)}
						</VStack>
						{yourTrades.length ? (
							<Flex wrap="wrap" gap="2" w="full">
								{yourTrades?.map((trade) => (
									<TradeCard key={trade.id} trade={trade} />
								))}
							</Flex>
						) : (
							<Center w="full" padding={8}>
								<VStack maxW="sm">
									<Text fontSize="xs" textAlign="center" color="primary.500">
										Sorry, we are working on assessments for your trade. We will
										contact you when it is ready.
									</Text>
								</VStack>
							</Center>
						)}
					</VStack>
				)}

				{otherTrades.length && (
					<VStack align="start" w="full">
						{loading && <Skeleton height="20px" width="full" />}

						<VStack align="start" spacing={1}>
							<HStack align="start" w="full">
								<Text
									fontSize="md"
									fontWeight="semibold"
									className="!m-0"
									color="primary.500"
								>
									Other Trades
								</Text>
							</HStack>
							{!!otherTrades.length && (
								<Text fontSize="sm" color="primary.300" margin={0}>
									Select a Trade to start earning
								</Text>
							)}
						</VStack>
						<Flex wrap="wrap" gap="2" w="full">
							{otherTrades?.map((trade) => (
								<TradeCard key={trade.id} trade={trade} />
							))}
						</Flex>
					</VStack>
				)}
			</VStack>
		</>
	);
};
