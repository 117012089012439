import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Select,
	Stack,
} from "@chakra-ui/react";
import { IoChevronBackOutline } from "react-icons/io5";
import CertificateIcon from "../../assets/certificate-icon.png";
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { gql } from "src/__generated__";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { EducationLevel } from "src/types";

const ADD_EDUCATION = gql(`
  mutation AddPortfolioEducation($schoolName: String!, $fieldOfStudy: String!, $qualification: String!) {
  addPortfolioEducation(schoolName: $schoolName, fieldOfStudy: $fieldOfStudy, qualification: $qualification) {
    id
    schoolName
  }
}
`);

const formSchema = z.object({
	schoolName: z
		.string()
		.min(3, "School name is required and must be at least 3 characters"),
	fieldOfStudy: z
		.string()
		.min(3, "Field of study is required and must be at least 3 characters"),
	qualification: z
		.preprocess((data) => {
			return data === "" ? undefined : data;
		}, z.nativeEnum(EducationLevel))
		.refine(
			(data) => {
				return !!data;
			},
			{
				message: "Qualification is required",
				path: [""],
			}
		),
});

export const AddEducation = () => {
	const navigate = useNavigate();

	const [addEducation, addEducationHandle] = useMutation(ADD_EDUCATION, {
		onCompleted: () => {
			navigate("/my-portfolio");
		},
		refetchQueries: ["MyPortfolio"],
		awaitRefetchQueries: true,
	});

	const {
		register,

		handleSubmit,
		formState: { errors, touchedFields, isSubmitted },
	} = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		addEducation({
			variables: {
				schoolName: data.schoolName,
				fieldOfStudy: data.fieldOfStudy,
				qualification: data.qualification,
			},
		});
	};
	return (
		<>
			<Stack spacing={0} h={"90vh"}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col h-full justify-between"
				>
					<Stack
						spacing={0}
						px={{
							base: 8,
							lg: 20,
						}}
					>
						<HStack
							borderBottom={"1px"}
							borderColor={"#E7E7E7"}
							py={6}
							textColor={"#212121"}
							mb={{
								base: 4,
								lg: 8,
							}}
						>
							<Link to="/my-portfolio">
								<div className="hover:cursor-pointer flex items-center gap-3">
									<IoChevronBackOutline />
									<span className="text-base lg:text-lg">Back</span>
								</div>
							</Link>
						</HStack>

						<div className="overflow-y-scroll grid grid-cols-1 lg:grid-cols-2 lg:border-[1px] border-[#C9C9C9] lg:px-12">
							<div className="hidden lg:block py-8">
								<div className="mb-8 bg-[#EDEDED] w-[72px] h-[72px] rounded-full flex items-center justify-center p-6">
									<img src={CertificateIcon} alt="Certificate Icon" />
								</div>
								<div className="flex flex-col w-[70%]">
									<h2 className="mb-2 text-xl lg:text-2xl font-medium text-[#121212] font-[AeonikPro]">
										Upload education
									</h2>
									<p className="text-lg lg:text-xl text-[#464646]">
										Provide your details in the required fields.
									</p>
								</div>
							</div>
							<div className="border-l-[0] lg:border-l-[1px] border-[#E7E7E7] lg:pl-12 py-8 flex flex-col gap-6">
								<div className="flex flex-col gap-6 lg:gap-8">
									<FormControl
										isInvalid={
											(touchedFields.schoolName || isSubmitted) &&
											!!errors.schoolName
										}
									>
										<FormLabel htmlFor="schoolName">School Name</FormLabel>
										<Input
											id="schoolName"
											placeholder="Example: University of Lagos"
											{...register("schoolName")}
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>
											{errors.schoolName?.message}
										</FormErrorMessage>
									</FormControl>

									<FormControl
										isInvalid={
											(touchedFields.qualification || isSubmitted) &&
											!!errors.fieldOfStudy
										}
									>
										<FormLabel htmlFor="fieldOfStudy">Field of Study</FormLabel>
										<Input
											id="fieldOfStudy"
											placeholder="Example: Computer Science"
											{...register("fieldOfStudy")}
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										/>
										<FormErrorMessage>
											{errors.fieldOfStudy?.message}
										</FormErrorMessage>
									</FormControl>

									<FormControl
										isInvalid={
											(touchedFields.qualification || isSubmitted) &&
											!!errors.qualification
										}
									>
										<FormLabel htmlFor="qualification">Qualification</FormLabel>
										<Select
											id="qualification"
											{...register("qualification")}
											variant="outline"
											rounded={0}
											border={"1px solid #D7D7D7"}
										>
											<option value={""}>Select Option</option>
											<option value={EducationLevel.OND}>
												Ordinary National Diploma (OND)
											</option>
											<option value={EducationLevel.HND}>
												Higher National Diploma (HND)
											</option>
											<option value={EducationLevel.BACHELORS}>
												Bachelor's Degree (BSc)
											</option>
											<option value={EducationLevel.MASTERS}>
												Master's Degree (MSc)
											</option>
											<option value={EducationLevel.DOCTORATE}>
												Doctorate Degree (PhD)
											</option>
										</Select>
										<FormErrorMessage>
											{errors.qualification?.message}
										</FormErrorMessage>
									</FormControl>
								</div>
							</div>
						</div>
					</Stack>
					<Stack
						py={{
							base: 2,
							lg: 4,
						}}
						px={{
							base: 8,
							lg: 20,
						}}
						borderTop={"1px"}
						borderColor={"#E7E7E7"}
						alignItems={"end"}
					>
						<Button
							type="submit"
							colorScheme="brand"
							rounded={0}
							px={8}
							py={6}
							rightIcon={<FiArrowRight size={"18px"} />}
							isLoading={addEducationHandle.loading}
						>
							Submit
						</Button>
					</Stack>
				</form>
			</Stack>
		</>
	);
};
